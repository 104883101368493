/**
 * Copyright © 2025 Adnuntius AS.
 */
import * as _ from 'lodash';

import {Component, Inject, Input, OnInit} from '@angular/core';
import {MEDIA_TYPE, MediaTypeHelper} from "../../../../components/util/media-type-helper";
import {TranscodeItem} from './asset-transcode';

@Component({
  selector: 'asset-render',
  template: `
    <div *ngIf="mediaType.id === MEDIA_TYPE_CONSTANTS.html.id || mediaType.id === MEDIA_TYPE_CONSTANTS.xhtml.id">
      <div class="small" ng-class="{'htmlAssetContainer': !!asset.htmlCreativeType}"><span
          class="fa fa-file-code-o"></span>
        <span *ngIf="asset.htmlCreativeType"><span *ngIf="translation"> {{translation}}</span> HTML Creative Collection</span>
        <span *ngIf="!asset.htmlCreativeType"> HTML file</span>
      </div>
    </div>
    <div *ngIf="mediaType.id === MEDIA_TYPE_CONSTANTS.subtitle.id">
      <div class="small"><span class="fa fa-file-code-o"></span> Subtitle file</div>
    </div>
    <div *ngIf="mediaType.id === MEDIA_TYPE_CONSTANTS.js.id">
      <div class="small"><span class="fa fa-file-code-o"></span> JavaScript file</div>
    </div>
    <div *ngIf="mediaType.id === MEDIA_TYPE_CONSTANTS.css.id">
      <div class="small"><span class="fa fa-file-code-o"></span> CSS file</div>
    </div>
    <div *ngIf="mediaType.id === MEDIA_TYPE_CONSTANTS.json.id">
      {{mediaType}}
      <div class="small"><span class="fa fa-file-code-o"></span> JSON file</div>
    </div>
    <div *ngIf="mediaType.id === MEDIA_TYPE_CONSTANTS.txt.id">
      <div class="small"><span class="fa fa-file-text-o"></span> Text file</div>
    </div>
    <div *ngIf="mediaType.video">
      <ng-container *ngIf="transcodePreviewImage() as previewCdnId; else noPreview">
        <img src="{{previewCdnId}}">
      </ng-container>
      <ng-template #noPreview>
        <video src="{{asset.cdnId}}" autoplay loop muted="{{!!asset.cdnId}}"></video>
      </ng-template>
    </div>
    <div *ngIf="mediaType.id === MEDIA_TYPE_CONSTANTS.csv.id">
      <div class="small"><span class="fa fa-table"></span> CSV file</div>
    </div>
    <div *ngIf="mediaType.id === MEDIA_TYPE_CONSTANTS.excel.id">
      <div class="small"><span class="fa fa-file-excel-o"></span> Excel file</div>
    </div>
    <div *ngIf="mediaType.audio">
      <div class="small"><span class="fa fa-file-video-o"></span> Audio file</div>
    </div>
    <div *ngIf="mediaType.id === MEDIA_TYPE_CONSTANTS.zip.id">
      <div class="small"><span class="fa fa-file-code-o"></span> Zip file</div>
    </div>
    <div *ngIf="mediaType.id === MEDIA_TYPE_CONSTANTS.font.id">
      <div class="small"><span class="fa fa-font"></span> Font file</div>
    </div>
    <img *ngIf="mediaType.image" src="{{asset.cdnId}}">
    <div *ngIf="mediaType.id === MEDIA_TYPE_CONSTANTS.flash.id">
      <object classid="clsid:d27cdb6e-ae6d-11cf-96b8-444553540000" width="{{asset.width}}" height="{{asset.height}}">
        <param name="AllowScriptAccess" value="never"/>
        <param name="movie" value="{{asset.cdnId}}"/>
        <param name="wmode" value="transparent"/>
        <!--[if !IE]>-->
        <object type="application/x-shockwave-flash" data="{{asset.cdnId}}" width="{{asset.width}}"
                height="{{asset.height}}">
          <param name="AllowScriptAccess" value="never"/>
          <param name="movie" value="{{asset.cdnId}}"/>
          <param name="wmode" value="transparent"/>
          <!--<![endif]-->
          <p class="small">{{asset.fileName}}</p>
          <!--[if !IE]>-->
        </object>
        <!--<![endif]-->
      </object>
    </div>
  `
})
export class AssetRenderComponent implements OnInit {
  readonly MEDIA_TYPE_CONSTANTS: any = MEDIA_TYPE;

  @Input() asset: any;
  mediaType: any;
  translation: string;

  constructor(@Inject('$translate') private translate) {
  }

  ngOnInit(): void {
    const mimeTypes = _.get(this.asset, 'mimeType') || _.get(this.asset, 'creativeMimeType');
    this.mediaType = MediaTypeHelper.getMediaType(mimeTypes) || {};
    if (this.asset) {
      this.asset.cdnId = _.get(this.asset, 'cdnId') || _.get(this.asset, 'creativeCdnId') || _.get(this.asset, 'imageUrl');
    }

    if (this.asset.htmlCreativeType) {
      const key = 'creative.htmlCreative.' + this.asset.htmlCreativeType;
      const translation = this.translate.instant(key);
      this.translation = key === translation ? "" : translation;
    }
  }

  transcodePreviewImage(): string {
    return Object.values(this.asset.transcode as {[key: string]: TranscodeItem})
        .find(value => value.mimeType === 'image/jpeg')?.cdnId;
  }

}
