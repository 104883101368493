/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {Uuid} from "../../components/util/uuid";
import standardFormModule from '../common/standard-form-module';
import adnAuthService from '../../components/auth/auth';
import {UrlParamsEncoder} from "../../components/util/encode-as-url-params";
import {AdServerConfig} from "../../components/api/api";
import {AdTagReplacers} from "../ad-unit/ad-tag-replacers";

const MODULE_NAME = 'ad-tag-controller';

angular.module(MODULE_NAME, [standardFormModule, adnAuthService])

  .controller('AdTagCtrl', function($window, $location, AuthService, LocalNetworkProfile, searchResource, $timeout, $stateParams, $document) {
    const ctrl = this;
    const ogHtml = '<!DOCTYPE html>\n<html lang="en">\n<head>\n<meta charset="utf-8">\n<title>Adnuntius Ad Tag Generator and Tester</title>\n</head>\n<body><h3>Adnuntius Ad Tag Generator and Tester</h3>\n<!--DIVS-->\n<!--JS-->\n</body>\n</html>';
    const ogJs = "\x3Cscript src=\"https://cdn.adnuntius.com/adn.js\" async>\x3C/script>\n\x3Cscript>\nwindow.adn = window.adn || {}; adn.calls = adn.calls || [];\nadn.calls.push(function() {/*PREREQUEST*/\n   adn.request({ /*OUTSIDE*/ adUnits: [\n      /*ADUNITS*/\n   ]});\n});\n\x3C/script>";
    const ogEmail = "<a href=\"https://delivery.adnuntius.com/c?network=[[TAG_ID]]&auId=[[AD_UNIT]]&autor=true&iid=[UNIQUE_IDENTIFIER]\">\n    <img src=\"https://delivery.adnuntius.com/i?network=[[TAG_ID]]&auId=[[AD_UNIT]][[TARGETING]]&format=image&iid=[UNIQUE_IDENTIFIER]\"[[AD_UNIT_WIDTH]][[AD_UNIT_HEIGHT]] />\n</a>\n";
    const ogVast = "https://delivery.adnuntius.com/i?network=[[TAG_ID]]&auId=[[AD_UNIT]][[TARGETING]]&tt=[[VAST_VERSION]]";
    const ogNative = "https://delivery.adnuntius.com/i?network=[[TAG_ID]]&auId=[[AD_UNIT]][[TARGETING]]&tt=native";
    const networkTagId = LocalNetworkProfile.get('tagId');

    ctrl.idType = "CUSTOM";
    ctrl.defaultMailchimp = "*|CAMPAIGN_UID|**|UNIQID|*";

    let defaultAdTag = {
      auId: $stateParams.auId || '0000000000000001',
      auW: $stateParams.auW || undefined,
      auH: $stateParams.auH || undefined,
      container: '',
      load: '',
      proximity: 0
    };
    if ($stateParams.auName) {
      defaultAdTag.searchAu = {
        adUnitTag: $stateParams.auId,
        name: $stateParams.auName
      };
    }

    let replacer = {
      auId: 'auId: \'%auId%\'',
      auW: 'auW: \'%auW%\'',
      auH: 'auH: \'%auH%\'',
      targetId: 'targetId: \'%targetId%\'',
      container: 'container: \'%container%\'',
      requestParams: 'requestParams: { load: \'%load%\', proximity: %proximity% }',
      kvs: 'kv: %kvs%',
      dimensions: 'dimensions: %dimensions%',
      cs: 'c: %cs%',
      ks: 'keywords: %ks%',
      auml: 'auml: %auml%'
    };

    let splitStringsReplacer = function(splitStringArray, justTheValue) {
      return _.map(_.filter(_.map(splitStringArray, function(v) {
        return _.trim(v);
      }), function(v) {
        return _.isString(v) && v.length > 0;
      }), function(v) {
        return justTheValue ? v : "'" + v + "'";
      });
    };

    let valueReplacement = function(commaData, justTheValue) {
      let splitStrings = commaData.split(',');
      let mappedValues = splitStringsReplacer(splitStrings, justTheValue);
      return justTheValue ? mappedValues : "[" + mappedValues.join(', ') + "]";
    };

    let auTagCommentGen = function(adTag) {
      const theTarget = adTag.targetId ? adTag.targetId : "adn-" + adTag.auId;
      const adTagName = _.get(adTag, ['searchAu', 'name']);
      const commentTagContents = adTagName ? adTagName + " (" + adTag.auId + ")" : adTag.auId;
      return {
        target: theTarget,
        comment: commentTagContents
      };
    };

    ctrl.container = 'iframe';
    ctrl.load = 'direct';
    ctrl.cookies = 'allowAll';
    ctrl.proximity = 0;
    ctrl.whitelabelDomain = AdTagReplacers.getNetworkWhitelabelDomain(LocalNetworkProfile.getNetworkTag());
    ctrl.whitelabel = !!(ctrl.whitelabelDomain);

    const collectKvs = function(adTag, func) {
      let kvObj = [];
      _.forEach(adTag.kvs, function(entry) {
        if (_.isString(entry.k) && entry.k.length > 0 && _.isString(entry.v) && entry.v.length > 0) {
          if (_.isFunction(func)) {
            func(kvObj, entry.k, entry.v);
          } else {
            let values = valueReplacement(entry.v);
            kvObj.push("'" + entry.k + "': " + values);
          }
        }
      });
      return kvObj;
    };

    const collectDimensions = function(adTag) {
      let dims = [];
      _.forEach(adTag.dimensions, function(entry) {
        if (_.isFinite(entry[0]) && _.isFinite(entry[1])) {
          dims.push("[" + entry[0] + "," + entry[1] + "]");
        }
      });
      return dims;
    };

    let doReplacement = function() {
      let adUnitStrings = [],
        divsReplacement = [],
        simpleDivsReplacement = [];

      _.forEach(ctrl.adTags, function(tag, index) {
        _.forEach(ctrl.adTags, function(at, subIndex) {
          if (index !== subIndex && at.auId === tag.auId && at.targetId === tag.targetId) {
            at.targetId = "adn-id-" + Uuid.generate();
          }
        });
      });

      _.forEach(ctrl.adTags, function(adTag) {
        let adUnits = [];
        _.forEach(replacer, function(value, key) {
            if (adTag[key] || key === 'requestParams') {
              if (key === 'cs' || key === 'auml' || key === 'ks') {
                let cReplacement = valueReplacement(adTag[key]);
                adUnits.push(value.replace('%' + key + '%', cReplacement));
              } else if (key === 'dimensions') {
                const dimsObj = collectDimensions(adTag);
                if (dimsObj.length > 0) {
                  adUnits.push(value.replace('%' + key + '%', "[" + dimsObj.join(", ") + "]"));
                }
              } else if (key === 'kvs') {
                const kvObj = collectKvs(adTag);
                if (kvObj.length > 0) {
                  adUnits.push(value.replace('%' + key + '%', "{ " + kvObj.join(", ") + " }"));
                }
              } else if (key === 'requestParams') {
                if (adTag.load === 'direct') {
                  adUnits.push("requestParams: { load: 'direct' }");
                } else if (adTag.load && _.isFinite(adTag.proximity)) {
                  adUnits.push(value.replace('%load%', adTag.load).replace('%proximity%', adTag.proximity));
                }
              } else if (key === 'container') {
                if (adTag[key] === 'isolate') {
                  adUnits.push("isolateFrame: true");
                  if (ctrl.container === 'div') {
                    adUnits.push("container: 'iframe'");
                  }
                } else {
                  adUnits.push(value.replace('%' + key + '%', adTag[key]));
                  if (ctrl.container === 'isolate') {
                    adUnits.push("isolateFrame: false");
                  }
                }
              } else {
                adUnits.push(value.replace('%' + key + '%', adTag[key]));
              }
            }
          }
        );
        adUnitStrings.push("{ " + adUnits.join(", ") + " }");
        if (adTag.auId) {
          const tagData = auTagCommentGen(adTag);
          simpleDivsReplacement.push("<!-- " + tagData.comment + " -->\n<div id=\"" + tagData.target + "\" style=\"display: none\"></div>");
          divsReplacement.push("<hr>\n<p><small>Ad request: auId = " + adTag.auId + (adTag.targetId ? " and targetId = " + adTag.targetId : "") + "</small></p>\n<div id=\"" + (adTag.targetId ? adTag.targetId : "adn-" + adTag.auId) + "\" style=\"display: none\"></div>");
        }
      });

      let outsideStrings = [];
      if (AdServerConfig.obtain().getEnv() !== "production") {
        outsideStrings.push("env: '" + AdServerConfig.obtain().getEnv() + "'");
      }
      if (ctrl.cookies === 'blockAll' || ctrl.cookies === 'blockCookies') {
        outsideStrings.push("useCookies: false");
      }
      if (ctrl.container === 'div') {
        outsideStrings.push("container: 'div'");
      }
      if (ctrl.container === 'isolate') {
        outsideStrings.push("isolateFrame: true");
      }
      if (ctrl.load !== 'direct' && _.isFinite(ctrl.proximity)) {
        outsideStrings.push("requestParams: { load: '" + ctrl.load + "', proximity: " + ctrl.proximity + " }");
      }
      if (ctrl.segments) {
        let segmentValues = valueReplacement(ctrl.segments);
        outsideStrings.push("segments: " + segmentValues);
      }
      let prerequestReplacement = "";
      if (ctrl.cookies === 'blockAll') {
        prerequestReplacement = "adn.useLocalStorage(false);";
      }
      prerequestReplacement = prerequestReplacement ? "\n   " + prerequestReplacement : prerequestReplacement;
      const outsideReplacement = outsideStrings.length > 0 ? outsideStrings.join(", ") + "," : "";
      const basicAdCode = ogJs.replace("/*ADUNITS*/", adUnitStrings.join(",\n      ")).replace("/*OUTSIDE*/", outsideReplacement).replace("/*PREREQUEST*/", prerequestReplacement);
      ctrl.adnCode = simpleDivsReplacement.join("\n") + "\n" + basicAdCode;
      ctrl.htmlCode = ogHtml.replace("<!--JS-->", basicAdCode).replace("<!--DIVS-->", divsReplacement.join("\n"));

      ctrl.emailCode = "";
      _.forEach(ctrl.adTags, function(au) {
        let adUnitString = ogEmail.replace(/\[\[TAG_ID]]/g, networkTagId).replace(/\[\[AD_UNIT]]/g, au.auId);
        let widthReplacer = au.auW ? (" width=\"" + au.auW + "\"") : "";
        let heightReplacer = au.auH ? (" height=\"" + au.auH + "\"") : "";
        adUnitString = adUnitString.replace("[[AD_UNIT_WIDTH]]", widthReplacer).replace("[[AD_UNIT_HEIGHT]]", heightReplacer);

        if (ctrl.idType === 'MAILCHIMP') {
          adUnitString = adUnitString.replace(/\[UNIQUE_IDENTIFIER]/g, ctrl.defaultMailchimp);
        } else if (ctrl.uniqueId) {
          adUnitString = adUnitString.replace(/\[UNIQUE_IDENTIFIER]/g, ctrl.uniqueId);
        }

        const kvObj = collectKvs(au, function(collector, k, v) {
          const theObj = {};
          theObj[k] = valueReplacement(v, true);
          collector.push(theObj);
        });

        const params = {};
        if (kvObj.length > 0) {
          params.kv = JSON.stringify(kvObj);
        }
        if (au.cs) {
          params.c = valueReplacement(au.cs, true);
        }
        if (au.auml) {
          params.auml = valueReplacement(au.auml, true);
        }

        const targetingReplacements = _.isEmpty(params) ? "" : UrlParamsEncoder.encode(params, true);
        adUnitString = adUnitString.replace('[[TARGETING]]', targetingReplacements);

        const tagData = auTagCommentGen(au);
        ctrl.emailCode = ctrl.emailCode + ("<!-- " + tagData.comment + " -->") + "\n" + adUnitString;

        au.vast2 = ogVast.replace(/\[\[TAG_ID]]/g, networkTagId).replace(/\[\[AD_UNIT]]/g, au.auId).replace(/\[\[VAST_VERSION]]/g, "vast2").replace('[[TARGETING]]', targetingReplacements);
        au.vast3 = ogVast.replace(/\[\[TAG_ID]]/g, networkTagId).replace(/\[\[AD_UNIT]]/g, au.auId).replace(/\[\[VAST_VERSION]]/g, "vast3").replace('[[TARGETING]]', targetingReplacements);
        au.vast4 = ogVast.replace(/\[\[TAG_ID]]/g, networkTagId).replace(/\[\[AD_UNIT]]/g, au.auId).replace(/\[\[VAST_VERSION]]/g, "vast4").replace('[[TARGETING]]', targetingReplacements);
        au.vast4_1 = ogVast.replace(/\[\[TAG_ID]]/g, networkTagId).replace(/\[\[AD_UNIT]]/g, au.auId).replace(/\[\[VAST_VERSION]]/g, "vast4-1").replace('[[TARGETING]]', targetingReplacements);
        au.vast4_2 = ogVast.replace(/\[\[TAG_ID]]/g, networkTagId).replace(/\[\[AD_UNIT]]/g, au.auId).replace(/\[\[VAST_VERSION]]/g, "vast4-2").replace('[[TARGETING]]', targetingReplacements);

        au.native = ogNative.replace(/\[\[TAG_ID]]/g, networkTagId).replace(/\[\[AD_UNIT]]/g, au.auId).replace('[[TARGETING]]', targetingReplacements);

        if (ctrl.whitelabel && ctrl.whitelabelDomain) {
          au.vast2 = AdTagReplacers.replace(au.vast2, ctrl.whitelabelDomain, networkTagId);
          au.vast3 = AdTagReplacers.replace(au.vast3, ctrl.whitelabelDomain, networkTagId);
          au.vast4 = AdTagReplacers.replace(au.vast4, ctrl.whitelabelDomain, networkTagId);
          au.vast4_1 = AdTagReplacers.replace(au.vast4_1, ctrl.whitelabelDomain, networkTagId);
          au.vast4_2 = AdTagReplacers.replace(au.vast4_2, ctrl.whitelabelDomain, networkTagId);
          au.native = AdTagReplacers.replace(au.native, ctrl.whitelabelDomain, networkTagId);
        }
      });

      if (ctrl.whitelabel && ctrl.whitelabelDomain) {
        ctrl.adnCode = AdTagReplacers.replace(ctrl.adnCode, ctrl.whitelabelDomain);
        ctrl.htmlCode = AdTagReplacers.replace(ctrl.htmlCode, ctrl.whitelabelDomain);
        ctrl.emailCode = AdTagReplacers.replace(ctrl.emailCode, ctrl.whitelabelDomain);
      }

      ctrl.adnCode = AdTagReplacers.addNetworkTagId(ctrl.adnCode, networkTagId);
      ctrl.htmlCode = AdTagReplacers.addNetworkTagId(ctrl.htmlCode, networkTagId);

      const cutDownAdTags = _.map(ctrl.adTags, function(at) {
        const otherAt = angular.copy(at);
        delete otherAt.searchAu;
        return otherAt;
      });
      const url = $location.protocol() + "://" + $location.host() + ($location.host().indexOf("localhost") > -1 ? ":" + $location.port() : "");
      const value = $window.encodeURIComponent(JSON.stringify(cutDownAdTags));
      ctrl.publicLink = url + "/tag?design=" + value;
      ctrl.privateLink = url + "/ad-tag?design=" + value;
    };

    ctrl.canSearch = AuthService.isAuthenticated();

    ctrl.searchAdUnits = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "AdUnit"}).then(function(data) {
        ctrl.adUnitResults = data.searchResults;
      });
    };

    ctrl.useSearchResults = function(adTag) {
      adTag.auId = adTag.searchAu.adUnitTag;
      adTag.auW = adTag.searchAu.width;
      adTag.auH = adTag.searchAu.height;

      ctrl.changeValue();
    };

    ctrl.addAdUnit = function() {
      ctrl.adTags.push(angular.copy(defaultAdTag));
      delete defaultAdTag.searchAu;
      doReplacement();
    };
    ctrl.changeValue = function() {
      doReplacement();
    };
    ctrl.removeAdUnit = function(index) {
      _.pullAt(ctrl.adTags, index);
      doReplacement();
    };

    ctrl.addSegments = function() {
      ctrl.segments = "";
    };

    ctrl.removeSegments = function() {
      delete ctrl.segments;
    };

    ctrl.addDimensions = function(adTag) {
      adTag.dimensions = adTag.dimensions || [];
      adTag.dimensions.push([undefined, undefined]);
    };

    ctrl.removeDimensions = function(adTag, index) {
      _.pullAt(adTag.dimensions, index);
      doReplacement();
    };

    ctrl.addKv = function(adTag) {
      adTag.kvs = adTag.kvs || [];
      adTag.kvs.push({k: '', v: []});
    };

    ctrl.addC = function(adTag) {
      adTag.cs = "";
    };

    ctrl.addK = function(adTag) {
      adTag.ks = "";
    };

    ctrl.removeCs = function(adTag) {
      delete adTag.cs;
      doReplacement();
    };

    ctrl.removeKs = function(adTag) {
      delete adTag.ks;
      doReplacement();
    };

    ctrl.addAuml = function(adTag) {
      adTag.auml = "";
    };

    ctrl.removeAumls = function(adTag) {
      delete adTag.auml;
      doReplacement();
    };

    ctrl.removeKv = function(adTag, index) {
      _.pullAt(adTag.kvs, index);
      doReplacement();
    };

    ctrl.doPreview = function() {
      let previewContainer = angular.element($document[0].getElementById("previewId"));

      let existingIframe = previewContainer.find("iframe");
      if (existingIframe && existingIframe.length) {
        existingIframe.detach();
      }

      let ifr = $document[0].createElement('iframe');
      ifr.allowTransparency = true;

      ifr.setAttribute('style', 'width: 100%; min-height: 500px; border: 2px solid #ddd; padding: 5px;');
      ifr.setAttribute('width', "100%");
      ifr.setAttribute('scrolling', 'yes');
      ifr.frameBorder = '0';
      previewContainer.append(ifr);

      let iframeDoc = ifr.contentDocument || ifr.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(angular.copy(ctrl.htmlCode));
      iframeDoc.close();

      let iframeElement = angular.element(iframeDoc);
      let bodyElement = iframeElement.find("body");
      bodyElement.attr('style', 'margin: 0; padding: 0;');
    };

    let designAdTag;
    if ($stateParams.design) {
      try {
        designAdTag = JSON.parse($stateParams.design);
      } catch (e) {
        designAdTag = null;
      }
    }

    if (designAdTag) {
      ctrl.adTags = angular.copy(designAdTag);
    } else {
      ctrl.adTags = [];
      ctrl.addAdUnit();
    }

    defaultAdTag.auW = undefined;
    defaultAdTag.auH = undefined;
    defaultAdTag.auId = '0000000000000001';
    doReplacement();

    ctrl.refreshCodeMirror = function() {
      ctrl.refreshCodeMirrorVar = false;
      $timeout(function() {
        ctrl.refreshCodeMirrorVar = true;
      }, 200);
    };
    ctrl.refreshCodeMirror();
  });

export default MODULE_NAME;