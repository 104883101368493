/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';
import ngSanitize from 'angular-sanitize';
import _ from "lodash";

import template from './networks-tab.html';

import networkHelper from '../../../components/util/network-helper';
import resources from '../../../components/api/resources/resources';
import localNetworkProfile from '../../../components/session/local-network-profile';
import objectTypeParamsFilter from '../../common/filters/object-type-state-params-filter';
import messageFilter from '../../common/filters/message-params-translation-filter';
import {AUTH_EVENT} from "../../../components/auth/auth-constants";

const MODULE_NAME = 'network-tab-directive';

angular.module(MODULE_NAME, [translate, uiBootstrap, ngSanitize, resources, networkHelper, localNetworkProfile, messageFilter, objectTypeParamsFilter])
  .directive('adnNetworkTabDirective', function() {
    return {
      restrict: 'A',
      require: '^adnSidebarTabset',
      template: template,
      scope: {},
      bindToController: {
        networks: '<',
        parentMethodHook: '<'
      },
      controllerAs: 'ctrl',
      controller: function($element, $rootScope, $scope, $timeout, adnNetworkHelper, LocalNetworkProfile) {
        const ctrl = this;

        ctrl.$onInit = function() {
          ctrl.currentNetwork = _.find(ctrl.networks, ['id', LocalNetworkProfile.get('networkId')]);

          ctrl.networkSearchText = "";

          ctrl.networkSearchFilter = function(data) {
            if (ctrl.networks.length <= 15) {
              return true;
            }
            const updatedName = _.get(data, 'name', '').replaceAll(/ /g, "").replaceAll(/_/g, "").toLowerCase();
            const updatedId = _.get(data, 'id', '').replaceAll(/ /g, "").replaceAll(/_/g, "").toLowerCase();
            const updatedTagId = _.get(data, 'tagId', '').replaceAll(/ /g, "").replaceAll(/_/g, "").toLowerCase();
            const updatedNetworkSearchText = (ctrl.networkSearchText || '').replaceAll(/ /g, "").replaceAll(/_/g, "").toLowerCase();
            return updatedNetworkSearchText ? updatedName.indexOf(updatedNetworkSearchText) > -1 || updatedId.toLowerCase().indexOf(updatedNetworkSearchText) > -1 || updatedTagId.toLowerCase().indexOf(updatedNetworkSearchText) > -1 : true;
          };

          ctrl.switchNetwork = function(network) {
            adnNetworkHelper.switchNetwork(ctrl, $rootScope, network);
          };

          $rootScope.$on(AUTH_EVENT.networkChange, function(eventData, network) {
            ctrl.currentNetwork = network;
            $timeout(function() {
              $scope.$apply();
            });
          });
        };
      }
    };
  });

export default MODULE_NAME;