/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';
import Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';

more(Highcharts);
import solidGauge from 'highcharts/modules/solid-gauge';

solidGauge(Highcharts);


import highchartsNg from 'highcharts-ng';

import promiseSpinnerComponent from '../directives/html/promise-spinner-component';

import template from './speedo.html';

const MODULE_NAME = "speedo-directive";

angular.module(MODULE_NAME, [
  highchartsNg,
  translate,
  promiseSpinnerComponent
])

  .component('adnSpeedo', {
    bindings: {
      stat: '<',
      high: '<',
      low: '<'
    },
    controllerAs: 'ctrl',
    template: template,
    controller: function() {
      const ctrl = this;

      ctrl.haveStats = false;
      ctrl.chartHeight = 70;

      let createChartConfig = function() {
        return {
          chart: {
            type: 'solidgauge',
            height: ctrl.chartHeight
          },
          title: null,
          pane: {
            size: '210%',
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '110%'],
            background: {
              backgroundColor: '#ddd',
              innerRadius: '60%',
              outerRadius: '100%',
              shape: 'arc'
            }
          },
          credits: {
            enabled: false
          },
          exporting: {
            enabled: false
          },
          tooltip: {
            enabled: false
          },
          accessibility: {
            enabled: false
          },
          // the value axis
          yAxis: {
            stops: [
              [0.2, '#f48024'], // yellow
              [0.75, '#55BF3B'] // green
            ],
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            title: {
              enabled: false
            },
            labels: {
              enabled: false
            }
          },

          plotOptions: {
            solidgauge: {
              dataLabels: {
                y: 10,
                borderWidth: 0,
                useHTML: true
              }
            }
          }
        };
      };

      ctrl.$onInit = function() {
        const chartConfig = createChartConfig();

        let startStatsGet = function() {
          ctrl.statsChart = _.merge(chartConfig, {
            yAxis: {
              min: 0,
              max: 100,
            },
            series: [{
              name: 'Speed',
              data: [Math.round((ctrl.stat + Number.EPSILON) * 100) / 100],
              dataLabels: {
                format:
                  '<div style="text-align:center">' +
                  '<span style="font-size:12px">{y}%</span>' +
                  '</div>'
              },
              tooltip: {
                valueSuffix: '%'
              }
            }]
          });
          ctrl.haveStats = true;
        };
        startStatsGet();
      };
    }
  });

export default MODULE_NAME;