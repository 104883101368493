/**
 * Copyright © 2025 Adnuntius AS.
 */
import {LocalStorage} from "./local-storage";
import * as _ from "lodash";

type Pausing = {
  isAllowed: boolean;
};

export class LocalPausing {
  private static instance: LocalPausing;
  private static readonly LOCAL_PAUSING_STORAGE_KEY = 'user.pausing';
  pausing: Pausing;

  constructor() {
  }

  public static obtain(): LocalPausing {
    if (!this.instance) {
      this.instance = new LocalPausing();
    }
    this.instance.refresh();
    return this.instance;
  }

  private save() {
    LocalStorage.obtain().setItem(LocalPausing.LOCAL_PAUSING_STORAGE_KEY, this.pausing);
  }

  private refresh() {
    const localStorageInfo = LocalStorage.obtain().getItem(LocalPausing.LOCAL_PAUSING_STORAGE_KEY);
    this.pausing = _.isEmpty(localStorageInfo) ? _.isEmpty(this.pausing) ? {} : this.pausing : localStorageInfo;
  }

  destroy() {
    this.pausing = null;
    return LocalStorage.obtain().remove(LocalPausing.LOCAL_PAUSING_STORAGE_KEY);
  }

  set(value: boolean) {
    this.refresh();
    this.pausing.isAllowed = value;
    this.save();
  }

  get() {
    return this.pausing.isAllowed;
  }
}