/**
 * Copyright © 2025 Adnuntius AS.
 */
import {LocalStorage} from "./local-storage";
import * as _ from "lodash";

type DebugInfo = {
  userId: string;
  username: string;
  lastMasqueraderUsername: string;
  lastMasqUsername: string;
  lastMasqDate: Date;
  masqLast: boolean;
  signIn: Date;
  expired: Date;
};

export class LocalDebugInfo {
  private static instance: LocalDebugInfo;
  private static readonly DEBUG_INFO_KEY = 'debug';

  info: DebugInfo;

  private constructor() {
  }

  public static obtain(): LocalDebugInfo {
    if (!this.instance) {
      this.instance = new LocalDebugInfo();
    }
    this.instance.refresh();
    return this.instance;
  }

  private save() {
    LocalStorage.obtain().setItem(LocalDebugInfo.DEBUG_INFO_KEY, this.info);
  }

  private refresh() {
    const localStorageInfo = LocalStorage.obtain().getItem(LocalDebugInfo.DEBUG_INFO_KEY);
    this.info = _.isEmpty(localStorageInfo) ? _.isEmpty(this.info) ? {} : this.info : localStorageInfo;
  }

  setSignIn(userId: string, username: string, masqueraderUsername?: string, masqUsername?: string) {
    LocalDebugInfo.instance.refresh();
    this.info.userId = userId;
    this.info.username = username;
    this.info.signIn = username || userId ? new Date() : null;
    this.info.masqLast = false;
    if (masqUsername) {
      this.info.lastMasqueraderUsername = masqueraderUsername;
      this.info.lastMasqUsername = masqUsername;
      this.info.lastMasqDate = new Date();
      this.info.masqLast = true;
    }
    LocalDebugInfo.instance.save();
  }

  setInfo(key: string, val: any) {
    LocalDebugInfo.instance.refresh();
    this.info[key] = val;
    LocalDebugInfo.instance.save();
  }

  getInfo(key?: string) {
    return key ? this.info[key] : this.info;
  }
}
