/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

import template from './date-targeting-summary.html';
import {TargetingHelper} from "../targeting-helper";

const MODULE_NAME = 'date-targeting-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnTargetingDateTargetingSummary', function() {
    return {
      restrict: 'A',
      template: template,
      scope: {
        targets: '=adnTargetingDateTargetingSummary'
      },
      link: function(scope) {
        scope.data = [];
        scope.$watchCollection('targets', function(targets) {
          scope.target = targets || {};
        });
        scope.$watchCollection('target.dateRanges', function(target) {
          scope.data = [];
          _.forEach(target, function(v) {
            scope.data.push({first: v.first, second: v.second, entireDay: TargetingHelper.isEntireDay(v), comma: true});
          });
        });
      }
    };
  });

export default MODULE_NAME;