/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import 'angular-gridster';

import standardFormModule from '../common/standard-form-module';
import {adnFormHelper} from "../common/controller/form-helper";
import {LOCALES} from "../common/constants/locales";

const MODULE_NAME = 'report-translation-controller';

angular.module(MODULE_NAME, [
  'gridster',
  standardFormModule
])

  .controller('ReportTranslationCtrl', function(model, modelComms, reportTranslationDefaultsResource) {
    const ctrl = this;

    const init = function() {
      ctrl.model.locale = ctrl.model.locale || 'en';
      ctrl.model.defaultTranslation = ctrl.model.defaultTranslation || 'EN';

      if (!ctrl.translations) {
        reportTranslationDefaultsResource.get().then(function(transDefaults) {
          ctrl.translations = {};
          _.forEach(transDefaults, function(translations, key) {
            ctrl.translations[key] = {};
            _.forEach(translations, function(val, transKey) {
              const translationValue = _.get(ctrl.model, ['translations', transKey]);
              ctrl.translations[key][transKey] = {
                id: transKey,
                defaultTranslation: val,
                translation: ctrl.model.defaultTranslation.toLowerCase() === key ? translationValue || val : val,
                edited: ctrl.model.defaultTranslation.toLowerCase() === key && translationValue && translationValue !== val
              };
            });
          });
        });
      }
    };
    adnFormHelper.setUpForm(ctrl, model, {
      afterInit: init, modelComms: modelComms
    });
    ctrl.locales = LOCALES;

    ctrl.onBeforeReportTranslationSubmit = function() {
      ctrl.model.translations = {};
      _.forEach(ctrl.translations[ctrl.model.defaultTranslation.toLowerCase()], function(val, key) {
        if (val.edited && val.translation && val.translation !== val.defaultTranslation) {
          ctrl.model.translations[key] = val.translation;
        }
      });
    };
  });


export default MODULE_NAME;