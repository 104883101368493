/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

const DEFAULT_NAME = "object-type-state-params-filter";

angular.module(DEFAULT_NAME, [])

  .filter('adnObjectTypeStateParams', function() {
    return function(obj, type, params) {
      type = type || obj.objectType || obj.type;

      let stateParams = _.get(type, 'stateParams', {id: obj.id});
      return _.mapValues(stateParams, function(value) {
        if (_.isFunction(value)) {
          value = value(obj, params || {});
        }
        return value && value.charAt && value.charAt(0) === '@' ? _.get(obj, value.substr(1)) : value;
      });
    };
  });

export default DEFAULT_NAME;