/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import titleTemplate from '../base/default-title-template.html';
import editTemplate from './booking-calendar-edit.html';

import standardModule from '../common/standard-module';
import bookingCalendarController from './booking-calendar-controller';

const MODULE_NAME = 'booking-calendar-module';

angular.module(MODULE_NAME, [standardModule, bookingCalendarController])

  .config(function($stateProvider) {
    $stateProvider.state('app.booking-calendar', {
      url: '/booking-calendar',
      views: {
        'content@app': {
          template: editTemplate,
          controller: 'BookingCalendarCtrl',
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: "Booking Calendar"
      }
    });
  });

export default MODULE_NAME;