/**
 * Copyright © 2025 Adnuntius AS.
 */
import * as _ from 'lodash';
import {CHUNK_SIZE} from "../../resources-constants";
import {ApiConfig} from "../../../api";
import {Inject} from "@angular/core";

export class StatsResource {
  private readonly statsUri = ApiConfig.obtain().getVersionedUri('stats');
  private readonly statsTrafficUri = ApiConfig.obtain().getVersionedUri('stats/traffic');
  private readonly statsLocationUri = ApiConfig.obtain().getVersionedUri('stats/location');
  private readonly statsDomainUri = ApiConfig.obtain().getVersionedUri('stats/domain');
  private readonly statsTargetingUri = ApiConfig.obtain().getVersionedUri('stats/targeting');
  private readonly statsArticlesUri = ApiConfig.obtain().getVersionedUri('stats/article');
  private readonly predictTrafficUri = ApiConfig.obtain().getVersionedUri('stats/predict');
  private readonly cdnStatsUri = ApiConfig.obtain().getVersionedUri('stats/cdn');
  private readonly defaultParams = {
    showFilteredBidReasons: true,
    groupBy: CHUNK_SIZE.DAILY.key
  };

  constructor(@Inject('$http') private $http, @Inject('$q') private $q) {
  }

  private statsMeUp(url, params, cb, errCb, scope) {
    const theScope = scope || {};

    if (theScope.canceller) {
      theScope.canceller.resolve("Cancelling previous stats request");
    }

    params = _.assign({}, this.defaultParams, params);
    if (params.startDate && params.startDate.toISOString) {
      params.startDate = params.startDate.toISOString();
    }
    if (params.endDate && params.endDate.toISOString) {
      params.endDate = params.endDate.toISOString();
    }
    if (params.idKey) {
      params[params.idKey] = params.id;
    }
    if (!params.groupBy) {
      delete params.groupBy;
    } else {
      params.groupBy = params.groupBy.toUpperCase();
    }
    params.includeChunkRange = true;

    if (!params.startDate && _.get(params, [params.idKey], "").indexOf(";") > -1) {
      return (errCb || _.noop)({invalidDate: true});
    }

    const canceller = this.$q.defer();
    const options = {
      params: _.omit(params, ['id', 'idKey']),
      hasErrorHandler: _.isFunction(errCb),
      timeout: canceller.promise
    };
    if (theScope.$on) {
      theScope.$on('$stateChangeStart', function () {
        canceller.resolve("Changed page");
      });
    }
    theScope.canceller = canceller;

    theScope.isPending = true;
    return this.$http.get(url + '/', options).then(function (response) {
      theScope.isPending = false;
      return (cb || _.noop)(response.data);
    }).catch(function (response) {
      if (response.xhrStatus && response.xhrStatus.toLowerCase() === "abort") {
        return;
      }
      theScope.isPending = false;
      return (errCb || _.noop)(response);
    });
  }

  getPredict(params, cb, errCb, scope) {
    return this.statsMeUp(this.predictTrafficUri, params, cb, errCb, scope);
  }

  getCdn(params, cb, errCb, scope) {
    return this.statsMeUp(this.cdnStatsUri, params, cb, errCb, scope);
  }

  getTraffic(params, cb, errCb, scope) {
    return this.statsMeUp(this.statsTrafficUri + '/' + params.statTypeId, params, cb, errCb, scope);
  }

  getLocation(params, cb, errCb, scope) {
    return this.statsMeUp(this.statsLocationUri + '/' + params.statTypeId, params, cb, errCb, scope);
  }

  getDomain(params, cb, errCb, scope) {
    const suffix = params.statTypeId ? "/" + params.statTypeId : "";
    if (!suffix) {
      params.groupBy = 'DOMAIN_NAME';
    }
    return this.statsMeUp(this.statsDomainUri + suffix, params, cb, errCb, scope);
  }

  getTargeting(params, cb, errCb, scope) {
    return this.statsMeUp(this.statsTargetingUri, params, cb, errCb, scope);
  }

  getArticles(params, cb, errCb, scope) {
    return this.statsMeUp(this.statsArticlesUri, params, cb, errCb, scope);
  }

  get(params, cb, errCb, scope) {
    return this.statsMeUp(this.statsUri, params, cb, errCb, scope);
  }
}
