/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import _ from 'lodash';

import {OBJECT_TYPE} from "../common/constants/object-type";
import standardActionsModule from '../common/standard-actions-module';
import searchSelect from '../common/controller/search-select-component';
import creativeHelper from './creative-controller-helper';
import dr from './draggable-resizable-module';
import va from './asset/video-asset-directive';
import {Uuid} from "../../components/util/uuid";
import {LocalBulkCreativeInfo} from "../../components/session/local-bulk-creative-info";

const MODULE_NAME = "creative-upload-controller";

function lowestFactor(x, y) {
  const lowestNum = x > y ? x : y;
  for (let i = lowestNum; i > 1; i--) {
    if (x % i === 0 && y % i === 0) {
      return {x: x / i, y: y / i};
    }
  }
  return {x: x, y: y};
}

angular.module(MODULE_NAME, [
  standardActionsModule,
  searchSelect,
  va,
  dr,
  creativeHelper
])
  .controller('VideoUploadCtrl', function(Creative, CreativeHelper, Asset, adnListHelper, creative, uploadResponse, $state, $q) {
    const ctrl = this;

    let mInitPromises = [];
    let mLayoutAssetMatches = {};
    let prevAssetAssignments = {};

    ctrl.creativeFields = ['name', {param: 'Layout', label: 'layout'}, {param: 'CreativeSet', label: 'creativeSet'}, {param: '', label: 'URLs'}, {label: 'dimensions', param: 'width'}, {param: '', label: 'info'}, 'updateTime', {param: '', label: 'targeting'}];
    ctrl.doubleResolution = false;

    function ur(uploadResponses) {
      const u = uploadResponses[0];

      ctrl.asset = u.results[0];

      ctrl.layouts = _.map(u.layoutSuggestions, function(ls) {
        return ls.layout;
      });
      CreativeHelper.isolateAssetComponents(ctrl.layouts);
      if (ctrl.advertiser) {
        ctrl.model.advertiser = ctrl.advertiser;
      }
      ctrl.model.layout = u.layoutSuggestions[0].layout;
      const matched = CreativeHelper.matchConstraintsAndAssets(ctrl, mInitPromises, mLayoutAssetMatches, prevAssetAssignments, _.noop, true);
      $q.all(matched).then(function() {
        CreativeHelper.updateCreativeDims(ctrl, prevAssetAssignments, true);
        CreativeHelper.updateDefaultName(ctrl, true);
      });
    }

    ctrl.changeMode = function() {
      ctrl.mode = ctrl.mode === 'PLAIN' ? ctrl.mode = 'CROP' : 'PLAIN';
    };

    ctrl.mode = 'PLAIN';

    ctrl.videoCreatives = LocalBulkCreativeInfo.obtain().getVideoCreatives();
    adnListHelper.setUpList(ctrl, Creative, false, null, {id: _.map(ctrl.videoCreatives, 'id')});

    function init() {
      ctrl.model = creative || Creative.create(_.pick(ctrl.model, ['labels', 'advertiser']));
      ctrl.isNew = !creative;
      if (ctrl.isNew) {
        ctrl.model.objectState = 'HIDDEN';

        if ($state.previousParams.advertiser) {
          ctrl.model.advertiser = $state.previousParams.advertiser;
        }
        if ($state.previousParams.folio) {
          ctrl.model.folio = $state.previousParams.folio;
        }
        if ($state.previousParams.creativeSet) {
          ctrl.model.creativeSet = $state.previousParams.creativeSet;
        }
      }
      if (creative) {
        ur([uploadResponse]);
      }
      mInitPromises = [];
      mLayoutAssetMatches = {};
      prevAssetAssignments = {};
    }
    init();

    ctrl.isNew = true;

    ctrl.aspectRatios = [
      {width: 6, height: 5, exampleWidth: 300, exampleHeight: 250, id: 'standard', shortText: '6:5', text: '6:5 aspect ratio - 300x250'},
      {width: 1, height: 1, exampleWidth: 300, exampleHeight: 300, id: '1to1', shortText: '1:1', text: '1:1 aspect ratio - 300x300'},
      {width: 364, height: 45, exampleWidth: 728, exampleHeight: 90, id: '8to1', shortText: '364:45 (~8:1)', text: '364:45 (~8:1) aspect ratio - 728x90'},
      {width: 1, height: 1, exampleWidth: 300, exampleHeight: 300, id: 'custom', shortText: '', text: 'Custom'},
    ];

    ctrl.customChange = function() {
      if (_.get(ctrl.aspectRatio, ['id']) === 'custom') {
        const lowestFactors = lowestFactor(ctrl.aspectRatio.exampleWidth, ctrl.aspectRatio.exampleHeight);
        ctrl.aspectRatio.width = lowestFactors.x;
        ctrl.aspectRatio.height = lowestFactors.y;
        ctrl.aspectRatio.shortText = lowestFactors.x + ":" + lowestFactors.y;
      }
    };

    ctrl.crops = [];

    ctrl.updateAspectRatio = function() {
      ctrl.resizeHook.resizeAspect(ctrl.aspectRatio);
      ctrl.crops[ctrl.currentCropIndex].aspectRatio = _.cloneDeep(ctrl.aspectRatio);
    };

    ctrl.selectCrop = function(index) {
      ctrl.currentCropIndex = index;
      ctrl.resizeHook.resize(ctrl.crops[ctrl.currentCropIndex]);
      ctrl.aspectRatio = _.cloneDeep(ctrl.crops[ctrl.currentCropIndex].aspectRatio);
    };

    ctrl.numberChange = function(index) {
      ctrl.resizeHook.resize(ctrl.crops[index]);
    };

    ctrl.removeCrop = function(index) {
      _.pullAt(ctrl.crops, index);
      if (ctrl.currentCropIndex === index) {
        ctrl.currentCropIndex = 0;
      }
      if (ctrl.currentCropIndex + 1 > ctrl.crops.length) {
        ctrl.currentCropIndex = 0;
      }
    };

    ctrl.resizeHook = {
      onChange: function(pos) {
        const cropInfo = ctrl.crops[ctrl.currentCropIndex];
        _.merge(cropInfo, pos);
      },
      hasLoaded: function() {
        ctrl.resizeHook.resizeAspect(ctrl.aspectRatio);
        ctrl.resizeHook.centerX();
        ctrl.resizeHook.centerY();
      }
    };

    ctrl.maxCrops = 5;

    ctrl.addCrop = function(init) {
      if (ctrl.crops.length >= ctrl.maxCrops) {
        return;
      }
      ctrl.aspectRatio = ctrl.aspectRatios[0];
      ctrl.currentCropIndex++;
      const cropItem = {
        name: 'Video #' + (ctrl.currentCropIndex + 1),
        width: ctrl.aspectRatio.exampleWidth,
        height: ctrl.aspectRatio.exampleHeight,
        aspectRatio: _.cloneDeep(ctrl.aspectRatio),
        x: 0,
        y: 0
      };
      ctrl.crops.push(cropItem);
      if (init) {
        ctrl.resizeHook.init = cropItem;
      } else {
        ctrl.resizeHook.resize(cropItem);
        ctrl.resizeHook.resizeAspect(ctrl.aspectRatio);
      }
    };
    ctrl.currentCropIndex = -1;
    ctrl.addCrop(true);

    ctrl.centerX = function() {
      ctrl.resizeHook.centerX();
    };

    ctrl.centerY = function() {
      ctrl.resizeHook.centerY();
    };

    ctrl.selectLayout = function(layout) {
      if (layout) {
        ctrl.model.layout = layout;
      }
      ctrl.model.cToAssets = {};

      if (ctrl.model.defaultLayoutWidth === ctrl.model.width && ctrl.model.defaultLayoutHeight === ctrl.model.height) {
        ctrl.model.width = ctrl.model.layout.defaultWidth;
        ctrl.model.height = ctrl.model.layout.defaultHeight;
      }
      ctrl.model.width = ctrl.model.width || ctrl.model.layout.defaultWidth;
      ctrl.model.height = ctrl.model.height || ctrl.model.layout.defaultHeight;
      ctrl.model.defaultLayoutWidth = ctrl.model.layout.defaultWidth;
      ctrl.model.defaultLayoutHeight = ctrl.model.layout.defaultHeight;
      CreativeHelper.matchConstraintsAndAssets(ctrl, mInitPromises, mLayoutAssetMatches, prevAssetAssignments, _.noop, true);
    };

    ctrl.eventHook = {};
    ctrl.eventHook = {
      onUpdate: function(uploadResponses) {
        ur(uploadResponses);
      },
      assignAsset: function() {
        // don't need to worry about anything here
      }
    };

    ctrl.save = function() {
      _.forEach(_.keysIn(ctrl.model.cToAssets), function(t) {
        if (!ctrl.model.cToAssets[t]) {
          delete ctrl.model.cToAssets[t];
        }
      });
      CreativeHelper.doLayoutParams(ctrl);
      const transcodeValues = _.map(ctrl.crops, function(crop) {
        const transcodeConfig = {transcodeConfig: {enabled: true}};

        const newOutputConfig = _.cloneDeep(outputConfig);
        newOutputConfig.mpeg4.width = crop.width;
        newOutputConfig.mpeg4.height = crop.height;

        _.forEach([newOutputConfig.dash.dashStreamConfigs, newOutputConfig.hls.hlsStreamConfigs], function(configs) {
          _.forEach(configs, function(c) {
            c.width = crop.width;
            c.height = crop.height;
          });
        });

        transcodeConfig.transcodeConfig.outputs = newOutputConfig;
        const cropDuped = _.cloneDeep(crop);
        delete cropDuped.name;
        delete cropDuped.aspectRatio;
        delete cropDuped['$$hashKey'];
        transcodeConfig.transcodeConfig.input = {crop: cropDuped};
        transcodeConfig.ogCrop = crop;
        return transcodeConfig;
      });
      if (ctrl.isNew) {
        ctrl.model.objectState = 'ACTIVE';

        if (ctrl.mode === 'PLAIN') {
          ctrl.model.width = ctrl.asset.width;
          ctrl.model.height = ctrl.asset.height;

          if (ctrl.doubleResolution) {
            ctrl.model.width = Math.round(ctrl.model.width / 2);
            ctrl.model.height = Math.round(ctrl.model.height / 2);
          }
        }

        if (ctrl.mode === 'CROP' && transcodeValues[0]) {
          ctrl.model.name = transcodeValues[0].ogCrop.name;
          ctrl.model.width = transcodeValues[0].ogCrop.width;
          ctrl.model.height = transcodeValues[0].ogCrop.height;
        }

        if (ctrl.impTrackingUrl) {
          ctrl.model.impressionTrackingUrls = [ctrl.impTrackingUrl];
        }
      }

      const allCreatives = [];
      ctrl.isSaving = true;
      ctrl.model.$save().then(function(theCreative) {
        allCreatives.push(_.cloneDeep(theCreative));

        const creativeCopyPromises = [];
        if (transcodeValues[0]) {
          creativeCopyPromises.push(Asset.update(theCreative.id, ctrl.mode === 'CROP' ? transcodeValues[0] : {transcodeConfig: {enabled: true}}, ctrl.asset.id));
        }

        if (ctrl.mode === 'CROP') {
          _.forEach(transcodeValues, function(tv, index) {
            if (index > 0) {
              theCreative.name = tv.ogCrop.name;
              const creativePromise = Creative.copyCreative(theCreative, null, Uuid.generate(), 'APPROVED', theCreative.creativeSet, tv.transcodeConfig, tv.ogCrop.width + 'x' + tv.ogCrop.height, 'ACTIVE');
              creativeCopyPromises.push(creativePromise);
              creativePromise.then(function(c) {
                if (c.id) {
                  allCreatives.push(_.cloneDeep(c));
                }
              });
            }
          });
        }
        $q.all(creativeCopyPromises).then(function() {
          init();
          LocalBulkCreativeInfo.obtain().setVideoCreatives(allCreatives);
          ctrl.videoCreatives = LocalBulkCreativeInfo.obtain().getVideoCreatives();
          adnListHelper.setUpList(ctrl, Creative, false, null, {id: _.map(ctrl.videoCreatives, 'id')});
          ctrl.asset = null;
          ctrl.isSaving = false;
        });
      });
    };

    ctrl.objectType = OBJECT_TYPE.LineItem.type;
  });

export default MODULE_NAME;

const outputConfig = {
  "mpeg4": {"type": "mpeg4", "audioNormalisation": true, "maxBitrate": 2500000, "width": 300, "height": 250},
  "dash": {"type": "dash", "audioNormalisation": true, "dashStreamConfigs": [{"width": 300, "height": 250, "maxBitrate": 2500000}, {"width": 300, "height": 250, "maxBitrate": 1100000}, {"width": 300, "height": 250, "maxBitrate": 600000}]},
  "hls": {"type": "hls", "audioNormalisation": true, "hlsStreamConfigs": [{"width": 300, "height": 250, "maxBitrate": 2500000}, {"width": 300, "height": 250, "maxBitrate": 1100000}, {"width": 300, "height": 250, "maxBitrate": 600000}]}
};
