/**
 * Copyright © 2025 Adnuntius AS.
 */
import {Component, Input, OnInit} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'first-party-audience-publish-state-label',
  template: `<span class="label {{labelClass[publishState]}}">{{ "firstPartyAudience.publishState." + publishState | translate }}</span>`
})
export class FirstPartyAudiencePublishStateLabelComponent implements OnInit {
  labelClass: Record<string, string>;
  @Input() publishState: string;

  constructor(public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.labelClass = {};
    this.labelClass['APPROVED'] = 'label-success';
    this.labelClass['INACTIVE'] = 'label-danger';
  }
}