/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import {PERMISSIONS} from "../../components/auth/auth-constants";

import localUserPermissions from '../../components/session/local-user-permissions';

const MODULE_NAME = 'sections-module';

angular.module(MODULE_NAME, [
  uiRouter,
  localUserPermissions
])

  .config(function($stateProvider) {
    $stateProvider.state('app.wor', {
      abstract: true,
      data: {
        app: 'ADVERTISING'
      }
    }).state('app.adv', {
      abstract: true,
      data: {
        app: 'ADVERTISING'
      },
      resolve: {
        readOnly: function(LocalUserPermissions) {
          return !LocalUserPermissions.hasAny(PERMISSIONS.manageAdvertising.apiKey) && !LocalUserPermissions.hasAny(PERMISSIONS.axAdvertiser.apiKey);
        }
      }
    }).state('app.dat-seg', {
      abstract: true,
      data: {
        app: 'DATA'
      },
      resolve: {
        readOnly: function() {
          return false;
        }
      }
    }).state('app.dat-map', {
      abstract: true,
      data: {
        app: 'DATA'
      },
      resolve: {
        readOnly: function() {
          return false;
        }
      }
    }).state('app.dat-q', {
      abstract: true,
      data: {
        app: 'DATA'
      },
      resolve: {
        readOnly: function() {
          return false;
        }
      }
    }).state('app.inv', {
      abstract: true,
      data: {
        app: 'ADVERTISING'
      },
      resolve: {
        readOnly: function(LocalUserPermissions) {
          return !LocalUserPermissions.hasAny(PERMISSIONS.managePublishing.apiKey) && !LocalUserPermissions.hasAny(PERMISSIONS.axPublisher.apiKey);
        }
      }
    }).state('app.rep', {
      abstract: true,
      data: {
        app: 'ADVERTISING'
      },
      resolve: {
        readOnly: function(LocalUserPermissions) {
          return !LocalUserPermissions.hasNetwork(PERMISSIONS.manageReportTemplates.apiKey);
        }
      }
    });
  });

export default MODULE_NAME;