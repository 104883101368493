/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "site-group";

angular.module(MODULE_NAME, [])

  .factory("SiteGroup", function(adnResource) {
    return adnResource('sitegroups', {}, {}, 'SiteGroup');
  });

export default MODULE_NAME;