/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import deleteTemplate from '../../common/delete-actions.html';

import editTemplate from './custom-event-edit.html';
import listTemplate from './custom-event-list.html';

import adminModule from '../admin-module';
import standardModule from '../../common/standard-module';
import customEventCtrl from './custom-event-controller';

const MODULE_NAME = 'custom-event-module';

angular.module(MODULE_NAME, [adminModule, standardModule, customEventCtrl])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('custom-events.custom-event', 'CustomEvent', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list())
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: 'CustomEventCtrl',
            template: editTemplate,
            controllerAs: 'resource'
          },
          'context@app': {
            controller: /*@ngInject*/ function(model, modelComms, $stateParams) {
              const ctrl = this;
              ctrl.model = model;
              ctrl.modelComms = modelComms;
              ctrl.isNew = $stateParams.isNew;
            },
            controllerAs: 'ctrl',
            template: deleteTemplate
          }
        }
      }, 'CustomEvent'));
  });

export default MODULE_NAME;