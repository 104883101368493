/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import Highcharts from 'highcharts';
import highchartsNg from 'highcharts-ng';

import template from './burn-rate.html';

import promiseSpinnerComponent from '../directives/html/promise-spinner-component';
import resources from '../../../components/api/resources/resources';
import localNetworkProfile from '../../../components/session/local-network-profile';

const MODULE_NAME = 'burn-chart-directive';

angular.module(MODULE_NAME, [highchartsNg, resources, promiseSpinnerComponent, localNetworkProfile])

  .directive('adnBurnRateChart', function(ChartOptionsUtil, LocalNetworkProfile, $locale) {
    return {
      restrict: 'A',
      scope: {
        burnRateData: '<adnBurnRateChart'
      },
      template: template,
      link: {
        // using preLink to fill out chart config BEFORE the highchart directive is compiled
        pre: function preLink(scope) {

          let chartOptions = ChartOptionsUtil.getOptions('BURN_RATE');
          let timezone = LocalNetworkProfile.get('timeZone');
          let networkTimezoneOffset = moment.tz(timezone).utcOffset();
          Highcharts.setOptions({
            global: {timezoneOffset: networkTimezoneOffset * -1},
            lang: {
              shortMonths: $locale.DATETIME_FORMATS.SHORTMONTH,
              months: $locale.DATETIME_FORMATS.MONTH,
              weekdays: $locale.DATETIME_FORMATS.DAY,
              thousandsSep: $locale.NUMBER_FORMATS.GROUP_SEP || ' ',
              decimalPoint: $locale.NUMBER_FORMATS.DECIMAL_SEP || '.'
            }
          });
          let getSelFields = function() {
            return [chartOptions.fields.burnRate, chartOptions.fields.frequencyCapping];
          };

          let statsSeries = getSelFields(),
            createChartConfig = function(type, series, yAxisData, height) {
              return {
                chart: {
                  type: type,
                  zoomType: 'x',
                  showAxes: true,
                  height: height
                },
                plotOptions: {
                  series: {
                    marker: {
                      enabled: false
                    },
                    turboThreshold: 2000
                  }
                },
                tooltip: {
                  shared: true
                },
                xAxis: {
                  type: 'datetime',
                  title: {
                    enabled: false,
                    text: 'Date and Time'
                  }
                },
                yAxis: yAxisData,
                series: series,
                title: {
                  text: null
                },
                accessibility: {
                  enabled: false
                },
                credits: {
                  enabled: false
                },
                exporting: {
                  enabled: true
                }
              };
            };

          let yAxisData = {
            title: {text: null},
            min: 0,
            max: 100,
            opposite: false,
            allowDecimals: true,
            id: 0,
            labels: {style: {color: 'black'}}
          };
          scope.statsChart = createChartConfig('line', statsSeries, yAxisData, 300);

          let startStatsGet = function() {
            if (scope.burnRateData) {
              _.forEach(scope.statsChart.series, function(series) {

                series.data = _.map(scope.burnRateData, function(data) {
                  return {
                    x: moment(data.time).valueOf(),
                    y: _.get(data, series.id) * 100
                  };
                });
              });
              scope.haveStats = true;
              scope.noData = scope.burnRateData.length < 1;
              scope.isPending = false;
            } else {
              scope.error = true;
              scope.isPending = false;
            }
          };
          startStatsGet();
        },
        post: function postLink(scope, element) {
          element.addClass('chart');
        }
      }
    };
  });

export default MODULE_NAME;