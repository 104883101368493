/*
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import {CURRENCIES} from "./iso-currencies";
import _ from "lodash";

const MODULE_NAME = 'iso-currency';

angular.module(MODULE_NAME, [])
  .filter('stringToCurrency', function($filter) {
    return function(string) {
      if (!_.isString(string)) {
        return string;
      }
      const currency = CURRENCIES[string.substr(0, 3)];
      if (!currency) {
        return string;
      }
      const currencySplit = string.split(" ");
      if (currencySplit.length !== 2 || currencySplit[0].length !== 3) {
        return string;
      }
      return $filter('isoCurrency')(currencySplit[1], currencySplit[0]);
    };
  })

  .filter('isoCurrency', function($filter, LocalNetworkProfile) {
    return function(amount, currencyCode, fraction, hideCurrency, hideExtras) {
      let currency = CURRENCIES[(currencyCode || 'NOMATCH').toUpperCase()];

      if (!currency) {
        return amount;
      }

      let fractionSize = !_.isFinite(fraction) ? currency.fraction : fraction;
      fractionSize = hideExtras && amount % 1 === 0 ? 0 : fractionSize;
      const updatedCurrency = $filter('currency')(amount, hideCurrency ? "" : currency.symbol || currencyCode + ' ', fractionSize);
      if (updatedCurrency) {
        const defaultCurrency = LocalNetworkProfile.get('defaultCurrency');
        if (CURRENCIES[defaultCurrency] && CURRENCIES[defaultCurrency].symbol === currency.symbol) {
          return updatedCurrency.replace(currency.symbol, hideCurrency ? "" : currencyCode + " ");
        }
      }
      return updatedCurrency;
    };
  });

export default MODULE_NAME;