/**
 * Copyright © 2025 Adnuntius AS.
 */
import * as _ from 'lodash';
import {Uuid} from "../../../components/util/uuid";

export const actionsHelper = function(ctrl, model, modelComms, readOnly, $stateParams, $uibModal, $q, toastr, $state, template, config) {
  ctrl.readOnly = readOnly;

  ctrl.model = model;
  ctrl.modelComms = modelComms;
  ctrl.isNew = $stateParams.isNew;

  ctrl.copy = function() {
    const modalInstance = $uibModal.open({
      windowClass: '',
      template: template,
      controllerAs: 'modalCtrl',
      controller: /*@ngInject*/ function() {
        const modalCtrl = this;

        modelComms.addSubscriber(function(data, type) {
          if (!type) {
            modalCtrl.model = data;
            modalCtrl.model.name = data.name + " (copy)";
            if (_.isFunction(modelComms.postFunc)) {
              modelComms.postFunc(modalCtrl);
            }
          }
        });

        if (_.isFunction(modelComms.postFunc)) {
          modelComms.postFunc(modalCtrl);
        }

        modalCtrl.destination = true;

        if (_.isFunction(config.postInit)) {
          config.postInit(modalCtrl);
        }

        modalCtrl.copy = function() {
          if (_.isFunction(config.preCopy)) {
            config.preCopy(modalCtrl.model, modalCtrl);
          }
          modalCtrl.model.id = Uuid.generate();
          modalCtrl.model.$save().then(function(savedAdUnit) {
            modalInstance.close({model: savedAdUnit, destination: modalCtrl.destination});
          });
        };
      }
    });
    modalInstance.result.then(function(result) {
      if (result.destination) {
        $q.all(result.allPromises).then(function() {
          $state.go(config.url, {id: result.model.id}, {inherit: false});
        });
      } else {
        toastr.success("A copy of this " + config.lcase + " was successfully created.", config.ucase + " Copied");
      }
    });
  };
};
