/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';

const MODULE_NAME = 'booking-calendar-controller';

angular.module(MODULE_NAME, [])

  .controller('BookingCalendarCtrl', function(LineItemBookings, LocalUserPermissions, LocalNetworkProfile) {
    const ctrl = this;
    ctrl.compareReachAnalysis = false;

    ctrl.isAxAdvertiser = LocalUserPermissions.isAxAdvertiser();

    ctrl.columns = {
      impressions: true,
      rendered: true,
      clicks: true,
      viewables: true,
      visibles: true,
      conversions: true,
      budget: true
    };

    ctrl.chunk = 'DAY';

    const networkTz = LocalNetworkProfile.get('timeZone') || "utc";
    ctrl.startDate = moment().tz(networkTz).toDate();
    ctrl.endDate = moment().tz(networkTz).add(1, 'month').endOf('month').toDate();

    ctrl.getBookings = function() {
      ctrl.bookings = {};
      ctrl.chunkMaxes = _.cloneDeep(ctrl.columns);
      let startDate = ctrl.startDate.getFullYear() + '-' + String(ctrl.startDate.getMonth() + 1).padStart(2, '0') + '-' + String(ctrl.startDate.getDate()).padStart(2, '0');
      let endDate = ctrl.endDate.getFullYear() + '-' + String(ctrl.endDate.getMonth() + 1).padStart(2, '0') + '-' + String(ctrl.endDate.getDate()).padStart(2, '0');
      LineItemBookings.get({startDate: startDate, endDate: endDate, chunkSize: ctrl.chunk}, function(data) {
        ctrl.bookings = data;

        _.forEach(ctrl.columns, function(val, key) {
          ctrl.columns[key] = ctrl.bookings.total[key] > 0 || _.get(ctrl.bookings.total, [key, 'amount']) > 0;
        });

        _.forEach(ctrl.bookings.chunks, function(chunk) {
          _.forEach(ctrl.chunkMaxes, function(val, key) {
            ctrl.chunkMaxes[key] = Math.max(_.get(chunk, [key, 'amount']) || chunk[key] || 0, (ctrl.chunkMaxes[key] === true ? 0 : ctrl.chunkMaxes[key]) || 0);
          });
        });

        ctrl.hasResults = _.reduce(ctrl.columns, function(sum, val) {
          return val ? 1 + sum : sum;
        }, 0);

      }, function() {
        ctrl.bookings = {};
      });
    };

  });

export default MODULE_NAME;