/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';

import template from './category-targeting.html';

import {ADN_TARGETING_TYPES, SPECIAL_NO_CATEGORY_TARGET} from "../targeting-constants";

import resources from '../../../components/api/resources/resources';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import {TargetingHelper} from "../targeting-helper";

import {convertIabCategoryToString} from "../../../components/util/iab-taxonomy";

const MODULE_NAME = 'category-targeting-directive';

const ORIGIN_OPTIONS = {
  REF_DATA: {
    api: ['UPLOAD']
  },
  CONTENT: {
    api: ['SAMPLER']
  },
  IAB: {
    api: ['SAMPLER']
  }
};
const CATEGORY_TYPE_OPTIONS = {
  REF_DATA: {
    api: ['NORMAL', 'IAB']
  },
  CONTENT: {
    api: ['NORMAL']
  },
  IAB: {
    api: ['IAB']
  }
};

angular.module(MODULE_NAME, [translate, uiBootstrap, resources, promiseSpinner])

  .directive('adnCategoryTargeting', function(categorySearchResource) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnCategoryTargeting',
        underModel: '=',
        isLockedDown: '<',
        overridableTargeting: '<',
        pageType: '<',
        mandatory: '<',
        mode: '@'
      },
      link: {
        pre: function(scope) {
          scope.model = scope.model || [];
          scope.targetType = ADN_TARGETING_TYPES.category;
        },
        post: function(scope, element, attrs, tabset) {
          let complexInit = function() {
            TargetingHelper.complexInit(scope, ADN_TARGETING_TYPES.category.id, 'categories', 'notCategories');
          };
          let simpleInit = function() {
            let frontPageMethod = '';
            _.forEach(scope.model, function(entryObj) {
              const cat = _.get(entryObj, 'categories[0]');
              if (cat === SPECIAL_NO_CATEGORY_TARGET) {
                frontPageMethod = 'FRONT';
                return;
              }
              if (cat === "NOT " + SPECIAL_NO_CATEGORY_TARGET) {
                frontPageMethod = 'ANY';
              }
            });
            TargetingHelper.simpleInit(scope, ADN_TARGETING_TYPES.category.apiProp);
            scope.inputted.frontPageMethod = frontPageMethod || '';
          };
          let init = function() {
            complexInit();
            simpleInit();
            return TargetingHelper.isComplex(scope.model, ADN_TARGETING_TYPES.category.id);
          };
          scope.template = {
            loaded: true
          };
          let initInit = function() {
            scope.template.complexInput = init();
          };
          initInit();

          scope.originOption = {selection: 'ALL'};
          scope.categoryTypesOption = {selection: 'ALL'};

          scope.resolved = true;
          scope.search = function(newSearch) {
            scope.searchMeta.resolved = false;

            if (newSearch) {
              scope.searchMeta.currentPage = 1;
            }

            const originApi = _.uniq(_.flatten(_.map(_.filter(ORIGIN_OPTIONS, function(oo, key) {
              return scope.originOption.selection === 'ALL' || scope.originOption.selection === key;
            }), function(oo) {
              return oo.api;
            }))).join(';');
            const categoryTypeApi = _.uniq(_.flatten(_.map(_.filter(CATEGORY_TYPE_OPTIONS, function(oo, key) {
              return scope.originOption.selection === 'ALL' || scope.originOption.selection === key;
            }), function(oo) {
              return oo.api;
            }))).join(';');

            const params = {
              q: (scope.search.term || ''),
              pageSize: scope.searchMeta.pageSize,
              page: scope.searchMeta.currentPage - 1,
              origin: originApi,
              categoryType: categoryTypeApi
            };
            if (!params.q) {
              // this breaks on prod for some reason... will re-enable once fixed.
              params.orderBy = 'FREQUENCY';
              params.orderByDirection = 'DESCENDING';
            }

            categorySearchResource.query(params).then(function(page) {
              scope.list = page;

              _.forEach(_.get(scope.list, 'searchResults') || [], function(sr) {
                sr.updatedOrigins = _.uniqBy(sr.origins, function(og) {
                  return og === 'CRAWLER' || og === 'SAMPLER' ? 'SITE_CONTENT' : og;
                });
                sr.label = convertIabCategoryToString(sr.category);
              });

              if (!scope.template.complexInput) {
                let trimmedValues = TargetingHelper.trimValues(scope);

                _.forEach(_.get(scope.list, ['searchResults'], []), function(a) {
                  a.selected = _.indexOf(trimmedValues, a.category) > -1;
                });
              }
              scope.searchMeta.resolved = true;
            });
          };

          scope.searchMeta = {
            pageSize: 50,
            currentPage: 1,
            changePage: function() {
              scope.search();
            },
            resolved: true
          };

          function extractIabCode(iab) {
            const startIndex = iab.indexOf("[IAB_");
            const endIndex = iab.indexOf("]", startIndex);
            return startIndex > 0 && endIndex > startIndex ? iab.substring(startIndex + 1, endIndex) : iab;
          }

          function deriveIabCategory(searchResult) {
            return searchResult.categoryType === 'IAB' ? extractIabCode(searchResult.category) : searchResult.category;
          }

          scope.select = function(searchResult, param) {

            searchResult.selected = true;

            let trimmedValues = TargetingHelper.trimValues(scope);
            trimmedValues[param].push(deriveIabCategory(searchResult));
            TargetingHelper.assignToInput(scope, trimmedValues);
          };

          scope.addOr = function(searchResult) {
            scope.addEntry(deriveIabCategory(searchResult));
          };
          scope.addAnd = function(searchResult) {
            scope.addRow(null, deriveIabCategory(searchResult));
          };
          scope.addAndNot = function(searchResult) {
            scope.addNotRow(null, deriveIabCategory(searchResult));
          };

          scope.addKeywordAnd = function() {
            scope.inputted.valueAnded = scope.inputted.valueAnded || [];
            scope.inputted.valueAnded.push({value: ""});
          };

          scope.addEntry = function(entry) {
            TargetingHelper.checkAndCreateBaseInput(scope, 'categories', 'notCategories');
            const input = entry || '';
            scope.inputs.push({categories: [{k: input, not: false}], notCategories: []});
          };

          scope.addRow = function(index, entry) {
            TargetingHelper.checkAndCreateBaseInput(scope, 'categories', 'notCategories');
            const input = entry || '';
            const theIndex = _.isFinite(index) ? index : scope.inputs.length - 1;
            scope.inputs[theIndex].categories.push({k: input, not: false});
          };

          scope.addNotRow = function(index, entry) {
            TargetingHelper.checkAndCreateBaseInput(scope, 'categories', 'notCategories');
            const input = entry || '';
            const theIndex = _.isFinite(index) ? index : scope.inputs.length - 1;
            scope.inputs[theIndex].notCategories.push({k: input, not: false});
          };

          scope.negateRow = function(parentIndex, index, isNot) {
            let param = isNot ? 'notCategories' : 'categories';
            scope.inputs[parentIndex][param][index].not = !scope.inputs[parentIndex][param][index].not;
          };

          scope.removeRow = function(parentIndex, index, isNot) {
            scope.inputs[parentIndex][isNot ? 'notCategories' : 'categories'].splice(index, 1);
            if (scope.inputs[parentIndex].categories.length === 0 && scope.inputs[parentIndex].notCategories.length === 0) {
              scope.inputs.splice(parentIndex, 1);
            }
          };

          let prepareForSave = function() {
            scope.model = scope.model || [];
            scope.model.length = 0;

            const specialStuff = function() {
              if (scope.inputted.frontPageMethod === 'ANY') {
                scope.model.push({categories: ["NOT " + SPECIAL_NO_CATEGORY_TARGET]});
              }
              if (scope.inputted.frontPageMethod === 'FRONT') {
                let hasSpecialAlready = false;
                _.forEach(scope.model, function(catEntry) {
                  _.forEach(catEntry.categories, function(cArray) {
                    hasSpecialAlready = hasSpecialAlready || cArray === SPECIAL_NO_CATEGORY_TARGET;
                  });
                });
                if (!hasSpecialAlready) {
                  scope.model.push({categories: [SPECIAL_NO_CATEGORY_TARGET]});
                }
              }
            };

            if (scope.pageType === 'optionalProduct') {
              TargetingHelper.assignAllTheValuesToSimpleModel(scope, ADN_TARGETING_TYPES.category.apiProp);
            } else if (scope.template.complexInput || scope.pageType === 'optionalProduct') {
              TargetingHelper.assignComplexValuesToNewModel(scope, 'categories', 'notCategories');
            } else {
              TargetingHelper.assignAllTheValuesToPipedModel(scope, ADN_TARGETING_TYPES.category.apiProp);
            }
            specialStuff();
            init();
          };

          scope.hook = {};
          scope.hook.getTemplateData = function() {
            prepareForSave();

            return {
              vm: angular.copy(scope.vm),
              model: angular.copy(scope.model),
              constant: ADN_TARGETING_TYPES.category,
              summary: TargetingHelper.isComplex(scope.model, ADN_TARGETING_TYPES.category.id) ? (TargetingHelper.getSizeOfTargetingTypes(scope.model, ADN_TARGETING_TYPES.category.targets) + " categories with complex logic") : scope.inputted.value
            };
          };

          const copyTemplateFunc = function(template) {
            scope.model = angular.copy(template.data.model);
            initInit();
          };
          scope.hook.copyTemplate = copyTemplateFunc;

          tabset.register(ADN_TARGETING_TYPES.category.widget, scope.search, copyTemplateFunc);
          tabset.callBeforeSubmit(prepareForSave);
          tabset.registerAllTabs(prepareForSave);
        }
      }
    };
  });

export default MODULE_NAME;