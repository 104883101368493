/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import cdnStats from '../../common/directives/cdn-stats';

import standardFormModule from '../../common/standard-form-module';
import { AdServerConfig } from "../../../components/api/api";
import {DEFAULT_MARKETPLACE_BID_SPECIFICATION_FIELDS, DEFAULT_OBJECTIVE_FIELDS} from "../../../components/session/local-network-profile";
import { DEFAULT_MARKETPLACE_OBJECTIVE_FIELDS } from "../../../components/session/local-network-profile";
import { DEFAULT_BID_SPECIFICATION_FIELDS } from "../../../components/session/local-network-profile";

const MODULE_NAME = 'network-controller';

angular.module(MODULE_NAME, [standardFormModule, cdnStats])

  .controller('NetworkCtrl', function($state, network, MarketplaceProduct, networkProfile, currenciesResource, tierResource, NetworkProfile, Role, LocalNetworkProfile, localUserProfile) {
    const ctrl = this;

    ctrl.app = localUserProfile.getCurrentApp();

    let init = function() {
      ctrl.network = angular.copy(network);
      ctrl.networkProfile = angular.copy(networkProfile);
      ctrl.networkProfile.profile.adnDefaults = ctrl.networkProfile.profile.adnDefaults || {};
      LocalNetworkProfile.sanitiseProfileData(ctrl.networkProfile.profile.adnDefaults);
      if (ctrl.networkProfile.profile.adnDefaults.namedLocations.length === 0) {
        ctrl.networkProfile.profile.adnDefaults.namedLocations.push("");
      }
    };
    init();

    ctrl.defaultObjectiveFields = DEFAULT_OBJECTIVE_FIELDS;
    ctrl.defaultMarketplaceObjectiveFields = DEFAULT_MARKETPLACE_OBJECTIVE_FIELDS;
    ctrl.defaultBidSpecificationFields = DEFAULT_BID_SPECIFICATION_FIELDS;
    ctrl.defaultMarketplaceBidSpecificationFields = DEFAULT_MARKETPLACE_BID_SPECIFICATION_FIELDS;
    ctrl.isPureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();

    let theForm;
    ctrl.setForm = function(editForm) {
      theForm = editForm;
    };

    ctrl.editsMade = function() {
      if (theForm) {
        theForm.$setDirty();
      }
    };

    ctrl.removeLocations = function(index) {
      _.pullAt(ctrl.networkProfile.profile.adnDefaults.namedLocations, index);
      ctrl.editsMade();
    };

    ctrl.addLocations = function() {
      ctrl.networkProfile.profile.adnDefaults.namedLocations.push("");
    };

    ctrl.countryChangeHook = function() {
      ctrl.editsMade();
    };

    MarketplaceProduct.query({pageSize: 505, minimal: true}).$promise.then(function(data) {
      ctrl.marketplaceProducts = data.results;
    });

    currenciesResource.get().then(function(currencies) {
      ctrl.currencies = _.map(currencies, "currency");
    });

    if (ctrl.app === 'ADVERTISING') {
      tierResource.query().$promise.then(function(page) {
        ctrl.tiers = page.results;

        _.forEach(ctrl.tiers, function(t) {
          t.displayName = t.name + (_.find(ctrl.network.tiers, {id: t.id}) ? '' : ' (disabled)');
        });
      });
    }

    ctrl.isCurrentNetwork = network.id === LocalNetworkProfile.getNetworkId();
    if (ctrl.isCurrentNetwork) {
      Role.query(function(page) {
        ctrl.roles = page.results;
      });
    }

    ctrl.startDays = [{value: 0, text: 'Sunday'}, {value: 1, text: 'Monday'}, {value: 2, text: 'Tuesday'}, {value: 3, text: 'Wednesday'}, {value: 4, text: 'Thursday'}, {value: 5, text: 'Friday'}, {value: 6, text: 'Saturday'}];

    if (AdServerConfig.obtain().getEnv() !== "production") {
      ctrl.sftpHost = LocalNetworkProfile.getNetworkTag() + "." + AdServerConfig.obtain().getEnv() + '.data.adnuntius.com:8164';
    } else {
      ctrl.sftpHost = LocalNetworkProfile.getNetworkTag() + '.data.adnuntius.com:8164';
    }

    ctrl.saveNetwork = function(promise) {
      promise.then(function(network) {
        let savedNetwork = network && network.defaultCurrency ? network : ctrl.network;

        NetworkProfile.save({
          id: network.id,
          name: ctrl.network.name,
          profile: {
            adnUiLogo: ctrl.networkProfile.profile.adnUiLogo,
            adnDefaults: ctrl.networkProfile.profile.adnDefaults
          }
        }, function(savedNetworkProfile) {
          angular.extend(network, savedNetwork);
          angular.extend(networkProfile, savedNetworkProfile);

          LocalNetworkProfile.set('adnDefaults', networkProfile.profile.adnDefaults);
          LocalNetworkProfile.set('adnUiLogo', networkProfile.profile.adnUiLogo);
          LocalNetworkProfile.set('reportLogoUrl', network.reportLogoUrl);
          LocalNetworkProfile.set('availableCurrencies', network.availableCurrencies);
          LocalNetworkProfile.set('defaultCurrency', network.defaultCurrency);
          LocalNetworkProfile.set('defaultTierId', network.defaultTier.id);
          LocalNetworkProfile.set('tiers', network.tiers);
          LocalNetworkProfile.set('tagId', network.tagId);
        });
      });
    };

    ctrl.cancel = function(form) {
      init();
      form.$setPristine();
    };

    ctrl.delete = function(network) {
      network.$delete().then(function() {
        $state.go('app.networks');
      });
    };
  });

export default MODULE_NAME;
