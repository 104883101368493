/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';

import _ from 'lodash';

const MODULE_NAME = "objectives-view-component";

angular.module(MODULE_NAME, [translate])

  .component('adnObjectivesViewComponent', {
    bindings: {
      lineItem: '<'
    },
    controllerAs: 'ctrl',
    template: `
        <div ng-repeat="obj in ctrl.objs"><small>{{obj.label}}:</small> <span ng-if="!obj.val.amount">{{::obj.val | number}}</span><span ng-if="obj.val.amount">{{obj.val | adnStat}}</span></div>
        <div ng-if="ctrl.lineItem.type === 'SPONSORSHIP'">SoV: {{ctrl.lineItem.sponsorshipPercentage}}%</div>
    `,
    controller: function(LocalNetworkProfile, $translate) {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.objs = _.map(_.cloneDeep(ctrl.lineItem.objectives), function(val, key) {
          const transKey = 'lineItem.objective.' + key + '.list';
          const trans = LocalNetworkProfile.getNetworkTranslations(transKey);
          return {label: trans || $translate.instant(transKey), val: val};
        });
      };
    }
  });

export default MODULE_NAME;