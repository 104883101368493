/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import timeShifter from '../../components/util/time-shifter';
import _ from "lodash";

const MODULE_NAME = 'report-url-component';

angular.module(MODULE_NAME, [timeShifter])

  .component('adnReportUrlComponent', {
    bindings: {
      model: '<'
    },
    controllerAs: 'directive',
    controller: function() {
      const ctrl = this;
      ctrl.$onInit = function() {
        ctrl.model.safeReportUrl = ctrl.model.reportUrl;
        if (_.startsWith(ctrl.model.safeReportUrl, "//")) {
          ctrl.model.safeReportUrl = "https:" + ctrl.model.safeReportUrl;
        }
      };
    },
    template: `
      <span ng-if="!directive.model.reportUrl">URL not yet available</span>
      <a ng-if="directive.model.reportUrl" href="{{::directive.model.safeReportUrl}}" target="_blank">
        <small>{{::directive.model.safeReportUrl}}</small>
      </a>
    `
  });

export default MODULE_NAME;
