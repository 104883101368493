/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import localNetworkProfile from '../../components/session/local-network-profile';
import localUserPermissions from '../../components/session/local-user-permissions';
import trafficComponent from '../common/directives/related-data-chart-component';
import timeShifter from '../../components/util/time-shifter';
import standardFormModule from '../common/standard-form-module';
import rateLimitComponent from '../common/controller/rate-limit-component';
import standardAnalysisModule from '../common/standard-analysis-module';
import {adnFormHelper} from "../common/controller/form-helper";
import {lineItemCommons} from "../line-item/line-item-commons";
import {LocalNetworkInfo} from "../../components/session/local-network-info";

const MODULE_NAME = "folio-controller";

angular.module(MODULE_NAME, [
  standardFormModule,
  standardAnalysisModule,
  timeShifter,
  rateLimitComponent,
  localNetworkProfile,
  localUserPermissions,
  trafficComponent,
])

  .controller('FolioCtrl', function($stateParams, readOnly, $state, $filter, $translate, adnTimeShifter, LocalNetworkProfile, LocalUserPermissions, Team, searchResource, model, modelComms) {
    const ctrl = this;

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms, afterSave: function(pFolio) {
        ctrl.model.advertiser = pFolio.advertiser;
      },
      afterInit: function() {
        if ($state.previousParams.advertiser) {
          ctrl.model.advertiser = $state.previousParams.advertiser;
        }
        ctrl.blockSubmit = readOnly;
      }
    });

    ctrl.isPureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();
    ctrl.advertiserInfo = !ctrl.isPureMarketplace || $stateParams.isNew;
    ctrl.chartCurrency = _.get(model, ['objectives', 'BUDGET', 'currency']);


    const folioCommons = lineItemCommons(ctrl, $translate, $filter, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter);
    folioCommons.setAxAdvertiser();

    ctrl.isNew = $stateParams.isNew;

    ctrl.showTeams = LocalNetworkInfo.obtain().getInfo("teamsCount") > 1 || !LocalNetworkProfile.isPureMarketplacePlatform();

    Team.query(function(page) {
      const allTeams = page.results;
      const isViewAllObjects = LocalUserPermissions.hasNetwork('VIEW_ALL_OBJECTS');
      ctrl.teams = isViewAllObjects ? allTeams : _.filter(allTeams, function(g) {
        return LocalUserPermissions.isPartOf(g.network.tagId || g.network.id, g.id);
      });
      if (_.get(ctrl, ['model', 'team', 'id'])) {
        const hydratedTeam = _.find(allTeams, function(t) {
          return ctrl.model.team.id === t.id;
        });
        if (hydratedTeam) {
          ctrl.model.team = hydratedTeam;
        }
      }
      if (allTeams.length === 1 && ctrl.teams.length === 1 && !ctrl.model.team) {
        ctrl.model.team = allTeams[0];
      }
    });

    ctrl.perms = LocalUserPermissions.getAllVisPermissions();
    ctrl.canSeeReports = LocalUserPermissions.getAnyVisibility().RUN_REPORTS === true || ctrl.perms.AX_ADVERTISER;
    ctrl.primaryNetworkCurrency = LocalNetworkProfile.get('defaultCurrency');
    if (ctrl.isAxAdvertiser) {
      ctrl.allowsRateLimits = LocalNetworkProfile.isMarketplaceAdvertiserRateLimits();
      ctrl.rateLimitTypes = ['RENDERED_IMPRESSION', 'VISIBLE_IMPRESSION', 'VIEWABLE_IMPRESSION'];
    } else {
      ctrl.allowsRateLimits = true;
      ctrl.rateLimitTypes = ['IMPRESSION', 'RENDERED_IMPRESSION', 'VISIBLE_IMPRESSION', 'VIEWABLE_IMPRESSION', 'CLICK'];
    }

    ctrl.searchAdvertisers = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "Advertiser"}).then(function(data) {
        ctrl.advertiserResults = data.searchResults;
      });
    };

    ctrl.showingChart = '';

    ctrl.onBeforeSubmit = function() {
      if (!_.get(ctrl.model.advertiser, 'id')) {
        ctrl.model.advertiser = null;
      }

      if (ctrl.isNew) {
        LocalNetworkInfo.obtain().increment('folioCount');
      }
    };
  });

export default MODULE_NAME;