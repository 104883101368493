/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import extDemandPerf from '../../common/directives/ext-dem-performance-directive';
import standardAnalysisModule from '../../common/standard-analysis-module';

const MODULE_NAME = "external-demand-source-analysis";

angular.module(MODULE_NAME, [standardAnalysisModule, extDemandPerf])

  .controller('ExternalDemandSourceAnalysisCtrl', function($stateParams, LocalUserPermissions, model, modelComms) {
    const ctrl = this;

    ctrl.isNew = $stateParams.isNew;

    ctrl.model = angular.copy(model);
    ctrl.bidders = [];

    ctrl.isPrebid = ctrl.model.externalPartner === 'PREBID_JS';

    ctrl.biddersHook = {
      onBiddersFound: function(bidders, stats) {
        if (!_.isArray(bidders) || bidders.length === 0) {
          return;
        }
        ctrl.bidders = _.map(bidders, function(b) {
          return {
            bidder: b,
            biddersHook: function() {
            }
          };
        });
        ctrl.biddersHook.biddersRegistered = true;
        ctrl.allBidders = bidders.join(", ");
        ctrl.statsData = stats;
        ctrl.selBidder = ctrl.bidders[0].bidder;
        ctrl.oneFieldHook = {
        };
      },
      onSelections: function(stats, dateOption, startDate, endDate, countType, yAxisOption, selFieldIds, selFieldId) {
        _.forEach(ctrl.bidders, function(b) {
          if (!_.isFunction(_.get(b, 'biddersHook.reboot'))) {
            return;
          }
          b.biddersHook.reboot(stats, dateOption, startDate, endDate, countType, yAxisOption, selFieldIds, selFieldId);
        });
        if (_.isFunction(_.get(ctrl.oneFieldHook, 'reboot'))) {
          ctrl.oneFieldHook.reboot(stats, dateOption, startDate, endDate, countType, yAxisOption, selFieldIds, selFieldId);
        }
      }
    };

    modelComms.addSubscriber(function(data, type) {
      if (!type && data.name) {
        ctrl.model = data;

        if (ctrl.isNew) {
          ctrl.isNew = false;
        }
      }
    });

    ctrl.canSeeReports = LocalUserPermissions.getAnyVisibility().RUN_REPORTS === true;
  });

export default MODULE_NAME;