/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';

const MODULE_NAME = "field-translation-filter";

angular.module(MODULE_NAME, [translate])

  .filter('adnFieldTranslate', function($translate, LocalNetworkProfile) {
    return function(code, defaultText) {
      const networkTranslation = LocalNetworkProfile.getNetworkTranslations(code);
      if (networkTranslation) {
        return networkTranslation;
      }
      const trans = $translate.instant(code);
      return defaultText && trans === code ? defaultText : trans;
    };
  });

export default MODULE_NAME;