/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import standardAnalysisModule from '../common/standard-analysis-module';
import {lineItemListCommons} from "../line-item/line-item-commons";
import {orderListCommons} from "../order/order-commons";
import {LocalNetworkInfo} from "../../components/session/local-network-info";
import {creativeSetListCommons} from "../creative-set/creative-set-commons";
import {folioListCommons} from "../folio/folio-commons";
import {creativeListCommons} from "../creative/creative-commons";

const MODULE_NAME = "advertiser-information";

angular.module(MODULE_NAME, [standardAnalysisModule])

  .controller('AdvertiserInformationCtrl', function($stateParams, Folio, CreativeSet, LocalNetworkProfile, adnTimeShifter, $translate, $filter, $state, Order, Team, LineItem, Creative, LocalUserPermissions, model, modelComms, adnListHelper) {
    const ctrl = this;
    ctrl.query = {};
    ctrl.model = angular.copy(model);

    ctrl.canSeeReports = LocalUserPermissions.getAnyVisibility().RUN_REPORTS === true;

    ctrl.isNew = $stateParams.isNew;

    ctrl.showOrders = LocalUserPermissions.getSectionPerms().orders;
    ctrl.showCreativeSets = LocalUserPermissions.getSectionPerms().creativeSets && LocalNetworkInfo.obtain().getInfo('creativeSetCount');
    ctrl.showFolios = LocalUserPermissions.getSectionPerms().folios && LocalNetworkInfo.obtain().getInfo('folioCount');
    ctrl.showCreatives = LocalUserPermissions.getSectionPerms().creatives;
    ctrl.showVideoUpload = LocalNetworkProfile.showVideoUpload();

    ctrl.orders = {};
    adnListHelper.setUpBasicList(ctrl.orders, Order, 'fullOrdersForAdvertiser', {moreParams: {advertiser: model.id}, baseTable: 'order'});
    orderListCommons(ctrl.orders, false, LocalNetworkProfile, LocalUserPermissions);
    ctrl.orders.applyLimitedFilter();

    ctrl.perms = LocalUserPermissions.getAllVisPermissions();
    ctrl.pureMarketplacePlatform = LocalNetworkProfile.isPureMarketplacePlatform();

    ctrl.newOrder = function() {
      $stateParams.advertiser = model;
      $state.go('app.orders.new');
    };

    ctrl.newCreative = function() {
      $stateParams.advertiser = model;
      $state.go('app.creatives.new');
    };

    ctrl.newBulkCreative = function() {
      $stateParams.advertiser = model;
      $state.go('app.creatives.bulkupload');
    };

    ctrl.newVideoUpload = function() {
      $stateParams.advertiser = model;
      $state.go('app.creatives.videoupload');
    };

    ctrl.lineItems = {};
    adnListHelper.setUpBasicList(ctrl.lineItems, LineItem, 'fullLineItemsForAdvertiser', {
      moreParams: {advertiser: model.id},
      baseTable: 'lineItem',
      afterFill: function() {
        lineItemListCommons(ctrl.lineItems, true, LocalNetworkProfile, adnTimeShifter, $translate, $filter, LocalUserPermissions, Team, Order);
      }
    });
    ctrl.lineItems.perms = LocalUserPermissions.getAllVisPermissions();
    lineItemListCommons(ctrl.lineItems, false, LocalNetworkProfile, adnTimeShifter, $translate, $filter, LocalUserPermissions, Team, Order);
    ctrl.lineItems.blockTeams();
    ctrl.lineItems.assignAdvertiser(ctrl.model.id);

    ctrl.folios = {};
    if (LocalNetworkInfo.obtain().getInfo('folioCount') > 0) {
      adnListHelper.setUpBasicList(ctrl.folios, Folio, 'fullFoliosForAdvertiser', {
        moreParams: {advertiser: model.id},
        baseTable: 'folio',
        afterFill: function() {
          folioListCommons(ctrl.folios, true, LocalNetworkProfile, LocalUserPermissions);
        }
      });
      folioListCommons(ctrl.folios, false, LocalNetworkProfile, LocalUserPermissions);
    }

    ctrl.creativeSets = {};
    if (LocalNetworkInfo.obtain().getInfo('creativeSetCount') > 0) {
      adnListHelper.setUpBasicList(ctrl.creativeSets, CreativeSet, 'fullCreativeSetsForAdvertiser', {
        moreParams: {advertiser: model.id},
        baseTable: 'creativeSet',
        afterFill: function() {
          creativeSetListCommons(ctrl.creativeSets, true, LocalNetworkProfile, adnTimeShifter);
        }
      });
      creativeSetListCommons(ctrl.creativeSets, false, LocalNetworkProfile, adnTimeShifter);
    }

    ctrl.creatives = {};
    adnListHelper.setUpBasicList(ctrl.creatives, Creative, 'fullCreativesForAdvertiser', {
      moreParams: {advertiser: model.id},
      baseTable: 'creative',
      afterFill: function() {
        creativeListCommons(ctrl.creatives, true, LocalNetworkProfile, $state);
      }
    });
    creativeListCommons(ctrl.creatives, false, LocalNetworkProfile, $state);

    ctrl.hasObjectSource = LocalNetworkProfile.hasObjectSource();
    ctrl.creativeFields = ['name', {param: 'Layout', label: 'layout'}, {param: 'LineItem', label: 'lineItem'}, {label: 'URLs', param: ''}, {label: 'dimensions', param: 'width'}, {param: '', label: 'info'}, 'updateTime', {param: '', label: 'targeting'}];
    if (ctrl.hasObjectSource) {
      ctrl.creativeFields.splice(4, 0, 'source');
    }

    modelComms.addSubscriber(function(data, type) {
      if (!type && data.name) {
        ctrl.model = data;

        if (ctrl.isNew) {
          ctrl.isNew = false;
        }
      }
    });
  });

export default MODULE_NAME;