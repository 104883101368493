/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {OBJECT_TYPE} from "../common/constants/object-type";

import template from './global-search.html';
import searchItemTemplate from './global-search-result-item.html';

import standardFormModule from '../common/standard-form-module';
import objectStateParamsFilter from '../common/filters/object-type-state-params-filter';

const MODULE_NAME = 'global-search-directive';

angular.module(MODULE_NAME, [standardFormModule, objectStateParamsFilter])

  .directive('adnGlobalSearch', function($templateCache, $state, searchResource, adnObjectTypeStateParamsFilter) {
    return {
      scope: true,
      restrict: 'A',
      template: template,
      link: function postLink(scope, element) {
        $templateCache.put('globalSearchResultItem.html', searchItemTemplate);

        let updateParams = function(paramObj, term) {
          let words = (term || '').split(' ');
          paramObj.q = term;
          if (words[0]) {
            let matchObj = _.find(OBJECT_TYPE, function(o) {
              return o.searchType && o.searchType + ':' === words[0];
            });

            if (matchObj) {
              paramObj.types = matchObj.type;
              paramObj.q = "";

              if (words.length > 1) {
                paramObj.q = _.map(words.slice(1), function(w) {
                  return w.trim();
                }).join(" ");
              }
            }
          }
          return paramObj;
        };

        scope.searchQuery = function(term) {
          let paramObject = {
            q: null,
            types: null,
            pageSize: 10
          };
          updateParams(paramObject, term);
          return searchResource.query(paramObject).then(function(data) {
            let results = data.searchResults;
            results.push({
              name: "See full results for '" + term + "'",
              objectType: {
                name: 'Search',
                icon: 'fa-search',
                stateName: 'app.search',
                stateParams: paramObject
              }
            });
            return results;
          });
        };

        scope.selectItem = function(item) {
          $state.go(_.get(item, 'objectType.stateName') || 'app.search', adnObjectTypeStateParamsFilter(item), {inherit: false}).then(function() {
            element[0].blur();
          });
          scope.term = null;
        };

        element.on('submit', function(e) {
          e.preventDefault();
          $state.go('app.search', updateParams({q: null, types: null}, scope.term), {inherit: false}).then(function() {
            element[0].blur();
          });
          scope.term = null;
        });
      }
    };
  });

export default MODULE_NAME;