/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import uiCodemirror from 'angular-ui-codemirror';

import standardFormModule from '../common/standard-form-module';
import mimeTypeFilter from '../common/filters/mimeTypeFilter';
import otherFormButtons from '../common/directives/form-controls/form-buttons';
import externalAdUnitComponent from '../common/directives/external-ad-unit-component';
import targetingModule from '../targeting/targeting-module';
import {adnFormHelper} from "../common/controller/form-helper";
import {lineItemCommons} from "../line-item/line-item-commons";
import {campaignCommons} from "./campaign-commons";
import targetingTemplate from "../targeting/base-targeting-editor-modal.html";
import _ from "lodash";

const MODULE_NAME = 'campaign-controller';

angular.module(MODULE_NAME, [
  uiCodemirror,
  mimeTypeFilter,
  standardFormModule,
  otherFormButtons,
  externalAdUnitComponent,
  targetingModule
])

  .controller('CampaignCtrl', function($state, model, modelComms, $q, LineItem, lineItemView, $filter, $translate, $uibModal, Creative, LocalNetworkProfile, LineItemReview, LocalUserPermissions, adnTimeShifter) {
    const ctrl = this;
    ctrl.perms = LocalUserPermissions.getAllVisPermissions();

    if (!model.product) {
      $state.go('app.line-items.line-item', {id: model.id});
      return;
    }

    adnFormHelper.setUpForm(ctrl, model, {afterInit: function() {
        if (ctrl.model.startDate) {
          ctrl.model.modStartDate = adnTimeShifter.timeShift(ctrl.model.startDate);
        }
        if (ctrl.model.endDate) {
          ctrl.model.modEndDate = adnTimeShifter.timeShift(ctrl.model.endDate);
        }

        ctrl.model.advertiser = _.get(lineItemView, 'advertiser');

        LineItemReview.get({id: ctrl.model.id}, function(data) {
          ctrl.lir = data;
          campaignCommons(ctrl).setUpComponents(['lir']);
        }, function() {
          ctrl.lir = {};
        });

      }, afterSave: () => {}, modelComms: modelComms});

    const liCommons = lineItemCommons(ctrl, $translate, $filter, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter);
    liCommons.setUpTiers();

    ctrl.unreject = function(creative) {
      const creativeResource = new Creative({
        id: creative.id,
        userState: 'APPROVED',
        rejectedReason: null
      });

      const promises = [];
      promises.push(creativeResource.savePartial());

      const rejCreatives = _.filter(ctrl.lir.creatives, function(c) {
        return c.creative.userState === 'REJECTED';
      });

      if (rejCreatives.length === 1) {
        const lineItemResource = new LineItem({id: ctrl.model.id, userState: 'APPROVED'});
        promises.push(lineItemResource.savePartial());
      }

      $q.all(promises).then(function() {
        ctrl.model.userState = 'APPROVED';
        creative.userState = 'APPROVED';
        creative.rejectedReason = null;
        ctrl.unrejectError = false;
      }).catch(function() {
        ctrl.unrejectError = true;
      });
    };

    ctrl.editTarget = function(targeting, creative) {
      const targetingModel = creative ? new Creative(angular.copy(creative)) : angular.copy(ctrl.model);
      const typeTargeting = creative ? 'creativeTargeting' : 'lineItemTargeting';
      ctrl[typeTargeting] = {
        data: false
      };
      $uibModal.open({
        template: targetingTemplate,
        controller: 'TargetingCtrl',
        size: 'xl',
        resolve: {
          modelLineItem: function() {
            return targetingModel;
          },
          pageType: function() {
            return "campaign";
          },
          reachEnabled: function() {
            return 'LINE_ITEM';
          },
          targetingMode: function() {
            return null;
          },
          overridableTargeting: function() {
          },
          mandatoryTargets: _.noop,
          availableTargets: _.noop,
          targeting: function() {
            return targeting;
          },
          isLockedDown: function() {
            return ctrl.isLockedDown;
          }
        }
      })
        .result.then(function(wc) {
        _.assign(targeting, wc.wc);
        targetingModel.targeting = targeting;
        if (!ctrl.isNew) {
          targetingModel.savePartial('targeting').then(function(respLineItem) {
            ctrl[typeTargeting].data = true;
            _.set(targetingModel, 'validationWarnings', respLineItem.validationWarnings);
          });
        }
      });
    };

    ctrl.onBeforeSubmit = function() {
      liCommons.modDates(ctrl.model.modStartDate, ctrl.model.modEndDate);
    };
  });

export default MODULE_NAME;