/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import template from './data-export-copy-modal.html';

import standardActionsModule from '../../common/standard-actions-module';
import {Uuid} from "../../../components/util/uuid";

const MODULE_NAME = "data-export-actions-controller";

angular.module(MODULE_NAME, [standardActionsModule])

  .controller('DataExportActionsCtrl', function($q, toastr, $stateParams, $state, $uibModal, model, modelComms, readOnly, DataExport, adnModalLoading, $log) {
    const ctrl = this;
    ctrl.readOnly = readOnly;

    ctrl.model = model;
    ctrl.modelComms = modelComms;
    ctrl.isNew = $stateParams.isNew;

    ctrl.copy = function() {
      let modalInstance = $uibModal.open({
        template: template,
        windowClass: '',
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          modelComms.addSubscriber(function(data, type) {
            if (!type) {
              modalCtrl.model = data;
              modalCtrl.model.name = data.name + " (copy)";
              modalCtrl.model.description = data.description;
            }
          });

          modalCtrl.destination = true;
          modalCtrl.disabled = false;
          modalCtrl.validationErrors = [];
          modalCtrl.copyDataExport = function() {
            modalCtrl.disabled = true;
            adnModalLoading.applyModalLoading();
            const newId = Uuid.generate();
            $q.all(DataExport.copyDataExport(modalCtrl.model, newId)).then(function() {
              modalCtrl.disabled = false;
              modalInstance.close({destination: modalCtrl.destination, dataExportId: newId});
              adnModalLoading.removeModalLoading();
            }, function(errors) {
              modalCtrl.disabled = false;
              modalCtrl.validationErrors = errors.data.errors;
              adnModalLoading.removeModalLoading();
            });
          };
        }
      });
      modalInstance.result.then(function(result) {
        adnModalLoading.removeModalLoading();
        if (result.destination) {
          $state.go('app.data-exports.data-export', {id: result.dataExportId}, {inherit: false});
        }
        if (result.errors) {
          $log.warn(result.errors);
          toastr.warning("This data export could not be copied.", "Data Export Copy Failed");
        } else if (!result.destination) {
          toastr.success("A copy of this data export was successfully created.", "Data Export Copied");
        }
      }, function() {
        // dismissal handler to avoid unhandled exceptions -- don't blame me, blame uibModal
      });
    };
  });

export default MODULE_NAME;