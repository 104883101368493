/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = 'form-control-directive';

angular.module(MODULE_NAME, [])

  .directive('adnFormControl', function() {
    return {
      restrict: 'A',
      require: ['ngModel', '^^adnFormControlWrapper', '?^^adnFormControlValidation'],
      link: function(scope, element, attrs, controllers) {
        let ngModel = controllers[0],
          formControlWrapper = controllers[1],
          formControlValidation = controllers[2];

        formControlWrapper.addControl(ngModel);
        if (formControlValidation) {
          formControlValidation.setFormControl(ngModel);
        }
      }
    };
  });

export default MODULE_NAME;