/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './api-key-edit.html';
import deleteTemplate from '../../common/delete-actions.html';
import listTemplate from './api-key-list.html';

import standardModule from '../../common/standard-module';
import adminModule from '../admin-module';
import apiKeyCtrl from './api-key-controller';

const MODULE_NAME = 'api-key-module';

angular.module(MODULE_NAME, [adminModule, apiKeyCtrl, standardModule])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('api-keys.api-key', 'ApiKey', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        readOnly: function(LocalUserPermissions) {
          return LocalUserPermissions.isSelfServiceOwn();
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: 'ApiKeyCtrl',
            controllerAs: 'resource',
            template: editTemplate
          },
          'context@app': {
            controller: /*@ngInject*/ function(model, modelComms, $stateParams) {
              const ctrl = this;
              ctrl.model = model;
              ctrl.modelComms = modelComms;
              ctrl.isNew = $stateParams.isNew;
            },
            controllerAs: 'ctrl',
            template: deleteTemplate
          }
        }
      }, 'ApiKey'));
  });

export default MODULE_NAME;