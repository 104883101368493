/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import localNetworkProfile from '../../components/session/local-network-profile';
import localUserPermissions from '../../components/session/local-user-permissions';
import trafficComponent from '../common/directives/related-data-chart-component';
import timeShifter from '../../components/util/time-shifter';
import standardFormModule from '../common/standard-form-module';
import rateLimitComponent from '../common/controller/rate-limit-component';
import standardAnalysisModule from '../common/standard-analysis-module';
import moment from "moment/moment";
import 'moment-timezone';
import {ResourceHelper} from "../../components/util/resource-helper";

const MODULE_NAME = "budget-modal-controller";

export function addSecondForUi(budgets) {
  for (let i = 0; i < budgets.length; i++) {
    const prev = i > 0 ? budgets[i - 1] : null;
    const current = budgets[i];
    if (!prev) {
      continue;
    }
    if (moment(prev.modEndDay).diff(moment(current.modStartDay), "seconds") === 0) {
      current.modStartDay = moment(current.modStartDay).add(1, 'second').toDate();
    }
  }
  return budgets;
}

angular.module(MODULE_NAME, [
  standardFormModule,
  standardAnalysisModule,
  timeShifter,
  rateLimitComponent,
  localNetworkProfile,
  localUserPermissions,
  trafficComponent,
])

  .controller('BudgetModalCtrl', function(adnTimeShifter, adnModalLoading, ChartOptionsUtil, modelObject, budgetResult, moneyCurrency, objTranslation) {
    const modalCtrl = this;

    modalCtrl.objTranslation = objTranslation;
    const browserTz = moment.tz.guess();

    function updateTimes(budget) {
      if (budget.startDay) {
        budget.modStartDay = moment.tz(budget.startDay, browserTz).startOf('day').toDate();
      }
      if (budget.endDay) {
        budget.modEndDay = moment.tz(budget.endDay, browserTz).endOf('day').toDate();
      }
      budget.editable = moment.tz(budget.startDay, ChartOptionsUtil.getNetworkTimezone()).startOf('day').isAfter(moment.tz(ChartOptionsUtil.getNetworkTimezone())) ||
        moment.tz(budget.endDay, ChartOptionsUtil.getNetworkTimezone()).endOf('day').isAfter(moment.tz(ChartOptionsUtil.getNetworkTimezone()));
    }

    function addFromScratch() {
      const startDate = modelObject.modStartDate || adnTimeShifter.getMomentInNetworkTimezone().format("YYYY-MM-DD");
      const endDate = modelObject.modEndDate || adnTimeShifter.getMomentInNetworkTimezone().endOf("month").format("YYYY-MM-DD");
      const budget = {
        startDay: startDate,
        endDay: endDate,
        editable: true
      };
      const budgetObj = _.get(modelObject, ['objectives', 'BUDGET']) || {};
      if (_.isFinite(budgetObj.amount) && budgetObj.amount > 0) {
        budget.amount = budgetObj;
      }
      updateTimes(budget);
      return budget;
    }

    function checkDatesChange() {
      modalCtrl.changesDates = false;
      const matches = {
        startDateMatch: false,
        endDateMatch: false
      };
      if (modalCtrl.budget.length < 1) {
        return matches;
      }
      if (modelObject.startDate) {
        matches.startDateMatch = moment(modelObject.modStartDate).format("YYYY-MM-DD") === moment(modalCtrl.budget[0].modStartDay).format("YYYY-MM-DD");
        modalCtrl.changesDates = !matches.startDateMatch;
      }
      if (modelObject.endDate) {
        matches.endDateMatch = moment(modelObject.modEndDate).format("YYYY-MM-DD") === moment(modalCtrl.budget[modalCtrl.budget.length - 1].modEndDay).format("YYYY-MM-DD");
        modalCtrl.changesDates = modalCtrl.changesDates || !matches.endDateMatch;
      }
      return matches;
    }

    modalCtrl.budgetRollover = modelObject.budgetRollover;
    modalCtrl.moneyCurrency = moneyCurrency;
    modalCtrl.budget = _.cloneDeep(modelObject.budget) || [];
    if (modalCtrl.budget.length === 0) {
      modalCtrl.budget.push(addFromScratch());
    } else if (modelObject.startDate) {
      modalCtrl.budget[0].startDay = modalCtrl.budget[0].startDay || modelObject.startDate;
      if (modelObject.endDate) {
        modalCtrl.budget[modalCtrl.budget.length - 1].endDay = modalCtrl.budget[modalCtrl.budget.length - 1].endDay || modelObject.endDate;
      }
    }
    _.forEach(modalCtrl.budget, function(b) {
      updateTimes(b);
    });

    modalCtrl.changeCallback = function() {
      checkDatesChange();
    };

    modalCtrl.addBudgetPeriod = function() {
      if (modalCtrl.budget.length === 0) {
        modalCtrl.budget.push(addFromScratch());
        checkDatesChange();
        return;
      }
      const refBudget = modalCtrl.budget[modalCtrl.budget.length - 1];
      const adjustedStartDate = moment(refBudget.endDay).add(1, "day").startOf('day').format("YYYY-MM-DD");
      const adjustedEndDate = moment(adjustedStartDate).endOf('month').format("YYYY-MM-DD");
      const budget = {
        startDay: adjustedStartDate,
        endDay: adjustedEndDate
      };
      updateTimes(budget);
      modalCtrl.budget.push(budget);
      checkDatesChange();
    };

    _.forEach(modalCtrl.budget, function(b) {
      updateTimes(b);
    });

    modalCtrl.budget = addSecondForUi(modalCtrl.budget);

    modalCtrl.removePeriod = function(index) {
      _.pullAt(modalCtrl.budget, index);
      checkDatesChange();
    };

    modalCtrl.updateBudgets = function() {
      modelObject.budget = _.cloneDeep(modalCtrl.budget);

      const firstBudgetPeriod = _.cloneDeep(_.get(modelObject.budget, [0]));
      const endBudgetPeriod = _.cloneDeep(_.get(modelObject.budget, [modelObject.budget.length - 1]));
      const matches = checkDatesChange();

      _.forEach(modelObject.budget, function(b) {
        b.startDay = adnTimeShifter.getMomentInNetworkTimezone(moment(adnTimeShifter.timeShift(b.modStartDay, true))).format("YYYY-MM-DD");
        b.endDay = adnTimeShifter.getMomentInNetworkTimezone(adnTimeShifter.timeShift(b.modEndDay, true)).format("YYYY-MM-DD");

        ResourceHelper.checkMoney(b, ['amount']);
        delete b.modStartDay;
        delete b.modEndDay;
        delete b.nextPeriodOption;
      });

      const fieldsToSave = ['budget', 'budgetRollover'];
      if (modelObject.budget.length > 0) {
        if (modelObject.startDate && !matches.startDateMatch) {
          modelObject.startDate = adnTimeShifter.timeShift(moment(firstBudgetPeriod.modStartDay).startOf('day'), true);
          fieldsToSave.push('startDate');
        }
        if (modelObject.endDate && !matches.endDateMatch) {
          modelObject.endDate = adnTimeShifter.timeShift(moment(endBudgetPeriod.modEndDay).endOf('day'), true);
          fieldsToSave.push('endDate');
        }
      }
      const budgetObj = _.get(modelObject, ['objectives', 'BUDGET']) || {};
      if (modelObject.budget.length > 0 && budgetObj.amount && budgetObj.currency) {
        modelObject.objectives.BUDGET = null;
        fieldsToSave.push("objectives");
      }

      modelObject.budgetRollover = modalCtrl.budgetRollover;

      modalCtrl.disabled = true;
      adnModalLoading.applyModalLoading();
      modelObject.savePartial(fieldsToSave).then(function(savedObj) {
        budgetResult.data = true;
        modalCtrl.disabled = false;
        budgetResult.modalInstance.close({obj: savedObj});
        adnModalLoading.removeModalLoading();
      }, function(errors) {
        modalCtrl.disabled = false;
        modalCtrl.validationErrors = errors.data.errors;
        adnModalLoading.removeModalLoading();
      });
    };

  });

export default MODULE_NAME;