/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import {HttpHelper} from "../../../util/http-helper";

const MODULE_NAME = "network-data";

angular.module(MODULE_NAME, [])
  .factory("NetworkData", function(adnResource, $http) {
    return adnResource('networkdata', {}, HttpHelper.obtain($http).transformJsonObjActions('data'));
  });

export default MODULE_NAME;