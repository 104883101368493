/*
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import {getCountryName} from "../../app/common/directives/country-select/countries";

const MODULE_NAME = 'country-filter';

angular.module(MODULE_NAME, [])
  .filter('country', function () {
    return function (countryCode) {
      return getCountryName(countryCode);
    };
  });

export default MODULE_NAME;