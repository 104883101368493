/**
 * Copyright © 2025 Adnuntius AS.
 */
import * as _ from 'lodash';

export const MEDIA_TYPE = {
  gif: {id: 'gif', mimeTypes: ['IMAGE_GIF'], rawMimeTypes: ['image/gif'], image: true},
  jpg: {id: 'jpg', mimeTypes: ['IMAGE_JPEG', 'IMAGE_PJPEG'], rawMimeTypes: ['image/jpeg', 'image/pjpeg'], image: true},
  png: {id: 'png', mimeTypes: ['IMAGE_PNG', 'IMAGE_X_PNG'], rawMimeTypes: ['image/png', 'image/x-png'], image: true},
  svg: {id: 'svg', mimeTypes: ['IMAGE_SVG'], rawMimeTypes: ['image/svg+xml'], image: true},
  webp: {id: 'webp', mimeTypes: ['IMAGE_WEBP'], rawMimeTypes: ['image/webp'], image: true},
  font: {id: 'font', mimeTypes: ['APPLICATION_X_WOFF', 'APPLICATION_X_FONT_TTF', 'APPLICATION_X_FONT_WOFF', 'APPLICATION_MS_FONT_OBJECT', 'APPLICATION_FONT_WOFF', 'APPLICATION_X_FONT_OTF'], rawMimeTypes: ['application/x-font-otf', 'application/x-font-woff', 'application/x-font-ttf', 'application/x-woff', 'application/font-woff', 'application/vnd.ms-fontobject'], other: true},
  css: {id: 'css', mimeTypes: ['TEXT_CSS'], rawMimeTypes: ['text/css'], other: true},
  csv: {id: 'csv', mimeTypes: ['TEXT_CSV'], rawMimeTypes: ['text/csv'], other: true},
  excel: {id: 'excel', mimeTypes: ['APPLICATION_XLS', 'APPLICATION_XLSX'], rawMimeTypes: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'], other: true},
  js: {id: 'js', mimeTypes: ['APPLICATION_JAVASCRIPT', 'TEXT_JAVASCRIPT'], rawMimeTypes: ['application/javascript', 'text/javascript'], other: true},
  subtitle: {id: 'subtitle', mimeTypes: ['TEXT_VTT', 'TEXT_SRT'], rawMimeTypes: ['text/vtt', 'text/srt'], other: true},
  json: {id: 'json', mimeTypes: ['APPLICATION_JSON'], rawMimeTypes: ['application/json'], other: true},
  txt: {id: 'txt', mimeTypes: ['TEXT_PLAIN'], rawMimeTypes: ['text/plain'], other: true},
  flash: {id: 'flash', mimeTypes: ['APPLICATION_X_SHOCKWAVE_FLASH', 'APPLICATION_VND_ADOBE_FLASH_MOVIE'], rawMimeTypes: ['application/x-shockwave-flash', 'application/vnd.adobe.flash.movie'], other: true},
  zip: {id: 'zip', mimeTypes: ['APPLICATION_ZIP', 'APPLICATION_X_ZIP_COMPRESSED', 'MULTIPART_X_ZIP', 'APPLICATION_X_COMPRESSED'], rawMimeTypes: ['application/zip', 'application/x-zip-compressed', 'multipart/x-zip', 'application/x-compressed'], zip: true},
  xhtml: {id: 'xhtml', mimeTypes: ['TEXT_XHTML'], rawMimeTypes: ['application/xhtml+xml'], html: true},
  html: {id: 'html', mimeTypes: ['TEXT_HTML', 'TEXT_XHTML'], rawMimeTypes: ['text/html', 'application/xhtml+xml'], html: true},
  mp4: {id: 'mp4', mimeTypes: ['VIDEO_MP4', 'VIDEO_M4V', 'VIDEO_X_M4V'], rawMimeTypes: ['video/mp4', 'video/m4v', 'video/x-mp4'], video: true},
  webm: {id: 'webm', mimeTypes: ['VIDEO_WEBM'], rawMimeTypes: ['video/webm'], video: true},
  mp3: {id: 'mp3', mimeTypes: ['AUDIO_MP3'], rawMimeTypes: ['audio/mpeg'], audio: true},
  quicktime: {id: 'quicktime', mimeTypes: ['VIDEO_QUICKTIME'], rawMimeTypes: ['video/quicktime'], video: true},
  hls: {id: 'hls', mimeTypes: ['VIDEO_HLS'], rawMimeTypes: ['application/x-mpegURL'], video: true},
  dash: {id: 'dash', mimeTypes: ['VIDEO_DASH'], rawMimeTypes: ['application/dash+xml'], video: true}
};


export class MediaTypeHelper {
  static getMediaType(mimeTypes) {
    if (!mimeTypes) {
      return;
    }
    mimeTypes = _.isArray(mimeTypes) ? mimeTypes : [mimeTypes];
    return _.find(MEDIA_TYPE, function(mediaType) {
      return _.intersection(mediaType.mimeTypes, mimeTypes).length > 0 || _.intersection(mediaType.rawMimeTypes, mimeTypes).length > 0;
    });
  }

  static getMediaTypes(mimeTypes) {
    if (!mimeTypes) {
      return;
    }
    mimeTypes = _.isArray(mimeTypes) ? mimeTypes : [mimeTypes];
    return _.map(_.filter(MEDIA_TYPE, function(mediaType) {
      return _.intersection(mediaType.mimeTypes, mimeTypes).length > 0 || _.intersection(mediaType.rawMimeTypes, mimeTypes).length > 0;
    }), "id");
  }

  static isMediaType(mimeTypes, mediaType) {
    return _.indexOf(this.getMediaTypes(mimeTypes), mediaType.id) > -1;
  }

  static getImageMimeTypes() {
    return _.flatten(_.map(_.filter(MEDIA_TYPE, 'image'), "mimeTypes"));
  }

  static getStreamingImageMimeTypes() {
    return _.flatten([MEDIA_TYPE.jpg.mimeTypes, MEDIA_TYPE.png.mimeTypes, MEDIA_TYPE.gif.mimeTypes]);
  }

  static getVideoMimeTypes() {
    return _.flatten(_.map(_.filter(MEDIA_TYPE, 'video'), "mimeTypes"));
  }

  static getAudioMimeTypes() {
    return _.flatten(_.map(_.filter(MEDIA_TYPE, 'audio'), "mimeTypes"));
  }

  static getOtherMediaTypes() {
    return _.filter(MEDIA_TYPE, 'other');
  }
}