/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

const MODULE_NAME = 'custom-validation-integer';

angular.module(MODULE_NAME, [])

  .directive('adnIntegerValidation', function() {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, modelCtrl) {

        modelCtrl.$parsers.push(function(inputValue) {
          if (!_.isFinite(inputValue)) {
            return '';
          }
          let inputValueString = inputValue.toString(10);
          let transformedInput = inputValueString.replace(/\./g, '');
          if (transformedInput !== inputValueString) {
            transformedInput = parseInt(transformedInput);
            modelCtrl.$setViewValue(transformedInput);
            modelCtrl.$render();
            return transformedInput;
          }
          return inputValue;
        });
      }
    };
  });

export default MODULE_NAME;