/**
 * Copyright © 2025 Adnuntius AS.
 */

import * as _ from 'lodash';

export const interpolateWarning = function($interpolate, warning): string {
  const expression = $interpolate(warning.text);
  return expression(warning.parameters);
};

export const apiInterpolate = function(warning): string {
  let warningText = warning.text;
  _.forEach(warning.parameters, function (val, key) {
    warningText = warningText.replace(new RegExp("{{" + key + "}}", "gi"), val);
  });
  return warningText;
};