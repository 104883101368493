/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';
import checklistModel from 'checklist-model';

import template from './device-targeting.html';

import resources from '../../../components/api/resources/resources';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import {ADN_TARGETING_TYPES} from "../targeting-constants";
import {TargetingHelper} from "../targeting-helper";

const MODULE_NAME = 'device-targeting-directive';

angular.module(MODULE_NAME, [translate, uiBootstrap, checklistModel, resources, promiseSpinner])

  .directive('adnDeviceTargeting', function($translate, $filter, devicesResource) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnDeviceTargeting',
        underModel: '=',
        mandatory: '<',
        overridableTargeting: '<',
        isLockedDown: '<',
        mode: '@'
      },
      link: function(scope, element, attrs, tabset) {
        scope.loaded = true;
        scope.resolved = false;
        scope.model = scope.model || [];
        scope.targetType = ADN_TARGETING_TYPES.device;

        const emptyModel = {
          targetedDeviceTypes: [],
          targetedOSes: [],
          targetedBrands: []
        };

        let addNewDeviceModel = function() {
          scope.vm.push(angular.copy(emptyModel));
          scope.vmIndex = scope.vm.length - 1;
        };

        let sanityCheckVmIndex = function() {
          if (!_.isFinite(scope.vmIndex) || scope.vmIndex > (scope.vm.length - 1)) {
            scope.vmIndex = -1;
          }
        };

        let init = function() {
          scope.vm = [];
          _.forEach(scope.model, function(entryObj) {
            scope.vm.push(entryObj);
          });

          if (scope.vm.length === 0) {
            addNewDeviceModel();
          }
          sanityCheckVmIndex();
        };
        init();

        scope.edit = function(editIndex) {
          scope.vmIndex = editIndex;
        };

        scope.up = function() {
          scope.vmIndex = -1;
        };

        scope.add = function() {
          addNewDeviceModel();
        };

        scope.remove = function(index) {
          _.pullAt(scope.vm, index);
          if (scope.vmIndex >= index) {
            scope.vmIndex--;
          }
          sanityCheckVmIndex();
        };

        let arrSafe = function(data) {
          return _.isArray(data) && data.length > 0;
        };

        let prepareForSave = function() {
          scope.model = scope.model || [];
          scope.model.length = 0;

          _.forEach(scope.vm, function(entryObj) {
            if (arrSafe(_.get(entryObj, 'targetedDeviceTypes')) || arrSafe(_.get(entryObj, 'targetedOSes')) || arrSafe(_.get(entryObj, 'targetedBrands'))) {
              scope.model.push(entryObj);
            }
          });

          init();
        };

        scope.hook = {};
        scope.hook.getTemplateData = function() {
          prepareForSave();
          const summaryData = TargetingHelper.getSummary(scope.model, ADN_TARGETING_TYPES.device);
          let summaryString = [];
          _.forEach(summaryData, function(values, prop) {
            summaryString.push($translate.instant('targeting.device.' + prop) + ": " + $filter('adnDeviceTargetingSelections')(values, prop));
          });
          summaryString = summaryString.join("\n");

          return {
            vm: angular.copy(scope.vm),
            model: angular.copy(scope.model),
            constant: ADN_TARGETING_TYPES.device,
            summary: summaryString
          };
        };

        const copyTemplateFunc = function(template) {
          scope.model = angular.copy(template.data.model);
          init();
        };
        scope.hook.copyTemplate = copyTemplateFunc;

        tabset.callBeforeSubmit(prepareForSave);
        tabset.registerAllTabs(prepareForSave);

        tabset.register(ADN_TARGETING_TYPES.device.widget, function() {
          if (scope.resolved) {
            return;
          }
          devicesResource.get().then(function(deviceProperties) {
            scope.deviceProperties = deviceProperties;
            scope.resolved = true;
          });
        }, copyTemplateFunc);
      }
    };
  });

export default MODULE_NAME;