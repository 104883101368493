/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import localNetworkProfile from '../../../components/session/local-network-profile';

const MODULE_NAME = 'fee-display-component';

angular.module(MODULE_NAME, [localNetworkProfile])

  .component('adnFeeView', {
    bindings: {
      fee: '<'
    },
    controllerAs: 'ctrl',
    controller: function() {
      const ctrl = this;

      ctrl.$onInit = function() {

      };
    },
    template: `
      <span ng-if="ctrl.fee.type === 'PERCENTAGE'">
        {{ctrl.fee.percentage / 100 | percentage}}
      </span>
      <span ng-if="ctrl.fee.type === 'MONETARY'">
        {{ctrl.fee.monetary | adnStat}}
      </span>
      <span ng-if="ctrl.fee.type === 'MULTI_CURRENCY_MONETARY'">
        <span ng-repeat="ma in ctrl.fee.multiCurrencyMonetaryAmounts">{{ma | adnStat}}<span ng-if="!$last">, </span></span>
      </span>
    `
  });

export default MODULE_NAME;