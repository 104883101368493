/**
 * Copyright © 2025 Adnuntius AS.
 */
import * as _ from 'lodash';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {LayoutComponentHelper} from "../../components/util/layout-component-helper";

@Component({
  selector: 'layout-component-summary',
  template: `<div *ngFor="let out of output">{{out}}</div>`
})
export class LayoutComponentSummaryComponent implements OnInit {
  @Input() layout: Record<string, any>;
  output: Array<string>;

  constructor(@Inject('$translate') private translate: any) {
  }

  ngOnInit(): void {
    const summary = LayoutComponentHelper.getSummary(this.layout.layoutComponents);
    this.output = _.filter(_.map(summary, (val, key) => {
      return val > 0 ? val + " " + this.translate.instant('layout.fieldSummary.' + key + (val > 1 ? '.plural' : '')) : null;
    }), (val) => {
      return !!val;
    });
  }
}
