/*
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import moment from 'moment';
import 'moment-timezone';

import localNetworkProfile from '../session/local-network-profile';

const MODULE_NAME = "time-shifter";

angular.module(MODULE_NAME, [localNetworkProfile])

  .factory('adnTimeShifter', function(LocalNetworkProfile) {
    return {
      startAndEndOfDays: function(startDate, endDate) {
        const networkTimezone = this.getNetworkTimezone();
        const startDateString = moment(startDate).format('YYYY-MM-DD');
        const endDateString = moment(endDate).format('YYYY-MM-DD');
        const nowDateString = moment().format('YYYY-MM-DD');
        const timelessTimezonedStartDate = moment.tz(startDateString, networkTimezone);
        const timelessTimezonedEndDate = moment.tz(endDateString, networkTimezone);
        const timelessTimezonedNow = moment.tz(nowDateString, networkTimezone);
        const correctStartDate = timelessTimezonedStartDate.startOf('day');
        const nowRemainder = 30 - (moment().tz(networkTimezone).minute() % 30);
        const correctEndDate = timelessTimezonedEndDate.isSame(timelessTimezonedNow) ? moment().tz(networkTimezone).add(nowRemainder, "minutes") : timelessTimezonedEndDate.endOf('day');
        return {
          startDate: this.timeShift(correctStartDate.toDate()),
          endDate: this.timeShift(correctEndDate.toDate())
        };
      },
      getNetworkTimezone() {
        return LocalNetworkProfile.get('timeZone') || 'utc';
      },
      getMomentInNetworkTimezone(theDate) {
        return moment(theDate || moment()).tz(this.getNetworkTimezone());
      },
      timeShift: function(theDate, toBrowserTime) {
        const timeZone = this.getNetworkTimezone();
        const preferredTz = moment(theDate).tz(timeZone).utcOffset();
        const browserTz = moment(theDate).utcOffset();
        return moment(theDate).add((preferredTz - browserTz) * (toBrowserTime ? -1 : 1), "minute").toDate();
      }
    };
  });

export default MODULE_NAME;