/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import reachAnalysisComp from '../common/controller/reach-analysis-component';

const MODULE_NAME = "lineItemReachController";

angular.module(MODULE_NAME, [reachAnalysisComp])

  .controller('LineItemReachController', function(model, modelComms) {
    const ctrl = this;
    ctrl.model = model;
    ctrl.modelComms = modelComms;
  });

export default MODULE_NAME;