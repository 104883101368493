/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

const MODULE_NAME = 'geospatial-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnGeospatialTargetingSummary', function() {
    return {
      restrict: 'A',
      template: '{{data}} geospatial area{{data !== 1 ? "s" : ""}}',
      scope: {
        targets: '=adnGeospatialTargetingSummary'
      },
      link: function(scope) {
        scope.data = [];
        scope.$watchCollection('targets', function(targets) {
          scope.targets = targets || {};
        });
        scope.$watchCollection('targets', function(targets) {
          let oneTarget = targets[0] || {};
          scope.data = (_.get(oneTarget, 'definition.geometries') || []).length;
        });
      }
    };
  });

export default MODULE_NAME;