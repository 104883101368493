/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "payment";

angular.module(MODULE_NAME, [])

  .factory("Payment", function(adnResource) {
    return adnResource('payment');
  });

export default MODULE_NAME;