/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

import resources from '../../../components/api/resources/resources';
import promiseSpinnerComponent from '../directives/html/promise-spinner-component';
import lineChartDirective from './line-directive';
import explorationTable from './exploration-table';
import template from './exploration.html';

import {convertIabCategoryToString} from "../../../components/util/iab-taxonomy";
import moment from "moment";

const MODULE_NAME = "exploration-component";

angular.module(MODULE_NAME, [
  translate,
  resources,
  explorationTable,
  promiseSpinnerComponent,
  lineChartDirective
])

  .component('adnExplorationComponent', {
    template: template,
    bindings: {
      obj: '<',
      param: '@'
    },
    controllerAs: 'ctrl',
    controller: function(trafficHistogramResource, $filter, adnTimeShifter) {
      const ctrl = this;

      ctrl.explorerTypes = [
        {value: 'SEGMENT', title: 'Segment', chartData: 'segments', filterId: 'userSegment', filterName: true},
        {value: 'CATEGORY', title: 'Category', chartData: 'categories', filterId: 'category'},
        {value: 'KEYWORD', title: 'Keyword', chartData: 'keywords', filterId: 'keyword'},
        {value: 'KEY_VALUES', title: 'Key Value', chartData: 'keyValues', filterId: 'keyValue'},
        {value: 'COUNTRY', title: 'Country', chartData: 'countries', filterId: 'country'},
        {value: 'REGION', title: 'Region', chartData: 'regions', filterId: 'region'},
        {value: 'CITY', title: 'City', chartData: 'cities', filterId: 'city'},
        {value: 'DEVICE', title: 'Device', chartData: 'devices', filterId: 'device'}
      ];

      ctrl.$onInit = function() {
        ctrl.startDate = moment().add(1, 'hours');
        ctrl.endDate = moment().add(7, 'days');
        ctrl.modStartDate = adnTimeShifter.timeShift(ctrl.startDate);
        ctrl.modEndDate = adnTimeShifter.timeShift(ctrl.endDate);

        ctrl.clearExplorerFilter = function() {
          _.forEach(ctrl.explorerTypes, function(type) {
            if (type.filterName) {
              _.set(ctrl, type.filterName, null);
              _.set(ctrl, type.filterName + "AsString", null);
              _.set(ctrl, type.filterName + "AsStringName", null);
            }
            if (type.filterId) {
              _.set(ctrl, type.filterId, null);
              _.set(ctrl, type.filterId + "AsString", null);
              _.set(ctrl, type.filterId + "AsStringName", null);
            }
          });
          ctrl.hasFilter = false;
        };

        ctrl.updateFilter = function(type, value) {
          ctrl.hasUpdatedFilter = true;
          _.forEach(ctrl.explorerTypes, function(t) {
            if (t.value === type) {
              const id = _.get(value, 'id');
              const name = _.get(value, 'name');
              let filterValue;
              if (id && t.filterId) {
                ctrl[t.filterId] = ctrl[t.filterId] || [];
                filterValue = id;
                if (name && t.filterName) {
                  filterValue = {id: filterValue, name: name};
                }
                const findMatch = _.find(ctrl[t.filterId], function(entry) {
                  return entry === filterValue || (!!entry.id && entry.id === filterValue.id);
                });
                if (findMatch) {
                  return;
                }
                ctrl[t.filterId].push(filterValue);
                ctrl[t.filterId + "AsString"] = filterValue.id ? _.map(ctrl[t.filterId], function(f) {
                  return f.id;
                }).join(", ") : ctrl[t.filterId].join(", ");
                ctrl[t.filterId + "AsStringName"] = filterValue.name ? _.map(ctrl[t.filterId], function(f) {
                  return f.name;
                }).join(", ") : "";
                ctrl.hasFilter = true;
              }
            }
          });
        };

        ctrl.updateCustomFilter = function () {
          ctrl.updateFilter(ctrl.customFilterType, {id: ctrl.customFilter});
          // Clear the text so that another item can be added
          ctrl.customFilter = null;
        };

        ctrl.headingBadge = function(count) {
          return count < 50 ? count : "50+";
        };

        ctrl.exploreAdUnit = function() {
          ctrl.exploration = {};
          ctrl.exploration.error = false;
          ctrl.exploration.completed = false;
          ctrl.exploration.hasFilter = ctrl.hasFilter;

          const filter = {};
          _.forEach(ctrl.explorerTypes, function(type) {
            const rawValue = _.get(ctrl, type.filterId + "AsString", "");
            const elements = _.filter(_.map((_.isString(rawValue) ? rawValue : "").split(","), function(s) {
              return _.trim(s);
            }), function(s) {
              return _.isString(s) && s.length > 0;
            });
            let filterValue = elements.length && elements[0] !== "" ? elements : null;
            if (filterValue) {
              _.set(filter, type.value, _.map(filterValue,
                  function (item) {
                    return _.get(item, 'id') || item;
                })
              );
            }
          });

          const dates = {startDate: null, endDate: null};
          if (_.isDate(ctrl.modStartDate) && _.isDate(ctrl.modEndDate)) {
            const adjustedDates = adnTimeShifter.startAndEndOfDays(adnTimeShifter.timeShift(ctrl.modStartDate, true), adnTimeShifter.timeShift(ctrl.modEndDate, true));
            dates.startDate = adjustedDates.startDate;
            dates.endDate = adjustedDates.endDate;
          }

          trafficHistogramResource.get(
            ctrl.obj.id,
            ctrl.param || 'adUnit',
            _.map(ctrl.explorerTypes, function(et) {
              return et.value;
            }),
            filter,
            dates.startDate,
            dates.endDate
          ).then(function(data) {
            ctrl.firstRunThrough = true;
            _.forEach(ctrl.explorerTypes, function(type) {
              _.set(ctrl.exploration, type.chartData, _.map(_.get(data, ['data', type.value, 'histogram']) || [], function(entry) {
                if (type.filterName) {
                  let object = _.get(entry, type.filterId);
                  let id = _.get(object, 'id');
                  let name = _.get(object, 'name');
                  return {name: convertIabCategoryToString(name), y: entry.frequency, id: id, nextWeek: entry.expectedNextWeek};
                } else {
                  return {name: convertIabCategoryToString(entry.value), y: entry.frequency, id: entry.value, nextWeek: entry.expectedNextWeek};
                }
              }));

              const chartData = _.map(_.get(ctrl.exploration, type.chartData), function(data) {
                return {
                  name: data.name,
                  y: parseFloat($filter('number')(data.y, 0))
                };
              });
              ctrl.exploration[type.chartData].statsChart = (!chartData || !chartData.length) ? null : {
                chart: {
                  type: 'bar',
                  zoomType: 'x',
                  showAxes: true,
                  height: Math.min(100 + chartData.length * 20, 1000)
                },
                plotOptions: {
                  series: {
                    colorByPoint: true,
                    turboThreshold: 2000
                  }
                },
                legend: {
                  enabled: false
                },
                tooltip: {
                  formatter: function() {
                    return this.x + '<br><strong>' + this.y + '%</strong>';
                  }
                },
                xAxis: {
                  categories: _.map(chartData, 'name')
                },
                yAxis: {
                  min: 0,
                  max: 100,
                  title: {
                    text: 'Presence in Traffic Samples (%)'
                  },
                },
                series: [{
                  name: 'Presence (%)',
                  data: _.map(chartData, 'y')
                }],
                title: {
                  text: null
                },
                credits: {
                  enabled: false
                },
                exporting: {
                  enabled: true
                }
              };
            });

            _.forEach(ctrl.exploration, function(type, key) {
              if (_.isArray(type) && type.length > 0) {
                ctrl.selectedTile = ctrl.selectedTile || key;
              }
            });
            if (!ctrl.selectedTile) {
              ctrl.selectedTile = "segments";
            }

            ctrl.exploration.completed = true;
          }).catch(function() {
            ctrl.exploration.error = true;
            ctrl.exploration.completed = true;
          });
        };
        ctrl.exploreAdUnit();
      };
    }
  });

export default MODULE_NAME;