/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';
import _ from 'lodash';

import template from './tier-priority.html';

import resources from '../../../components/api/resources/resources';
import localUserPermissions from '../../../components/session/local-user-permissions';
import localNetworkProfile from '../../../components/session/local-network-profile';
import listHelper from '../../common/controller/list-helper';
import sortingHeader from '../../common/directives/html/sorting-header-directive';
import paginationComp from '../../common/directives/pagination/pagination-component';
import tierPrioCtrl from './tier-priority';

const MODULE_NAME = "tier-list-controller";

angular.module(MODULE_NAME, [
  uiBootstrap,
  resources,
  listHelper,
  sortingHeader,
  paginationComp,
  localUserPermissions,
  localNetworkProfile,
  tierPrioCtrl
])

  .controller('TierListCtrl', function($q, $uibModal, tierResource, Network, LocalNetworkProfile, readOnly) {
    const ctrl = this;
    ctrl.readOnly = readOnly;

    let network;
    let init = function() {
      ctrl.resolved = false;

      let tiersPromise = tierResource.query().$promise;
      let networkPromise = network ? network : Network.get({id: LocalNetworkProfile.getNetworkId()}).$promise;

      $q.all([tiersPromise, networkPromise]).then(function(promiseResults) {
        ctrl.tiers = promiseResults[0].results;
        network = promiseResults[1];

        let indexedTiers = _.keyBy(network.tiers, "id");
        _.forEach(ctrl.tiers, function(tier) {
          tier.enabled = !!indexedTiers[tier.id];
          let idx = _.findIndex(network.tiers, ['id', tier.id]);
          tier.pos = tier.enabled ? (idx + 1) : 999;
        });

        ctrl.resolved = true;
      });
    };
    init();

    ctrl.updatePriority = function() {
      $uibModal.open({
        template: template,
        controller: 'TierPriorityModalCtrl',
        controllerAs: 'modalCtrl',
        size: 'lg',
        resolve: {
          network: network
        }
      }).result.then(function(updatedNetwork) {
        network = updatedNetwork;
        init();
        LocalNetworkProfile.set('tiers', network.tiers);
      });
    };
  });

export default MODULE_NAME;