/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import { HttpHelper } from "../../../util/http-helper";
import { ResourceHelper } from "../../../util/resource-helper";

const MODULE_NAME = "marketplaceproduct";

angular.module(MODULE_NAME, [])

  .factory('MarketplaceProductOptions', function(adnResource) {
    return adnResource('axproduct/options', {}, {}, "axProductOptions");
  })

  .factory('MarketplaceProduct', function(adnResource, $http) {
    const transformJsonObjActions = HttpHelper.obtain($http).transformJsonObjActions('data');
    transformJsonObjActions.save.transformRequest.unshift(function(product) {
      ['minimumCpm', 'minimumCpc', 'minimumBudget', 'maximumBudget'].forEach(fieldName => ResourceHelper.checkMoney(product, fieldName));
      return product;
    });
    return adnResource('axproduct', null, transformJsonObjActions, "axProduct");
  });

export default MODULE_NAME;
