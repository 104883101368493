/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from "angular";
import localUserPermissions from '../../../components/session/local-user-permissions';

const MODULE_NAME = 'object-states-component';

angular.module(MODULE_NAME, [localUserPermissions])

  .component('adnViewObjectStates', {
    bindings: {
      list: '<',
      hideLoading: '@'
    },
    controllerAs: 'ctrl',
    controller: function(LocalUserPermissions) {
      const ctrl = this;
      ctrl.$onInit = function() {
        ctrl.hideLoading = ctrl.hideLoading || ctrl.hideLoading === "true";
        ctrl.hideHidden = LocalUserPermissions.isAxAdvertiser() || LocalUserPermissions.isAxPublisherOnly() || LocalUserPermissions.isAxPublisherAdminOnly();
      };
    },
    template: `
    <span class="btn-group">
      <label class="btn btn-default btn-xs" ng-model="ctrl.list.include" ng-change="ctrl.list.makeIncludeQuery()" uib-btn-radio="'ACTIVE'">Active</label>
      <label class="btn btn-default btn-xs" ng-model="ctrl.list.include" ng-change="ctrl.list.makeIncludeQuery()" uib-btn-radio="'INACTIVE'">Archived</label>
      <label ng-if="!ctrl.hideHidden" class="btn btn-default btn-xs" ng-model="ctrl.list.include" ng-change="ctrl.list.makeIncludeQuery()" uib-btn-radio="'HIDDEN'">Deleted</label>
    </span>
    <span ng-if="!ctrl.hideLoading && !ctrl.list.includeQuery.$resolved" style="display: inline-block; margin-left: 5px" class="fa fa-cog fa-spin"></span>
    `
  });

export default MODULE_NAME;
