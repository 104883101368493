/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import 'moment-timezone';
import {adnFormHelper} from "../common/controller/form-helper";
import {lineItemCommons, setLineItemDefaults} from "../line-item/line-item-commons";

const MODULE_NAME = 'maui-controller';

angular.module(MODULE_NAME, [])

  .controller('MauiCtrl', function(LocalUserPermissions, LocalNetworkProfile, adnTimeShifter, model, $translate, $filter) {
    const ctrl = this;

    ctrl.isNew = true;
    lineItemCommons(ctrl, $translate, $filter, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter);
    setLineItemDefaults(LocalNetworkProfile, ctrl, model);

    ctrl.targeting = {
      Publishers: [
        {id: "schibsted", name: "Schibsted", count: 83},
        {id: "amedia", name: "Amedia", count: 11},
        {id: "tvno", name: "TV.no", count: 1},
      ],
      Sites: [
        {id: 'aftenposten', name: "Aftenposten", count: 10},
        {id: 'dagblader', name: "Dagbladet", count: 9}
      ],
      SiteGroups: [
        {id: 'something', name: "Something", count: 30},
        {id: 'something2', name: "Something 2", count: 19}
      ],
      Devices: [
        {id: 'desktop', name: 'Desktop', count: 40},
        {id: 'mobile', name: 'Mobile', count: 50},
        {id: 'tablet', name: 'Tablet', count: 11}
      ],
      Interest: [
        {id: 'sport', name: 'Sport', count: 90},
        {id: 'art', name: 'Art', count: 50},
        {id: 'male', name: 'Male', count: 11},
        {id: 'female', name: 'Female', count: 13}
      ],
      Location: [
        {id: 'norway', name: 'Norway', count: 90},
        {id: 'oslo', name: 'Oslo', count: 50},
        {id: 'bergen', name: 'Bergen', count: 11}
      ],
      Layout: [
        {id: 'image', name: 'Image', count: 50},
        {id: 'thirdparty', name: 'Third-Party', count: 50},
        {id: 'html', name: 'HTML', count: 11}
      ]
    };

    ctrl.showTargetingButton = false;
    ctrl.selTargeting = {};
    ctrl.selEl = function(key, value, val) {
      ctrl.selTargeting[val.id] = !ctrl.selTargeting[val.id];
      ctrl.showTargetingButton = true;
    };

    ctrl.clickTargetingButton = function() {
      ctrl.showTargetingButton = false;
      _.forEach(ctrl.targeting, function(val) {
        _.forEach(val, function(v) {
          v.count = v.count / 2;
        });
      });
    };

    adnFormHelper.setUpForm(ctrl, model, {
      afterSave: function(persistedLineItem) {
        ctrl.targetingResult = ctrl.targetingResult || {};
        ctrl.targetingResult.data = false;
        ctrl.model.executionStateEndedReason = null;
        _.assign(ctrl.model, _.pick(persistedLineItem, [
          'executionState',
          'executionStateEndedReason',
          'validationWarnings',
          'objectiveDeliveryPredictions',
          'axProduct',
          'targeting'
        ]));
        ctrl.minCpmForSave = false;
        ctrl.model.order = persistedLineItem.order;

        ctrl.currentEndDate = ctrl.model.endDate;
        ctrl.currentSmoothed = ctrl.model.smoothed;
      },
      afterInit: function() {
        if (ctrl.model.startDate) {
          ctrl.model.modStartDate = adnTimeShifter.timeShift(ctrl.model.startDate);
        }
        if (ctrl.model.endDate) {
          ctrl.model.modEndDate = adnTimeShifter.timeShift(ctrl.model.endDate);
        }

        // for an AX_PUBLISHER viewing a line item, some parts of the line item will be suppressed
        const axPublisherPermission = ctrl.model.permission === 'AX_PUBLISHER';
        ctrl.readOnlyAxPublisher = axPublisherPermission && !ctrl.model.order;
        ctrl.showTargeting = !axPublisherPermission || ctrl.model.targeting;
        ctrl.showOrder = !axPublisherPermission || ctrl.model.order;
        ctrl.showObjectives = !axPublisherPermission || ctrl.model.objectives;
        ctrl.showBidSpecification = !axPublisherPermission || ctrl.model.bidSpecification;
        ctrl.showExclusionLabels = !axPublisherPermission || ctrl.model.exclusionLabels;
        ctrl.showLabels = !axPublisherPermission || ctrl.model.labels;
        ctrl.blockSubmit = ctrl.blockSubmit || ctrl.readOnlyAxPublisher;

        ctrl.model.bidSpecification = ctrl.model.bidSpecification || {};
        ctrl.model.bidSpecification.cpa = ctrl.model.bidSpecification.cpa || {};
        ctrl.model.bidSpecification.cpa.cost = ctrl.model.bidSpecification.cpa.cost || {};
        ctrl.model.bidSpecification.cpa.initialECpm = ctrl.model.bidSpecification.cpa.initialECpm || {};
        ctrl.model.bidSpecification.cpmCostModel = ctrl.model.bidSpecification.cpmCostModel || null;
        ctrl.model.bidSpecification.bidStrategy = ctrl.model.bidSpecification.bidStrategy || null;

        ctrl.axUserStates = {};
        if (ctrl.isAxAdvertiser) {
          ctrl.axUserStates = {
            PROPOSED: ctrl.model.userState === 'PROPOSED',
            SUBMITTED: ctrl.model.userState === 'SUBMITTED',
            RESERVED: ctrl.model.userState === 'RESERVED'
          };
        }

        ctrl.currentEndDate = ctrl.model.endDate;
        ctrl.currentSmoothed = ctrl.model.smoothed;
      }
    });

  });

export default MODULE_NAME;