/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import template from './coupon-copy-modal.html';

import standardActionsModule from '../../common/standard-actions-module';
import { actionsHelper } from "../../common/controller/actions-helper";

const MODULE_NAME = "coupon-actions-controller";

angular.module(MODULE_NAME, [
  standardActionsModule
])
  .controller('CouponActionsCtrl', function($q, toastr, $state, $stateParams, $uibModal, model, modelComms, readOnly) {
    const ctrl = this;

    modelComms.postFunc = function(ctrl) {
      ctrl.model.code = "";
    };

    actionsHelper(ctrl, model, modelComms, readOnly, $stateParams, $uibModal, $q, toastr, $state, template, {
      url: 'app.coupons.coupon',
      lcase: 'coupon',
      ucase: 'Coupon'
    });
  });

export default MODULE_NAME;