/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {ApiConfig} from "../api/api";

const MODULE_NAME = "adn-inactive-targets";

angular.module(MODULE_NAME, [
])

  .factory('adnInactiveTargets', function($http, $q) {
    const ONE_HOUR = 60 * 60 * 1000;
    const TEN_MINUTES = 10 * 60 * 1000;

    let inactiveData;
    let timing = 0;

    return {
      cancelExistingData: function(id) {
        if (inactiveData) {
          let hasId = _.find(inactiveData.lineItems, function(li) {
            return _.get(li, ['lineItem', 'id']) === id;
          });
          if (!hasId) {
            hasId = _.find(inactiveData.creatives, function(li) {
              return _.get(li, ['creative', 'id']) === id;
            });
          }
          if (hasId) {
            inactiveData = null;
          }
        }
      },
      results: function(forceCall) {
        if ((!forceCall || (forceCall && Date.now() - TEN_MINUTES < timing)) && inactiveData && (Date.now() - ONE_HOUR < timing)) {
          return $q.when(inactiveData);
        }
        return $http.get(ApiConfig.obtain().getVersionedUri(['inactivetargets']), {
          params: _.assign({}, {format: 'json'})
        }).then(function(page) {
          timing = Date.now();
          inactiveData = page.data;
          return inactiveData;
        });
      }
    };
  });

export default MODULE_NAME;