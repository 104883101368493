/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import standardAnalysisModule from '../common/standard-analysis-module';
import {lineItemCommons, lineItemListCommons} from "../line-item/line-item-commons";
import _ from "lodash";
import moment from "moment/moment";
import {creativeListCommons} from "../creative/creative-commons";

const MODULE_NAME = "order-information";

angular.module(MODULE_NAME, [standardAnalysisModule])

  .controller('OrderInformationCtrl', function(NetworkData, $q, MarketplaceProduct, $stateParams, $log, Creative, modelComms, localUserProfile, LocalNetworkProfile, adnTimeShifter, Team, Order, $translate, $filter, $state, LineItem, LocalUserPermissions, model, adnListHelper) {
    const ctrl = this;
    ctrl.query = {};
    ctrl.model = angular.copy(model);

    ctrl.isNew = $stateParams.isNew;
    ctrl.isAxAdvertiser = LocalUserPermissions.isAxAdvertiser();
    ctrl.chartCurrency = _.get(model, ['objectives', 'BUDGET', 'currency']);
    ctrl.showCreatives = LocalUserPermissions.getSectionPerms().creatives;

    if (modelComms) {
      modelComms.addSubscriber(function(data, type) {
        if (!type) {
          model = _.cloneDeep(data);
        }
      });
    }

    ctrl.lineItems = {};
    adnListHelper.setUpBasicList(ctrl.lineItems, LineItem, 'fullLineItemsForOrder', {
      moreParams: {order: model.id},
      baseTable: 'lineItem',
      afterFill: function() {
        ctrl.lineItemsEmpty = _.isEmpty(ctrl.lineItems.items);
        ctrl.lineItemsCount = Object.keys(ctrl.lineItems.items || []).length;
        _.forEach(ctrl.lineItems.items || [], function(li) {
          if (li.startDate) {
            li.modStartDate = adnTimeShifter.timeShift(li.startDate);
          }
          if (li.endDate) {
            li.modEndDate = adnTimeShifter.timeShift(li.endDate);
          }
        });
        ctrl.selLineItem = _.find(ctrl.lineItems.items || [], function() {
          return true;
        });
        lineItemListCommons(ctrl.lineItems, true, LocalNetworkProfile, adnTimeShifter, $translate, $filter, LocalUserPermissions, Team, Order, 'ORDER_VIEW');
      }
    });
    ctrl.lineItems.perms = LocalUserPermissions.getAllVisPermissions();
    lineItemListCommons(ctrl.lineItems, false, LocalNetworkProfile, adnTimeShifter, $translate, $filter, LocalUserPermissions, Team, Order, 'ORDER_VIEW');

    ctrl.creatives = {};
    adnListHelper.setUpBasicList(ctrl.creatives, Creative, 'fullCreativesForOrder', {
      moreParams: {order: model.id},
      baseTable: 'creative',
      afterFill: function() {
        creativeListCommons(ctrl.creatives, true, LocalNetworkProfile, $state);
      }
    });
    creativeListCommons(ctrl.creatives, false, LocalNetworkProfile, $state);

    ctrl.onBeforeLineItemSubmit = function() {
      ctrl.lineItem.startDate = _.isDate(ctrl.lineItem.modStartDate) ? adnTimeShifter.timeShift(ctrl.lineItem.modStartDate, true) : null;
      ctrl.lineItem.endDate = _.isDate(ctrl.lineItem.modEndDate) ? adnTimeShifter.timeShift(ctrl.lineItem.modEndDate, true) : null;
    };

    ctrl.showVersion2 = LocalNetworkProfile.isPureMarketplacePlatform() && (localUserProfile.get("username") === 'adnuntius@adnuntius.com' || localUserProfile.get("username") === 'broker1@adnuntius.com');
    let commons;
    ctrl.loadExternalLineItem = function(viewType) {
      $state.go("app.line-items.new", {viewType: viewType, full: viewType === 2, orderId: model.id, orderName: model.name, advertiserId: _.get(ctrl.model, ['advertiser', 'id']), advertiserName: _.get(ctrl.model, ['advertiser', 'name'])});
    };

    ctrl.loadLineItemForm = function() {
      ctrl.lineItemForm = {};
      const networkDefaults = LocalNetworkProfile.getDefaults();
      let startDate = adnTimeShifter.timeShift(moment().startOf('day').toDate(), true),
        endDate = adnTimeShifter.timeShift(moment().add(networkDefaults.lineItemDaysApart, 'day').endOf('day').toDate(), true);
      if (_.isDate(ctrl.model.modObjectiveStartDate)) {
        startDate = adnTimeShifter.timeShift(ctrl.model.modObjectiveStartDate, true);
      }
      if (_.isDate(ctrl.model.modObjectiveEndDate)) {
        endDate = adnTimeShifter.timeShift(ctrl.model.modObjectiveEndDate, true);
      }

      ctrl.lineItem = LineItem.create({
        userState: ctrl.isSelfService || ctrl.isAxAdvertiser ? networkDefaults.marketplaceLineItemUserState || "PROPOSED" : networkDefaults.lineItemUserState || 'APPROVED',
        order: ctrl.model,
        startDate: startDate,
        endDate: endDate,
        modStartDate: adnTimeShifter.timeShift(startDate),
        modEndDate: adnTimeShifter.timeShift(endDate)
      });

      ctrl.lineItem.deliveryCurve = ctrl.isAxAdvertiser ? LocalNetworkProfile.getDefaults().marketplaceDeliveryCurve : LocalNetworkProfile.getDefaults().deliveryCurve;

      if (ctrl.isAxAdvertiser) {
        let teamToProduct;
        let defaultsQuery = NetworkData.get({filterByValue: 'defaultMarketplaceProducts', filterBy: 'type'}).$promise.then(function(page) {
          if (_.get(page, ['results', 'length'])) {
            const entry = page.results[0];
            try {
              teamToProduct = JSON.parse(entry.data.teamToProduct);
            } catch {
              $log.warn("Found a problem find teams to products for defaults");
            }
          }
        });

        $q.when(defaultsQuery).then(function() {
          MarketplaceProduct.query({excludeInvalid: true}).$promise.then(function(page) {
            const marketplaceProducts = page.results;
            if (teamToProduct) {
              const matchedMpId = _.find(teamToProduct, function(val, key) {
                const isTeam = LocalUserPermissions.has('AX_ADVERTISER', key);
                return isTeam && _.find(marketplaceProducts, function(mp) {
                  return mp.id === teamToProduct[key];
                });
              });
              const matchedMp = _.find(marketplaceProducts, ['id', matchedMpId]);
              if (matchedMp) {
                ctrl.lineItem.axProduct = matchedMp;
                _.set(ctrl.lineItem, 'targeting', matchedMp.targeting);
                ctrl.lineItem.rateLimits = matchedMp.rateLimits;
              }
            }
          });
        });
      }

      commons = lineItemCommons(ctrl, $translate, $filter, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter, 'lineItem');
      commons.setBidSpecificationFields();
      commons.setObjectivesAsString();
      commons.setObjectiveFields();

      const defaultTierId = LocalNetworkProfile.get('defaultTierId');
      if (defaultTierId) {
        ctrl.lineItem.tier = {id: defaultTierId};
      }

      ctrl.showLineItemForm = true;
    };

    ctrl.saveLineItem = function(promise) {
      promise.then(function(lineItem) {
        ctrl.showLineItemForm = false;

        if (!_.isArray(ctrl.items)) {
          ctrl.items = [];
        }
        ctrl.lineItems.addItemToList(lineItem);
      });
    };

    modelComms.addSubscriber(function(data, type) {
      if (!type && data.name) {
        ctrl.model = data;

        if (ctrl.isNew) {
          ctrl.isNew = false;
        }
      }
    });
  });

export default MODULE_NAME;