/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

const MODULE_NAME = 'star-factory';

import resources from '../api/resources/resources';
import localNetworkProfile from '../session/local-network-profile';

const STARRED_KEY = 'adnStarred';

angular.module(MODULE_NAME, [resources, localNetworkProfile])

  .factory('StarService', function($q, UserProfile, localUserProfile, LocalNetworkProfile) {
    let user,
      starred;

    let refresh = function() {
      user = localUserProfile.get();
      user.profile[STARRED_KEY] = user.profile[STARRED_KEY] || {};
      starred = user.profile[STARRED_KEY];
    };
    refresh();

    let objectTypeSafe = function(objectType) {
      return objectType && objectType.type;
    };

    let updateStar = function(objectId, objectType, makeStarred) {
      if (!objectId || !objectTypeSafe(objectType) || !_.isBoolean(makeStarred)) {
        return $q.reject();
      }
      let objKey = objectType.type;

      // need to refresh in case of another tab having starred something.
      refresh();
      let networkId = LocalNetworkProfile.get('networkId');
      if (!starred[networkId] || !starred[networkId][objKey]) {
        _.set(starred, [networkId, objKey], []);
      }
      if (_.includes(starred[networkId][objKey], objectId) === makeStarred) {
        return $q.reject();
      }
      if (makeStarred) {
        starred[networkId][objKey].push(objectId);
      } else {
        _.pull(starred[networkId][objKey], objectId);
      }
      return UserProfile.save({
        id: user.userId,
        profile: _.pick(user.profile, STARRED_KEY)
      }, function(apiUser) {
        user = localUserProfile.safeSaveProfile(STARRED_KEY, apiUser.profile[STARRED_KEY]);
      }).$promise;
    };

    return {
      getStarred: function(objectType) {
        if (!objectTypeSafe(objectType)) {
          return [];
        }
        refresh();
        let idArray = _.get(starred, [LocalNetworkProfile.get('networkId'), objectType.type]);
        return idArray ? angular.copy(idArray) : [];
      },
      isStarred: function(objectId, objectType) {
        let idArray = this.getStarred(objectType);
        return _.includes(idArray, objectId);
      },
      updateStar: updateStar
    };
  });

export default MODULE_NAME;