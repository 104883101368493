/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import uiTranslate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import _ from 'lodash';

import targetingCtrl from './base-targeting-controller';
import tabsetDirective from '../common/directives/tabs/tabset-directive';
import widget from '../common/directives/widget/widget';
import targetSummary from './target-summary-directive';
import targetSuperSummary from './target-super-summary-directive';

import adUnit from './targeting/adunit-targeting-directive';
import adUnitSummary from './summary/adunit-summary-directive';
import adUnitMatchingLabel from './targeting/ad-unit-matching-label-targeting-directive';
import adUnitMatchingLabelSummary from './summary/ad-unit-matching-label-summary-directive';
import category from './targeting/category-targeting-directive';
import categorySummary from './summary/category-summary-directive';
import dateTargeting from './targeting/date-targeting-directive';
import dateTargetingSummary from './summary/date-targeting-summary-directive';
import dayParting from './targeting/day-parting-targeting-directive';
import dayPartingSummary from './summary/day-parting-summary-directive';
import device from './targeting/device-targeting-directive';
import deviceSummary from './summary/device-summary-directive';
import geo from './targeting/geospatial-targeting-directive';
import geoSummary from './summary/geospatial-summary-directive';
import domainTargeting from './targeting/domain-targeting-directive';
import domainSummaryTargeting from './summary/domain-summary-directive';
import ipAddress from './targeting/ip-address-targeting-directive';
import ipAddressSummary from './summary/ip-address-summary-directive';
import keyValue from './targeting/keyvalue-targeting-directive';
import keyValueSummary from './summary/keyvalue-summary-directive';
import keyword from './targeting/keyword-targeting-directive';
import keywordSummary from './summary/keyword-summary-directive';
import namedLocation from './targeting/named-location-targeting-directive';
import namedLocationSummary from './summary/named-location-summary-directive';
import publisher from './targeting/publisher-targeting-directive';
import publisherSummary from './summary/publisher-summary-directive';
import thirdPartyAudience from './targeting/third-party-audience-targeting-directive';
import thirdPartyAudienceSummary from './summary/third-party-audience-summary-directive';
import retargeting from './targeting/retargeting-targeting-directive';
import retargetingSummary from './summary/retargeting-summary-directive';
import segment from './targeting/segment-targeting-directive';
import segmentSummary from './summary/segment-summary-directive';
import semantic from './targeting/semantic-targeting-directive';
import semanticSummary from './summary/semantic-summary-directive';
import site from './targeting/site-targeting-directive';
import siteSummary from './summary/site-summary-directive';
import siteCountry from './targeting/site-country-targeting-directive';
import siteCountrySummary from './summary/site-country-summary-directive';
import siteGroup from './targeting/site-group-targeting-directive';
import siteGroupSummary from './summary/site-group-summary-directive';
import targetingTemplate from './targeting/targeting-template-directive';
import url from './targeting/url-targeting-directive';
import urlSummary from './summary/url-summary-directive';
import viewability from './targeting/viewability-targeting-directive';
import viewabilitySummary from './summary/viewability-summary-directive';

const MODULE_NAME = 'targeting-module';

angular.module(MODULE_NAME, [
  uiTranslate,
  uiBootstrap,
  tabsetDirective,
  widget,
  targetingCtrl,
  targetSummary,
  targetSuperSummary,
  adUnit,
  adUnitSummary,
  adUnitMatchingLabel,
  adUnitMatchingLabelSummary,
  category,
  categorySummary,
  dateTargeting,
  dateTargetingSummary,
  dayParting,
  dayPartingSummary,
  device,
  deviceSummary,
  domainTargeting,
  domainSummaryTargeting,
  ipAddress,
  ipAddressSummary,
  keyword,
  keywordSummary,
  keyValue,
  keyValueSummary,
  namedLocation,
  namedLocationSummary,
  publisher,
  publisherSummary,
  thirdPartyAudience,
  thirdPartyAudienceSummary,
  retargeting,
  retargetingSummary,
  segment,
  segmentSummary,
  semantic,
  semanticSummary,
  site,
  siteSummary,
  siteCountry,
  siteCountrySummary,
  siteGroup,
  siteGroupSummary,
  targetingTemplate,
  geo,
  geoSummary,
  url,
  urlSummary,
  viewability,
  viewabilitySummary
])

  .filter('adnDeviceTargetingSelections', function($translate) {
    return function(selections) {
      return _.map(selections, function(selection) {
        let key = 'targeting.device.' + selection,
          tx = $translate.instant(key);
        return tx === key ? selection : tx;
      }).join(', ');
    };
  });

export default MODULE_NAME;