/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import standardAnalysisModule from '../common/standard-analysis-module';

const MODULE_NAME = "advertiser-analysis";

angular.module(MODULE_NAME, [standardAnalysisModule])

  .controller('AdvertiserAnalysisCtrl', function($stateParams, LocalUserPermissions, model, modelComms) {
    const ctrl = this;
    ctrl.query = {};
    ctrl.model = angular.copy(model);

    ctrl.canSeeReports = LocalUserPermissions.getAnyVisibility().RUN_REPORTS === true;

    ctrl.isNew = $stateParams.isNew;
    ctrl.perms = LocalUserPermissions.getAllVisPermissions();

    modelComms.addSubscriber(function(data, type) {
      if (!type && data.name) {
        ctrl.model = data;

        if (ctrl.isNew) {
          ctrl.isNew = false;
        }
      }
    });
  });

export default MODULE_NAME;