/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "network-forecast";

angular.module(MODULE_NAME, [])

  .factory("NetworkForecast", function(adnResource) {
    return adnResource('networkforecast');
  });

export default MODULE_NAME;