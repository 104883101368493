/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import standardFormModule from '../common/standard-form-module';
import {adnFormHelper} from "../common/controller/form-helper";
import _ from "lodash";

const MODULE_NAME = 'site-group-controller';

angular.module(MODULE_NAME, [standardFormModule])

  .controller('SiteGroupCtrl', function(model, modelComms, LocalUserPermissions, Team) {
    const ctrl = this;

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms, afterInitAfterSave: function() {
        ctrl.type = ctrl.type || (ctrl.model.ownerTeam ? 'TEAM' : 'NETWORK') || 'NETWORK';
      }
    });
    ctrl.isAxPublisherAdmin = LocalUserPermissions.hasNetworkPermission('AX_PUBLISHER_ADMIN');
    ctrl.hasNetworkPermission = LocalUserPermissions.hasNetworkPermission('MANAGE_SYSTEM');

    ctrl.model.teams = _.map(ctrl.model.teams, 'id');

    ctrl.selectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = _.map(ctrl.mpTeams, 'id');
    };

    ctrl.deselectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = [];
    };

    Team.query(function(page) {
      const allTeams = page.results;
      const isViewAllObjects = LocalUserPermissions.hasNetwork('VIEW_ALL_OBJECTS');
      ctrl.teams = isViewAllObjects ? allTeams : _.filter(allTeams, function(g) {
        return LocalUserPermissions.isPartOf(g.network.tagId || g.network.id, g.id);
      });
      ctrl.mpTeams = _.filter(ctrl.teams, function(team) {
        return team.type !== 'AX_PUBLISHER';
      });
      ctrl.allMarketplacePublisherTeams = _.filter(ctrl.teams, function(team) {
        return team.type === 'AX_PUBLISHER';
      });
      ctrl.showOwnership = ctrl.allMarketplacePublisherTeams.length > 0;
    });

    ctrl.onBeforeSubmit = function() {
      if (ctrl.model.ownerTeam) {
        const validMpTeams = _.map(ctrl.mpTeams, 'id');
        ctrl.model.teams = _.filter(ctrl.model.teams, function(teamId) {
          return validMpTeams.indexOf(teamId) > -1;
        });
      }
      if (ctrl.type === 'NETWORK' || !ctrl.model.ownerTeam) {
        ctrl.model.ownerTeam = null;
        ctrl.model.teams = [];
      }
    };
  });

export default MODULE_NAME;