/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "first-party-audience";

angular.module(MODULE_NAME, [])

  .factory('FirstPartyAudience', function(adnResource) {
    return adnResource('firstpartyaudiences', {}, {}, "FirstPartyAudiences");
  });

export default MODULE_NAME;