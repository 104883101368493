/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';

import template from './site-targeting.html';

import resources from '../../../components/api/resources/resources';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import searchPagination from '../../common/directives/pagination/search-pagination-component';
import {ADN_TARGETING_TYPES} from "../targeting-constants";
import {convertIabCategoryToString} from "../../../components/util/iab-taxonomy";
import {findCountryName} from "../../common/directives/country-select/countries";
import {LocalNetworkInfo} from "../../../components/session/local-network-info";

const MODULE_NAME = 'site-targeting-directive';

angular.module(MODULE_NAME, [translate, uiBootstrap, resources, promiseSpinner, searchPagination])

  .directive('adnSiteTargeting', function(Site, searchResource, LocalNetworkProfile) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnSiteTargeting',
        underModel: '=',
        isLockedDown: '<',
        modelLineItem: '<',
        runningModel: '<',
        mandatoryTargets: '<',
        overridableTargeting: '<',
        mandatory: '<',
        mode: '@'
      },
      link: {
        pre: function(scope) {
          scope.model = scope.model || {};
          scope.model.sites = scope.model.sites || [];
          scope.model.negated = scope.model.negated || false;
          scope.targetType = ADN_TARGETING_TYPES.site;
        },
        post: function(scope, element, attrs, tabset) {
          scope.loaded = true;

          scope.isPureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();

          scope.selectAll = function() {
            _.forEach(_.get(scope.list, ['searchResults'], []), function(au) {
              au.selected = true;
              scope.model.sites.push(au);
            });
            scope.model.sites = _.uniqBy(scope.model.sites, 'id');
          };

          scope.removeAll = function() {
            scope.model.sites = [];
            if (scope.list && scope.list.searchResults) {
              _.forEach(scope.list.searchResults, function(au) {
                au.selected = false;
              });
            }
          };

          scope.select = function(site) {
            site.selected = true;
            scope.model.sites.push(site);
          };

          scope.deselect = function(site) {
            _.remove(scope.model.sites, ['id', site.id]);
            if (scope.list && scope.list.searchResults) {
              (_.find(scope.list.searchResults, ["id", site.id]) || {}).selected = false;
            }
          };

          const updateSelectedSearchResults = function() {
            let selIds = _.keyBy(scope.model.sites, 'id');
            _.forEach(_.get(scope.list, ['searchResults'], []), function(a) {
              a.selected = !!selIds[a.id];
            });
          };

          const countriesSelected = scope.runningModel[ADN_TARGETING_TYPES.siteCountry.targets][ADN_TARGETING_TYPES.siteCountry.apiProp];
          if (_.isArray(countriesSelected) && countriesSelected.length === 1) {
            scope.filter = scope.filter || {};
            scope.filter.country = countriesSelected[0].toLowerCase();
          }

          scope.search = function(newSearch, filterSearch) {
            const metaResolvedParam = filterSearch ? "filterResolved" : "resolved";
            const metaResolvedErrorParam = metaResolvedParam + "Error";
            scope.searchMeta[metaResolvedParam] = false;
            scope.searchMeta[metaResolvedErrorParam] = false;

            if (!filterSearch) {
              scope.filter = {
                country: _.get(scope, ['filter', 'country']) || null,
                category: null
              };
            }

            if (newSearch) {
              scope.searchMeta.currentPage = 1;
            }

            const searchParams = {q: scope.search.term || '', types: 'Site', objectState: 'ACTIVE', pageSize: scope.searchMeta.pageSize, page: scope.searchMeta.currentPage - 1};
            if (scope.filter.country) {
              searchParams.country = scope.filter.country;
            }
            if (scope.filter.category) {
              searchParams.categories = scope.filter.category;
            }
            const orderId = _.get(scope.modelLineItem, ['order', 'id']);
            if (orderId) {
              searchParams.filterBy = 'ORDER_TEAM';
              searchParams.filterByValue = orderId;
            }

            const targeting = _.get(scope, ['runningModel'], {});
            if (targeting.publisherTarget && targeting.publisherTarget.earningsAccounts && targeting.publisherTarget.negated === false) {
              searchParams.earningsAccount = _.map(targeting.publisherTarget.earningsAccounts, function(s) {
                return s.id || s;
              });
            }
            if (targeting.siteGroupTarget && targeting.siteGroupTarget.siteGroups && targeting.siteGroupTarget.negated === false) {
              searchParams.siteGroup = _.map(targeting.siteGroupTarget.siteGroups, function(s) {
                return s.id || s;
              });
            }

            searchResource.query(searchParams).then(function(page) {
              scope.limited = (searchParams.earningsAccount && searchParams.earningsAccount.length > 0) ||
                (searchParams.siteGroup && searchParams.siteGroup.length > 0);
              scope.limitedType = scope.isPureMarketplace ? "publisher" : "earnings account";
              page.searchResults = _.map(page.searchResults || [], function(item) {
                item.categories = _.map(item.categories, convertIabCategoryToString);
                item.country = item.country ? findCountryName(item.country) : "";
                return item;
              });
              scope.list = page;

              const categoriesBuckets = _.get(page, ['aggregations', 'categories', 'buckets']);
              const countryBuckets = _.get(page, ['aggregations', 'country', 'buckets']);
              const isMandatory = scope.mandatoryTargets.indexOf(ADN_TARGETING_TYPES.siteCountry.id) > -1;
              scope.hasCountries = LocalNetworkInfo.obtain().getInfo("siteCountries").length > 1 && !isMandatory && (scope.filter.country || (countryBuckets && !_.isEmpty(countryBuckets)));
              scope.hasCategories = scope.filter.categories || (categoriesBuckets && !_.isEmpty(categoriesBuckets));
              scope.hasCountriesTable = LocalNetworkInfo.obtain().getInfo("siteCountries").length > 1 && (scope.filter.country || (countryBuckets && !_.isEmpty(countryBuckets)));

              if (scope.hasCountries) {
                _.forEach(countryBuckets, function(country) {
                  country.name = findCountryName(country.name);
                });
              }
              if (scope.hasCategories) {
                _.forEach(categoriesBuckets, function(category) {
                  category.name = convertIabCategoryToString(category.name);
                });
              }

              updateSelectedSearchResults();

              scope.searchMeta[metaResolvedParam] = true;
            }).catch(function(err) {
              scope.list = {};
              scope.searchMeta[metaResolvedParam] = true;
              if (_.get(err, ['data', 'code']) === 'error.api.order.no.team') {
                return scope.searchMeta[metaResolvedErrorParam] = "Specify a team on the order to obtain site results here";
              }
              const statusText = _.get(err, ['statusText']);
              if (statusText) {
                scope.searchMeta[metaResolvedErrorParam] = statusText;
              }
            });
          };

          scope.searchMeta = {
            pageSize: 50,
            currentPage: 1,
            changePage: function() {
              scope.search(false, true);
            },
            resolvedError: '',
            filterResolvedError: '',
            filterResolved: true,
            resolved: true
          };

          scope.hook = {};
          scope.hook.getTemplateData = function() {
            var summary = (scope.model.negated === true ? "NOT: " : "") + _.map(scope.model.sites, function(item) {
              return item.name;
            }).join(", ");

            return {
              vm: angular.copy(scope.model),
              model: angular.copy(scope.model),
              constant: ADN_TARGETING_TYPES.site,
              summary: summary
            };
          };

          const copyTemplateFunc = function(template) {
            scope.model = angular.copy(template.data.model);
            updateSelectedSearchResults();
          };
          scope.hook.copyTemplate = copyTemplateFunc;

          tabset.register(ADN_TARGETING_TYPES.site.widget, function() {
            if (scope.query) {
              return;
            }
            scope.search();
            if (!scope.model.sites.length) {
              return;
            }
            const ids = _.map(scope.model.sites, 'id');
            scope.query = Site.query({ids: ids, pageSize: scope.model.sites.length, includeActive: true, includeInactive: true, includeHidden: true}, function(page) {
              const keyedResults = _.keyBy(page.results, 'id');
              _.forEach(scope.model.sites, function(sel) {
                const fullSiteDetail = keyedResults[sel.id];
                if (fullSiteDetail) {
                  _.assign(sel, _.pick(fullSiteDetail, ['description', 'country', 'requestedInLastDay']));
                  sel.categories = _.cloneDeep(_.get(fullSiteDetail, ['iabCategories']));
                  sel.categories = _.map(sel.categories, convertIabCategoryToString);
                  sel.country = sel.country ? findCountryName(sel.country) : "";
                  sel.earningsAccountName = _.get(fullSiteDetail, ['earningsAccount', 'name']);
                }
              });
            });
          }, copyTemplateFunc);
        }
      }
    };
  });

export default MODULE_NAME;