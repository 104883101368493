/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import twoPanelTemplate from '../../common/two-panel.html';
import editTemplate from './role-edit.html';
import analysisTemplate from './role-analysis.html';
import copyTemplate from '../../common/copy-actions.html';
import listTemplate from './role-list.html';

import adminModule from '../admin-module';
import roleController from './role-controller';
import roleAnalysis from './role-analysis';
import roleActions from './role-actions-controller';

const MODULE_NAME = 'role-module';

angular.module(MODULE_NAME, [adminModule, roleController, roleAnalysis, roleActions])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('roles.role', 'Role', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      data: {
        app: ['ADVERTISING', 'DATA']
      },
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: null,
            template: twoPanelTemplate
          },
          'context@app': {
            controller: 'RoleActionsCtrl',
            controllerAs: 'ctrl',
            template: copyTemplate
          },
          'leftPanel@app.roles.role': {
            template: editTemplate,
            controller: 'RoleCtrl',
            controllerAs: 'resource'
          },
          'rightPanel@app.roles.role': {
            template: analysisTemplate,
            controller: 'RoleAnalysisCtrl',
            controllerAs: 'resource'
          }
        }
      }, 'Role'));
  });

export default MODULE_NAME;