/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import ngFileUpload from 'ng-file-upload';

import {ASSET_ERROR} from "../../resources-constants";

import assetHelper from '../../../../util/asset-helper';
import {ApiConfig} from "../../../api";

const MODULE_NAME = "adunittag";

angular.module(MODULE_NAME, [ngFileUpload, assetHelper])

  .factory('ReferenceData', function(adnAssetHelper, $q, Upload) {
    return {
      get: function(ids) {
        ids = _.isArray(ids) ? ids : [ids];
        return adnAssetHelper.getAsJson('assets', {id: ids.join(';'), pageSize: ids.length});
      },
      fromCreative: function(id) {
        return adnAssetHelper.fromParentAsJson('assets', id);
      },
      upload: function(data, uploadType) {
        if (!data || !(data.file || data.content)) {
          return $q.reject(ASSET_ERROR.NO_FILE);
        }
        return Upload.upload({
          url: ApiConfig.obtain().getVersionedUri([uploadType || 'categories', 'upload']),
          data: data
        }).then(function(response) {
          return response;
        }).catch(function(err) {
          return $q.reject(err);
        });
      }
    };
  });

export default MODULE_NAME;