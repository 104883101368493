/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';
import ngSanitize from 'angular-sanitize';
import {OBJECT_TYPE} from "../../common/constants/object-type";

import template from './last-verbed-tab.html';

import resources from '../../../components/api/resources/resources';
import localNetworkProfile from '../../../components/session/local-network-profile';
import objectTypeParamsFilter from '../../common/filters/object-type-state-params-filter';
import messageFilter from '../../common/filters/message-params-translation-filter';

const MODULE_NAME = 'last-verbed-tab-directive';

let ctrlMagic = function(ctrl, prefix, icon, $element, localUserProfile, LocalNetworkProfile, LocalUserPermissions) {
  let networkId = LocalNetworkProfile.getNetworkId(),
    app = localUserProfile.getCurrentApp(),
    profileKey = 'adn' + _.upperFirst(prefix) + networkId + (app === 'ADVERTISING' ? '' : app),
    localUser = localUserProfile.get(),
    profile = localUser.profile;

  ctrl.icon = icon;
  ctrl.prefix = prefix;
  ctrl.perms = LocalUserPermissions.getAllNetworkPermissions();
  const canSeeUsers = ctrl.perms.MANAGE_SYSTEM === true || ctrl.perms.SELF_SERVICE_USER_ADMIN === true;

  let hydrate = function(theObj) {
    if (!_.isObject(theObj)) {
      return;
    }
    ctrl.lastVerbed = angular.copy(theObj);
    ctrl.lastVerbed = _.filter(ctrl.lastVerbed, function(lv) {
      return _.isObject(lv) && OBJECT_TYPE[lv.objectType];
    });
    _.forEach(ctrl.lastVerbed, function(le) {
      le.objectType = OBJECT_TYPE[le.objectType];
    });
    ctrl.lastVerbed = _.filter(ctrl.lastVerbed, function(le) {
      return !!le.objectType && (canSeeUsers || le.objectType !== OBJECT_TYPE.User);
    });
  };

  localUserProfile.subscribe(profileKey, function(updatedObj) {
    hydrate(updatedObj);
  });
  hydrate(profile[profileKey]);
};

angular.module(MODULE_NAME, [translate, uiBootstrap, ngSanitize, resources, localNetworkProfile, messageFilter, objectTypeParamsFilter])
  .directive('adnLastEditedTab', function() {
    return {
      restrict: 'A',
      require: '^adnSidebarTabset',
      template: template,
      scope: {},
      bindToController: {
        parentMethodHook: '<'
      },
      controllerAs: 'ctrl',
      controller: function($element, localUserProfile, LocalNetworkProfile, LocalUserPermissions) {
        ctrlMagic(this, 'lastEdited', 'fa-pencil', $element, localUserProfile, LocalNetworkProfile, LocalUserPermissions);
      }
    };
  })
  .directive('adnLastViewedTab', function() {
    return {
      restrict: 'A',
      template: template,
      scope: {},
      bindToController: {
        parentMethodHook: '<'
      },
      controllerAs: 'ctrl',
      controller: function($element, localUserProfile, LocalNetworkProfile, LocalUserPermissions) {
        ctrlMagic(this, 'lastViewed', 'fa-eye', $element, localUserProfile, LocalNetworkProfile, LocalUserPermissions);
      }
    };
  });

export default MODULE_NAME;