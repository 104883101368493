/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import twoPanelTemplate from '../common/two-panel.html';
import editTemplate from './line-item-edit-basic.html';
import editTemplate2a from './line-item-edit-2a.html';
import analysisTemplate from './line-item-analysis.html';
import lineItemActionsTemplate from './line-item-actions.html';
import lineItemReachTemplate from './line-item-reach.html';
import lineItemDiagnosticsTemplate from './line-item-diagnostics.html';
import listTemplate from './line-item-list.html';

import lineItemListComponent from '../common/controller/line-item-list-component';
import standardModule from '../common/standard-module';
import lineItemController from './line-item-controller';
import pubInfoComponent from '../common/controller/publisher-info-component';
import lineItemAnalysis from './line-item-analysis-controller';
import lineItemActionsController from './line-item-actions-controller';
import lineItemReachController from './line-item-reach-controller';
import objectivesViewComponent from './widget/objectives-view-component';
import bidsViewComponent from './widget/bids-view-component';
import lineItemDiagController from './line-item-diagnostics-controller';
import velocityComponent from "../common/controller/velocity-component";
import {lineItemBulkAction, lineItemListCommons} from "./line-item-commons";
import contextTemplate from "./line-item-list-context.html";

const MODULE_NAME = 'line-item-module';

angular.module(MODULE_NAME, [standardModule, pubInfoComponent, velocityComponent, lineItemController, lineItemListComponent,  objectivesViewComponent, bidsViewComponent, lineItemAnalysis, lineItemActionsController, lineItemReachController, lineItemDiagController])
  .config(function($stateProvider, adnRouterHelperProvider) {
    const rh = adnRouterHelperProvider.$get().createRouterAndChildHelper('line-items.line-item', 'LineItem', 'Creative', 'app.adv', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        listConfig: function() {
          return {};
        },
        extension: /*@ngInject*/ function(LocalNetworkProfile, adnTimeShifter, $translate, $filter, LocalUserPermissions, Team, Order) {
          return {
            func: function(ctrl, afterFill) {
              lineItemListCommons(ctrl, afterFill, LocalNetworkProfile, adnTimeShifter, $translate, $filter, LocalUserPermissions, Team, Order, 'LINE_ITEM_VIEW');
            }
          };
        },
        bulk: function() {
          return {
            bulkAction: lineItemBulkAction
          };
        },
        sectionParam: function() {
          return 'lineItems';
        },
        model: function() {
          return {};
        },
        modelComms: function() {
          return {};
        }
      },
      views: {
        'context@app': {
          template: contextTemplate,
          controller: 'LineItemTemplateActionsCtrl',
          controllerAs: 'ctrl'
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        resolve: {
          advertiserFromParam: /*@ngInject*/ function($stateParams) {
            if ($stateParams.advertiserId && $stateParams.advertiserName) {
              return {
                id: $stateParams.advertiserId,
                name: $stateParams.advertiserName
              };
            }
          },
          orderFromParam: /*@ngInject*/ function($stateParams) {
            if ($stateParams.orderId && $stateParams.orderName) {
              return {
                id: $stateParams.orderId,
                name: $stateParams.orderName
              };
            }
          },
          lineItemView: /*@ngInject*/ function($q, $stateParams, dataViewResource) {
            if (!$stateParams.isNew) {
              return dataViewResource.get('LineItemView', {id: $stateParams.id}, false).catch(function() {
                // DataView can trip itself up, so sending through a null for an error here.
                return null;
              });
            }
          },
          full: /*@ngInject*/ function($stateParams) {
            return !!$stateParams.full;
          },
          viewType: /*@ngInject*/ function($stateParams) {
            return $stateParams.viewType === 2 ? 2 : 1;
          },
          model: /*@ngInject*/ function($q, $stateParams, LineItem) {
            if ($stateParams.isNew) {
              return LineItem.create({
                id: $stateParams.id,
                userState: 'RESERVED'
              });
            }
            return LineItem.get({id: $stateParams.id}).$promise;
          }
        },
        views: {
          'content@app': {
            controller: function(modelComms, $stateParams, full) {
              const ctrl = this;
              ctrl.full = full;

              ctrl.isNew = $stateParams.isNew;

              modelComms.addSubscriber(function (data, type) {
                if (type && type === 'commMade') {
                  ctrl.full = data.full;
                }

                if (!type && !!data.name) {
                  ctrl.lineItem = data;

                  if (ctrl.isNew) {
                    ctrl.isNew = false;
                  }
                }
              });
            },
            controllerAs: 'ctrl',
            template: twoPanelTemplate
          },
          'rightPanel@app.line-items.line-item': {
            template: analysisTemplate,
            controller: 'LineItemAnalysisCtrl',
            controllerAs: 'ctrl'
          },
          'leftPanel@app.line-items.line-item': {
            template: editTemplate,
            controller: 'LineItemCtrl',
            controllerAs: 'resource'
          },
          'leftPanelPartA@app.line-items.line-item': {
            template: editTemplate2a,
            controller: 'LineItemCtrl',
            controllerAs: 'resource'
          },
          'diagnostics@app.line-items.line-item': {
            template: lineItemDiagnosticsTemplate,
            controller: 'LineItemDiagnosticsController',
            controllerAs: 'ctrl'
          },
          'reach@app.line-items.line-item': {
            template: lineItemReachTemplate,
            controller: 'LineItemReachController',
            controllerAs: 'ctrl'
          },
          'context@app': {
            template: lineItemActionsTemplate,
            controller: 'LineItemActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'LineItem', [{type: 'Advertiser', func: 'canBeShownAdvertiser'}, {type: 'Order', func: 'isNotSelfService'}]));
  });

export default MODULE_NAME;