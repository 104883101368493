/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import resources from '../../../components/api/resources/resources';

import template from './top-n-component.html';

const MODULE_NAME = "top-n-selector";

angular.module(MODULE_NAME, [
  resources
])

  .component('adnTopNSelector', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      topN: '<',
      topNHook: '<',
      totalCount: '<'
    },
    controller: function() {
      const ctrl = this;

      ctrl.$onInit = function() {
        const defaultLevels = [50, 100, 200, 500, 1000, 2000, 5000];
        let matchIndex = 0;
        for (let i = 0; i < defaultLevels.length; i++) {
          const level = defaultLevels[i];
          if (level < ctrl.totalCount) {
            matchIndex = i;
          }
        }
        ctrl.levels = defaultLevels.slice(0, matchIndex + 2);

        ctrl.updateLevel = function(level) {
          ctrl.topN = level;
          if (_.get(ctrl.topNHook, 'newN')) {
            ctrl.topNHook.newN(level);
          }
        };
      };
    }
  });

export default MODULE_NAME;