/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import standardAnalysisModule from '../common/standard-analysis-module';

const MODULE_NAME = "folder-analysis";

angular.module(MODULE_NAME, [standardAnalysisModule])

  .controller('FolderAnalysisCtrl', function($stateParams, model, modelComms, visitorProfileResource) {
    const ctrl = this;
    ctrl.query = {};
    ctrl.model = angular.copy(model);

    ctrl.isNew = $stateParams.isNew;

    ctrl.profile = {};
    ctrl.folderScript = `<script src="https://cdn.adnuntius.com/adn.js" async></script>
<script>
    window.adn = window.adn || {};
    adn.calls = adn.calls || [];
    adn.calls.push(function() {
        adn.view('${model.folderId}');
    });
</script>`;


    ctrl.runRetrieve = function() {
      ctrl.profile = {};
      if (!ctrl.browserId) {
        ctrl.profile.hasError = true;
        ctrl.profile.errorInfo = "Specify a browser ID to retrieve a user profile.";
        return;
      }
      visitorProfileResource.getVisitor(ctrl.model.folderId, ctrl.browserId, function(data) {
        ctrl.profile.results = angular.copy(data);
        delete ctrl.profile.results.id;
      }, function(err) {
        if (err.status === 404) {
          ctrl.profile.errorInfo = "Unable to retrieve a profile for " + ctrl.browserId;
        } else {
          ctrl.profile.errorInfo = err.info;
        }
      }, ctrl.profile);
    };

    modelComms.addSubscriber(function(data, type) {
      if (!type && data.name) {
        ctrl.model = data;

        if (ctrl.isNew) {
          ctrl.isNew = false;
        }
      }
    });
  });

export default MODULE_NAME;