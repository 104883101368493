/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiCodemirror from 'angular-ui-codemirror';

import standardFormModule from '../../common/standard-form-module';
import listHelper from '../../common/controller/list-helper';
import {adnFormHelper} from "../../common/controller/form-helper";
import targetingTemplate from "../../targeting/base-targeting-editor-modal.html";
import layoutSelectorTemplate from "./layout-selector.html";
import {ADN_TARGETING_TYPES} from "../../targeting/targeting-constants";

const MODULE_NAME = 'product-controller';

angular.module(MODULE_NAME, [
  uiCodemirror,
  standardFormModule,
  listHelper
])

  .controller('ProductCtrl', function($stateParams, adnListHelper, Order, ReportTemplate, LocalNetworkProfile, Layout, $uibModal, model, modelComms) {
    const ctrl = this;

    ctrl.isNew = $stateParams.isNew;

    let afterInit = function() {
        ctrl.hasLayouts = false;
        if (ctrl.model && ctrl.model.layouts && ctrl.model.layouts.length) {
          Layout.query(_.map(ctrl.model.layouts, 'id'), function(page) {
            ctrl.model.layouts = page.results;
            ctrl.hasLayouts = true;
          });
        } else {
          ctrl.hasLayouts = true;
        }

        ctrl.model.layouts = ctrl.model.layouts || [];

        ctrl.model.cpmSpecification = ctrl.model.cpmSpecification || 'FIXED';

        ctrl.model.allowedDimensions = _.isArray(ctrl.model.allowedDimensions) ? ctrl.model.allowedDimensions : [[]];
        ctrl.model.allowedDimensions[0] = _.isArray(ctrl.model.allowedDimensions[0]) ? ctrl.model.allowedDimensions[0] : [];

        ctrl.targetingResult = {};
        ctrl.creativeTargetingResult = {};

        ctrl.model.data = ctrl.model.data || {};
        ctrl.model.data.presentationOptions = ctrl.model.data.presentationOptions || {};
      },
      afterSave = function() {
        ctrl.targetingResult.data = false;
        ctrl.creativeTargetingResult.data = false;
      };
    adnFormHelper.setUpForm(ctrl, model, {afterSave: afterSave, afterInit: afterInit, modelComms: modelComms});
    ctrl.model.defaultOrders = _.map(ctrl.model.defaultOrders, 'id');

    ctrl.addDimensions = function() {
      ctrl.model.allowedDimensions = ctrl.model.allowedDimensions || [];
      ctrl.model.allowedDimensions.push([]);
      ctrl.editsMade();
    };
    ctrl.eventHook = {};
    ctrl.model.targeting = ctrl.model.targeting || {};
    ctrl.model.creativeTargeting = ctrl.model.creativeTargeting || {};

    ctrl.editTarget = function(targeting, isCreative) {
      const typeTargeting = isCreative ? 'creativeTargetingResult' : 'targetingResult';
      const apiParam = isCreative ? 'creativeTargeting' : 'targeting';
      ctrl[typeTargeting] = {
        data: false
      };
      $uibModal.open({
        template: targetingTemplate,
        controller: 'TargetingCtrl',
        size: 'xl',
        resolve: {
          modelLineItem: function() {
            return angular.copy(ctrl.model);
          },
          pageType: function() {
            return isCreative ? "optionalProduct" : "product";
          },
          reachEnabled: function() {
          },
          mandatoryTargets: _.noop,
          availableTargets: function() {
            return isCreative ? _.pick(ADN_TARGETING_TYPES, ['site', 'userSegment', 'category', 'namedLocation', 'device', 'keyValue', 'domain']) : null;
          },
          targeting: function() {
            return targeting;
          },
          overridableTargeting: function() {
          },
          targetingMode: function() {
            return null;
          },
          isLockedDown: function() {
            return ctrl.isLockedDown;
          }
        }
      })
        .result.then(function(wc) {
        _.assign(targeting, wc.wc);
        if (!ctrl.isNew) {
          ctrl.model.savePartial(apiParam).then(function(respLineItem) {
            ctrl[typeTargeting].data = true;
            _.set(ctrl.model, 'validationWarnings', respLineItem.validationWarnings);
          });
        }
      });
    };

    Order.query({joins: 'inner join team on team.id = order.teamId', where: "team.type in SUI"}).$promise.then(function(page) {
      ctrl.suiOrders = page.results;
    });

    ReportTemplate.query({filterBy: 'reportType', filterByValue: 'SELF_SERVICE'}).$promise.then(function(page) {
      ctrl.reportTemplates = page.results;
    });

    const enabledTiers = LocalNetworkProfile.get('tiers'),
      currentTier = ctrl.model.defaultTier;

    ctrl.tiers = angular.copy(enabledTiers);
    if (currentTier) {
      const hasCurrent = !!_.find(ctrl.tiers, function(t) {
        return t.id === _.get(currentTier, 'id');
      });
      // add current tier to list if not in there already -- is probably disabled
      if (!hasCurrent) {
        ctrl.tiers.push(currentTier);
      }
    }
    _.forEach(ctrl.tiers, function(t) {
      t.displayName = t.name + (_.find(enabledTiers, {id: t.id}) ? '' : ' (disabled)');
    });

    ctrl.editLayouts = function() {
      ctrl.layoutResult = {
        data: false
      };
      const modalInstance = $uibModal.open({
        template: layoutSelectorTemplate,
        size: 'lg',
        controllerAs: 'modalCtrl',
        controller:  /*@ngInject*/ function() {
          const modalCtrl = this;

          modalCtrl.selLayouts = _.cloneDeep(ctrl.model.layouts);

          const initSelLayouts = _.map(modalCtrl.selLayouts, (l) => {
            return l.id;
          });

          modalCtrl.select = function(item) {
            item.selected = true;
            modalCtrl.selLayouts.push(item);
          };

          modalCtrl.deselect = function(item) {
            item.selected = false;
            _.remove(modalCtrl.selLayouts, function(l) {
              return item.id === l.id;
            });
            _.forEach(modalCtrl.items, function(it) {
              if (item.id === it.id) {
                it.selected = false;
              }
            });
          };

          adnListHelper.setUpBasicList(modalCtrl, Layout, '', {
            moreParams: {layoutFilter: 'SIMPLE', includeInactive: false, includeHidden: false},
            afterFill: () => {
              _.forEach(modalCtrl.items, (item) => {
                item.selected = initSelLayouts.indexOf(item.id) > -1;
              });
            }
          });

          modalCtrl.saveChanges = function() {
            ctrl.model.layouts = modalCtrl.selLayouts;
            if (!ctrl.isNew) {
              ctrl.model.savePartial('layouts').then(function(respLineItem) {
                ctrl.layoutResult.data = true;
                _.set(ctrl.model, 'validationWarnings', respLineItem.validationWarnings);
                modalInstance.close();
              });
            }
            modalInstance.close();
          };
        }
      });
    };

    ctrl.removeDimensions = function(index) {
      _.pullAt(ctrl.model.allowedDimensions, index);
      ctrl.eventHook.removeControlHook('dims' + index + '.width');
      ctrl.eventHook.removeControlHook('dims' + index + '.height');
      ctrl.editsMade();
    };
  });

export default MODULE_NAME;