/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import template from './report-history.html';

import resources from '../../components/api/resources/resources';
import {ObjectTypeFinder} from "../common/constants/object-type";

const MODULE_NAME = "report-history-component";

angular.module(MODULE_NAME, [resources])

  .component('adnReportHistory', {
    bindings: {
      reportExecutions: '<',
      objType: '@',
      objId: '@',
      size: '@',
      hideEmptyMessage: '@'
    },
    template: template,
    controllerAs: 'ctrl',
    controller: function(ReportExecution) {
      const ctrl = this;
      ctrl.collapseExecutions = true;

      let setUpExecutions = function(reportExecutions) {
        if (!reportExecutions || !reportExecutions.length) {
          ctrl.reportExecutions = null;
          return;
        }

        ctrl.reportExecutions = reportExecutions;
      };

      ctrl.$onInit = function() {
        if (ctrl.objType && ctrl.objId && _.get(ObjectTypeFinder.findByCaps(ctrl.objType), ['apiParam'])) {
          const params = {};
          params[ObjectTypeFinder.findByCaps(ctrl.objType).apiParam] = ctrl.objId;
          ReportExecution.query(params).$promise.then(function(page) {
            setUpExecutions(page.results);
            ctrl.resolved = true;
          });
        } else {
          ctrl.resolved = true;
        }
      };
    }
  });

export default MODULE_NAME;