/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';

import {ADN_TARGETING_TYPES} from "./targeting-constants";

import {TargetingHelper} from "./targeting-helper";

const MODULE_NAME = 'targeting-super-summary';

angular.module(MODULE_NAME, [translate, uiBootstrap])

  .directive('adnTargetSuperSummary', function($uibModal) {
    return {
      restrict: 'A',
      template: `
        <div ng-if="!targetResults.length">-</div>
        <div ng-if="targetResults.length && obj.name" class="pull-right"><button type="button" ng-click="expand()" class="btn btn-default btn-xs"><span class="fa fa-target fa-eye" title="See more targeting details"></span></button></div>
        <ul class="list-inline commaSeparated" ng-if="::targetResults.length">
          <li ng-repeat="target in ::targetResults"><span ng-if="target.negated">NOT&nbsp;</span><span>{{target.size}}</span>&nbsp;<span>{{'targeting.' + target.targetingType.id + (target.size === 1 ? '' : '.plural') | translate | lowercase}}</span></li>
        </ul>
      `
      ,
      transclude: true,
      scope: {
        targeting: '<adnTargetSuperSummary',
        obj: '<'
      },
      link: function(scope, element, attrs) {
        scope.headingClass = attrs.headingClass || 'list-group-item-heading';

        scope.targetResults = _.map(_.filter(_.map(scope.targeting, function(lValue, key) {
          return {
            size: TargetingHelper.getSizeOfTargetingTypes(scope.targeting[key], key),
            key: key,
            negated: lValue.negated
          };
        }), function(lData) {
          return lData.size > 0;
        }), function(lData) {
          lData.targetingType = _.find(ADN_TARGETING_TYPES, {targets: lData.key});
          return lData;
        });

        scope.expand = function() {
          $uibModal.open({
            size: 'lg',
            windowClass: '',
            template: `
              <div class="modal-header">
                <button type="button" class="close" ng-click="$dismiss()">&times;</button>
                <h4 class="modal-title">Targeting for {{modalCtrl.obj.name}}</h4>
              </div>
              <div class="modal-body">
                <div adn-target-summary="modalCtrl.targeting" model="modalCtrl.obj"></div>
              </div>
              <div class="modal-footer">
                <a class="btn btn-link btn-xs" ng-click="$dismiss()" translate="button.cancel"></a>
              </div>
            `,
            controllerAs: 'modalCtrl',
            controller: function() {
              const modalCtrl = this;
              modalCtrl.obj = scope.obj;
              modalCtrl.targeting = scope.targeting;
            }
          });
        };
      }
    };
  });

export default MODULE_NAME;