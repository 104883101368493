/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import {OBJECT_TYPE} from "../common/constants/object-type";

import standardActionsModule from '../common/standard-actions-module';
import searchSelect from '../common/controller/search-select-component';

const MODULE_NAME = "campaign-actions-controller";

angular.module(MODULE_NAME, [
  standardActionsModule,
  searchSelect
])
  .controller('CampaignActionsCtrl', function($stateParams, model, modelComms, readOnly) {
    const ctrl = this;

    ctrl.objectType = OBJECT_TYPE.LineItem.type;
    ctrl.objectId = model.id;
    ctrl.readOnly = readOnly;
    ctrl.isNew = $stateParams.isNew;

    ctrl.model = model;
    ctrl.modelComms = modelComms;

  });

export default MODULE_NAME;