/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../../common/standard-form-module';

const MODULE_NAME = 'tier-priority-controller';

angular.module(MODULE_NAME, [standardFormModule])

  .controller('TierPriorityModalCtrl', function($uibModalInstance, Network, tierResource, network) {
    const modalCtrl = this;
    // only want to save tiers, not everything else on the network resource.
    modalCtrl.network = new Network({id: network.id, tiers: network.tiers});

    let refreshActiveNetworkData = function() {
      // API will return network object referring to tiers that have been deleted. Hence, this extra logic.
      let tierIds = _.map(modalCtrl.tiers, "id");
      modalCtrl.hasDisabledTiers = _.find(modalCtrl.tiers, ['enabled', false]);
      let activeNetworkTierLength = _.intersection(tierIds, _.map(modalCtrl.network.tiers, "id")).length;
      if (activeNetworkTierLength !== tierIds.length) {
        // means still some deleted tiers in the network
        modalCtrl.network.tiers = _.filter(modalCtrl.network.tiers, function(t) {
          return tierIds.indexOf(t.id) > -1;
        });
      }
      return activeNetworkTierLength;
    };

    tierResource.query(function(page) {
      modalCtrl.tiers = page.results;
      let indexedNetworkTiers = _.keyBy(modalCtrl.network.tiers, "id");
      _.forEach(modalCtrl.tiers, function(tier) {
        tier.enabled = !!indexedNetworkTiers[tier.id];
        let idx = _.findIndex(modalCtrl.network.tiers, ['id', tier.id]);
        tier.pos = tier.enabled ? (idx + 1) : 999;
      });
      refreshActiveNetworkData();
    });

    modalCtrl.toggleEnable = function(tier) {
      if (tier.enabled) {
        modalCtrl.network.tiers.push(tier);
      } else {
        tier.pos = 999;
        modalCtrl.network.tiers = _.reject(modalCtrl.network.tiers, ['id', tier.id]);
      }
      refreshActiveNetworkData();
    };

    modalCtrl.priority = function(idx, raise) {
      let iLength = refreshActiveNetworkData();
      for (let i = 0; i < iLength; i++) {
        let tier = modalCtrl.network.tiers[i];
        tier.pos = (i + 1) * 10;
        if (i === idx && raise) {
          tier.pos -= 11;
        } else if (i === idx && !raise) {
          tier.pos += 11;
        }
      }
      modalCtrl.network.tiers = _.orderBy(modalCtrl.network.tiers, 'pos', 'asc');

      let indexedNetworkTiers = _.keyBy(modalCtrl.network.tiers, 'id');
      _.forEach(modalCtrl.tiers, function(tier) {
        let networkTier = indexedNetworkTiers[tier.id];
        tier.pos = networkTier ? networkTier.pos : 999;
      });
    };

    modalCtrl.save = function(promise) {
      promise.then(function(updatedNetwork) {
        angular.extend(modalCtrl.network, updatedNetwork);
        $uibModalInstance.close(updatedNetwork);
      });
    };
  });

export default MODULE_NAME;