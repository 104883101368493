/**
 * Copyright © 2025 Adnuntius AS.
 */
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'advertiser-status-label',
  template: `
    <span class="label {{labelClass}}">{{ translation }}</span>
  `
})
export class AdvertiserStatusLabelComponent implements OnInit {
  labelClass: any;
  translation: string;
  @Input() bidInLastDay: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.labelClass = this.bidInLastDay ? 'label-success' : 'label-warning';
    this.translation = this.bidInLastDay ? 'Delivering' : 'No Recent Bids';
  }
}
