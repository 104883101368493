/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiRouter from '@uirouter/angularjs';
import translate from 'angular-translate';
import {OBJECT_TYPE, ObjectTypeFinder} from "../constants/object-type";
import {calculateCrumbs, updateCrumb} from "./crumb-calculator";

import listTitleTemplate from '../../base/default-title-template.html';
import elementTitleTemplate from '../../base/default-item-title.html';
import defaultContextTemplate from '../../common/list-default-actions.html';

import collapsibleSidebar from "../sidebar/collapsible-sidebar";
import resourceListController from '../../common/controller/resource-list-controller';
import stateTitleDirective from '../../common/directives/html/state-title-directive';
import objectTypeParamsFilter from '../../common/filters/object-type-state-params-filter';
import baseContextController from '../../base/base-context-controller';
import localUserPermissions from '../../../components/session/local-user-permissions';
import localNetworkProfile from '../../../components/session/local-network-profile';
import {Uuid} from "../../../components/util/uuid";
import {ModelComms} from "./model-comms";

const MODULE_NAME = "router-helper";

angular.module(MODULE_NAME, [
  uiRouter,
  translate,
  collapsibleSidebar,
  stateTitleDirective,
  objectTypeParamsFilter,
  resourceListController,
  baseContextController,
  localUserPermissions,
  localNetworkProfile
])

  .factory('adnRouterHelper', function($injector) {
    let getDefaultTitleView = function(aObjectType, aCrumbParams) {
      return {
        template: elementTitleTemplate,
        controllerAs: 'title',
        controller: /*@ngInject*/ function(model, modelComms, LocalUserPermissions) {
          const ctrl = this;
          ctrl.objectType = OBJECT_TYPE[aObjectType];

          const paramOnResponse = ctrl.objectType.paramOnResponse;
          ctrl.itemName = paramOnResponse ? _.get(model, [paramOnResponse, 'name']) : (model.name || model.fileName || 'New');
          ctrl.perms = LocalUserPermissions.getAllVisPermissions();
          ctrl.sectionPerms = LocalUserPermissions.getSectionPerms();

          modelComms.addSubscriber(function(data, type) {
            if (!type) {
              ctrl.itemName = data.name || _.get(model, ['lineItem', 'name']) || data.fileName || 'New';
            }
            if (!aCrumbParams) {
              return;
            }

            if (type) {
              const crumbIndex = _.findIndex(ctrl.crumbs, function(crumb) {
                return crumb.objectType.type === type.type && !crumb.id;
              });
              if (crumbIndex > -1) {
                ctrl.crumbs[crumbIndex] = updateCrumb(ctrl, type, data);
              }
            } else {
              const newCrumbs = [];
              const theCrumbs = _.filter(aCrumbParams, function(c) {
                return c.func ? LocalUserPermissions[c.func]() : true;
              });
              _.forEach(theCrumbs, function(lCrumb) {
                calculateCrumbs(ctrl, lCrumb.type || lCrumb, paramOnResponse ? data[paramOnResponse] : data, newCrumbs);
              });
              ctrl.crumbs = newCrumbs;
            }
          });
        }
      };
    };

    let RouterHelper = function(states, resourceProvider, childResourceProvider, parent, dir, listTemplate) {
      let rh = this;

      let splitStates = states.split('.'),
        statePlural = splitStates[0],
        state = splitStates[1];

      rh.listPath = function() {
        return 'app.' + statePlural;
      };
      rh.list = function(extras) {
        let views = {
          'content@app': {
            controller: 'ResourceListCtrl',
            controllerAs: 'list',
            template: listTemplate
          },
          'context@app': {
            template: defaultContextTemplate,
            controller: 'BaseContextController',
            controllerAs: 'ctrl'
          },
          'title@app': {
            template: listTitleTemplate
          }
        };
        return {
          url: '/' + statePlural,
          parent: parent,
          resolve: _.merge({}, {
            ResourceFactory: function() {
              return $injector.get(resourceProvider);
            },
            ChildResourceFactory: function() {
              return childResourceProvider ? $injector.get(childResourceProvider) : null;
            },
            orderByParam: function() {
              return 'name';
            },
            listConfig: function() {
            },
            objectType: function() {
              return ObjectTypeFinder.findObjectFromStateName(states);
            },
            sectionParam: function() {
            },
            extension: function() {
            },
            listExtension: function() {
            },
            bulk: function() {
            }
          }, _.get(extras, 'resolve', {})),
          views: _.merge({}, views, _.get(extras, 'views', {})),
          data: _.assign({}, _.get(extras, 'data', {}), {
            title: "{{'" + _.camelCase(state) + ".plural'|translate}}"
          })
        };
      };

      rh.newPath = function(state) {
        return 'app.' + statePlural + '.' + state;
      };

      rh.newItemPath = function() {
        return 'app.' + statePlural + '.new';
      };
      rh.newItem = function(extras) {
        return {
          url: '/new',
          params: _.merge({}, {copyId: false, folioId: false, folioName: false, orderId: false, orderName: false, full: false, viewType: false, advertiserId: false, advertiserName: false, earningsAccountId: false, earningsAccountName: false, earningsAccountCountry: false, earningsAccountOwnerTeamId: false}, extras || {}),
          views: {},
          onEnter: /*@ngInject*/ function($state, $stateParams) {
            let passExtras = {};
            if (extras) {
              _.forEach(extras, function(v, k) {
                passExtras[k] = $stateParams[k];
              });
            }
            if ($stateParams.copyId) {
              passExtras.copyId = $stateParams.copyId;
            }
            if ($stateParams.orderId && $stateParams.orderName) {
              passExtras.orderId = $stateParams.orderId;
              passExtras.orderName = $stateParams.orderName;
            }
            if ($stateParams.advertiserId && $stateParams.advertiserName) {
              passExtras.advertiserId = $stateParams.advertiserId;
              passExtras.advertiserName = $stateParams.advertiserName;
            }
            if ($stateParams.folioId && $stateParams.folioName) {
              passExtras.folioId = $stateParams.folioId;
              passExtras.folioName = $stateParams.folioName;
            }
            if ($stateParams.earningsAccountId && $stateParams.earningsAccountName) {
              passExtras.earningsAccountId = $stateParams.earningsAccountId;
              passExtras.earningsAccountName = $stateParams.earningsAccountName;
              passExtras.earningsAccountCountry = $stateParams.earningsAccountCountry;
              passExtras.earningsAccountOwnerTeamId = $stateParams.earningsAccountOwnerTeamId;
            }
            if ($stateParams.viewType) {
              passExtras.viewType = $stateParams.viewType;
            }
            if ($stateParams.full) {
              passExtras.full = $stateParams.full;
            }
            $state.go('app.' + states, _.merge({}, {id: Uuid.generate(), isNew: true}, passExtras || {}));
          }
        };
      };

      rh.itemPath = function() {
        return 'app.' + states;
      };

      rh.modelCommsItem = function(aExtras, aObjectType, aCrumbParams) {

        _.set(aExtras, 'resolve.modelComms', function() {
          return new ModelComms();
        });

        if (aObjectType && OBJECT_TYPE[aObjectType]) {
          _.set(aExtras, 'views.title@app', getDefaultTitleView(aObjectType, aCrumbParams));
        }

        return rh.item(aExtras, aObjectType);
      };
      rh.item = function(aExtras) {
        let views = {
          'content@app': {
            controller: (_.upperFirst(_.camelCase(state)) || _.capitalize(state)) + 'Ctrl',
            controllerAs: 'resource'
          },
          'context@app': {},
          'title@app': {
            template: elementTitleTemplate
          }
        };
        return {
          url: _.get(aExtras, 'url') || '/' + state + '/:id',
          params: _.merge({}, {isNew: false, full: false, viewType: false, copyId: false, folioId: false, folioName: false, orderId: false, orderName: false, advertiserId: false, advertiserName: false, earningsAccountId: false, earningsAccountName: false, earningsAccountCountry: false, earningsAccountOwnerTeamId: false}, _.get(aExtras, 'params', {})),
          resolve: _.merge({}, {
            model: /*@ngInject*/ function($stateParams) {
              let resource = $injector.get(resourceProvider);
              if ($stateParams.isNew) {
                return _.isFunction(resource.create) ? resource.create({id: $stateParams.id}) : new resource({id: $stateParams.id});
              }
              return resource.get({id: $stateParams.id}).$promise;
            }
          }, _.get(aExtras, 'resolve', {})),
          views: _.merge({}, views, _.get(aExtras, 'views')),
          data: {
            title: _.get(aExtras, ['data', 'title'], "{{'" + _.camelCase(state) + "'|translate}}")
          }
        };
      };

      rh.generateStates = function($stateProvider) {
        $stateProvider.state(rh.listPath(), rh.list())
          .state(rh.newItemPath(), rh.newItem())
          .state(rh.itemPath(), rh.item());
      };

      rh.getDefaultTitleView = function(aObjectType) {
        return getDefaultTitleView(aObjectType);
      };
    };

    return {
      createRouterHelper: function(states, resourceProvider, parent, dir, listTemplate) {
        return new RouterHelper(states, resourceProvider, null, parent, dir, listTemplate);
      },
      createRouterAndChildHelper: function(states, resourceProvider, childProvider, parent, dir, listTemplate) {
        return new RouterHelper(states, resourceProvider, childProvider, parent, dir, listTemplate);
      }
    };
  });

export default MODULE_NAME;