/**
 * Copyright © 2025 Adnuntius AS.
 */
import {LocalStorage} from "./local-storage";
import * as _ from "lodash";
import {Inject} from "@angular/core";
import {LocaleHelper} from "../util/locale-helper";

type Profile = {
  userId: string;
  username: string;
  name: string;
  displayName: string;
  locale: string;
  canMasquerade: boolean,
  twoFactorAuth: boolean,
  login2faRequired: boolean,
  networkTagIds: any;
  defaultNetworkTagId: string;
  preferredCurrency: string;
  supportUserId: string;
  app: string;
  profile: any;
};

const CALT_TAG_ID = 'b7462';
function getDefaultNetworkTag(networkTagIds: any) {
  if (!_.isArray(networkTagIds)) {
    return null;
  }
  if (networkTagIds.indexOf(CALT_TAG_ID) > -1) {
    return CALT_TAG_ID;
  }
  return _.head(networkTagIds);
}

export class LocalUserProfile {
  private static readonly USER_PROFILE_STORAGE_KEY = 'user.profile';
  private static readonly APPS_INFO = {
    ADVERTISING: {
      id: 'ADVERTISING'
    },
    DATA: {
      id: 'DATA'
    }
  };

  subs = [];
  profile: Profile;

  constructor(@Inject('$locale') private $locale: any, @Inject('LocalUserPermissions') private localUserPermissions: any) {
  }

  private static initDefaultProfile(id, username, name, displayName, locale, canMasquerade, twoFactorAuth, login2faRequired, networkTagIds, preferredCurrency, supportUserId, app): Profile {
    /* eslint-disable angular/window-service */
    const updatedLocale = locale || LocaleHelper.getNavigatorLanguage(window);
    return {
      userId: id,
      username: username,
      name: name,
      displayName: displayName,
      canMasquerade: canMasquerade,
      twoFactorAuth: twoFactorAuth,
      login2faRequired: login2faRequired,
      networkTagIds: networkTagIds,
      locale: updatedLocale,
      preferredCurrency: preferredCurrency,
      defaultNetworkTagId: getDefaultNetworkTag(networkTagIds),
      supportUserId: supportUserId,
      app: app || 'ADVERTISING',
      profile: {
        adnLists: {},
        adnPrefs: {
          showReleaseHighlight: 'SHOW',
          timeZone: 'UTC',
          defaultPageSize: 25,
          locale: updatedLocale,
          modalCtrl: false,
          showSupportWidget: true
        }
      }
    };
  }

  private save() {
    LocalStorage.obtain().setItem(LocalUserProfile.USER_PROFILE_STORAGE_KEY, this.profile);
  }

  private refresh() {
    const localStorageInfo = LocalStorage.obtain().getItem(LocalUserProfile.USER_PROFILE_STORAGE_KEY);
    this.profile = _.isEmpty(localStorageInfo) ? _.isEmpty(this.profile) ? {} : this.profile : localStorageInfo;
    if (!_.isEmpty(this.profile) && _.isArray(this.profile.networkTagIds) && (!this.profile.defaultNetworkTagId || this.profile.networkTagIds.indexOf(this.profile.defaultNetworkTagId) < 0)) {
      this.profile.defaultNetworkTagId = getDefaultNetworkTag(this.profile.networkTagIds);
    }
  }

  create(user, networks, profile, supportUserId, app) {
    this.profile = LocalUserProfile.initDefaultProfile(user.id, user.username, user.name, user.displayName, user.locale, user.canMasquerade, user.twoFactorAuth, user.login2faRequired, networks, user.preferredCurrency, supportUserId, app);
    _.merge(this.profile.profile, profile);
    this.save();
    return this.profile;
  }

  destroy() {
    this.profile = null;
    return LocalStorage.obtain().remove(LocalUserProfile.USER_PROFILE_STORAGE_KEY);
  }

  get(prop?): any {
    this.refresh();
    return prop ? _.get(this.profile, prop) : this.profile;
  }

  getProfile() {
    return this.get('profile');
  }

  getCurrentApp() {
    const currentApp: string = this.get('app');
    const availableApps = this.localUserPermissions.getApps();
    if (availableApps.length > 0 && availableApps.indexOf(currentApp) < 0) {
      return availableApps[0];
    }
    if (availableApps.indexOf(currentApp) > -1 && LocalUserProfile.APPS_INFO[currentApp]) {
      return currentApp;
    }
    return LocalUserProfile.APPS_INFO.ADVERTISING.id;
  }

  set(prop, value) {
    this.refresh();
    _.set(this.profile, prop, value);
    this.save();
    this.refresh();
  }

  getPreferredCurrency(): string {
    return _.get(this.get(), 'preferredCurrency') || _.get(this.get(), ['profile', 'adnPrefs', 'preferredCurrency']);
  }

  safeSaveProfile(fields, data): any {
    this.refresh();
    _.set(this.profile.profile, fields, data);
    this.save();
    this.refresh();
    return this.profile;
  }

  isSameUser(uProfile: any): boolean {
    this.refresh();
    return _.get(this.profile, 'userId') && _.get(this.profile, 'userId') === _.get(uProfile, 'userId');
  }

  canSeeReachCharts(): boolean {
    return false;
    // return _.includes(["adnuntius@adnuntius.com", "broker1@adnuntius.com", "richard@adnuntius.com"], this.get("username"));
  }

  canPauseAdUnits(): boolean {
    return _.includes(["adnuntius@adnuntius.com", "broker1@adnuntius.com"], this.get("username"));
  }

  canLimitBurnRates(): boolean {
    return _.includes(["adnuntius@adnuntius.com", "broker1@adnuntius.com"], this.get("username"));
  }

  canSwitchToMaui(): boolean {
    return _.includes(["dirk@adnuntius.com", "adnuntius@adnuntius.com", "broker1@adnuntius.com", "henrik@adnuntius.com", "dennis@adnuntius.com", "lasse@adnuntius.com", "micke@adnuntius.com", "stian@adnuntius.com"], this.get("username"));
  }

  isReadOnly(): boolean {
    return _.includes(["reg@hasv.no"], this.get("supportUserId"));
  }

  hasNetwork(networkTagId) {
    this.refresh();
    return _.includes(this.profile.networkTagIds, networkTagId);
  }

  updateDefaultNetworkId(networkTagId) {
    this.refresh();
    if (networkTagId && _.indexOf(this.profile.networkTagIds, networkTagId) > -1) {
      this.profile.defaultNetworkTagId = networkTagId;
    }
  }

  subscribe(key, func) {
    if (!_.isFunction(func)) {
      return;
    }
    this.subs.push({key: key, func: func});
  }

  publish(key, updatedObj) {
    _.forEach(this.subs, (sub) => {
      if (sub.key === key) {
        sub.func(updatedObj);
      }
    });
  }
}