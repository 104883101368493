/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "data-export";

angular.module(MODULE_NAME, [])

  .factory('DataExport', function(adnResource, $q) {
    const DataExport = adnResource('dataexports', {}, {
      copy: {
        params: {dataExportId: '@dataExportId', name: '@name', description: '@description'}
      }
    }, "dataExport");

    DataExport.copyDataExport = function(dataExport, newId) {
      if (!dataExport || !dataExport.id) {
        return $q.reject("Insufficient parameters for the data export copy");
      }
      return DataExport.copy({
        id: dataExport.id,
        dataExportId: newId || '',
        name: dataExport.name,
        description: dataExport.description
      });
    };

    return DataExport;
  });

export default MODULE_NAME;