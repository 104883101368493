/**
 * Copyright © 2025 Adnuntius AS.
 */
import {ApiConfig} from "../../api";
import {Inject} from "@angular/core";

import * as _ from 'lodash';

export class ReachEstimateResource {
  private readonly uriDiag: string = ApiConfig.obtain().getVersionedUri(['reachestimate', 'diag']);

  constructor(@Inject('$http') private $http: any, @Inject('$q') private $q: any) {
  }

  save(lineItemId, data, params, cb, errCb) {
    const versioned = ['reachestimate'];
    if (lineItemId) {
      versioned.push(lineItemId);
    }
    const uri = ApiConfig.obtain().getVersionedUri(versioned);
    return this.$http.post(uri, data, {params: params || {}}).then(function(response) {
      return (cb || _.noop)(response.data);
    }).catch(function(response) {
      return (errCb || _.noop)(response);
    });
  }

  diag(cb, errCb) {
    return this.$http.get(this.uriDiag).then(function(response) {
      return (cb || _.noop)(response.data);
    }).catch(function(response) {
      return (errCb || _.noop)(response);
    });
  }
}
