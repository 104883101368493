/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiTranslate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import uiSelect from 'ui-select';
import {IAB_TAXONOMY} from "../../../components/util/iab-taxonomy";

const MODULE_NAME = 'iab-multi-search-select';

angular.module(MODULE_NAME, [
  uiSelect,
  uiBootstrap,
  uiTranslate
])

  .component('adnIabMultiSelect', {
    template: `
    <ui-select name="{{ctrl.fieldName}}" multiple ng-model="ctrl.model[ctrl.param]" theme="bootstrap" title="Select IAB Category" adn-form-control>
      <ui-select-match placeholder="Select IAB Category" allow-clear="{{::ctrl.allowClear}}">{{$item.value || $select.selected.value || ctrl.model[ctrl.param].value}}</ui-select-match>
      <ui-select-choices repeat="datum.id as datum in ctrl.dataResults" refresh="ctrl.search($select)" refresh-delay="50">
        <div ng-bind-html="datum.value | highlight: $select.search"></div>
      </ui-select-choices>
      <ui-select-no-choice>
        No matching results found
      </ui-select-no-choice>
    </ui-select>
    `,
    controllerAs: 'ctrl',
    bindings: {
      model: '<',
      param: '@',
      allowClear: '@',
      fieldName: '@'
    },
    controller: function() {
      const ctrl = this;

      const preparedTaxonomy = _.map(IAB_TAXONOMY, function(iab) {
        iab.lowerCase = iab.value.toLowerCase();
        iab.filteredLowerCase = iab.lowerCase.replaceAll(/and/gi, "");
        iab.words = _.filter(iab.filteredLowerCase.split(" "), function(w) {
          return w.length >= 3;
        });
        return iab;
      });

      ctrl.$onInit = function() {
        ctrl.allowClear = ctrl.allowClear === 'true' || ctrl.allowClear === true;
        ctrl.multiple = ctrl.multiple === 'true' || ctrl.multiple === true;

        // populated stored IDs with names of IAB
        ctrl.model[ctrl.param] = _.map(ctrl.model[ctrl.param], function(iabInModel) {
          return _.find(preparedTaxonomy, function(iab) {
            return iab.id === iabInModel;
          }) || iabInModel;
        });

        ctrl.search = function(searchElement) {
          const searchString = searchElement.search.toLowerCase();
          const filtered = !searchString ? preparedTaxonomy : _.orderBy(_.filter(preparedTaxonomy, function(iab) {
            return iab.lowerCase.indexOf(searchString) > -1;
          }), function(iab) {
            const initPosition = Math.max(10 - iab.lowerCase.indexOf(searchString), 0);
            const repeated = iab.filteredLowerCase.split(searchString).length - 1;
            const startOfWords = _.filter(iab.words, function(word) {
              return word.indexOf(searchString) === 0;
            }).length;
            return 1000 - (startOfWords * 10 + repeated * 2 + initPosition);
          });
          ctrl.dataResults = filtered.slice(0, 150);
        };

        ctrl.ready = true;
      };
    }
  });

export default MODULE_NAME;