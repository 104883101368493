/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';
import checklistModel from 'checklist-model';

import template from './site-group-targeting.html';

import resources from '../../../components/api/resources/resources';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import {ADN_TARGETING_TYPES} from "../targeting-constants";

const MODULE_NAME = 'site-group-targeting-directive';

angular.module(MODULE_NAME, [translate, uiBootstrap, checklistModel, resources, promiseSpinner])

  .directive('adnSiteGroupTargeting', function(SiteGroup) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnSiteGroupTargeting',
        underModel: '=',
        isLockedDown: '<',
        overridableTargeting: '<',
        mandatory: '<',
        mode: '@'
      },
      link: function(scope, element, attrs, tabset) {
        scope.resolved = false;
        scope.loaded = true;
        scope.targetType = ADN_TARGETING_TYPES.siteGroup;

        // we send through to the API [id, id]. API returns [{id}, {id}]. Hence the difference here.
        scope.model = scope.model || {siteGroups: []};
        if (_.get(scope.model.siteGroups, '[0].id')) {
          scope.model.siteGroups = _.map(scope.model.siteGroups, function(s) {
            return s.id;
          });
        }

        scope.selectAll = function() {
          scope.model.siteGroups = _.map(scope.siteGroups, function(s) {
            return s.id;
          });
        };

        scope.deselectAll = function() {
          scope.model.siteGroups = [];
        };

        scope.hook = {};
        scope.hook.getTemplateData = function() {
          var summary = (scope.model.negated === true ? "NOT: " : "") + _.map(_.filter(scope.siteGroups, function(site) {
            return scope.model.siteGroups.indexOf(site.id) > -1;
          }), 'name').join(", ");
          return {
            vm: angular.copy(scope.model),
            model: angular.copy(scope.model),
            constant: ADN_TARGETING_TYPES.siteGroup,
            summary: summary
          };
        };

        const copyTemplateFunc = function(template) {
          scope.model = angular.copy(template.data.model);
        };
        scope.hook.copyTemplate = copyTemplateFunc;

        tabset.register(ADN_TARGETING_TYPES.siteGroup.widget, function() {
          if (scope.resolved) {
            return;
          }
          SiteGroup.query().$promise.then(function(page) {
            scope.siteGroups = page.results;
            scope.resolved = true;
          });
        }, copyTemplateFunc);
      }
    };
  });

export default MODULE_NAME;