/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

const MODULE_NAME = 'site-target-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnTargetingSiteTargetSummary', function(Site) {
    return {
      restrict: 'A',
      template: `
        <div>
            <strong ng-if="target.negated">NOT:&nbsp;</strong>
            <span>{{sites}}</span>
            <div ng-if="restricted && isLimited"><a ng-click="restrictionLifted(true)"><span class="fa fa-chevron-down"></span> Show all</a></div>
        </div>`,
      scope: {
        targets: '=adnTargetingSiteTargetSummary'
      },
      link: function(scope) {
        let cache = {};

        scope.quantity = 10;
        scope.restricted = true;

        scope.restrictionLifted = function(restrictionLifted) {
          if (restrictionLifted) {
            scope.restricted = false;
          }
          scope.sites = scope.restricted ? scope.data.slice(0, scope.quantity).join(", ") : scope.data.join(", ");
          scope.isLimited = scope.data.length > scope.quantity;
        };

        scope.$watchCollection('targets', function(targets) {
          scope.target = targets;
        });
        scope.$watchCollection('target', function(target) {
          const hasNameAlready = !!_.get(target, 'sites[0].name');
          if (hasNameAlready) {
            scope.data = _.map(target.sites, function(site) {
              return site.name;
            });
          } else {
            const siteIds = _.get(target, 'sites[0].id') ? _.map(target.sites, 'id') : target.sites,
              queryIds = _.without(siteIds, _.keys(cache));
            Site.query(queryIds, function(page) {
              _.forEach(page.results, function(s) {
                cache[s.id] = s.name;
              });
              scope.data = _.map(siteIds, function(id) {
                return cache[id];
              });
            });
          }
          scope.restrictionLifted();
        });
      }
    };
  });

export default MODULE_NAME;