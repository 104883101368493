/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from "lodash";

import standardFormModule from '../../common/standard-form-module';
import {adnFormHelper} from "../../common/controller/form-helper";
import {IAB_TAXONOMY} from "../../../components/util/iab-taxonomy";

const MODULE_NAME = 'context-service-configuration-controller';

angular.module(MODULE_NAME, [standardFormModule])

  .controller('ContextServiceConfigurationCtrl', function(model, $stateParams, ContextServiceConfiguration, modelComms, crawlerResource) {
    const ctrl = this;
    ctrl.iabTaxonomy = angular.copy(IAB_TAXONOMY);
    ctrl.vm = ctrl.vm || {};
    ctrl.isNew = $stateParams.isNew;

    function afterInitAfterSave() {
      ctrl.model.contextService = ctrl.model.contextService || 'ADNUNTIUS';

      ctrl.vm = ctrl.vm || {};
      ctrl.vm.keywordMetaTags = _.get(ctrl.model, ['crawlerConfig', 'keywordMetaTags'], []).join(", ");
      ctrl.vm.categoryMetaTags = _.get(ctrl.model, ['crawlerConfig', 'categoryMetaTags'], []).join(", ");
      ctrl.vm.deriveKeywordsFromUrl = _.get(ctrl.model, ['crawlerConfig', 'deriveKeywordsFromUrl'], false);
      ctrl.vm.categoryKeywords = [];
      _.forEach(_.get(ctrl.model, ['crawlerConfig', 'categoryKeywords']), function(val, key) {
        ctrl.vm.categoryKeywords.push({category: key, keywords: (val || []).join(", ")});
      });
      ctrl.vm.categoryKeywords.sort(function(a, b) {
        const iabEntry = IAB_TAXONOMY.find(entry => entry.id === a.category);
        return iabEntry && iabEntry.order > IAB_TAXONOMY.find(entry => entry.id === b.category).order;
      });
      ctrl.vm.selectorConfiguration = [];
      _.forEach(_.get(ctrl.model, ['crawlerConfig', 'selectorConfiguration']), function(val, key) {
        ctrl.vm.selectorConfiguration.push({domain: key, value: (val || {})});
      });
      ctrl.vm.selectorConfiguration.sort();
      ctrl.vm.trackedKeywords = _.get(ctrl.model, ['crawlerConfig', 'trackedKeywords'], []).map(trackedKeyword => {
        return {
          keyword: trackedKeyword.keyword,
          mappings: trackedKeyword.mappings.map(mapping => {
            if (mapping.includes(",") && !mapping.startsWith('"')) {
              return `"${mapping}"`;
            }
            return mapping;
          }).join(", ")
        };
      });
    }

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms,
      afterInit: afterInitAfterSave,
      afterSave: afterInitAfterSave
    });

    ctrl.addData = function() {
      ctrl.model.teamMappings = ctrl.model.teamMappings || [];
      ctrl.model.teamMappings.push({team: "", siteGroup: ""});
      ctrl.editsMade();
    };

    ctrl.toggleKeyword = function(keyword) {
      keyword.showTextArea = !keyword.showTextArea;
      keyword.showTextAreaRows = Math.max(parseInt(keyword.keywords.length / 150, 10), 4);
    };

    ctrl.deleteData = function(index) {
      _.pullAt(ctrl.model.teamMappings, index);
      ctrl.editsMade();
    };

    ctrl.testConfiguration = function() {
      ctrl.onBeforeSubmit();
      ctrl.loadingTest = true;
      crawlerResource.testConfiguration(ctrl.testUrl, ctrl.model.crawlerConfig).then(populateModel);
    };

    function populateModel(crawlResults) {
      const keywords = getKeyType(crawlResults, "keywords") || [];
      const categories = getKeyType(crawlResults, "categories") || [];
      const words = getKeyType(crawlResults, "words") || [];

      ctrl.crawlResults = {};
      ctrl.crawlResults.keywords = keywords.sort();
      ctrl.crawlResults.categories = categories.sort();
      ctrl.crawlResults.words = words.sort();
      ctrl.loadingTest = false;
    }

    function getKeyType(crawlResults, keyType) {
      return crawlResults.results
        .filter(result => result.key === keyType)
        .flatMap(result => result.values)
        .map(keyword => convertCategory(keyword.value));
    }

    function convertCategory(name) {
      const upper = name.toUpperCase();
      return _.get(IAB_TAXONOMY.find(entry => entry.id === upper), 'value') || name;
    }

    ctrl.addIabCategory = function() {
      ctrl.vm.categoryKeywords = ctrl.vm.categoryKeywords || [];
      ctrl.vm.categoryKeywords.push({category: "", keywords: ""});
      ctrl.editsMade();
    };

    ctrl.addSelector = function() {
      ctrl.vm.selectorConfiguration = ctrl.vm.selectorConfiguration || [];
      ctrl.vm.selectorConfiguration.push({domain: "", value: {isJavaScript: false}});
      ctrl.editsMade();
    };

    ctrl.deleteIabCategory = function(index) {
      _.pullAt(ctrl.vm.categoryKeywords, index);
      ctrl.editsMade();
    };

    ctrl.deleteSelector = function(index) {
      _.pullAt(ctrl.vm.selectorConfiguration, index);
      ctrl.editsMade();
    };

    ctrl.toggleEditTrackedKeyword = function(index) {
      _.forEach(ctrl.vm.trackedKeywords, function(trackedKeyword, i) {
        trackedKeyword.edited = i === index ? !trackedKeyword.edited : false;
      });
      ctrl.editsMade();
    };

    ctrl.addTrackedKeyword = function() {
      ctrl.vm.trackedKeywords = ctrl.vm.trackedKeywords || [];
      ctrl.vm.trackedKeywords.unshift(newTrackedKeywordEntry());
    };

    const newTrackedKeywordEntry = function() {
      return {
        keyword: '',
        mappings: '',
        edited: true
      };
    };

    ctrl.removeTrackedKeyword = function(trackedKeyword) {
      ctrl.editsMade();
      trackedKeyword.edited = false;
      trackedKeyword.deleted = true;
    };

    ctrl.getExampleRows = function() {
      const rows = [];
      rows.push({
        keyword: "tracked keyword"
      });
      rows.push({
        keyword: 'keyword',
        mappings: ['"mapped, keyword, with, comma"', 'multi word keyword']
      });
      rows.push({
        keyword: 'soccer',
        mappings: ['football', 'footy', 'foosball']
      });
      rows.push({
        keyword: 'food',
        mappings: ['pad thai', 'pad see ew', 'pad siew']
      });
      return rows;
    };

    ctrl.getRows = function() {
      const rows = [];
      _.map(ctrl.vm.trackedKeywords, trackedKeyword => sanitiseVmTrackedKeywords(trackedKeyword))
        .forEach(trackedKeyword => rows.push(trackedKeyword));
      return rows;
    };

    const sanitiseVmTrackedKeywords = trackedKeyword => {
      const mapping = trackedKeyword.mappings.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g);
      return {
        keyword: trackedKeyword.keyword,
        mappings: mapping ? mapping.map(mapping => mapping.trim()) : []
      };
    };

    ctrl.uploadHook = function() {
      ctrl.isLoading = true;
      ContextServiceConfiguration.get(ctrl.model.id).$promise.then(function(data) {
        ctrl.isLoading = true;
        const resource = _.head(data.results);
        ctrl.model = resource;
        ctrl.saveDirect(resource);
        ctrl.isLoading = false;
      });
    };

    ctrl.onBeforeSubmit = function() {
      // validate and avoid duplicate teams
      ctrl.model.teamMappings = _.map(_.keyBy(_.filter(ctrl.model.teamMappings, function(tm) {
        return tm.team && tm.team.id && _.trim(tm.siteGroup).length > 0;
      }), 'team.id'), function(tm) {
        return tm;
      });

      ctrl.model.crawlerConfig = ctrl.model.crawlerConfig || {};
      ctrl.model.crawlerConfig.keywordMetaTags = _.isString(ctrl.vm.keywordMetaTags) ? ctrl.vm.keywordMetaTags.split(",").map(tag => tag.trim()) : [];
      ctrl.model.crawlerConfig.categoryMetaTags = _.isString(ctrl.vm.categoryMetaTags) ? ctrl.vm.categoryMetaTags.split(",").map(tag => tag.trim()) : [];
      ctrl.model.crawlerConfig.deriveKeywordsFromUrl = _.isBoolean(ctrl.vm.deriveKeywordsFromUrl) ? ctrl.vm.deriveKeywordsFromUrl : false;
      ctrl.model.crawlerConfig.categoryKeywords = {};
      ctrl.model.crawlerConfig.trackedKeywords = _.isArray(ctrl.vm.trackedKeywords) ? ctrl.vm.trackedKeywords
        .filter(trackedKeyword => !(trackedKeyword.deleted || false))
        .filter(trackedKeyword => trackedKeyword.keyword.trim().length)
        .map(trackedKeyword => sanitiseVmTrackedKeywords(trackedKeyword)) : [];
      ctrl.vm.categoryKeywords.forEach(categoryKeyword => {
        if (categoryKeyword.category) {
          ctrl.model.crawlerConfig.categoryKeywords[categoryKeyword.category] = (categoryKeyword.keywords || "")
            .split(",").map(keyword => keyword.trim()).filter(keyword => keyword.length > 0);
        }
      });
      ctrl.model.crawlerConfig.selectorConfiguration = {};
      ctrl.vm.selectorConfiguration.forEach(selector => {
        if (selector.domain) {
          if (selector.value.isJavaScript) {
            delete selector.value.excludeSelector;
          }
          ctrl.model.crawlerConfig.selectorConfiguration[selector.domain] = (selector.value || {});
        }
      });
    };
  });

export default MODULE_NAME;
