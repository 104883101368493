/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import localNetworkProfile from '../../components/session/local-network-profile';
import _ from "lodash";

const MODULE_NAME = 'dimension-output-component';

angular.module(MODULE_NAME, [localNetworkProfile])

  .component('adnDimensionOutput', {
    bindings: {
      adUnit: '<'
    },
    controllerAs: 'ctrl',
    controller: function() {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.isFixed = _.get(ctrl.adUnit, ['dimensions', 0, 0]) >= 0 && _.get(ctrl.adUnit, ['dimensions', 0, 1]) >= 0;
      };
    },
    template: `
        <div ng-if="ctrl.isFixed"><span ng-repeat="dims in ctrl.adUnit.dimensions">{{dims[0]}}&times;{{dims[1]}}<span ng-if="!$last">, </span></span></div>
        <div ng-if="!ctrl.isFixed">{{ctrl.adUnit.minWidth}}&times;{{ctrl.adUnit.minHeight}} &ndash; {{ctrl.adUnit.width}}&times;{{ctrl.adUnit.height}}</div>
    `
  });

export default MODULE_NAME;