/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

import template from './search-pagination.html';

const MODULE_NAME = 'search-pagination-directive';

angular.module(MODULE_NAME, [translate])

  .component('adnSearchPagination', {
    template: template,
    bindings: {
      page: '<',
      searchMeta: '<'
    },
    controllerAs: 'ctrl',
    controller: _.noop
  });

export default MODULE_NAME;