/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "earnings-account";

angular.module(MODULE_NAME, [])

  .factory("EarningsAccount", function(adnResource) {
    return adnResource('earningsaccounts', {}, {}, 'EarningsAccount');
  });

export default MODULE_NAME;