/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import translate from 'angular-translate';
import Highcharts from 'highcharts';
import highchartsNg from 'highcharts-ng';

import template from './stats-widget.html';

import localNetworkProfile from '../../../components/session/local-network-profile';
import {OBJECT_TYPE} from "../../common/constants/object-type";

const MODULE_NAME = "bar-chart-widget-directive";

angular.module(MODULE_NAME, [highchartsNg, localNetworkProfile, translate])

  .directive('adnBarChartWidget', function(statsResource, ChartOptionsUtil, $translate, $log, $locale, LocalNetworkProfile) {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        widgetType: '<adnBarChartWidget'
      },
      template: template,
      controllerAs: 'ctrl',
      controller: function postLink() {
        const ctrl = this;

        ctrl.isCollection = true;

        let timezone = LocalNetworkProfile.get('timeZone');
        let networkTimezoneOffset = moment.tz(timezone).utcOffset();

        let chunkYValue = function(yval) {
          if (_.has(yval, 'amount')) {
            return yval.amount;
          } else if (_.has(yval, 'count')) {
            return yval.count;
          }
          return yval;
        };

        ctrl.$onInit = function() {

          ctrl.dateOption = ChartOptionsUtil.getDateOptions().last7Days;
          if (ctrl.widgetType.dateOption && ChartOptionsUtil.getDateOptions()[ctrl.widgetType.dateOption]) {
            ctrl.dateOption = ChartOptionsUtil.getDateOptions()[ctrl.widgetType.dateOption];
          }
          ctrl.startDate = ctrl.dateOption.startDate();
          ctrl.endDate = ctrl.dateOption.endDate();

          ctrl.chartTitle = "Data for " + $translate.instant('chart.dateOption.' + ctrl.dateOption.id + '.sentence');

          ctrl.ids = _.map(ctrl.widgetType.objIds, 'id');

          if (!ctrl.ids.length) {
            ctrl.isPending = false;
            ctrl.noData = "Select at least one object to report stats on.";
            return;
          }

          let advStatsSeries = {
              categories: [],
              title: {text: null}
            },
            createChartConfig = function() {
              return {
                chart: {
                  type: 'bar',
                  height: ctrl.widgetType.height || 300
                },
                xAxis: advStatsSeries,
                tooltip: {
                  shared: true
                },
                series: [],
                title: {
                  text: null
                },
                credits: {
                  enabled: false
                },
                accessibility: {
                  enabled: false
                },
                exporting: {
                  enabled: true
                }
              };
            };

          ctrl.statsChart = createChartConfig();

          ctrl.selFieldId = ctrl.widgetType.selFieldId;

          const objType = OBJECT_TYPE[ctrl.widgetType.objectType] || OBJECT_TYPE.LineItem;
          ctrl.chartType = ChartOptionsUtil.getOptions(objType.caps);

          const setUpFieldData = function(theField) {
            const networkTrans = LocalNetworkProfile.getNetworkTranslations(theField);
            return {name: networkTrans || $translate.instant('report.field.' + theField), data: [], adnId: theField};
          };

          const extractFieldData = function(theField, chunk, selFieldData) {
            const dataPoint = chunk[theField];
            const value = chunkYValue(dataPoint) || 0;
            selFieldData.data.push(value);
          };

          let startStatsGet = function() {

            let statsParams = {
              id: _.slice(ctrl.ids, 0, 15).join(';'),
              idKey: ctrl.chartType.idKey,
              startDate: ctrl.startDate,
              endDate: ctrl.endDate,
              groupBy: objType.caps
            };
            statsResource.get(statsParams, function(stats) {
              Highcharts.setOptions({
                global: {timezoneOffset: networkTimezoneOffset * -1},
                lang: {
                  shortMonths: $locale.DATETIME_FORMATS.SHORTMONTH,
                  months: $locale.DATETIME_FORMATS.MONTH,
                  weekdays: $locale.DATETIME_FORMATS.DAY,
                  thousandsSep: $locale.NUMBER_FORMATS.GROUP_SEP || ' ',
                  decimalPoint: $locale.NUMBER_FORMATS.DECIMAL_SEP || '.'
                }
              });
              ctrl.totals = stats.totals || stats;
              ctrl.error = false;

              ctrl.statsChart.xAxis.categories = [];

              const fieldsOfJoy = [];
              _.forEach(['', '2', '3', '4', '5', '6', '7'], function(suffix) {
                if (ctrl.widgetType['selField' + suffix]) {
                  fieldsOfJoy.push(setUpFieldData(ctrl.widgetType['selField' + suffix]));
                }
              });

              _.forEach(stats.chunks, function(chunk) {

                _.forEach(fieldsOfJoy, function(fieldData) {
                  extractFieldData(fieldData.adnId, chunk, fieldData);
                });

                ctrl.statsChart.xAxis.categories.push(chunk[objType.apiParam].name);
              });

              ctrl.statsChart.series = [];
              _.forEach(fieldsOfJoy, function(fieldData) {
                ctrl.statsChart.series.push(fieldData);
              });


              if (!stats.chunks.length) {
                ctrl.noData = "No data was found for your selected " + $translate.instant('object.' + ctrl.widgetType.objectType + '.plural').toLowerCase();
              }
              ctrl.haveStats = true;
              ctrl.isPending = false;
            }, function(data) {
              $log.warn(data);
              if (data.status !== -1) {
                ctrl.error = true;
              }
              ctrl.isPending = false;
            }, ctrl);
          };
          startStatsGet();

        };
      }
    };
  });

export default MODULE_NAME;