/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "layoutInclude";

angular.module(MODULE_NAME, [])

  .factory('LayoutInclude', function(adnResource) {
    return adnResource('layoutincludes');
  });

export default MODULE_NAME;