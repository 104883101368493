/**
 * Copyright © 2025 Adnuntius AS.
 */
import {LocalStorage} from "./local-storage";
import * as moment from 'moment';
import * as _ from "lodash";

type SessionData = {
  accessToken: string;
  refreshToken: string;
  username: string;
  expires: string;
  masqueraderUsername: string;
};

export class Session {
  private static instance: Session;

  static readonly SESSION_KEY = 'session';

  session: SessionData | any;

  private constructor() {
  }

  public static obtain(): Session {
    if (!this.instance) {
      this.instance = new Session();
    }
    const localStorageSession = LocalStorage.obtain().getItem(Session.SESSION_KEY);
    this.instance.session = _.isObject(localStorageSession) && !_.isEmpty(localStorageSession) ? localStorageSession : {};
    return this.instance;
  }

  public static obtainData(): SessionData {
    return this.obtain().session;
  }

  isExpired(): boolean {
    return moment(this.session.expires).isBefore(moment());
  }

  init(scope: string, accessToken: string, refreshToken: string, username: string, expires: number | Date, masqueraderUsername?: string): SessionData {
    this.session.accessToken = accessToken;
    this.session.scope = scope;
    this.session.refreshToken = refreshToken;
    this.session.username = username;
    this.session.masqueraderUsername = !masqueraderUsername || masqueraderUsername === username ? "" : masqueraderUsername;
    this.session.expires = (expires instanceof Date) ? moment(expires).toISOString() : moment().add(expires, 's').toISOString();
    LocalStorage.obtain().setItem(Session.SESSION_KEY, this.session);
    return Session.obtainData();
  }

  destroy() {
    LocalStorage.obtain().remove(Session.SESSION_KEY);
  }
}
