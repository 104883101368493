/**
 * Copyright © 2025 Adnuntius AS.
 */
import {Component, Input, OnChanges, OnInit} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'line-item-status-explanation-label',
  template: `<span>{{ "lineItem.userState.explanation." + adjustedState | translate }}</span>`
})
export class LineItemStatusExplanationLabelComponent implements OnInit, OnChanges {
  @Input() userState: string;
  adjustedState: string;

  constructor(public translate: TranslateService) {
  }

  ngOnChanges() {
    this.updateValues();
  }

  ngOnInit(): void {
    this.updateValues();
  }

  private updateValues() {
    this.adjustedState = this.userState;
  }
}