/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from "lodash";
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import moment from "moment";

import {Uuid} from "../../../components/util/uuid";
import resources from '../../../components/api/resources/resources';
import timeShifter from '../../../components/util/time-shifter';
import localNetworkProfile from '../../../components/session/local-network-profile';
import targetingModule from '../../targeting/targeting-module';
import heatmapComponent from '../../common/directives/heatmap-component';

import modalTemplate from '../../targeting/base-targeting-editor-modal.html';
import template from './reach-analysis-component.html';
import {ADN_TARGETING_TYPES} from "../../targeting/targeting-constants";
import {calculateAllocation, calculateHighchartsAllocation} from "./allocation-calculator";

const MODULE_NAME = "reach-analysis-comp";

angular.module(MODULE_NAME, [translate, uiBootstrap, resources, timeShifter, localNetworkProfile, targetingModule, heatmapComponent])

  .component('adnReachAnalysis', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      model: '<',
      modelComms: '<',
      copyHook: '<',
      targeting: '<',
      callbackHook: '<',
      targetingType: '@',
      type: '@',
      objectType: '@'
    },
    controller: function($translate, $log, $uibModal, $state, $timeout, localUserProfile, Creative, dataViewResource, adnTimeShifter, LineItem, LocalNetworkProfile, searchResource, reachEstimateResource, Order, Team, Product, MarketplaceProduct, LocalUserPermissions) {
      const ctrl = this;

      ctrl.math = Math;
      ctrl.sectionPerms = LocalUserPermissions.getSectionPerms();
      ctrl.canSeeCharts = localUserProfile.canSeeReachCharts();
      ctrl.canSeeCalendars = LocalNetworkProfile.canSeeReachCalendars() || localUserProfile.canSeeReachCharts();
      const sponsorshipAllocationStore = {};
      const auctionAllocationStore = {};
      ctrl.noDataSponsorshipMessage = "No competitors with sponsorship were found";
      ctrl.noDataAuctionMessage = "No auction competitors were found";

      ctrl.$onInit = function() {
        const ctrl = this;
        ctrl.hasResults = false;
        ctrl.hasBegun = false;

        ctrl.isLineItem = ctrl.model && (ctrl.objectType === 'LINE_ITEM' || ctrl.objectType === '' || ctrl.objectType === 'TARGETING');
        ctrl.isGeneral = !ctrl.model && !ctrl.objectType && ctrl.type && ctrl.objectType !== 'TARGETING';
        ctrl.isTargeted = ctrl.model && (ctrl.objectType !== 'LINE_ITEM' && ctrl.objectType !== '') && ctrl.objectType !== 'TARGETING';

        ctrl.translation = "A line item could deliver:";
        if (ctrl.isTargeted && !ctrl.isAxAdvertiser && !ctrl.isSelfService) {
          ctrl.objectTrans = ctrl.objectType === 'SITE' ? 'site' : ctrl.objectType === 'AD_UNIT' ? 'ad unit' : 'object';
          ctrl.translation = "This " + ctrl.objectTrans + " has the following available:";
        }
        if (ctrl.objectType === 'TARGETING') {
          ctrl.objectTrans = ctrl.targetingType === 'CREATIVE' ? "creative" : "line item";
          ctrl.translation = "The targeting applied to this " + ctrl.objectTrans + " could deliver:";
        }

        ctrl.lineItem = ctrl.model;
        ctrl.excludeLineItem = true;
        ctrl.forecastType = ctrl.isLineItem && ctrl.objectType !== 'TARGETING' ? 'LINE_ITEM' : 'CUSTOM';
        ctrl.forecastMethod = 'NORMAL';
        ctrl.isAxAdvertiser = LocalUserPermissions.isAxAdvertiser();
        ctrl.isSelfService = LocalUserPermissions.isSelfService();

        ctrl.reachFields = [
          {id: 'name', name: 'Name'},
          {id: 'startDate', name: 'Start Date'},
          {id: 'endDate', name: 'End Date'},
          {id: 'bidSpecification', name: 'Bid'},
          {id: 'impressionObjective', name: 'Objective'},
          {id: 'impressionDelivery', name: 'Delivered'},
          {id: 'overlapPercent', name: 'Allocation Percentage'},
          {id: 'dateOverlap', name: 'Date Overlap'},
          {id: 'matchingSimilarity', name: 'Matching Similarity'},
          {id: 'impressionAllocationEstimate', name: 'Estimated Allocation'},
          {id: 'tierPriority', name: 'Tier and Prio'}
        ];
        ctrl.sorting = {
          orderByParam: 'matchingSimilarity',
          asc: true
        };
        ctrl.sorting.makeSortQuery = function(param) {
          if (ctrl.sorting.orderByParam === param.id) {
            ctrl.sorting.asc = !ctrl.sorting.asc;
          } else {
            ctrl.sorting.orderByParam = param.id;
            ctrl.sorting.asc = true;
          }
        };

        if (ctrl.objectType === 'TARGETING' && _.get(ctrl, ['lineItem', 'order']) && !_.get(ctrl, ['lineItem', 'order', 'team', 'id'])) {
          // get the order so you can get the team for the reach analysis
          Order.get({id: ctrl.lineItem.order.id}, function(order) {
            ctrl.lineItem.order = order;
          });
        }

        ctrl.sortComparator = function(v1, v2) {
          let v1Value = v1.value || 0;
          let v2Value = v2.value || 0;
          if (ctrl.sorting.orderByParam === 'bidSpecification') {
            _.forEach(v1.value, function(val) {
              if (val.amount >= 0) {
                v1Value = val.amount;
                return false;
              }
            });
            _.forEach(v2.value, function(val) {
              if (val.amount >= 0) {
                v2Value = val.amount;
                return false;
              }
            });
          }
          return (v1Value < v2Value) ? -1 : 1;
        };

        let defaultTeam;

        let assignDefaultTeam = function() {
          defaultTeam = _.find(ctrl.teams || [], ['id', _.get(ctrl.lineItem, 'order.team.id')]);
        };

        let updateOrder = function() {
          let orderId = _.get(ctrl.lineItem, 'order.id');
          if (orderId) {
            Order.get({id: orderId}, function(ord) {
              ctrl.lineItem.order = ord;
              assignDefaultTeam();
            });
          }
        };
        updateOrder();

        Team.query(function(page) {
          let allGroups = page.results;
          ctrl.teams = _.filter(allGroups, function(g) {
            return LocalUserPermissions.isPartOf(g.network.tagId || g.network.id, g.id);
          });
          assignDefaultTeam();
        });

        if (ctrl.isSelfService) {
          Product.query(function(page) {
            ctrl.products = page.results;
          });
        }

        if (ctrl.isAxAdvertiser) {
          MarketplaceProduct.query(function(page) {
            ctrl.axProducts = page.results;
          });
        }

        ctrl.tiers = angular.copy(LocalNetworkProfile.get('tiers'));

        ctrl.searchLineItems = function(searchElement) {
          searchResource.query({"q": searchElement.search, "types": "LineItem"}).then(function(data) {
            ctrl.lineItemResults = data.searchResults;
          });
        };

        ctrl.hideTargeting = ctrl.isAxAdvertiser || ctrl.objectType === 'TARGETING';
        ctrl.onProductChange = function() {
          const selProduct = ctrl.forecastModel.axProduct;
          const optionalTargeting = _.get(selProduct, ['optionalTargetTypes'], []);
          ctrl.hideTargeting = ctrl.isAxAdvertiser && (!ctrl.forecastModel.axProduct || (optionalTargeting.length === 0));
        };

        ctrl.editTarget = function() {
          $uibModal.open({
            template: modalTemplate,
            controller: 'TargetingCtrl',
            size: 'xl',
            resolve: {
              modelLineItem: function() {
                return null;
              },
              pageType: function() {
                return "reach";
              },
              reachEnabled: function() {
              },
              overridableTargeting: function() {
              },
              targetingMode: function() {
                return null;
              },
              mandatoryTargets: _.noop,
              availableTargets: function() {
                if (!ctrl.isAxAdvertiser) {
                  return;
                }
                const optionalTargeting = _.get(ctrl.forecastModel, ['axProduct', 'optionalTargetTypes'], []);
                const optionalTargetIds = _.map(_.filter(ADN_TARGETING_TYPES, function(tt) {
                  return _.indexOf(optionalTargeting, tt.apiType) > -1;
                }), 'id');
                return _.pick(ADN_TARGETING_TYPES, optionalTargetIds);
              },
              targeting: function() {
                return ctrl.forecastModel.targeting;
              },
              isLockedDown: function() {
                return false;
              }
            }
          })
            .result.then(function(wc) {
            _.assign(ctrl.forecastModel.targeting, wc.wc);
          });
        };

        if (ctrl.modelComms) {
          ctrl.modelComms.addSubscriber(function(data, type) {
            if (!type) {
              let prevOrderId = _.get(ctrl.lineItem, 'order.id');
              ctrl.lineItem = angular.copy(data);
              if (prevOrderId !== _.get(data, 'order.id')) {
                updateOrder();
              }
            }
          });
        }

        ctrl.forecastModel = {};
        ctrl.forecastModel.targeting = {};
        ctrl.forecastModel.adType = 'STANDARD';
        ctrl.forecastModel.isStandard = true;
        ctrl.forecastModel.isVideo = false;
        ctrl.forecastModel.isNative = false;

        if (ctrl.objectType === 'AD_UNIT') {
          ctrl.forecastModel.targeting = {
            adUnitTarget: {
              adUnits: [{id: ctrl.model.id}]
            }
          };
        } else if (ctrl.objectType === 'SITE') {
          ctrl.forecastModel.targeting = {
            siteTarget: {
              sites: [{id: ctrl.model.id}]
            }
          };
        }

        ctrl.forecastModel.dimensions = _.isArray(ctrl.forecastModel.dimensions) ? ctrl.forecastModel.dimensions : [[]];
        ctrl.forecastModel.dimensions[0] = _.isArray(ctrl.forecastModel.dimensions[0]) ? ctrl.forecastModel.dimensions[0] : [];

        const networkDefaults = LocalNetworkProfile.getDefaults();
        const startDate = moment().startOf('day').toDate(),
          endDate = moment().add(networkDefaults.lineItemDaysApart, 'day').endOf('day').toDate();
        ctrl.forecastModel.startDate = adnTimeShifter.timeShift(startDate, true);
        ctrl.forecastModel.endDate = adnTimeShifter.timeShift(endDate, true);
        if (ctrl.forecastModel.startDate) {
          ctrl.forecastModel.modStartDate = adnTimeShifter.timeShift(ctrl.forecastModel.startDate);
        }
        if (ctrl.forecastModel.endDate) {
          ctrl.forecastModel.modEndDate = adnTimeShifter.timeShift(ctrl.forecastModel.endDate);
        }

        ctrl.addDimensions = function() {
          ctrl.forecastModel.dimensions = ctrl.forecastModel.dimensions || [];
          ctrl.forecastModel.dimensions.push([]);
        };
        ctrl.eventHook = {};

        ctrl.removeDimensions = function(index) {
          _.pullAt(ctrl.forecastModel.dimensions, index);
          ctrl.eventHook.removeControlHook('dims' + index + '.width');
          ctrl.eventHook.removeControlHook('dims' + index + '.height');
        };

        ctrl.copyLineItem = function() {
          ctrl.isCopyPending = true;
          let newLineItem = LineItem.create({
            id: Uuid.generate(),
            name: 'Line Item from Reach Analysis',
            startDate: ctrl.forecastModel.startDate,
            endDate: ctrl.forecastModel.endDate,
            targeting: ctrl.forecastModel.targeting
          });

          newLineItem.$save().then(function(savedLineItem) {
            const uniqueDims = _.uniqWith(ctrl.forecastModel.dimensions, _.isEqual);
            _.forEach(uniqueDims, function(dims, index) {
              let newCreative = Creative.create({
                id: Uuid.generate(),
                name: "Creative " + (index + 1) + " for " + savedLineItem.name,
                lineItem: {id: savedLineItem.id},
                width: dims[0],
                height: dims[1]
              });
              newCreative.$save().then(function() {
                if (index + 1 >= uniqueDims.length) {
                  ctrl.isCopyPending = false;
                  $state.go('app.line-items.line-item', {id: savedLineItem.id});
                }
              });
            });
          });
        };

        ctrl.copyFromSearchedLineItem = function() {
          if (!ctrl.lineItem) {
            return;
          }
          ctrl.copyPending = true;
          ctrl.initialised = true;
          dataViewResource.get('LineItemView', {id: ctrl.lineItem.id}).then(function(results) {
            ctrl.lineItem = results.lineItem;
            ctrl.lineItem.creatives = results.creatives;
            ctrl.lineItem.team = _.get(results.order, 'team');

            if (ctrl.lineItem.startDate) {
              ctrl.lineItem.modStartDate = adnTimeShifter.timeShift(ctrl.lineItem.startDate);
            }
            if (ctrl.lineItem.endDate) {
              ctrl.lineItem.modEndDate = adnTimeShifter.timeShift(ctrl.lineItem.endDate);
            }

            ctrl.copyPending = false;
            ctrl.copyFromLineItem();
          });
        };

        if (ctrl.copyHook) {
          ctrl.copyHook.copyFuncs = ctrl.copyHook.copyFuncs || [];
          ctrl.copyHook.copyFuncs.push(function(type, forecastModel) {
            if (type === ctrl.type) {
              return;
            }
            ctrl.forecastModel = angular.copy(forecastModel);
          });
        }

        ctrl.copyDetails = function() {
          ctrl.copyHook.initCompare();
          _.forEach(_.get(ctrl.copyHook, 'copyFuncs'), function(f) {
            f(ctrl.type, ctrl.forecastModel);
          });
        };

        ctrl.copyFromLineItem = function() {
          ctrl.forecastModel = {};
          ctrl.forecastModel.objectives = angular.copy(ctrl.lineItem.objectives);
          ctrl.forecastModel.startDate = angular.copy(ctrl.lineItem.startDate);
          ctrl.forecastModel.modStartDate = angular.copy(ctrl.lineItem.modStartDate);
          ctrl.forecastModel.endDate = angular.copy(ctrl.lineItem.endDate);
          ctrl.forecastModel.modEndDate = angular.copy(ctrl.lineItem.modEndDate);
          ctrl.forecastModel.targeting = angular.copy(ctrl.lineItem.targeting);
          ctrl.forecastModel.team = defaultTeam || ctrl.lineItem.team;
          ctrl.forecastModel.cpm = angular.copy(ctrl.lineItem.bidSpecification.cpm);
          ctrl.forecastModel.tier = angular.copy(ctrl.lineItem.tier);

          ctrl.forecastModel.dimensions = [];
          _.forEach(ctrl.lineItem.creatives, function(creative) {
            if (creative.width && creative.height) {
              ctrl.forecastModel.dimensions.push([creative.width, creative.height]);
            }
          });
          ctrl.forecastModel.dimensions[0] = _.isArray(ctrl.forecastModel.dimensions[0]) ? ctrl.forecastModel.dimensions[0] : [];

          ctrl.forecastModel.dimensions = _.uniqWith(ctrl.forecastModel.dimensions, _.isEqual);

          let creative = _.head(ctrl.lineItem.creatives);
          if (creative) {
            ctrl.forecastModel.width = creative.width;
            ctrl.forecastModel.height = creative.height;
          }
        };

        let prevForecast, prevLineItemId;
        ctrl.triggerForecast = function(chartRerun) {
          if (_.isFunction(_.get(ctrl.callbackHook, ['onReachButtonClicked']))) {
            ctrl.callbackHook.onReachButtonClicked();
          }

          const pendingParam = chartRerun ? "accuracyIsPending" : "isPending";

          ctrl.forecastModel.isStandard = ctrl.forecastModel.adType === 'STANDARD';
          ctrl.forecastModel.isVideo = ctrl.forecastModel.adType === 'VAST';
          ctrl.forecastModel.isNative = ctrl.forecastModel.adType === 'NATIVE';

          let fType = ctrl.forecastType;
          ctrl[fType] = ctrl[fType] || {};
          ctrl[fType][pendingParam] = true;
          ctrl[fType].hasBegun = true;
          ctrl[fType].hasResults = false;
          ctrl[fType].error = false;
          ctrl.forecastedResults = ctrl.forecastMethod;

          let lineItemId = _.get(ctrl.lineItem, 'id'),
            forecast = {
              accuracy: ctrl.forecastModel.accuracy
            };
          if (ctrl.forecastType === 'CUSTOM') {
            lineItemId = null;
            ctrl.forecastModel = ctrl.forecastModel || {};
            forecast = angular.copy(ctrl.forecastModel);
            forecast.team = _.get(forecast.team, 'id');
            forecast.product = _.get(forecast.product, 'id');
            forecast.axProduct = _.get(forecast.axProduct, 'id');
            forecast.startDate = _.isDate(ctrl.forecastModel.modStartDate) ? adnTimeShifter.timeShift(ctrl.forecastModel.modStartDate, true) : null;
            forecast.endDate = _.isDate(ctrl.forecastModel.modEndDate) ? adnTimeShifter.timeShift(ctrl.forecastModel.modEndDate, true) : null;
            if (ctrl.excludeLineItem) {
              forecast.excludeId = _.get(ctrl.lineItem, 'id');
            }
            if (ctrl.isAxAdvertiser || ctrl.isSelfService) {
              forecast.creativeTargeting = forecast.targeting;
              forecast.targeting = null;
            }

            forecast.dimensions = forecast.dimensions || [];
            forecast.dimensions = _.filter(forecast.dimensions, function(dims) {
              return _.isArray(dims) && dims[0] && dims[1];
            });
            forecast.dimensions = forecast.dimensions || [];

            forecast.cpm = forecast.cpm || null;

            forecast.tier = forecast.tier || {};
            forecast.tier = _.get(forecast.tier, 'id');

            _.forEach(forecast.objectives, function(value, key) {
              if (!_.isFinite(value)) {
                delete forecast.objectives[key];
              }
            });
            if (_.isEmpty(forecast.objectives)) {
              delete forecast.objectives;
            }
            if (ctrl.targeting) {
              forecast.targeting = ctrl.targeting;
            }
          }

          delete forecast.width;
          delete forecast.height;

          forecast.accuracy = forecast.accuracy || 0.6;

          if (ctrl.objectType === 'TARGETING') {
            forecast.excludeCompetitors = true;
            forecast.team = _.get(ctrl.lineItem, 'order.team.id', null);
            forecast.axProduct = _.get(ctrl.lineItem, ['axProduct', 'id']);

            const validCreatives = _.filter(_.get(ctrl.lineItem, 'creativesForReach', []), function(creative) {
              return creative.dimensionType !== 'FIXED' || (_.isFinite(creative.width) && _.isFinite(creative.height));
            });
            const hasFlexible = _.find(validCreatives, function(creative) {
              return creative.dimensionType !== 'FIXED';
            });
            forecast.dimensions = hasFlexible ? [] : _.uniqWith(_.map(validCreatives, function(creative) {
              return [creative.width, creative.height];
            }), _.isEqual);

            if (ctrl.targetingType === 'CREATIVE') {
              forecast.targeting = _.get(ctrl.lineItem, ['targeting']);
              forecast.creativeTargeting = ctrl.targeting;
            }
          }

          ctrl.creativeDims = _.cloneDeep(forecast.dimensions);

          reachEstimateResource.save(lineItemId, forecast, ctrl.objectType === 'SITE' || ctrl.objectType === 'AD_UNIT' ? {availability: true} : {}, function(results) {
            ctrl[fType][pendingParam] = false;
            ctrl[fType].hasResults = true;


            ctrl[fType].forecast = results || {};
            ctrl[fType].forecast.byTier = {};

            // Average the data from multiple runs. A single run doesn't always provide nice results on a day-by-day level. So we average the data
            // across multiple runs to give a nicer appearance for the calendar views.
            const paramsChanged = !_.isEqual(prevForecast, forecast) ||  !_.isEqual(prevLineItemId, lineItemId);
            prevForecast = _.cloneDeep(forecast);
            prevLineItemId = _.cloneDeep(lineItemId);

            sponsorshipAllocationStore[fType] = sponsorshipAllocationStore[fType] || {};
            auctionAllocationStore[fType] = auctionAllocationStore[fType] || {};
            if (paramsChanged) {
              sponsorshipAllocationStore[fType] = {};
              auctionAllocationStore[fType] = {};
            }

            if (results.allocationDates) {
              const data = calculateAllocation(auctionAllocationStore[fType], results.allocationDates, results.auctionAllocation);
              const auctionCalendar = calculateHighchartsAllocation(data);

              const sponsorshipData = calculateAllocation(sponsorshipAllocationStore[fType], results.allocationDates, results.sponsorshipAllocation);
              const sponsorshipCalendar = calculateHighchartsAllocation(sponsorshipData);

              ctrl[fType].auctionDates = _.keys(auctionAllocationStore[fType]);
              ctrl[fType].sponsorshipDates = _.keys(sponsorshipAllocationStore[fType]);

              ctrl[fType].auctionCalendar = auctionCalendar;
              ctrl[fType].sponsorshipCalendar = sponsorshipCalendar;

              ctrl[fType].auctionRedraw = ctrl[fType].auctionRedraw || {};
              ctrl[fType].sponsorshipRedraw = ctrl[fType].sponsorshipRedraw || {};

              if (_.isFunction(ctrl[fType].auctionRedraw.execute)) {
                $timeout(function() {
                  ctrl[fType].auctionRedraw.execute();
                  ctrl[fType].sponsorshipRedraw.execute();
                }, 100);
              }
            }

            ctrl[fType].creativeScaling = results.matchedCreativePercentage >= 0 ? results.matchedCreativePercentage / 100.0 : 1;
            if (ctrl.targetingType === 'CREATIVE' && ctrl[fType].creativeScaling === 1) {
              ctrl[fType].forecast.targetingPercent = 100;
              ctrl[fType].forecast.targetingScore = 10;
            }

            ctrl[fType].forecast.compArray = _.map(results.competitors, function(c, key) {
              ctrl[fType].forecast.byTier[c.tierPriority] = ctrl[fType].forecast.byTier[c.tierPriority] || {tier: c.tier, imps: 0, rends: 0, tierName: c.tierName, tierPriority: c.tierPriority, liCount: 0, liImpCount: 0, liMinMaxCount: 0, liOverlapCount: 0, maxLiReach: 0};
              let tierInfo = ctrl[fType].forecast.byTier[c.tierPriority];
              tierInfo.imps += c.impressionAllocationEstimate || 0;
              tierInfo.rends += c.renderedAllocationEstimate || 0;

              if (c.impressionAllocationEstimate > 0) {
                tierInfo.liImpCount++;
              }

              tierInfo.tierMin = c.tierMinimum;
              tierInfo.tierMax = c.tierMaximum;

              tierInfo.maxLiReach = !tierInfo.maxLiReach || c.overlapReach > tierInfo.maxLiReach ? c.overlapReach : tierInfo.maxLiReach;
              tierInfo.liOverlapCount += c.overlapReach;
              if (tierInfo.liOverlapCount > ctrl[fType].forecast.reach) {
                tierInfo.liOverlapCount = ctrl[fType].forecast.reach;
              }

              if (c.overlapPercent > 0) {
                tierInfo.noMinMax = tierInfo.noMinMax || (!c.impressionObjective && !c.clickObjective && !c.renderedObjective && !c.visibleObjective && !c.viewableObjective && !c.conversionObjective);

                if (tierInfo.noMinMax) {
                  tierInfo.liMinMaxCount++;
                }

                tierInfo.liCount++;

                let theCpm = _.get(c.bidSpecification, 'cpm') || {amount: 0};
                tierInfo.minCpm = !tierInfo.minCpm || theCpm.amount < tierInfo.minCpm.amount ? theCpm : tierInfo.minCpm;
                tierInfo.maxCpm = !tierInfo.maxCpm || theCpm.amount > tierInfo.maxCpm.amount ? theCpm : tierInfo.maxCpm;

                let theCpc = _.get(c.bidSpecification, 'cpc') || {amount: 0};
                tierInfo.minCpc = !tierInfo.minCpc || theCpc.amount < tierInfo.minCpc.amount ? theCpc : tierInfo.minCpc;
                tierInfo.maxCpc = !tierInfo.maxCpc || theCpc.amount > tierInfo.maxCpc.amount ? theCpc : tierInfo.maxCpc;

                let theCpa = _.get(c.bidSpecification, 'cpa') || {amount: 0};
                tierInfo.minCpa = !tierInfo.minCpa || theCpa.amount < tierInfo.minCpa.amount ? theCpc : tierInfo.minCpa;
                tierInfo.maxCpa = !tierInfo.maxCpa || theCpa.amount > tierInfo.maxCpa.amount ? theCpc : tierInfo.maxCpa;
              }

              return _.merge({}, c, {
                lineItemId: key
              });
            });
            ctrl[fType].forecast.totalAllocated = _.reduce(ctrl[fType].forecast.byTier, function(sum, obj) {
              return sum + obj.imps;
            }, 0);
            ctrl[fType].forecast.totalRenderedAllocated = _.reduce(ctrl[fType].forecast.byTier, function(sum, obj) {
              return sum + obj.rends;
            }, 0);

            ctrl[fType].forecast.topCompsArray = _.take(_.orderBy(ctrl[fType].forecast.compArray, ['sameTargeting', 'matchingSimilarity'], ['desc', 'desc']), 20);
            // Remove empty fees from line-item bids
            ctrl[fType].forecast.topCompsArray.forEach(function(li) {
              for (let key in li.bidSpecification) {
                let val = li.bidSpecification[key];
                if (Array.isArray(val) && val.length === 0) {
                  delete li.bidSpecification[key];
                }
              }
            });

          }, function(err) {
            ctrl[fType][pendingParam] = false;
            ctrl[fType].error = true;
            if (!err || !err.data) {
              ctrl[fType].errorMessage = "An unspecified error took place.";
              $log.warn("Unspecified reach estimate error: ", err);
              return;
            }
            ctrl[fType].errorMessage = err.data.error || $translate.instant(err.data.text, err.data.parameters);
          });
        };
      };
    }
  });

export default MODULE_NAME;