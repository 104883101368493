/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import template from './order-list.html';

import standardFormModule from '../../common/standard-form-module';
import lineItemListSummaryDir from '../../line-item/line-item-list-summary-directive';

const MODULE_NAME = "order-list-directive";

angular.module(MODULE_NAME, [standardFormModule, lineItemListSummaryDir])

  .directive('adnOrderList', function(Order, adnListHelper) {
    return {
      restrict: 'A',
      scope: {},
      template: template,
      bindToController: {
        widgetType: '<adnOrderList'
      },
      controllerAs: 'widget',
      controller: function() {
        const ctrl = this;
        ctrl.$onInit = function() {
          adnListHelper.setUpBasicList(ctrl, Order, 'orderWidgetList', {
            listMethod: 'getListView',
            pageSize: Math.min((_.isFinite(ctrl.widgetType.rows) && ctrl.widgetType.rows > 0 ? ctrl.widgetType.rows : 10), 100),
            afterFill: function() {
              _.forEach(ctrl.items, function(item) {
                item.lineItems = _.map(item.lineItems);
              });
            }
          });
        };
      }
    };
  });

export default MODULE_NAME;