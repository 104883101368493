/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {OBJECT_TYPE} from "../constants/object-type";

import resources from '../../../components/api/resources/resources';

import template from './column-selector-component.html';

const MODULE_NAME = "column-selector";

angular.module(MODULE_NAME, [
  resources
])

  .component('adnColumnSelector', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      primaryType: '@',
      secondaryType: '@',
      secondaryTypeDirect: '@',
      hideStats: '=',
      showCustom: '=',
      sel: '<',
      changeHook: '<',
      chartOptionsProfileKey: '@'
    },
    controller: function($timeout, $log, ChartOptionsUtil, UserProfile, localUserProfile, LocalNetworkProfile) {
      const ctrl = this;

      let localUser = localUserProfile.get();

      const customField = {id: 'custom', name: 'Custom Fields', text: 'Custom Fields', key: 'custom'};
      ctrl.$onInit = function() {
        ctrl.sel = ctrl.sel || {};
        ctrl.showTotals = false;
        let chartOptionObject = OBJECT_TYPE[ctrl.primaryType];
        let groupByObject = OBJECT_TYPE[ctrl.secondaryType];

        const fieldKeyPrefix = _.get(groupByObject, ['apiParam'], ctrl.secondaryTypeDirect);
        let chartOptionsFieldsKey = fieldKeyPrefix ?  fieldKeyPrefix + 'Fields' : 'totalsFields';

        const chartOptions = ChartOptionsUtil.getOptions(chartOptionObject.caps);
        const allFields = _.filter(chartOptions[chartOptionsFieldsKey], function(f) {
          return !f.availability || (LocalNetworkProfile.isPureMarketplacePlatform() && f.availability.MARKETPLACE) || (!LocalNetworkProfile.isPureMarketplacePlatform() && f.availability.DIRECT);
        });
        ctrl.sel.fullFields = _.filter(allFields, function(field) {
          return !field.unselected;
        });
        ctrl.dataParam = (groupByObject || chartOptionObject).apiParam;
        ctrl.stateName = (groupByObject || chartOptionObject).stateName;

        const fieldsUpdated = function(selFields, fullText, allFields) {
          ctrl.sel.fullFields = _.filter(chartOptions[chartOptionsFieldsKey], function(f) {
            return selFields.indexOf(f.key) > -1;
          });
          if (selFields.indexOf("custom") > -1) {
            ctrl.sel.fullFields.push(customField);
          }
          if (!_.find(ctrl.fields, _.get(ctrl.sorting, 'orderByParam')) && ctrl.sel.fullFields.length > 0) {
            ctrl.sorting = ctrl.sorting || {};
            ctrl.sorting.orderByParam = ctrl.sel.fullFields[0].key;
            ctrl.sorting.asc = true;
          }
          ctrl.fullText = fullText || false;
          ctrl.allFields = allFields || false;

          if (_.isFunction(_.get(ctrl, ['changeHook', 'fieldChange']))) {
            ctrl.changeHook.fieldChange(ctrl.showTotals);
          }
        };

        let profile = localUserProfile.get().profile;
        const path = ctrl.chartOptionsProfileKey || groupByObject.apiParam + 'RowsOptions';
        const profileParam = _.get(chartOptions, path);
        if (!profileParam) {
          $log.warn("Missing a good profile param. Found this value: " + profileParam + " for this apiParam: " + path);
          $log.warn(chartOptions);
          $log.warn(ctrl.chartOptionsProfileKey, groupByObject.apiParam);
        }
        const profileSelFieldIds = _.get(profile[profileParam], 'selFieldIds') || [];
        const profileFullText = _.get(profile[profileParam], 'fullText') || false;
        const profileAllFields = _.get(profile[profileParam], 'allFields') || false;
        if (profileSelFieldIds.length > 0) {
          fieldsUpdated(profileSelFieldIds, profileFullText, profileAllFields);
        }
        ctrl.availableFields = angular.copy(allFields);
        if (ctrl.showCustom) {
          ctrl.availableFields.push(customField);
        }
        ctrl.sel.fullText = profileFullText;
        ctrl.sel.allFields = profileAllFields;
        ctrl.sel.availableFields = _.map(_.filter(ctrl.availableFields, function(value) {
          return profileSelFieldIds.indexOf(value.key) > -1;
        }), 'key');
        if (ctrl.sel.availableFields.length < 1) {
          ctrl.sel.availableFields = _.map(_.filter(ctrl.availableFields, function(f) {
            return !f.unselected;
          }), function(f) {
            return f.key;
          });
        }

        ctrl.updateFields = function() {
          $timeout(function() {
            if (_.isString(profileParam)) {
              profile[profileParam] = {
                selFieldIds: ctrl.sel.availableFields,
                fullText: ctrl.sel.fullText,
                allFields: ctrl.sel.allFields
              };
              UserProfile.save({
                id: localUser.userId,
                profile: _.pick(profile, profileParam)
              }, function(apiUser) {
                localUser = localUserProfile.safeSaveProfile(profileParam, apiUser.profile[profileParam]);
                profile = localUser.profile;
              });
            } else {
              $log.warn("Can't save settings because of a faulty profileParam of " + profileParam);
            }
            fieldsUpdated(ctrl.sel.availableFields, ctrl.sel.fullText, ctrl.sel.allFields);
          }, 101);
        };
      };
    }
  });

export default MODULE_NAME;