/**
 * Copyright © 2025 Adnuntius AS.
 */
import * as _ from 'lodash';

import {ApiConfig} from "../../api";
import {Inject} from "@angular/core";

export class DevicesResource {
  private readonly uri = ApiConfig.obtain().getVersionedUri('devices');

  constructor(@Inject('$http') private $http: any) {
  }

  get() {
    return this.$http.get(this.uri, {
      params: {context: null}
    }).then(function(response) {
      const props = {};
      _.forEach(response.data.results, function(group) {
        props[group.group] = _.map(group.properties, 'value').sort();
      });
      return props;
    });
  }
}