/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import authProvider from '../api/auth-provider';

const MODULE_NAME = "http-interceptor";

angular.module(MODULE_NAME, [authProvider])
  .factory('authHttpInterceptor', function(AuthService, $injector) {
    let LocalNetworkProfile;

    return {
      request: function(config) {

        const configUrl = config.url || "";
        if (!config.cache && (configUrl.indexOf('api') > -1 || configUrl.indexOf("preview") > -1) || configUrl.indexOf("synchronous") > -1) {
          let params = {};
          if (AuthService.isAuthenticated()) {
            const token = AuthService.getSessionData().accessToken;
            if (_.startsWith(config.url, 'https:')) {
              params.auth_token = token;
            } else {
              config.headers = _.assign({}, config.headers, {Authorization: 'Bearer ' + token});
            }
          }

          if (!_.has(config, ['params', 'context'])) {
            LocalNetworkProfile = LocalNetworkProfile || $injector.get("LocalNetworkProfile");
            const networkId = LocalNetworkProfile.get('networkId');
            const tagId = LocalNetworkProfile.get('tagId');
            if (configUrl.indexOf("preview") > -1) {
              params.context = networkId;
            } else if (configUrl.indexOf("releasenotes") < 0) {
              params.context = tagId || networkId;
            }
          }
          config.params = _.assign({}, config.params, params);
        }
        return config;
      }
    };
  });

export default MODULE_NAME;