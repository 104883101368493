/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';

import template from './target-summary.html';
import {ADN_TARGETING_TYPES} from "./targeting-constants";
import {TargetingHelper} from "./targeting-helper";

const MODULE_NAME = 'targeting-summary';

angular.module(MODULE_NAME, [translate, uiBootstrap])

  .directive('adnTargetSummary', function($timeout) {
    return {
      restrict: 'A',
      template: template,
      transclude: true,
      scope: {
        targeting: '<adnTargetSummary',
        model: '<',
        copyHook: '<?',
        type: '@?'
      },
      link: function(scope, element, attrs) {
        scope.headingClass = attrs.headingClass || 'list-group-item-heading';

        const targTypes = ADN_TARGETING_TYPES;

        function targetingChanged() {
          function calcMandatories() {
            scope.mandatoryElements = TargetingHelper.getTargetsWithTargetsDirectly(_.get(scope.model, ['axProduct', 'targeting']));
          }

          calcMandatories();
          // doing this to get the updated product details where appropriate
          $timeout(calcMandatories, 2000);

          scope.targets = _.filter(targTypes, function(targetConfig) {
            const size = TargetingHelper.getSizeOfTargetingTypes(targetConfig.targets2 ? scope.targeting : _.get(scope.targeting, [targetConfig.targets]), targetConfig.targets, scope.type);
            targetConfig.currentSize = size;
            return size > 0;
          });
        }

        scope.$watchCollection(_.get(scope.product, ['axProduct', 'targeting']), targetingChanged);
        scope.$watchCollection('targeting', targetingChanged);

        scope.copyTarget = function(target) {
          scope.copyHook.copy(target);
        };

        if (scope.copyHook) {
          scope.copyHook.results = function(target, results) {
            target.results = results;
          };
          scope.copyHook.clear = function() {
            _.forEach(scope.targets, function(t) {
              t.results = false;
            });
          };
        }
      }
    };
  });

export default MODULE_NAME;