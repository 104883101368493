/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

import {IpAddress} from "../../../components/util/ip-valid";

import template from './ip-address-targeting.html';
import {ADN_TARGETING_TYPES} from "../targeting-constants";

const MODULE_NAME = 'ip-address-targeting-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnIpAddressTargeting', function() {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnIpAddressTargeting',
        underModel: '=',
        isLockedDown: '<',
        overridableTargeting: '<',
        mandatory: '<',
        mode: '@'
      },
      link: {
        post: function(scope, element, attrs, tabset) {
          scope.loaded = true;
          scope.targetType = ADN_TARGETING_TYPES.ipAddress;
          let init = function() {
            let inputted = [];
            _.forEach(_.get(scope.model, 'addresses'), function(addy) {
              inputted.push(addy);
            });
            scope.inputted = {value: inputted.join(", ")};
          };
          init();

          let prepareForSave = function() {
            let values = scope.inputted.value.split(",");
            let trimmedValues = _.filter(_.map(values, function(v) {
              return _.trim(v);
            }), function(v) {
              return _.isString(v) && v.length > 0 && IpAddress.isValid(v);
            });

            scope.model = scope.model || {addresses: []};
            scope.model.addresses = trimmedValues;
            init();
          };

          scope.hook = {};
          scope.hook.getTemplateData = function() {
            prepareForSave();
            var summary = (scope.model.negated === true ? "NOT: " : "") + scope.model.addresses.join(", ");
            return {
              vm: angular.copy(scope.inputted),
              model: angular.copy(scope.model),
              constant: ADN_TARGETING_TYPES.ipAddress,
              summary: summary
            };
          };

          const copyTemplateFunc = function(template) {
            scope.model = angular.copy(template.data.model);
            init();
          };
          scope.hook.copyTemplate = copyTemplateFunc;

          tabset.register(ADN_TARGETING_TYPES.ipAddress.widget, prepareForSave, copyTemplateFunc);
          tabset.callBeforeSubmit(prepareForSave);
          tabset.registerAllTabs(prepareForSave);
        }
      }
    };
  });

export default MODULE_NAME;