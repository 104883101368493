/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import {ADN_TARGETING_TYPES} from "../targeting-constants";
import {TargetingHelper} from "../targeting-helper";

const MODULE_NAME = 'device-targeting-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnTargetingDeviceSummary', function() {
    return {
      restrict: 'A',
      template: '<ul class="list-unstyled"><li ng-repeat="(prop, values) in summary"><strong translate="targeting.device.{{::prop}}"></strong>: {{values | adnDeviceTargetingSelections: prop}}</li></ul>',
      scope: {
        targets: '=adnTargetingDeviceSummary'
      },
      link: function(scope) {
        scope.$watchCollection('targets', function(targets) {
          scope.summary = TargetingHelper.getSummary(targets, ADN_TARGETING_TYPES.device);
        });
      }
    };
  });

export default MODULE_NAME;