/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {Uuid} from "../../../components/util/uuid";

import userProfileSaver from '../../../components/session/user-profile-saver';
import {SubmitCalls} from "./submit-calls";

const MODULE_NAME = "submit-directive";

angular.module(MODULE_NAME, [
  userProfileSaver
])

  .directive('adnApiResourceSubmit', function($q, $log, $rootScope, $interpolate, UserProfileSaver) {

    return {
      require: 'form',
      restrict: 'A',
      scope: {
        resource: '<',
        endPoint: '@',
        onBeforeSubmit: '&',
        saveMethod: '<?',
        onSubmit: '&adnApiResourceSubmit'
      },
      link: function postLink(scope, element, attr, form) {
        let formControlErrors = [];

        /**
         * Converts validation context paths into form controller object refs and sets validation errors
         * appropriately.
         */
        const handleValidation = function(resource, errors) {
          formControlErrors = [];
          _.forEach(_.isArray(errors) ? errors : [errors], function(error) {
            const fieldName = (resource.resourceName + "." + error.context),
              fieldNameStandarised = "resource.model." + error.context,
              fieldNameDirect = error.context;
            const control = form[fieldName] || form[fieldNameStandarised] || form[fieldNameDirect];

            const errorData = error.error || error;
            errorData.apiText = errorData.text ? $interpolate(errorData.text)(errorData.parameters) : '';

            if (control) {
              control.$setValidity(errorData.code, false);
              control.$error[errorData.code] = errorData;
              formControlErrors.push({
                control: control,
                key: errorData.code,
                apiText: errorData.apiText
              });
            }
            if (!control || errorData.withApi) {
              form.apiErrors = form.apiErrors || [];
              form.apiErrors.push({
                key: errorData.code,
                apiText: errorData.apiText
              });
            }
          });
        };


        const clearValidation = function(form) {
          _.forEach(formControlErrors, function(validationObj) {
            validationObj.control.$setValidity(validationObj.key, true);
          });
          form.apiErrors = [];
          form.clientErrors = [];
          formControlErrors = [];
        };

        const successForm = function(form) {
          form.$setValidity('validation', true);
          form.validSubmission = true;
          form.$setPristine();
        };

        const errorForm = function(form) {
          form.$setValidity('validation', false);
          form.validSubmission = false;
          form.$setPristine();
        };

        const clientErrorsForm = function(form) {
          handleValidation({}, form.clientErrors);
          form.clientErrors = [];
          errorForm(form);
        };

        attr.$set('novalidate', 'novalidate');
        element.on('submit', function(e) {
          // set the form in a pending state
          form.$setValidity('validation');

          e.preventDefault();

          clearValidation(form);

          let resources = _.isArray(scope.resource) ? scope.resource : [scope.resource];
          let promises = [];

          let beforeSubmitFunction = scope.onBeforeSubmit(form);

          // before submit might not be a promise, but if it is, let's make sure to execute it before proceeding.
          $q.when(beforeSubmitFunction).then(function() {
            if (form.clientErrors.length > 0) {
              clientErrorsForm(form);
              return false;
            }
            _.forEach(resources, function(resource) {
              if (!resource.id) {
                resource.id = Uuid.generate();
              }

              // use the "savePartial" method on resources so as not to overwrite the potentially hydrated
              // resources instance with association references that the API returns.
              const qStrings = _.get(resource, ['ignoreProduct']) ? {ignoreProduct: true} : null;

              let obj = resource;
              let method = 'savePartial';
              if (scope.saveMethod) {
                obj = scope;
                method = 'saveMethod';
              }
              promises.push(obj[method](null, scope.endPoint || 'save', qStrings).then(function(apiResource) {
                SubmitCalls.updateFormWarnings(form, apiResource || []);
                return apiResource;
              }, function(response) {
                if ((response.status === 400 || response.status === 403 || response.status === 422) && (response.data.type === 'ValidationResult' || response.data.type === 'ErrorMessage')) {
                  handleValidation(resource, _.get(response, ['data', 'errors']) || response.data);
                }
                return $q.reject(response);
              }));
            });

            let promise = promises.length === 1 ? promises[0] : $q.all(promises);
            scope.onSubmit({promise: promise});

            promise.then(function(returnedData) {
              _.forEach(_.isArray(returnedData) ? returnedData : [returnedData], function(r) {
                UserProfileSaver.saveObj(r, 'adnLastEdited');
                // adding this one to last viewed because it might be new.
                UserProfileSaver.saveObj(r, 'adnLastViewed');
              });
              successForm(form);
            }, function(e) {
              $log.warn(e);
              errorForm(form);
            }).finally(function() {
              $rootScope.$broadcast("SUBMIT_PROCESSED");
            });
          });
        });
      }
    };
  });

export default MODULE_NAME;