/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

const MODULE_NAME = "individual-widget";

angular.module(MODULE_NAME, [])

  .directive('adnWidget', function($compile) {
    return {
      restrict: 'A',
      scope: {
        adnWidget: '@',
        adnWidgetModel: '=',
        runningModel: '<',
        mandatoryTargets: '<',
        adnParams: '<',
        underModel: '=',
        modelLineItem: '<',
        mode: '@',
        overridableTargeting: '<',
        pageType: '<',
        isLockedDown: '<'
      },
      link: function postLink(scope, element, attr) {
        let widgetName = attr.$normalize(scope.adnWidget);
        attr.$set('adnWidget', null);
        attr.$set('adnWidgetModel', null);
        attr.$set('runningModel', 'runningModel');
        attr.$set('mandatoryTargets', 'mandatoryTargets');
        attr.$set('adnParams', null);
        attr.$set(widgetName, 'adnWidgetModel');
        attr.$set('underModel', 'underModel');
        attr.$set('modelLineItem', 'modelLineItem');
        attr.$set('mode', scope.mode);
        attr.$set('overridableTargeting', 'overridableTargeting');
        attr.$set('pageType', 'pageType');
        attr.$set('isLockedDown', scope.isLockedDown);

        _.forEach(scope.adnParams, function(v, k) {
          attr.$set(k, v);
        });

        element.replaceWith($compile(element)(scope));
      }
    };
  });

export default MODULE_NAME;