/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = 'creative-dims-component';

angular.module(MODULE_NAME, [])

  .component('adnCreativeDimsOutput', {
    bindings: {
      creative: '<'
    },
    controllerAs: 'ctrl',
    controller: function() {
    },
    template: `
          <div ng-if="::ctrl.creative.dimensionType === 'FIXED'">
            <span ng-if="::!ctrl.creative.width && !ctrl.creative.height">-</span><span ng-if="::!!ctrl.creative.width || !!ctrl.creative.height">{{::ctrl.creative.width || '--'}}&times;{{::ctrl.creative.height || '--'}}</span>
          </div>
          <div ng-if="::ctrl.creative.dimensionType === 'FIXED_WIDTH'">
            {{ctrl.creative.width || '--'}}px width
          </div>
          <div ng-if="::ctrl.creative.dimensionType === 'FIXED_HEIGHT'">
            {{ctrl.creative.height || '--'}}px height
          </div>
          <div ng-if="::ctrl.creative.dimensionType === 'FLEXIBLE'">
            Flexible
          </div>
    `
  });

export default MODULE_NAME;