/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "layout";

angular.module(MODULE_NAME, [])

  .factory('Layout', function(adnResource) {
    return adnResource('layouts');
  });

export default MODULE_NAME;