/**
 * Copyright © 2025 Adnuntius AS.
 */
import * as _ from 'lodash';

import {ApiConfig} from "../../api";
import {Inject} from "@angular/core";
import {HttpHelper} from "../../../util/http-helper";

export class AuthenticatedUserResource {
  private readonly url = ApiConfig.obtain().getVersionedUri('user');
  private readonly verifyUrl = ApiConfig.obtain().getVersionedUri('user/2fa/verify');

  constructor(@Inject('$http') private $http: any) {
  }

  verify2fa(code) {
    return this.$http.get(this.verifyUrl, {
      params: _.merge({context: null, concise: true, useNetworkTags: true}, {code: code}),
      transformResponse: HttpHelper.obtain(this.$http).jsonObjResponseTransformer('profile', true)
    }).then(_.iteratee('data'));
  }

  get(paramOptions) {
    return this.$http.get(this.url, {
      params: _.merge({context: null, concise: true, useNetworkTags: true}, paramOptions),
      transformResponse: HttpHelper.obtain(this.$http).jsonObjResponseTransformer('profile', true)
    }).then(_.iteratee('data'));
  }
}