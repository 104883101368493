/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

const MODULE_NAME = "folio";

angular.module(MODULE_NAME, [])

  .factory('Folio', function(adnResource, $q) {
    let Folio = adnResource('folios', {}, {
      copy: {
        params: {folioId: '@folioId', name: '@name', copyLineItems: '@copyLineItems', copyTargeting: '@copyTargeting', copyCreatives: '@copyCreatives', creativeSetUserState: '@creativeSetUserState'}
      }
    });

    Folio.copyFolio = function(folio, newId, lineItemUserState, copyLineItems, copyTargeting, copyCreatives) {
      if (!folio || !folio.id) {
        return $q.reject("Insufficient parameters for the folio copy");
      }
      return Folio.copy({
        id: folio.id,
        folioId: newId || '',
        name: folio.name,
        copyTargeting: _.isBoolean(copyTargeting) ? copyTargeting : true,
        copyCreatives: _.isBoolean(copyCreatives) ? copyCreatives : true,
        copyLineItems: _.isBoolean(copyLineItems) ? copyLineItems : true,
        creativeSetUserState: lineItemUserState || ''
      });
    };

    return Folio;
  });

export default MODULE_NAME;