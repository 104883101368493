/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import {OBJECT_TYPE} from "../../../../app/common/constants/object-type";

import httpInterceptor from '../../../auth/http-interceptor';
import apiErrorHandler from '../../../../app/common/directives/error/api-error-handler';
import {ApiConfig} from "../../api";
import {HttpHelper} from "../../../util/http-helper";

const MODULE_NAME = "notification";

angular.module(MODULE_NAME, [
  httpInterceptor,
  apiErrorHandler
])
  .factory('Notification', function($httpBackend, $http, $httpParamSerializer, $rootScope, authHttpInterceptor, ApiErrorHandler) {
    function Notification(props) {
      _.assign(this, props);
      this.time = moment(this.time);
      this.objectType = _.get(OBJECT_TYPE, this.objectType, {
        stateName: '.'
      });
    }

    Notification.prototype.equals = function(other) {
      return this.id === other.id;
    };

    let url = ApiConfig.obtain().getVersionedUri('notifications');

    return {
      query: function(since, success) {
        const minDate = moment.utc().subtract(28, 'd');
        const notifSince = moment(since).isBefore(minDate) ? minDate : since;

        let config = authHttpInterceptor.request({
          url: url,
          params: {
            since: notifSince && notifSince.toJSON ? notifSince.toJSON() : notifSince
          },
          headers: $http.defaults.headers.common
        });
        config.url = url + '?' + $httpParamSerializer(config.params);

        // TODO: Remove all this hackery if / when https://github.com/angular/angular.js/issues/2049 is resolved
        $httpBackend('GET', config.url, null, function(status, response) {
          let data;
          try {
            data = JSON.parse(response);
          } catch(e) {
            data = {};
          }
          if (HttpHelper.obtain($http).isSuccess(status)) {
            success(_.map(data.results, function(notification) {
              return new Notification(notification);
            }));
          } else {
            if (status === 401) {
              $rootScope.$broadcast('event:auth-loginRequired');
            }
            if (!response && status === -1) {
              return;
            }
            ApiErrorHandler.responseError({
              status: status,
              config: config,
              data: data,
              response: response
            });
          }
        }, config.headers);
      }
    };
  });

export default MODULE_NAME;