/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import template from './creative-set-copy-modal.html';

import standardActionsModule from '../common/standard-actions-module';
import {OBJECT_TYPE} from "../common/constants/object-type";
import {Uuid} from "../../components/util/uuid";

const MODULE_NAME = 'creative-set-actions-controller';

angular.module(MODULE_NAME, [standardActionsModule])

  .controller('CreativeSetActionsCtrl', function($q, toastr, adnModalLoading, $log, $stateParams, $state, $uibModal, model, modelComms, readOnly, CreativeSet) {
      const ctrl = this;

      ctrl.objectType = OBJECT_TYPE.CreativeSet.type;
      ctrl.objectId = model.id;
      ctrl.readOnly = readOnly;

      ctrl.model = model;
      ctrl.modelComms = modelComms;
      ctrl.isNew = $stateParams.isNew;

      ctrl.copy = function() {
          let modalInstance = $uibModal.open({
              template: template,
              windowClass: '',
              size: 'lg',
              controllerAs: 'modalCtrl',
              controller: /*@ngInject*/ function() {
                  const modalCtrl = this;

                  modelComms.addSubscriber(function(data, type) {
                      if (!type) {
                          modalCtrl.creativeSet = data;
                          modalCtrl.creativeSet.name = data.name + " (copy)";
                      }
                  });

                  modalCtrl.destination = true;
                  modalCtrl.copy = {
                      targeting: true,
                      creatives: true
                  };

                  modalCtrl.disabled = false;
                  modalCtrl.validationErrors = [];
                  modalCtrl.copyCreativeSet = function() {
                      modalCtrl.disabled = true;
                      adnModalLoading.applyModalLoading();
                      const newId = Uuid.generate();
                      $q.all(CreativeSet.copyCreativeSet(modalCtrl.creativeSet, newId, modalCtrl.copy.creatives)).then(function() {
                          modalCtrl.disabled = false;
                          modalInstance.close({destination: modalCtrl.destination, creativeSetId: newId});
                          adnModalLoading.removeModalLoading();
                      }, function(errors) {
                          modalCtrl.disabled = false;
                          modalCtrl.validationErrors = errors.data.errors;
                          adnModalLoading.removeModalLoading();
                      });
                  };
              }
          });
          modalInstance.result.then(function(result) {
              adnModalLoading.removeModalLoading();
              if (result.destination) {
                  $state.go('app.creative-sets.creative-set', {id: result.creativeSetId}, {inherit: false});
              }
              if (result.errors) {
                  $log.warn(result.errors);
                  toastr.warning("This creative set could not be copied.", "Creative Copy Failed");
              } else if (!result.destination) {
                  toastr.success("A copy of this creative set was successfully created.", "Creative Set Copied");
              }
          }, function() {
              // dismissal handler to avoid unhandled exceptions -- don't blame me, blame uibModal
          });
      };
  });

export default MODULE_NAME;