/**
 * Copyright © 2025 Adnuntius AS.
 */
import * as angular from 'angular';

import template from './line-item-list-summary.html';

import standardFormModule from '../common/standard-form-module';

const MODULE_NAME = 'line-item-list-summary-directive';

angular.module(MODULE_NAME, [standardFormModule])
  .directive('adnLineItemListSummary', function() {
    return {
      restrict: 'A',
      template: template,
      scope: {
        items: '<adnLineItemListSummary',
        actionIcon: '@',
        actionTitle: '@',
        onActionClick: '&'
      },
      bindToController: true,
      controllerAs: 'list',
      controller: function($attrs) {
        const ctrl = this;
        ctrl.$onInit = function() {
          ctrl.hasActions = !!$attrs.onActionClick;
        };
      }
    };
  });

export default MODULE_NAME;