/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';

const MODULE_NAME = 'ip-address-targeting-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnTargetingIpAddressSummary', function() {
    return {
      restrict: 'A',
      template: `
           <span>{{data30}}</span>
           <div ng-if="hasRest > 0"><div><a ng-click="showMore=!showMore"><span class="fa fa-fw fa-chevron-down" ng-class="{'fa-rotate-270': !showMore}"></span><span ng-if="!showMore">Show</span><span ng-if="showMore">Hide</span> {{hasRest | adnStat}} remaining entries</a></div><div ng-if="showMore">{{dataRest}}</div></div>
      `,
      scope: {
        targets: '=adnTargetingIpAddressSummary'
      },
      link: function(scope) {
        scope.data = [];
        const splitNum = 20;
        scope.$watchCollection('targets', function(targets) {
          scope.target = targets || {};
        });
        scope.$watchCollection('target', function(target) {
          scope.data30 = target.addresses.slice(0, splitNum).join(", ");
          scope.dataRest = target.addresses.slice(splitNum).join(", ");
          scope.hasRest = target.addresses.length - splitNum;
        });
      }
    };
  });

export default MODULE_NAME;