/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import twoPanelTemplate from '../common/two-panel.html';
import editTemplate from './external-ad-unit-edit.html';
import analysisTemplate from './external-ad-unit-analysis.html';
import copyTemplate from '../common/copy-actions.html';
import listTemplate from './external-ad-unit-list.html';

import standardModule from '../common/standard-module';
import externalAdUnitController from './external-ad-unit-controller';
import externalAdUnitAnalysisController from './external-ad-unit-analysis';
import externalAdUnitActionsController from './external-ad-unit-actions-controller';

const MODULE_NAME = 'external-ad-unit-module';

angular.module(MODULE_NAME, [standardModule, externalAdUnitController, externalAdUnitAnalysisController, externalAdUnitActionsController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('external-ad-units.external-ad-unit', 'ExternalAdUnit', 'app.inv', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list())
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: null,
            template: twoPanelTemplate
          },
          'context@app': {
            template: copyTemplate,
            controller: 'ExternalAdUnitActionsCtrl',
            controllerAs: 'ctrl'
          },
          'leftPanel@app.external-ad-units.external-ad-unit': {
            template: editTemplate,
            controller: 'ExternalAdUnitCtrl',
            controllerAs: 'ctrl'
          },
          'rightPanel@app.external-ad-units.external-ad-unit': {
            template: analysisTemplate,
            controller: 'ExternalAdUnitAnalysisCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'ExternalAdUnit'));
  });

export default MODULE_NAME;