/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {ApiConfig} from "../../api";

const MODULE_NAME = "segment";

angular.module(MODULE_NAME, [])

  .factory('SegmentWithTargeting', function($http) {
    return {
      postTargeting: function(targeting, params) {
        return $http.post(ApiConfig.obtain().getVersionedUri(['segments', 'withtargeting']), targeting, {
          params: _.assign({}, {format: 'json', concise: true}, params ? params : {})
        }).then(_.iteratee('data'));
      }
    };
  })

  .factory('Segment', function(adnResource, localUserProfile) {
    const Segment = adnResource('segments', {}, {});

    Segment.ogQuery = angular.copy(Segment.query);
    Segment.query = function(a1, a2, a3) {
      const params = {};
      if (localUserProfile.getCurrentApp() !== 'ADVERTISING') {
        params.dataSource = 'ADNUNTIUS';
      }
      return Segment.ogQuery(_.merge({}, a1, params), a2, a3);
    };

    return Segment;
  });

export default MODULE_NAME;