/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import twoPanelTemplate from '../../common/two-panel.html';
import editTemplate from './team-group-edit.html';
import analysisTemplate from './team-group-analysis.html';
import deleteTemplate from '../../common/delete-actions.html';
import listTemplate from './team-group-list.html';

import adminModule from '../admin-module';
import teamController from './team-group-controller';
import teamAnalysis from './team-group-analysis';
import {PERMISSIONS} from "../../../components/auth/auth-constants";

const MODULE_NAME = 'team-group-module';

angular.module(MODULE_NAME, [adminModule, teamController, teamAnalysis])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('team-groups.team-group', 'TeamGroup', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      data: {
        app: ['ADVERTISING', 'DATA']
      },
      resolve: {
        readOnly: /*@ngInject*/ function (LocalUserPermissions) {
          return !LocalUserPermissions.hasNetwork(PERMISSIONS.manageSystem.apiKey)
            && !LocalUserPermissions.hasDataNetworkPermission(PERMISSIONS.dataAdmin.apiKey)
            && !LocalUserPermissions.hasNetwork(PERMISSIONS.axAdvertiserAdmin.apiKey);
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: null,
            template: twoPanelTemplate
          },
          'context@app': {
            controller: /*@ngInject*/ function(model, modelComms, $stateParams) {
              const ctrl = this;
              ctrl.model = model;
              ctrl.modelComms = modelComms;
              ctrl.isNew = $stateParams.isNew;
            },
            controllerAs: 'ctrl',
            template: deleteTemplate
          },
          'leftPanel@app.team-groups.team-group': {
            template: editTemplate,
            controller: 'TeamGroupCtrl',
            controllerAs: 'resource'
          },
          'rightPanel@app.team-groups.team-group': {
            template: analysisTemplate,
            controller: 'TeamGroupAnalysisCtrl',
            controllerAs: 'resource'
          }
        }
      }, 'TeamGroup'));
  });

export default MODULE_NAME;