/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardAnalysisModule from '../common/standard-analysis-module';
import auditHistoryComp from './widget/audit-history-component';
import burnRateDirective from '../common/directives/burn-rate-directive';
import resources from '../../components/api/resources/resources';
import targetingModule from '../targeting/targeting-module';
import * as moment from "moment";

const MODULE_NAME = "line-item-analysis";

const objToStatRef = {
  IMPRESSION: {
    stat: 'impressions',
    isCost: false
  },
  BUDGET: {
    stat: 'totalCost',
    isCost: true
  },
  CLICK: {
    stat: 'clicks',
    isCost: false
  },
  CONVERSION: {
    stat: 'conversions',
    isCost: false
  },
  VISIBLE_IMPRESSION: {
    stat: 'visibles',
    isCost: false
  },
  VIEWABLE_IMPRESSION: {
    stat: 'viewables',
    isCost: false
  },
  RENDERED_IMPRESSION: {
    stat: 'rendered',
    isCost: false
  }
};

angular.module(MODULE_NAME, [standardAnalysisModule, auditHistoryComp, burnRateDirective, targetingModule, resources])
  .controller('LineItemAnalysisCtrl', function ($stateParams, burnRatesResource, statsResource, LocalUserPermissions, LocalNetworkProfile, model, modelComms, lineItemView) {
    const ctrl = this;

    ctrl.canSeeReports = LocalUserPermissions.getAnyVisibility().RUN_REPORTS === true
      || LocalUserPermissions.getAnyVisibility().AX_ADVERTISER === true;
    ctrl.perms = LocalUserPermissions.getAllVisPermissions();
    ctrl.isPureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();
    ctrl.domainChartsEnabled = LocalNetworkProfile.isDomainCharts();
    ctrl.blockPubLink = !(ctrl.perms.MANAGE_PUBLISHING || ctrl.perms.AX_PUBLISHER || ctrl.perms.AX_PUBLISHER_ADMIN || ctrl.perms.MANAGE_SYSTEM);

    ctrl.showingChart = 'LINE_ITEM';

    lineItemView = lineItemView || {creatives: {}};
    ctrl.creativesEmpty = _.isEmpty(lineItemView.creatives);
    ctrl.creatives = lineItemView.creatives;
    ctrl.creativeCount = Object.keys(ctrl.creatives).length;
    ctrl.selCreative = _.find(lineItemView.creatives, function () {
      return true;
    });

    ctrl.lineItem = model;

    ctrl.chartCurrency = _.get(model, ['bidSpecification', 'cpm', 'currency'])
      || _.get(model, ['bidSpecification', 'cpc', 'currency'])
      || _.get(model, ['objectives', 'BUDGET', 'currency']);

    ctrl.isNew = $stateParams.isNew;
    ctrl.showStats = model.permission !== 'AX_PUBLISHER';

    ctrl.getWinRates = function () {
      if (ctrl.delivery) {
        return;
      }

      let now = moment.tz(LocalNetworkProfile.get("timeZone"));
      let dayLength = now - now.clone().subtract(1, 'd');

      if (_.includes(['READY', 'RUNNING'], model.executionState)) {
        const statsStartDate = moment.tz(model.startDate, LocalNetworkProfile.get("timeZone"));
        statsResource.get({
          lineItemId: model.id,
          startDate: statsStartDate,
          groupBy: 'DAILY',
          showFilteredBidReasons: true,
          currency: ctrl.chartCurrency
        }, function (response) {
          let calculateDeliveryRates = function (label, statsChunk) {
            let data = Object.assign({}, statsChunk);
            let bids = statsChunk.bids || 0;
            let frequencyCapped = statsChunk.frequencyCapped || 0;
            let rateLimited = statsChunk.rateLimited || 0;
            let belowFloor = statsChunk.belowFloor || 0;
            let auctioned = bids - frequencyCapped - rateLimited - belowFloor;
            data.label = label;
            data.totalMatches = bids;
            data.auctionEntries = auctioned;
            data.impressions = statsChunk.impressions;
            data.rendered = statsChunk.rendered;
            data.cappedRate = bids <= 0 ? 0.0 : 1.0 * frequencyCapped / bids;
            data.smoothingRate = bids <= 0 ? 0.0 : 1.0 * rateLimited / bids;
            data.belowFloorRate = bids <= 0 ? 0.0 : 1.0 * belowFloor / bids;
            data.winRate = auctioned <= 0 ? 0.0 : 1.0 * statsChunk.impressions / auctioned;
            data.renderRate = statsChunk.impressions <= 0 ? 0.0 : 1.0 * statsChunk.rendered / statsChunk.impressions;
            return data;
          };

          let today = (now).format('YYYY-MM-DD');
          let yesterday = (now.clone().subtract(1, 'd')).format('YYYY-MM-DD');
          ctrl.delivery = [];
          let deliveryYesterday = {};
          for (let i = 1; i < 3; i++) {
            if (response.chunks.length >= i) {
              let statsChunk = response.chunks[response.chunks.length - i];
              let chunkStart = statsChunk.chunkStart;
              if (chunkStart.startsWith(today)) {
                ctrl.delivery.push(calculateDeliveryRates('Today', statsChunk));
              } else if (chunkStart.startsWith(yesterday)) {
                deliveryYesterday = calculateDeliveryRates('Yesterday', statsChunk);
                ctrl.delivery.push(deliveryYesterday);
              }
            }
          }

          let objectiveDiagnostics = [];
          if (_.isDate(model.endDate)) {
            let remainingLength = model.endDate - now;
            let remainingDays = remainingLength / dayLength;
            _.forEach(ctrl.lineItem.objectives, function (num, oKey) {
              const statRef = objToStatRef[oKey];
              if (!statRef) {
                return;
              }
              let diag = {type: oKey};
              diag.alreadyDelivered = _.get(response.totals, statRef.stat);
              // This is special handling to deal with backend code that
              // removes the totalCost field from the totals if there are no fees
              if (!diag.alreadyDelivered && statRef.stat === 'totalCost') {
                diag.alreadyDelivered = _.get(response.totals, 'cost');
              }
              let objective = num;
              if (statRef.isCost) {
                diag.alreadyDelivered = _.get(diag.alreadyDelivered, 'amount') || 0;
                objective = _.get(objective, 'amount') || 0;
              }
              diag.deliveredYesterday = _.get(deliveryYesterday, statRef.stat) || 0;
              if (!diag.deliveredYesterday && statRef.stat === 'totalCost') {
                diag.deliveredYesterday = _.get(deliveryYesterday, 'cost');
              }
              let impressionsYesterday = _.get(deliveryYesterday, 'impressions') || 0;
              let totalMatchesYesterday = _.get(deliveryYesterday, 'totalMatches') || 0;
              if (statRef.isCost) {
                diag.deliveredYesterday = _.get(diag.deliveredYesterday, 'amount') || 0;
              }
              diag.possibleEventsYesterday = impressionsYesterday <= 0 ? 0 : (diag.deliveredYesterday / impressionsYesterday) * totalMatchesYesterday;
              diag.remaining = objective - diag.alreadyDelivered;
              diag.requiredPerDay = remainingDays <= 0 ? diag.remaining : diag.remaining / remainingDays;
              if (statRef.isCost) {
                let currency = _.get(num, 'currency') || 'USD';
                diag.alreadyDelivered = {amount: diag.alreadyDelivered, currency: currency};
                diag.remaining = {amount: diag.remaining, currency: currency};
                diag.requiredPerDay = {amount: diag.requiredPerDay, currency: currency};
                diag.deliveredYesterday = {amount: diag.deliveredYesterday, currency: currency};
                diag.possibleEventsYesterday = {amount: diag.possibleEventsYesterday, currency: currency};
              } else {
                diag.alreadyDelivered = Math.round(diag.alreadyDelivered);
                diag.remaining = Math.round(diag.remaining);
                diag.requiredPerDay = Math.round(diag.requiredPerDay);
                diag.deliveredYesterday = Math.round(diag.deliveredYesterday);
                diag.possibleEventsYesterday = Math.round(diag.possibleEventsYesterday);
              }
              objectiveDiagnostics.push(diag);
            });
          }
          ctrl.objectiveDiagnostics = objectiveDiagnostics;
        });
      } else {
        ctrl.delivery = [];
        ctrl.objectiveDiagnostics = {};
      }
    };

    ctrl.getBurnRates = function () {
      if (ctrl.burnRateData) {
        return;
      }
      let now = moment();
      let weekAgo = now.clone().subtract(7, 'd');
      let startDate = model.startDate || weekAgo;
      if (weekAgo > startDate) {
        startDate = weekAgo;
      }

      burnRatesResource.get({id: model.id, startDate: startDate}, function (response) {
        ctrl.lastBurnRate = response.currentBurnRate;
        ctrl.burnRateData = response.rows;
      });
    };

    modelComms.addSubscriber(function (data, type) {
      if (!type && !!data.name) {
        ctrl.lineItem = data;

        if (ctrl.isNew) {
          ctrl.isNew = false;
        }
      }
    });
  });

export default MODULE_NAME;