/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {IAB_TAXONOMY} from "../../components/util/iab-taxonomy";

import iabSearchSelect from '../common/controller/iab-multi-select-component';
import standardFormModule from '../common/standard-form-module';
import {adnFormHelper} from "../common/controller/form-helper";
import {LocalNetworkInfo} from "../../components/session/local-network-info";
import lineItemCreativeListTemplate from "../line-item/line-item-creative-list.html";
import moment from "moment/moment";
import {getMatchingFee, simplifyFee} from "../line-item/line-item-controller";
import {postFillGenerateFormatFees} from "../creative/creative-commons";

const MODULE_NAME = 'creative-set-controller';

angular.module(MODULE_NAME, [standardFormModule, iabSearchSelect])

  .controller('CreativeSetCtrl', function($stateParams, advertiserFromParam, CreativeSetInfo, folioFromParam, $state, $templateCache, Creative, adnListHelper, adnTimeShifter, LocalUserPermissions, LocalNetworkProfile, model, modelComms) {
    const ctrl = this;

    ctrl.isNew = $stateParams.isNew;
    ctrl.isCreativeSet = true; // required for shared creative HTML
    ctrl.pureMarketplacePlatform = LocalNetworkProfile.isPureMarketplacePlatform();
    ctrl.currencies = LocalNetworkProfile.get('availableCurrencies');

    $templateCache.put("creativeList.html", lineItemCreativeListTemplate);

    if (ctrl.isNew && folioFromParam) {
      model.folio = _.cloneDeep(folioFromParam);
    }
    if (ctrl.isNew && advertiserFromParam) {
      model.advertiser = _.cloneDeep(advertiserFromParam);
    }

    const creativeQuery = function() {
      if (_.isFunction(ctrl.makeQuery)) {
        return;
      }
      // want to minimise layout parameter information, which can be heavy duty
      adnListHelper.setUpBasicList(ctrl, Creative, 'fullCreativesForCreativeSet', {
        moreParams: {creativeSet: model.id},
        concise: true,
        pageSize: 101,
        afterFill: function() {
          ctrl.updateRelData(ctrl.items, 'creatives');

          const data = postFillGenerateFormatFees(ctrl);
          const refCurrency = getUiFees(data.creativeFormatFees, data.validItems.length);
          _.forEach(ctrl.items, function(item) {
            const fee = simplifyFee(LocalNetworkProfile, refCurrency, item.creativeFormatFee);
            if (fee && fee.amount > 0) {
              item.simplifiedCreativeFormatFee = fee;
            }
          });
        }
      });
    };

    const formatFeesCache = [];
    let numCreativeCache = -1;

    function getUiFees(creativeFormatFees, numCreatives) {
      const referenceCurrency = _.cloneDeep(_.get(ctrl, ['model', 'billingCurrency']));
      ctrl.uiFees = ctrl.uiFees || {fees: []};
      ctrl.uiFees.fees.length = 0;

      if (_.get(ctrl.model, ['creativeTagTechFeeCpm', 'type'])) {
        const cpmTechFee = _.cloneDeep(ctrl.model.creativeTagTechFeeCpm);
        cpmTechFee.name = cpmTechFee.name || LocalNetworkProfile.getNetworkTranslations("creativeTagFee");
        cpmTechFee.id = cpmTechFee.id || "creative-tag-tech-fee";
        ctrl.uiFees.fees.push(cpmTechFee);
      }
      if (creativeFormatFees) {
        numCreativeCache = numCreatives;
        formatFeesCache.length = 0;
        _.forEach(creativeFormatFees, function(f) {
          const fee = simplifyFee(LocalNetworkProfile, referenceCurrency, f);
          if (fee) {
            formatFeesCache.push(fee);
          }
        });
      }
      if (formatFeesCache.length) {
        const uniqFees = _.uniqBy(formatFeesCache, function(ff) {
          if (ff.monetary.currency === referenceCurrency) {
            return ff.monetary.amount;
          }
        });
        if (uniqFees.length) {
          const ff = {
            name: LocalNetworkProfile.getNetworkTranslations("creativeFormatFee"),
            id: "creative-format-fee",
            type: 'MONETARY',
            monetary: {
              currency: uniqFees[0].monetary.currency
            }
          };
          if (uniqFees.length === 1 && numCreativeCache === formatFeesCache.length) {
            ff.monetary.amount = uniqFees[0].monetary.amount;
          } else {
            const minFee = numCreativeCache > formatFeesCache.length ? {monetary: {amount: 0}} : _.sortBy(uniqFees, ['monetary.amount'])[0];
            const maxFee = _.sortBy(uniqFees, ['monetary.amount'])[uniqFees.length - 1];
            ff.monetary.amount = minFee.monetary.amount;
            ff.monetary.maxAmount = maxFee.monetary.amount;
          }
          ctrl.uiFees.fees.push(ff);
        }
      }
      function standardiseFees(fees) {
        _.forEach(fees, function(theFee) {
          if (theFee.type === 'MULTI_CURRENCY_MONETARY') {
            const matchingFee = getMatchingFee(theFee.multiCurrencyMonetaryAmounts, referenceCurrency);
            if (matchingFee) {
              theFee.monetary = matchingFee;
            }
          }
        });
      }
      standardiseFees(ctrl.uiFees.fees);
      standardiseFees(creativeFormatFees);
      return referenceCurrency;
    }

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms, afterInit: function() {
        ctrl.model.renderOption = ctrl.model.renderOption || null;

        if (ctrl.isNew) {
          ctrl.model.startDate = moment().startOf('day').toDate();
        }
        if (ctrl.model.startDate) {
          ctrl.model.modStartDate = adnTimeShifter.timeShift(ctrl.model.startDate);
        }
        if (ctrl.model.endDate) {
          ctrl.model.modEndDate = adnTimeShifter.timeShift(ctrl.model.endDate);
        }
      }, afterSave: function(persistedCreativeSet) {
        ctrl.teamsWarning = (_.get(ctrl.model, 'teams') || []).length !== (_.get(persistedCreativeSet, 'teams') || []).length;
        ctrl.model.renderOption = ctrl.model.renderOption || null;
        _.set(ctrl.model, ['creativeTagTechFeeCpm'], _.get(persistedCreativeSet, ['creativeTagTechFeeCpm']));
      }, afterInitAfterSave: function() {
        if (!ctrl.isNew) {
          creativeQuery();
        }
        ctrl.model.userState = ctrl.model.userState || 'APPROVED';
        getUiFees();
      }
    });
    ctrl.isAxPublisherAdmin = LocalUserPermissions.hasNetworkPermission('AX_PUBLISHER_ADMIN');
    ctrl.iabTaxonomy = angular.copy(IAB_TAXONOMY);
    ctrl.canUpdateCreativeSet = LocalUserPermissions.hasNetwork('MANAGE_SYSTEM') || LocalUserPermissions.hasNetwork('AX_PUBLISHER_ADMIN') || LocalUserPermissions.hasAny('MANAGE_PUBLISHING');

    if (!ctrl.isNew) {
      CreativeSetInfo.get({id: model.id}, function(cs) {
        ctrl.model.advertiser = _.get(cs, 'advertiser');
        ctrl.updateModelHappened();
      });
    }

    ctrl.showVideoUpload = LocalNetworkProfile.showVideoUpload();
    ctrl.newVideoUpload = function() {
      $stateParams.advertiser = ctrl.model.advertiser;
      $stateParams.folio = ctrl.model.folio;
      $stateParams.creativeSet = ctrl.model;
      $state.go('app.creatives.videoupload');
    };

    ctrl.editsHook = {
      editsMade: function() {
        ctrl.editsMade();
      }
    };

    ctrl.onBeforeSubmit = function() {
      if (ctrl.isNew) {
        LocalNetworkInfo.obtain().increment('creativeSetCount');
      }

      ctrl.model.startDate = _.isDate(ctrl.model.modStartDate) ? adnTimeShifter.timeShift(ctrl.model.modStartDate, true) : null;
      ctrl.model.endDate = _.isDate(ctrl.model.modEndDate) ? adnTimeShifter.timeShift(ctrl.model.modEndDate, true) : null;
    };
  });

export default MODULE_NAME;