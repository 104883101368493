/**
 * Copyright © 2025 Adnuntius AS.
 */
import {ApiConfig} from "../../api";
import {Inject} from "@angular/core";
import * as _ from 'lodash';

export class DataViewResource {
  private readonly uri: string = ApiConfig.obtain().getVersionedUri('dataview');

  currencies: any;

  constructor(@Inject('$http') private $http: any, @Inject('$q') private $q: any, @Inject('$log') private $log: any) {
  }

  query() {
    // not really useful from a UI perspective
    return this.$http.get(this.uri).then(_.iteratee('data')).catch((response) => {
      this.$log.warn("DataView.query: " + response);
    });
  }

  getView(options, viewModel, parameters) {
    if (parameters && !_.isPlainObject(parameters)) {
      parameters = {id: parameters};
    }
    return this.$http.post(this.uri, [{
        viewModel: viewModel,
        parameters: parameters || {}
      }],
      {params: options}
    ).then((response) => {
      if (!(response.data.results && response.data.results.length === 1)) {
        return this.$q.reject('Expected only one data-view result');
      }
      const result: any = _.head(response.data.results);
      if (result.viewModel !== viewModel) {
        return this.$q.reject('Expected viewModel ' + viewModel + ' but got ' + result.viewModel);
      }
      return result.dataView;
    }).catch((response) => {
      this.$log.warn("DataView.getView", response);
      return this.$q.reject('Error returned from DataView');
    });
  }

  get(viewModel, parameters, includeStats) {
    if (parameters && !_.isPlainObject(parameters)) {
      parameters = {id: parameters};
    }
    return this.$http.post(this.uri, [{
      viewModel: viewModel,
      parameters: parameters || {}
    }],
      {params: {'includeStats': includeStats}}).then((response) => {
      if (_.get(response, ['data', 'results', 'length']) !== 1) {
        return this.$q.reject('Expected only one data-view result');
      }
      const result: any = _.head(response.data.results);
      if (result.viewModel !== viewModel) {
        return this.$q.reject('Expected viewModel ' + viewModel + ' but got ' + result.viewModel);
      }
      return _.get(result, ['dataView', 'results']) || _.get(result, ['dataView']) || {};
    }).catch((response) => {
      this.$log.warn("DataView.get", response);
      return this.$q.reject('Error returned from DataView');
    });
  }
}
