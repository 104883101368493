/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import uiTranslate from 'angular-translate';

import template from './creative-rendered.html';
import adTemplate from './creative-container.html';

import resources from '../../../components/api/resources/resources';
import previewComponent from './preview-component';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import {CreativeViewer} from "../../../components/util/creative-viewer";
import {Uuid} from "../../../components/util/uuid";

const MODULE_NAME = 'creative-rendered-component';

angular.module(MODULE_NAME, [
  uiTranslate,
  resources,
  promiseSpinner,
  previewComponent
])

  .component('adnCreativeRendered', {
    bindings: {
      creative: '<',
      maxWidth: '@',
      maxHeight: '@'
    },
    template: template,
    controllerAs: 'ctrl',
    controller: function($element, $document) {
      const ctrl = this;
      ctrl.resolved = false;

      ctrl.$onInit = function() {
        let apiPreviewData = {
          creativeWidth: ctrl.creative.width,
          creativeHeight: ctrl.creative.height,
          creativeContent: adTemplate.replace("{{responseId}}", "resp-" + Uuid.generate())
            .replace("{{{creativeId}}}", ctrl.creative.id)
            .replace("{{{rt}}}", "rt-" + Uuid.generate())
            .replace("{{{content}}}", ctrl.creative.renderedHtml),
          center: true
        };

        let divContainer = $element[0].querySelector('.previewContainer');
        new CreativeViewer($document).viewCreative(divContainer, apiPreviewData);
      };
    }
  });

export default MODULE_NAME;