/**
 * Copyright © 2025 Adnuntius AS.
 */
import {TranslateService} from '@ngx-translate/core';
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'line-item-smoothing-label',
  template: `<span class="label {{labelClass}}">{{ info }}</span>`
})
export class LineItemSmoothingLabelComponent implements OnInit {
  info: string;
  labelClass: string;
  @Input() burnRate: number;

  constructor(public translate: TranslateService) {
  }

  ngOnInit(): void {
    const burnRatePerCent = this.burnRate * 100;

    if (burnRatePerCent > 90) {
      this.info = "Light Smoothing Applied";
      this.labelClass = "label-default";
    } else if (burnRatePerCent > 50) {
      this.info = "Smoothing Applied";
      this.labelClass = "label-default";
    } else if (burnRatePerCent > 25) {
      this.info = "Heavy Smoothing Applied";
      this.labelClass = "label-warning";
    } else {
      this.info = "Very Heavy Smoothing Applied";
      this.labelClass = "label-warning";
    }
  }
}
