/**
 * Copyright © 2025 Adnuntius AS.
 */
import {Component, Inject, Input} from '@angular/core';
import {interpolateWarning} from "./interpolate-warnings-filter";

@Component({
  selector: 'simple-validation-messages',
  template: `
    <div>
      <!-- Must be on one line with no white space to ensure :empty CSS style works -->
      <div class="alert alert-warning"><p *ngFor="let warning of warnings">{{ interpolateWarning(warning) }}</p></div>
    </div>
  `
})
export class SimpleValidationMessagesComponent {
  @Input() warnings: any;

  constructor(@Inject('$interpolate') private $interpolate) {
  }

  interpolateWarning(warning) {
    return interpolateWarning(this.$interpolate, warning);
  }
}
