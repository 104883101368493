/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import localNetworkProfile from '../../../session/local-network-profile';
import {ApiConfig} from "../../api";

const MODULE_NAME = "user-segment-list";

angular.module(MODULE_NAME, [localNetworkProfile])

  .factory('UserSegmentList', function($q, $http, LocalNetworkProfile) {
    return {
      get: function() {
        return $http.get(ApiConfig.obtain().getVersionedUri('segments'), {params: {pageSize: 200, includeInactive: false}}).then(function(page) {
          return page.data;
        });
      },
      query: function(segmentIds) {
        segmentIds = angular.isArray(segmentIds) ? segmentIds : [segmentIds];
        if (segmentIds.length === 0) {
          return $q.resolve([]);
        }
        let networkId = LocalNetworkProfile.getNetworkId();
        return $http.get(ApiConfig.obtain().getVersionedUri('segments'), {
          params: {
            context: networkId,
            id: segmentIds.join(';'),
            pageSize: segmentIds.length
          }
        }).then(function(page) {
          return page.data;
        });
      }
    };
  });

export default MODULE_NAME;