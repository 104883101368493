/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {Uuid} from "../../components/util/uuid";
import template from './external-ad-unit-copy-modal.html';

import standardActionsModule from '../common/standard-actions-module';

const MODULE_NAME = 'external-ad-unit-actions-controller';

angular.module(MODULE_NAME, [standardActionsModule])

  .controller('ExternalAdUnitActionsCtrl', function($q, toastr, $stateParams, $state, $uibModal, model, modelComms, readOnly) {
    const ctrl = this;
    ctrl.readOnly = readOnly;

    ctrl.model = model;
    ctrl.modelComms = modelComms;
    ctrl.isNew = $stateParams.isNew;

    ctrl.copy = function() {
      let modalInstance = $uibModal.open({
        template: template,
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          modelComms.addSubscriber(function(data, type) {
            if (!type) {
              modalCtrl.externalAdUnit = data;
              modalCtrl.externalAdUnit.name = data.name + " (copy)";
            }
          });

          modalCtrl.addDimensions = function() {
            modalCtrl.externalAdUnit.dimensions = modalCtrl.externalAdUnit.dimensions || [];
            modalCtrl.externalAdUnit.dimensions.push([]);
          };

          modalCtrl.eventHook = {};
          modalCtrl.removeDimensions = function(index) {
            _.pullAt(modalCtrl.externalAdUnit.dimensions, index);
            modalCtrl.eventHook.removeControlHook('dims' + index + '.width');
            modalCtrl.eventHook.removeControlHook('dims' + index + '.height');
          };

          modalCtrl.destination = true;

          modalCtrl.copy = function() {
            modalCtrl.externalAdUnit.id = Uuid.generate();
            delete modalCtrl.externalAdUnit.width;
            delete modalCtrl.externalAdUnit.height;
            modalCtrl.externalAdUnit.$save().then(function(savedExternalAdUnit) {
              modalInstance.close({externalAdUnit: savedExternalAdUnit, destination: modalCtrl.destination});
            });
          };
        }
      });
      modalInstance.result.then(function(result) {
        if (result.destination) {
          $q.all(result.allPromises).then(function() {
            $state.go('app.external-ad-units.external-ad-unit', {id: result.externalAdUnit.id});
          });
        } else {
          toastr.success("A copy of this ad unit was successfully created.", "Ad Unit Copied");
        }
      });
    };
  });

export default MODULE_NAME;