/**
 * Copyright © 2025 Adnuntius AS.
 */
import * as _ from 'lodash';
import * as moment from 'moment';
import {ApiConfig} from "../../../api";
import {Inject} from "@angular/core";

export class BurnRatesResource {
  private readonly uri = ApiConfig.obtain().getVersionedUri('burnrates');
  private readonly defaultParams = {
    startDate: moment().subtract(7, 'd'),
    endDate: moment()
  };

  constructor(@Inject('$http') private $http) {
  }

  get(params, cb, errCb) {
    params = _.assign({}, this.defaultParams, params);
    if (params.startDate && params.startDate.toISOString) {
      params.startDate = params.startDate.toISOString();
    }
    if (params.endDate && params.endDate.toISOString) {
      params.endDate = params.endDate.toISOString();
    }
    const options = {
      params: _.omit(params, ['id', 'idKey']),
      hasErrorHandler: _.isFunction(errCb)
    };
    return this.$http.get(this.uri + '/' + params.id, options).then(function(response) {
      return (cb || _.noop)(response.data);
    }).catch(function(response) {
      return (errCb || _.noop)(response);
    });
  }
}
