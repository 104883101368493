/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';

import localNetworkProfile from '../../components/session/local-network-profile';

import template from './creative-list-component.html';
import {getListChildren} from "../common/controller/resource-list-controller";
import {OBJECT_TYPE} from "../common/constants/object-type";
import {LocalNetworkInfo} from "../../components/session/local-network-info";
import {creativeAttach, creativeBulkAction, creativeCopyInBulk} from "./creative-commons";

const MODULE_NAME = "creative-list-comp";

angular.module(MODULE_NAME, [translate, uiBootstrap, localNetworkProfile])

  .component('adnCreativeList', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      list: '<',
      readOnly: '<readonly',
      calledFrom: '@'
    },
    controller: function($templateCache, $uibModal, $q, adnListHelper, Creative) {
      const ctrl = this;

      ctrl.hasCreativeSet = LocalNetworkInfo.obtain().getInfo('creativeSetCount') > 0;
      ctrl.attachSuffix = "Line Item" + (ctrl.hasCreativeSet ? " or Creative Set" : "");

      ctrl.$onInit = function() {
        ctrl.list.canExpand = false;

        ctrl.list.copyInBulk = function() {
          creativeCopyInBulk(ctrl.list, {
            $uibModal: $uibModal,
            Creative: Creative,
            $q: $q
          }, function() {
            ctrl.list.makeQuery();
          });
        };

        ctrl.list.attach = function() {
          creativeAttach(ctrl.list, {
            $uibModal: $uibModal,
            Creative: Creative,
            $q: $q
          }, function() {
            ctrl.list.makeQuery();
          });
        };

        if (!ctrl.list.getChildren) {
          getListChildren(ctrl.list, adnListHelper, OBJECT_TYPE.Creative, Creative);
        }

        if (!ctrl.list.bulkAction) {
          ctrl.list.bulkAction = creativeBulkAction(ctrl.list, {$uibModal: $uibModal});
        }
      };
    }
  });

export default MODULE_NAME;