/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import standardAnalysisModule from '../common/standard-analysis-module';
import _ from "lodash";
import moment from "moment/moment";
import {creativeSetListCommons} from "../creative-set/creative-set-commons";
import {creativeListCommons} from "../creative/creative-commons";

const MODULE_NAME = "folio-information";

angular.module(MODULE_NAME, [standardAnalysisModule])

  .controller('FolioInformationCtrl', function(CreativeSet, $stateParams, modelComms, Creative, LocalNetworkProfile, adnTimeShifter, Team, Folio, $translate, $filter, $state, LineItem, LocalUserPermissions, model, adnListHelper) {
    const ctrl = this;
    ctrl.query = {};
    ctrl.model = angular.copy(model);

    ctrl.isNew = $stateParams.isNew;
    ctrl.isAxAdvertiser = LocalUserPermissions.isAxAdvertiser();
    ctrl.showVideoUpload = LocalNetworkProfile.showVideoUpload();
    ctrl.showCreatives = LocalUserPermissions.getSectionPerms().creatives;

    ctrl.creativeSets = {};
    adnListHelper.setUpBasicList(ctrl.creativeSets, CreativeSet, 'fullCreativeSetsForFolio', {
      moreParams: {folio: model.id},
      baseTable: 'creativeSet',
      afterFill: function() {
        ctrl.creativeSetsEmpty = _.isEmpty(ctrl.creativeSets.items);
        ctrl.creativeSetsCount = Object.keys(ctrl.creativeSets.items || []).length;
        _.forEach(ctrl.creativeSets.items || [], function(li) {
          if (li.startDate) {
            li.modStartDate = adnTimeShifter.timeShift(li.startDate);
          }
          if (li.endDate) {
            li.modEndDate = adnTimeShifter.timeShift(li.endDate);
          }
        });
        ctrl.selLineItem = _.find(ctrl.creativeSets.items || [], function() {
          return true;
        });
        creativeSetListCommons(ctrl.creativeSets, true, LocalNetworkProfile, adnTimeShifter, $translate, $filter, LocalUserPermissions, Team, Folio);
      }
    });
    ctrl.creativeSets.perms = LocalUserPermissions.getAllVisPermissions();
    creativeSetListCommons(ctrl.creativeSets, false, LocalNetworkProfile, adnTimeShifter, $translate, $filter, LocalUserPermissions, Team, Folio);

    ctrl.creatives = {};
    adnListHelper.setUpBasicList(ctrl.creatives, Creative, 'fullCreativesForFolio', {
      moreParams: {folio: model.id},
      baseTable: 'creative',
      afterFill: function() {
        creativeListCommons(ctrl.creatives, true, LocalNetworkProfile, $state);
      }
    });
    creativeListCommons(ctrl.creatives, false, LocalNetworkProfile, $state);

    ctrl.onBeforeLineItemSubmit = function() {
      ctrl.creativeSet.startDate = _.isDate(ctrl.creativeSet.modStartDate) ? adnTimeShifter.timeShift(ctrl.creativeSet.modStartDate, true) : null;
      ctrl.creativeSet.endDate = _.isDate(ctrl.creativeSet.modEndDate) ? adnTimeShifter.timeShift(ctrl.creativeSet.modEndDate, true) : null;
    };

    ctrl.loadExternalCreativeSet = function() {
      $state.go("app.creative-sets.new", {folioId: model.id, folioName: model.name, advertiserId: _.get(ctrl.model, ['advertiser', 'id']), advertiserName: _.get(ctrl.model, ['advertiser', 'name'])});
    };

    ctrl.newVideoUpload = function() {
      $stateParams.advertiser = ctrl.model.advertiser;
      $stateParams.folio = ctrl.model;
      $state.go('app.creatives.videoupload');
    };

    ctrl.loadLineItemForm = function() {
      ctrl.creativeSetForm = {};
      const networkDefaults = LocalNetworkProfile.getDefaults();
      let startDate = adnTimeShifter.timeShift(moment().startOf('day').toDate(), true),
        endDate = adnTimeShifter.timeShift(moment().add(networkDefaults.creativeSetDaysApart, 'day').endOf('day').toDate(), true);
      if (_.isDate(ctrl.model.modObjectiveStartDate)) {
        startDate = adnTimeShifter.timeShift(ctrl.model.modObjectiveStartDate, true);
      }
      if (_.isDate(ctrl.model.modObjectiveEndDate)) {
        endDate = adnTimeShifter.timeShift(ctrl.model.modObjectiveEndDate, true);
      }

      ctrl.creativeSet = LineItem.create({
        userState: ctrl.isSelfService || ctrl.isAxAdvertiser ? networkDefaults.marketplaceLineItemUserState || "PROPOSED" : networkDefaults.creativeSetUserState || 'APPROVED',
        folio: ctrl.model,
        startDate: startDate,
        endDate: endDate,
        modStartDate: adnTimeShifter.timeShift(startDate),
        modEndDate: adnTimeShifter.timeShift(endDate)
      });

      ctrl.creativeSet.deliveryCurve = ctrl.isAxAdvertiser ? LocalNetworkProfile.getDefaults().marketplaceDeliveryCurve : LocalNetworkProfile.getDefaults().deliveryCurve;

      //commons = creativeSetCommons(ctrl, $translate, $filter, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter, 'creativeSet');

      const defaultTierId = LocalNetworkProfile.get('defaultTierId');
      if (defaultTierId) {
        ctrl.creativeSet.tier = {id: defaultTierId};
      }

      ctrl.showLineItemForm = true;
    };

    ctrl.saveLineItem = function(promise) {
      promise.then(function(creativeSet) {
        ctrl.showLineItemForm = false;

        if (!_.isArray(ctrl.items)) {
          ctrl.items = [];
        }
        ctrl.creativeSets.addItemToList(creativeSet);
      });
    };

    modelComms.addSubscriber(function(data, type) {
      if (!type && data.name) {
        ctrl.model = data;

        if (ctrl.isNew) {
          ctrl.isNew = false;
        }
      }
    });
  });

export default MODULE_NAME;