/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

import template from './report-requester.html';

import resources from '../../../components/api/resources/resources';
import eventTimer from '../../common/controller/event-timer-component';
import searchSelect from '../../common/controller/search-select-component';
import dateSelector from '../../common/directives/form-controls/date-selector';
import reportHistoryComp from '../../report/report-history-component';
import {ADN_REPORT_TYPES} from '../../report/report-types';
import timeShifter from '../../../components/util/time-shifter';
import localNetworkProfile from '../../../components/session/local-network-profile';
import localUserPermissions from '../../../components/session/local-user-permissions';

const MODULE_NAME = 'report-requester';

angular.module(MODULE_NAME, [
  resources,
  reportHistoryComp,
  dateSelector,
  eventTimer,
  searchSelect,
  timeShifter,
  localNetworkProfile,
  localUserPermissions
])

  .component('adnReportRequester', {
    bindings: {
      pObject: '<obj',
      pReportType: '@adnReportType',
      collapsible: '@adnCollapsible',
      currency: '<'
    },
    template: template,
    controllerAs: 'ctrl',
    controller: function($filter, LocalNetworkProfile, User, SiteGroup, EarningsAccount, LocalUserPermissions, adnTimeShifter, searchResource, localUserProfile, ReportExecution, ReportTemplate) {
      const ctrl = this;

      let allTemplates = [];
      let filterTemplates = function(reportType) {
        return reportType ? _.filter(allTemplates, function(t) {
          return t.reportType === reportType || (reportType === 'LINE_ITEM' && t.reportType === 'SELF_SERVICE');
        }) : angular.copy(allTemplates);
      };

      ctrl.$onInit = function() {
        ctrl.collapsible = ctrl.collapsible || ctrl.collapsible === 'true';
        ctrl.isSmall = !!ctrl.pReportType;
        ctrl.reportType = {};

        ctrl.availableCurrencies  = LocalNetworkProfile.get('availableCurrencies');
        const preferredCurrency = localUserProfile.getPreferredCurrency();
        ctrl.currency = ctrl.currency || preferredCurrency || LocalNetworkProfile.get('defaultCurrency');

        ctrl.objectHolder = {};
        ctrl.objectHolder.object = ctrl.pObject;
        ctrl.objectType = 'ORDER';

        ctrl.perms = LocalUserPermissions.getAllVisPermissions();
        ctrl.canSeeObjects = true;//ctrl.perms.MANAGE_ADVERTISING || ctrl.perms.MANAGE_PUBLISHING || ctrl.perms.MANAGE_BUSINESS || ctrl.perms.MANAGE_SYSTEM;
        ctrl.canExecute = ctrl.perms.RUN_REPORTS || ctrl.perms.AX_ADVERTISER || ctrl.perms.AX_PUBLISHER;

        const networkDefaults = LocalNetworkProfile.getDefaults();
        ctrl.canSeeAdvertiserReports = networkDefaults.runReportsOptions.indexOf("ADVERTISER_REPORTS") > -1;
        ctrl.reportType.type = ctrl.pReportType;
        if (!ctrl.reportType.type) {
          if (ctrl.perms.MANAGE_ADVERTISING) {
            ctrl.reportType.type = 'LINE_ITEM';
          } else if (ctrl.perms.MANAGE_PUBLISHING) {
            ctrl.reportType.type = 'AD_UNIT';
          } else if (ctrl.perms.MANAGE_BUSINESS) {
            ctrl.reportType.type = 'ADVERTISER';
          } else if (ctrl.perms.MANAGE_SYSTEM) {
            ctrl.reportType.type = 'NETWORK';
          }
        }
        ctrl.selReportType = ADN_REPORT_TYPES[ctrl.reportType.type];

        LocalNetworkProfile.isSuiPlatform();
        ctrl.reportTypes = _.map(_.filter(ADN_REPORT_TYPES, function(rt) {
          if (rt.perms) {
            return !!_.find(_.isArray(rt.perms) ? rt.perms : [rt.perms], function(perm) {
              return ctrl.perms[perm] === true;
            });
          }
          return rt.id !== 'SELF_SERVICE' || LocalNetworkProfile.isSuiPlatform();
        }), function(rt) {
          return rt.id;
        });

        EarningsAccount.query(function(page) {
          ctrl.earningsAccounts = page.results;
        });

        SiteGroup.query(function(page) {
          ctrl.siteGroups = page.results;
        });

        User.query({minimal: true}, function(page) {
          ctrl.users = page.results;
        });

        ReportTemplate.query().$promise.then(function(page) {
          allTemplates = angular.copy(page.results);
          ctrl.reportTemplates = filterTemplates(ctrl.reportType.type);
          ctrl.childReportTemplates = filterTemplates('LINE_ITEM');
        });

        ctrl.filterReportTemplates = function() {
          ctrl.reportTemplates = filterTemplates(ctrl.reportType.type);
          ctrl.objectHolder.object = undefined;
          ctrl.selReportType = ADN_REPORT_TYPES[ctrl.reportType.type];
        };

        ctrl.reportRequest = {dates: false};
        ctrl.reportRequest.startDate = moment(adnTimeShifter.timeShift(moment())).subtract(1, 'month').startOf('day').toDate();
        ctrl.reportRequest.endDate = moment(adnTimeShifter.timeShift(moment())).endOf('day').toDate();

        ctrl.newReportExecutions = [];
        ctrl.isRefreshing = false;

        ctrl.timerObj = {};
        let callOnRegister = false;
        let checkNewExecutions = function() {
          callOnRegister = true;
        };
        ctrl.timerObj.registered = function() {
          checkNewExecutions = function(cancelTimers) {
            if (cancelTimers) {
              ctrl.timerObj.cancelTimers();
            }
            let found = _.find(ctrl.newReportExecutions, function(re) {
              return re.isAwaiting();
            });
            if (found) {
              ctrl.timerObj.refreshTimers();
            } else {
              ctrl.timerObj.cancelTimers();
            }
          };
          if (callOnRegister) {
            checkNewExecutions();
          }
        };

        ctrl.timerObj.refreshEvent = function() {
          let ids = _.map(ctrl.newReportExecutions, 'id');
          if (ids.length) {
            ctrl.timerObj.setRefreshing(true);
            ReportExecution.query(ids).$promise.then(function(page) {
              ctrl.newReportExecutions = page.results;
              ctrl.timerObj.setRefreshing(false);
              checkNewExecutions();
            });
          }
        };

        ctrl.startReport = function() {
          let reportTemplate = ctrl.reportRequest.reportTemplate;
          let childReportTemplate = ctrl.reportRequest.childReportTemplate;
          let objectName = ADN_REPORT_TYPES.NETWORK.id === ctrl.reportType.type ? 'Network' : _.get(ctrl.objectHolder, 'object.name');
          let reportParams = {
            name: [reportTemplate.name, objectName, $filter('date')(new Date(), 'shortDate')].join(' - '),
            reportTemplate: reportTemplate,
            childReportTemplate: childReportTemplate || null,
            parameters: {
              localePriority: 'descending',
              timeZone: LocalNetworkProfile.get('timeZone') || 'UTC',
              currency: ctrl.currency || LocalNetworkProfile.get('defaultCurrency') || 'EUR',
              locale: localUserProfile.get('locale') || 'en_GB'
            }
          };
          let selReportType = ADN_REPORT_TYPES[ctrl.reportType.type];
          if ((selReportType.specifyDates || ctrl.reportRequest.dates === true) && _.isDate(ctrl.reportRequest.startDate)) {
            reportParams.parameters.startDate = moment(adnTimeShifter.timeShift(ctrl.reportRequest.startDate, true)).toDate();
            if (_.isDate(ctrl.reportRequest.endDate)) {
              reportParams.parameters.endDate = moment(adnTimeShifter.timeShift(ctrl.reportRequest.endDate, true)).toDate();
            }
          }
          if (ADN_REPORT_TYPES.LINE_ITEM.id === ctrl.reportType.type) {
            reportParams.parameters.lineItemId = ctrl.objectHolder.object.id;
            reportParams.relatedLineItems = [{id: ctrl.objectHolder.object.id}];
          } else if (ADN_REPORT_TYPES.NETWORK.id === ctrl.reportType.type) {
            reportParams.parameters[selReportType.idKey] = LocalNetworkProfile.getNetworkId();
          } else if (ADN_REPORT_TYPES.LINE_ITEM_LIST.id === ctrl.reportType.type) {
            if (ctrl.objectType === 'ORDER') {
              reportParams.parameters[selReportType.idKey] = _.get(ctrl.objectHolder, ['object', 'id']);
            } else {
              reportParams.parameters[selReportType.idsKey] = _.map(_.get(ctrl.objectHolder, ['lineItems'], []), function(li) {
                return li.id;
              });
            }
          } else {
            reportParams.parameters[selReportType.idKey] = ctrl.objectHolder.object.id;
          }

          ReportExecution.start(reportParams).then(function(reportExecution) {
            ctrl.newReportExecutions.unshift(reportExecution);
            ctrl.additionHappened = false;
            checkNewExecutions(true);
          });
        };
      };
    }
  });

export default MODULE_NAME;