/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import template from './product-copy-modal.html';

import standardActionsModule from '../../common/standard-actions-module';
import { actionsHelper } from "../../common/controller/actions-helper";

const MODULE_NAME = "product-actions-controller";

angular.module(MODULE_NAME, [
  standardActionsModule
])
  .controller('ProductActionsCtrl', function($q, toastr, $state, $stateParams, $uibModal, model, modelComms, readOnly) {
    const ctrl = this;

    actionsHelper(ctrl, model, modelComms, readOnly, $stateParams, $uibModal, $q, toastr, $state, template, {
      url: 'app.products.product',
      lcase: 'product',
      ucase: 'Product'
    });
  });

export default MODULE_NAME;