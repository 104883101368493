/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import resources from './api/resources/resources';

const MODULE_NAME = "workspace-registry";

angular.module(MODULE_NAME, [resources])

  .factory('WorkspaceRegistry', function($q, Workspace, localUserProfile) {
    let registry = {
      workspaces: []
    };

    const sortByFunc = function(ws) {
      return (ws.name || "").toLowerCase();
    };

    registry.sync = function() {
      if (localUserProfile.getCurrentApp() !== 'ADVERTISING') {
        return $q.when();
      }
      registry.$promise = Workspace.query().$promise.then(function(page) {
        return angular.copy(_.sortBy(page.results, sortByFunc), registry.workspaces);
      });
      return registry.$promise;
    };
    registry.getPromise = function() {
      return registry.$promise || registry.sync();
    };
    registry.remove = function(workspace) {
      return _.remove(registry.workspaces, ['id', workspace.id]);
    };
    registry.update = function(workspace) {
      registry.remove(workspace);
      registry.workspaces.unshift(workspace);
      const sortedWorkspaces = _.sortBy(registry.workspaces, sortByFunc);
      registry.workspaces.length = 0;
      _.forEach(sortedWorkspaces, function(ws) {
        registry.workspaces.push(ws);
      });
    };

    return registry;
  });

export default MODULE_NAME;