/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import translate from 'angular-translate';

import template from './sorting-header.html';

const MODULE_NAME = "sorting-header-directive";

angular.module(MODULE_NAME, [translate])

  .directive('adnSortingHeader', function() {
    return {
      restrict: 'A',
      template: template,
      scope: {},
      bindToController: {
        list: '<adnSortingHeader',
        params: '<adnParams',
        hideActions: '<',
        extraField: '<'
      },
      controllerAs: 'ctrl',
      controller: function() {
        const ctrl = this;
        ctrl.$onInit = function() {
          ctrl.translationPrefix = 'label';
        };
      }
    };
  });

export default MODULE_NAME;