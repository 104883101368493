/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "team";

angular.module(MODULE_NAME, [])

  .factory("Team", function(adnResource) {
    return adnResource('teams');
  });

export default MODULE_NAME;