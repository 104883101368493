/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';

import titleTemplate from '../default-title-template.html';
import nudeTemplate from './nude-template.html';
import adnTemplate from './adn.html';
import adnLayoutTemplate from './adn-layout.html';
import adnRequestTemplate from './adn-request.html';
import adnFeedbackTemplate from './adn-feedback.html';
import adnUtilityTemplate from './adn-utility.html';
import tagTemplate from '../../ad-tag/ad-tag-generator.html';
import releaseNotesDisplayComponent from "../simple/release-notes-display-component";

import adTagCtrl from '../../ad-tag/ad-tag-controller';
import previewModule from './preview-module';
import BUILD_INFO from '../../../gen-config/build-info';

const MODULE_NAME = 'nude-module';

angular.module(MODULE_NAME, [translate, releaseNotesDisplayComponent, uiBootstrap, previewModule, adTagCtrl])

  .config(function($stateProvider) {
    $stateProvider
      .state('nude', {
        abstract: true,
        template: nudeTemplate,
        controller: /*@ngInject*/ function() {
          const ctrl = this;
          ctrl.releaseId = BUILD_INFO.gitSha.substring(0, 10);
        },
        controllerAs: 'nude'
      })
      .state('nude.release-notes', {
        url: '/release-notes',
        views: {
          'content@nude': {
            template: `
                <div class="container container-small releaseNotesContainer">
                  <adn-release-notes use-private="ctrl.usePrivate"></adn-release-notes>
                </div>
            `,
            controllerAs: 'ctrl',
            controller: /*@ngInject*/ function(AuthService) {
              const ctrl = this;
              ctrl.usePrivate = AuthService.isAuthenticated();
            }
          },
          'title@nude': {
            template: `
              <div class="container container-small releaseNotesContainer">
                <h1 style="font-size: 20px;margin-bottom: 10px;">Release Notes for the Adnuntius Platform</h1>
              </div>
            `
          }
        },
        data: {
          public: true,
          title: 'Release Notes'
        }
      })
      .state('nude.release-notes.release-note', {
        url: '/release-note/:urlSlug',
        views: {
          'content@nude': {
            template: `
              <div class="container container-small releaseNotesContainer">
                <adn-release-notes use-private="ctrl.usePrivate" url-slug="ctrl.urlSlug"></adn-release-notes>
              </div>
            `,
            controllerAs: 'ctrl',
            controller: /*@ngInject*/ function(releaseNotesHelper, AuthService, $stateParams) {
              const ctrl = this;
              ctrl.usePrivate = AuthService.isAuthenticated();

              ctrl.$onInit = function() {
                ctrl.urlSlug = $stateParams.urlSlug;
              };
            }
          },
          'title@nude': {
            template: `
              <div class="container container-small releaseNotesContainer">
                <h1 style="font-size: 20px;margin-bottom: 10px;">Release Notes for the Adnuntius Platform</h1>
                <p>
                  <a ui-sref="nude.release-notes">Show all Release Notes</a>
                </p>
              </div>
            `
          }
        },
        data: {
          public: true,
          title: 'Release Notes'
        }
      })
      .state('nude.adn', {
        url: '/adn',
        views: {
          'content@nude': {
            template: adnTemplate,
            controller: function() {
            },
            controllerAs: 'ctrl'
          },
          'request@nude.adn': {
            template: adnRequestTemplate
          },
          'feedback@nude.adn': {
            template: adnFeedbackTemplate
          },
          'layout@nude.adn': {
            template: adnLayoutTemplate
          },
          'utility@nude.adn': {
            template: adnUtilityTemplate
          },
          'title@nude': {
            template: titleTemplate
          }
        },
        data: {
          public: true,
          title: 'Documentation for adn.js'
        }
      })
      .state('nude.tag', {
        url: '/tag?auId&auW&auH&design&auName',
        reloadOnSearch: false,
        views: {
          'content@nude': {
            template: tagTemplate,
            controller: 'AdTagCtrl',
            controllerAs: 'ctrl'
          },
          'title@nude': {
            template: titleTemplate
          }
        },
        data: {
          public: true,
          title: "Ad Tag Generator and Tester"
        }
      });
  });

export default MODULE_NAME;