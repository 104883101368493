/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import releaseNotesHelper from "../../../components/util/release-notes-helper";
import releaseNoteDisplayComponent from "./release-note-display-component";

import localNetworkProfile from '../../../components/session/local-network-profile';

import template from './release-notes.html';

const MODULE_NAME = "release-notes-display-comp";

angular.module(MODULE_NAME, [translate, uiBootstrap, releaseNotesHelper, localNetworkProfile, releaseNoteDisplayComponent])

  .component('adnReleaseNotes', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      apps: "<",
      usePrivate: "<",
      urlSlug: "<",
      target: "@"
    },
    controller: function(releaseNotesHelper) {
      const ctrl = this;

      ctrl.isRunning = true;
      ctrl.$onInit = function() {
        if (ctrl.urlSlug) {
          const method = ctrl.usePrivate ? "getFromUrlSlug" : "getPublicFromUrlSlug";
          releaseNotesHelper[method](ctrl.urlSlug).then(function(data) {
            ctrl.notes = [data];
            ctrl.isRunning = false;
          }).catch(function() {
            ctrl.notes = [];
            ctrl.isRunning = false;
          });
        } else {
          const method = ctrl.usePrivate ? "query" : "queryPublic";
          releaseNotesHelper[method](ctrl.apps, false).then(function(data) {
            ctrl.notes = data;
            ctrl.isRunning = false;
          }).catch(function() {
            ctrl.notes = [];
            ctrl.isRunning = false;
          });
        }
      };
    }
  });

export default MODULE_NAME;