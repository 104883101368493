/**
 * Copyright © 2025 Adnuntius AS.
 */
import { OBJECT_STATE } from "../../../../components/api/resources/resources-constants";

import { MediaTypeHelper, MEDIA_TYPE } from "../../../../components/util/media-type-helper";

import htmlLayoutTemplate from './html-layout.hbs';
import vastUploadTemplate from './vast-upload.hbs';
import vastStreamingTemplate from './vast-streaming.hbs';
import vastVpaidTemplate from './vast-vpaid.hbs';
import vastAudioUploadTemplate from './vast-audio-upload.hbs';
import vastExternalLoadTemplate from './vast-external-load.hbs';
import imageTemplate from './image-layout.hbs';
import thirdPartyTemplate from './third-party-layout.hbs';

const htmlLayout = (function() {
  const ac = {
    type: 'ASSET',
    tag: 'html',
    name: 'HTML',
    mediaType: 'html',
    required: 'MANDATORY',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MEDIA_TYPE.html.mimeTypes,
    objectState: OBJECT_STATE.active,
    assets: []
  };
  return {
    id: 'html-layout-example',
    name: 'HTML',
    kv: 'html5',
    description: 'Delivers an ad from a HTML file and its associated assets.',
    layoutType: 'HTML',
    layoutComponents: [ac],
    renderTemplate: htmlLayoutTemplate
  };
})();

const vastUpload = (function() {
  const tc1 = {
    type: 'TEXT',
    tag: 'firstQuartile',
    name: 'Custom Event ID to record First Quartile event',
    required: 'OPTIONAL',
    minLength: 1,
    maxLength: 64
  };
  const tc2 = {
    type: 'TEXT',
    tag: 'thirdQuartile',
    name: 'Custom Event ID to record Third Quartile event',
    required: 'OPTIONAL',
    minLength: 1,
    maxLength: 64
  };
  const tc3 = {
    type: 'TEXT',
    tag: 'complete',
    name: 'Custom Event ID to record Complete event',
    required: 'OPTIONAL',
    minLength: 1,
    maxLength: 64
  };
  const ac = {
    type: 'ASSET',
    tag: 'Video',
    mediaType: 'video',
    maxFileSizeBytes: 5120000,
    maxFileSizeKb: 5120000 / 1024,
    mimeTypes: MediaTypeHelper.getVideoMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const uc = {
    type: 'URL',
    tag: 'destinationUrl',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'vast-layout-example',
    name: 'VAST Video',
    kv: 'vastUpload',
    layoutType: 'VAST',
    notes: 'A pre-roll VAST advertisement is shown along with the clip.',
    description: 'Delivers a VAST Video ad. Supports VAST versions 2.0, 3.0 and 4.0',
    renderTemplate: vastUploadTemplate,
    vastUrl: 'https://delivery.adnuntius.com/i?tzo=-660&auId=000000000007eb8e&tt=vast2&examples=videoUpload',
    layoutComponents: [ac, uc, tc1, tc2, tc3]
  };
})();

const vastStreaming = (function() {
  const tc1 = {
    type: 'TEXT',
    tag: 'firstQuartile',
    name: 'Custom Event ID to record First Quartile event',
    required: 'OPTIONAL',
    minLength: 1,
    maxLength: 64
  };
  const tc2 = {
    type: 'TEXT',
    tag: 'thirdQuartile',
    name: 'Custom Event ID to record Third Quartile event',
    required: 'OPTIONAL',
    minLength: 1,
    maxLength: 64
  };
  const tc3 = {
    type: 'TEXT',
    tag: 'complete',
    name: 'Custom Event ID to record Complete event',
    required: 'OPTIONAL',
    minLength: 1,
    maxLength: 64
  };
  const ac = {
    type: 'ASSET',
    tag: 'Video',
    mediaType: 'video',
    maxFileSizeBytes: 5120000,
    maxFileSizeKb: 5120000 / 1024,
    mimeTypes: MediaTypeHelper.getVideoMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const uc = {
    type: 'URL',
    tag: 'destinationUrl',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'vast-streaming-layout-example',
    name: 'VAST Streaming Video',
    kv: 'vastStreaming',
    layoutType: 'VAST',
    notes: 'A pre-roll VAST advertisement is shown along with the clip.',
    description: 'Delivers a VAST Streaming Video ad. Supports VAST versions 2.0, 3.0 and 4.0',
    renderTemplate: vastStreamingTemplate,
    layoutComponents: [ac, uc, tc1, tc2, tc3]
  };
})();

const vastVpaid = (function() {
  const tc1 = {
    type: 'TEXT',
    tag: 'firstQuartile',
    name: 'Custom Event ID to record First Quartile event',
    required: 'OPTIONAL',
    minLength: 1,
    maxLength: 64
  };
  const tc2 = {
    type: 'TEXT',
    tag: 'thirdQuartile',
    name: 'Custom Event ID to record Third Quartile event',
    required: 'OPTIONAL',
    minLength: 1,
    maxLength: 64
  };
  const tc3 = {
    type: 'TEXT',
    tag: 'complete',
    name: 'Custom Event ID to record Complete event',
    required: 'OPTIONAL',
    minLength: 1,
    maxLength: 64
  };
  const ac1 = {
    type: 'ASSET',
    tag: 'Video',
    mediaType: 'video',
    name: 'Video file',
    maxFileSizeBytes: 5120000,
    maxFileSizeKb: 5120000 / 1024,
    mimeTypes: MediaTypeHelper.getVideoMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const ac2 = {
    type: 'ASSET',
    tag: 'Script',
    mediaType: 'other',
    name: 'VPAID Javascript file',
    maxFileSizeBytes: 5120000,
    maxFileSizeKb: 5120000 / 1024,
    mimeTypes: MEDIA_TYPE.js.mimeTypes,
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const uc = {
    type: 'URL',
    tag: 'destinationUrl',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'vast-vpaid-example',
    name: 'VAST VPAID Video',
    kv: 'vastVpaid',
    layoutType: 'VAST',
    notes: 'A pre-roll VPAID VAST advertisement is shown along with the clip.',
    description: 'Delivers a VPAID VAST Video ad. Supports VAST versions 2.0, 3.0 and 4.0',
    renderTemplate: vastVpaidTemplate,
    vastUrl: 'https://delivery.adnuntius.com/i?tzo=-660&auId=000000000007eb8e&tt=vast3&examples=videoVpaid',
    layoutComponents: [ac1, ac2, uc, tc1, tc2, tc3]
  };
})();

const vastAudioUpload = (function() {
  const ac = {
    type: 'ASSET',
    tag: 'Audio',
    mediaType: 'audio',
    maxFileSizeBytes: 5120000,
    maxFileSizeKb: 5120000 / 1024,
    mimeTypes: MediaTypeHelper.getAudioMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  return {
    id: 'vast-audio-layout-example',
    name: 'VAST Audio',
    kv: 'vastAudioUpload',
    layoutType: 'VAST',
    notes: 'Plays an audio file.',
    description: 'Delivers a VAST Audio ad. Supports VAST versions 2.0, 3.0 and 4.0',
    renderTemplate: vastAudioUploadTemplate,
    vastUrl: 'https://delivery.adnuntius.com/i?tzo=-660&auId=000000000007eb8e&tt=vast2&examples=vastaudio',
    layoutComponents: [ac]
  };
})();

const vastExternalHosted = (function() {
  const videoUrl = {
      type: 'URL',
      tag: 'videoUrl',
      name: 'Video URL',
      required: 'MANDATORY'
    },
    delivery = {
      type: 'CHOICE',
      tag: 'delivery',
      name: 'Video Delivery',
      display: 'RADIO',
      required: 'MANDATORY',
      options: ["progressive", "streaming"]
    },
    mimeType = {
      type: 'CHOICE',
      tag: 'mimeType',
      name: 'Video MIME Type',
      display: 'RADIO',
      required: 'MANDATORY',
      options: ["video/mp4", "video/m4v", "video/xm4v", "video/webm"]
    },
    clickDestinationUrl = {
      type: 'URL',
      tag: 'destinationUrl',
      name: 'Click Destination URL',
      required: 'MANDATORY'
    },
    duration = {
      type: 'TEXT',
      tag: 'duration',
      name: 'Video Duration (HH:MM:SS)',
      required: 'OPTIONAL',
      minLength: 8,
      maxLength: 8
    };
  return {
    id: 'vast-externally-hosted',
    name: 'VAST Video (media file hosted by third-party)',
    layoutType: 'VAST',
    kv: 'vastHosted',
    description: 'Delivers a VAST ad with a video hosted on a third-party CDN. Supports VAST versions 2.0, 3.0 and 4.0',
    renderTemplate: vastExternalLoadTemplate,
    notes: 'A pre-roll VAST advertisement is shown along with the clip.',
    vastUrl: 'https://delivery.adnuntius.com/i?tzo=-660&auId=000000000007eb8e&tt=vast2&examples=videoText',
    layoutComponents: [videoUrl, delivery, mimeType, clickDestinationUrl, duration]
  };
})();

const standardLayout = (function() {
  const ac = {
    type: 'ASSET',
    tag: 'Image',
    mediaType: 'image',
    maxFileSizeBytes: 512000,
    maxFileSizeKb: 512000 / 1024,
    mimeTypes: MediaTypeHelper.getImageMimeTypes(),
    objectState: OBJECT_STATE.active,
    required: 'MANDATORY',
    assets: []
  };
  const uc = {
    type: 'URL',
    tag: 'destination',
    name: 'Destination url',
    required: 'MANDATORY'
  };
  return {
    id: 'image-layout-example',
    name: 'Image',
    kv: 'image',
    layoutType: 'STANDARD',
    description: 'Delivers an image fixed in its size.',
    renderTemplate: imageTemplate,
    layoutComponents: [ac, uc]
  };
})();

const thirdPartyLayout = (function() {
  const tc = {
    type: 'TEXT',
    tag: 'thirdParty',
    name: 'Ad code',
    required: 'MANDATORY',
    minLength: 1,
    maxLength: 10000
  };
  return {
    id: 'third-party-layout-example',
    name: 'Third Party',
    kv: 'thirdParty',
    notes: 'This example uses a default AdForm third-party creative.',
    layoutType: 'THIRD_PARTY',
    description: 'Delivers third-party ad code as a creative.',
    renderTemplate: thirdPartyTemplate,
    layoutComponents: [tc]
  };
})();

export const BASIC_LAYOUT_BUNCH = {
  html: htmlLayout,
  vastUpload: vastUpload,
  vastStreaming: vastStreaming,
  vastVpaid: vastVpaid,
  vastAudioUpload: vastAudioUpload,
  vastExternalHosted: vastExternalHosted,
  standard: standardLayout,
  thirdParty: thirdPartyLayout
};
