/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import uiCodemirror from 'angular-ui-codemirror';

import standardFormModule from '../../common/standard-form-module';
import listHelper from '../../common/controller/list-helper';
import {adnFormHelper} from "../../common/controller/form-helper";

const MODULE_NAME = 'layout-include-controller';

angular.module(MODULE_NAME, [
  uiCodemirror,
  standardFormModule,
  listHelper
])

  .controller('LayoutIncludeCtrl', function(Layout, adnListHelper, $stateParams, model, modelComms) {
    const ctrl = this;

    ctrl.isNew = $stateParams.isNew;

    let afterSave = function() {
      if (!ctrl.query) {
        ctrl.makeQuery();
      }
    };

    adnFormHelper.setUpForm(ctrl, model, {afterSave: afterSave, modelComms: modelComms});

    adnListHelper.setUpBasicList(ctrl, Layout, 'layoutsUsingLayoutInclude', {moreParams: {layoutInclude: model.id}, noInitQuery: true});

    if (!ctrl.isNew) {
      ctrl.makeQuery();
    }

    ctrl.showLayoutDetail = false;
  });

export default MODULE_NAME;