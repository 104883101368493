/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import ngSanitize from 'angular-sanitize';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';

import template from './audit-history.html';

import resources from '../../../components/api/resources/resources';
import timeShifter from '../../../components/util/time-shifter';
import messageParamsFilter from '../../common/filters/message-params-translation-filter';

const MODULE_NAME = "audit-history-comp";

angular.module(MODULE_NAME, [uiBootstrap, translate, ngSanitize, resources, messageParamsFilter, timeShifter])

  .component('adnAuditHistory', {
    bindings: {
      domainObject: '<',
      hook: '<'
    },
    controllerAs: 'ctrl',
    template: template,
    controller: function($translate, $interpolate, $filter, auditResource, adnTimeShifter, adnMessageParamsFilter) {
      const ctrl = this;

      function auditQuery() {
        auditResource.get(ctrl.domainObject, ctrl, _.get(ctrl.hook, ['startDate']), _.get(ctrl.hook, ['endDate']), _.get(ctrl.hook, ['chartMode'])).then(function(page) {
          ctrl.masquerade = false;
          ctrl.history = _.map(page.results, function(item) {
            if (!ctrl.masquerade && item.masqueraderUser) {
              ctrl.masquerade = true;
            }
            let messages = _.map(item.messages, function(message) {
              message = adnMessageParamsFilter(message, {status: 'forecast.status'});
              message.uiTranslation = $translate.instant(message.code, message.parameters);
              if (message.uiTranslation === message.code) {
                message.uiTranslation = undefined;
              }
              // want to avoid using ids in messages where possible, emphasise names.
              let newParams = _.clone(message.parameters);
              _.forEach(newParams, function(value, key) {
                if (_.endsWith(key.toLowerCase(), "id")) {
                  delete newParams[key];
                } else if (key.toLowerCase().indexOf("name") > -1) {
                  newParams[key] = "\x3Cstrong>" + value + "\x3C/strong>";
                } else {
                  newParams[key] = $filter('stringToCurrency')(value);
                }
              });
              message.apiTranslation = $interpolate(message.text)(newParams);
              return message;
            });
            item.adjustedTime = adnTimeShifter.timeShift(item.timestamp);
            return _.assign(item, {
              messages: messages
            });
          });
        });
      }

      ctrl.$onInit = function() {
        auditQuery();
        ctrl.changePage = function() {
          auditQuery();
        };
        if (ctrl.hook) {
          ctrl.hook.triggerAuditQuery = function() {
            auditQuery();
          };
        }
      };
    }
  });

export default MODULE_NAME;