/**
 * Copyright © 2025 Adnuntius AS.
 */
import {ApiConfig} from "../../api";
import {Inject} from "@angular/core";

export class DefaultInvoiceTranslationsResource {
  private readonly uri: string = ApiConfig.obtain().getVersionedUri('invoicetranslations/defaults');

  defaultInvoiceTranslations: any;

  constructor(@Inject('$http') private $http: any, @Inject('$q') private $q: any) {
  }

  get() {
    return this.defaultInvoiceTranslations ? this.$q.resolve(this.defaultInvoiceTranslations) : this.$http.get(this.uri, {
      params: {context: null}
    }).then((info) => {
      this.defaultInvoiceTranslations = info.data;
      return this.defaultInvoiceTranslations;
    });
  }
}
