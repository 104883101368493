/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = 'solo-invisibility-info-component';

angular.module(MODULE_NAME, [])

  .component('adnSoloVisibility', {
    bindings: {
      model: '<',
      isNew: '<',
      transKey: '@'
    },
    controllerAs: 'ctrl',
    controller: function() {
      const ctrl = this;
      ctrl.$onInit = function() {
        ctrl.transKey = ctrl.transKey || 'default';
      };
    },
    template: `<p class="alert alert-warning" ng-if="(!ctrl.model || !(ctrl.model.id || ctrl.model.length > 0)) && !ctrl.isNew"><span>{{'solo.' + ctrl.transKey | translate}}</span></p>`
  });

export default MODULE_NAME;
