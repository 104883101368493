/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardAnalysisModule from '../common/standard-analysis-module';

const MODULE_NAME = "creative-set-analysis";

angular.module(MODULE_NAME, [standardAnalysisModule])

  .controller('CreativeSetAnalysisCtrl', function($stateParams, model, Creative) {
    const ctrl = this;
    ctrl.query = {};
    ctrl.model = angular.copy(model);

    ctrl.showingChart = '';

    ctrl.isNew = $stateParams.isNew;

    Creative.query({creativeSet: model.id}).$promise.then(function(data) {
      const creatives = data.results;
      ctrl.creativesEmpty = creatives.length === 0;
      ctrl.creatives = creatives;
      ctrl.creativeCount = creatives.length;
      ctrl.selCreative = _.find(creatives, function() {
        return true;
      });
    });
  });

export default MODULE_NAME;