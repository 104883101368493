/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = 'modal-loading';

angular.module(MODULE_NAME, [])

  .factory('adnModalLoading', function($document, $timeout) {
    let loadingClass = "modalLoading";
    let getBody = function() {
      return angular.element($document).find('body');
    };
    let timeoutPromise,
      thisFactory = this;

    return {
      applyModalLoading: function() {
        getBody().addClass(loadingClass);
        timeoutPromise = $timeout(thisFactory.removeModalLoading, 30000);
      },
      removeModalLoading: function() {
        getBody().removeClass(loadingClass);

        if (timeoutPromise) {
          $timeout.cancel(timeoutPromise);
        }
      }
    };
  });

export default MODULE_NAME;