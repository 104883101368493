/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {IAB_TAXONOMY} from "../../components/util/iab-taxonomy";

import searchSelect from '../common/controller/search-select-component';
import customSelect from '../common/controller/custom-select-component';
import standardFormModule from '../common/standard-form-module';
import {adnFormHelper} from "../common/controller/form-helper";
import {ADN_TARGETING_TYPES} from "../targeting/targeting-constants";
import {LocalNetworkInfo} from "../../components/session/local-network-info";

const MODULE_NAME = 'ruleset-controller';

angular.module(MODULE_NAME, [standardFormModule, searchSelect, customSelect])

  .controller('RulesetCtrl', function($stateParams, adnListHelper, EarningsAccount, Site, AdUnit, LocalUserPermissions, Team, model, modelComms) {
    const ctrl = this;

    function initialiseFloorPrices(data) {
      ctrl.model.advertiserFloorPrices = _.isArray(_.get(data || ctrl.model, 'advertiserFloorPrices')) ? _.get(data || ctrl.model, 'advertiserFloorPrices') : [];
      ctrl.model.advertiserFloorPrices[0] = ctrl.model.advertiserFloorPrices[0] ? ctrl.model.advertiserFloorPrices[0] : {};
      ctrl.model.advertiserFloorPrices = _.map(ctrl.model.advertiserFloorPrices, function(advFloor) {
        if (_.get(advFloor, ['team', 'id'])) {
          advFloor.type = 'TEAM';
        } else {
          advFloor.type = 'ADVERTISER';
        }
        return advFloor;
      });
    }

    function initialiseSizes(data) {
      ctrl.model.sizes = _.isArray(_.get(data || ctrl.model, 'sizes')) ? _.get(data || ctrl.model, 'sizes') : [];
      ctrl.model.sizes[0] = ctrl.model.sizes[0] ? ctrl.model.sizes[0] : {dimensions:[]};
    }

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms, afterInit: function() {
      }, afterSave: function(data) {
        ctrl.teamsWarning = (_.get(ctrl.model, 'teams') || []).length !== (_.get(data, 'teams') || []).length;
      }, afterInitAfterSave: function(data) {
        ctrl.singularTeam = _.get(ctrl.model, ['teams', 0]);
        _.set(ctrl.model, 'teams', _.map(_.get(data || ctrl.model, 'teams') || [], 'id'));
        initialiseFloorPrices(data);
        initialiseSizes(data);
        ctrl.showSingular = ctrl.model.teams.length <= 1;

        ctrl.model.uiSizes = _.cloneDeep(ctrl.model.sizes);
        const highImpactIndex = _.findIndex(ctrl.model.uiSizes, function(uiSize) {
          return uiSize.dimensions[0] === 0 && uiSize.dimensions[1] === 0;
        });
        ctrl.includeHighImpact = highImpactIndex > -1;
        _.pullAt(ctrl.model.uiSizes, highImpactIndex);

        ctrl.showHighImpact = LocalNetworkInfo.obtain().getInfo("idsOfFullPageLayouts").length > 0 || ctrl.includeHighImpact;
      }
    });

    ctrl.targetingTypes = _.map(ADN_TARGETING_TYPES, function(tt) {
      return tt;
    });

    ctrl.isNew = $stateParams.isNew;

    if (ctrl.isNew) {
      ctrl.model.type = ctrl.model.type || 'INVENTORY';
    }

    ctrl.isAxPublisherAdmin = LocalUserPermissions.hasNetworkPermission('AX_PUBLISHER_ADMIN');

    ctrl.iabTaxonomy = angular.copy(IAB_TAXONOMY);

    ctrl.removeFloorPrices = function(index) {
      _.pullAt(ctrl.model.advertiserFloorPrices, index);
      initialiseFloorPrices();
      ctrl.editsMade();
    };

    ctrl.addFloorPrices = function() {
      ctrl.model.advertiserFloorPrices = ctrl.model.advertiserFloorPrices || [];
      ctrl.model.advertiserFloorPrices.push({type: 'ADVERTISER'});
      ctrl.editsMade();
    };

    ctrl.removeDimensions = function(index) {
      _.pullAt(ctrl.model.uiSizes, index);
      initialiseSizes();
      ctrl.editsMade();
    };

    ctrl.addDimensions = function() {
      ctrl.model.uiSizes = ctrl.model.uiSizes || [];
      ctrl.model.uiSizes.push({dimensions:[]});
      ctrl.editsMade();
    };

    ctrl.selectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = _.map(ctrl.allTeams, 'id');
    };

    ctrl.deselectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = [];
    };

    ctrl.advertiserTeamSearch = function(q) {
      return Team.query({minimal: true, filterBy: 'name;description', filterByLike: q, where: 'type = AX_ADVERTISER'}).$promise;
    };

    Team.query({minimal: true}).$promise.then(function(page) {
      ctrl.allTeams = _.filter(page.results, function(team) {
        return team.type === 'STANDARD' || team.type === 'AX_PUBLISHER';
      });
      if (ctrl.isNew && ctrl.allTeams.length === 1 && !ctrl.model.singularTeam) {
        ctrl.singularTeam = ctrl.allTeams[0];
      }
    });

    ctrl.publishers = {};
    adnListHelper.setUpBasicList(ctrl.publishers, EarningsAccount, 'fullPublishersForSiteRuleset', {moreParams: {siteRuleset: model.id}, baseTable: 'earningsAccount'});

    ctrl.sites = {};
    adnListHelper.setUpBasicList(ctrl.sites, Site, 'fullSitesForSiteRuleset', {moreParams: {siteRuleset: model.id}, baseTable: 'site'});

    ctrl.adUnits = {};
    adnListHelper.setUpBasicList(ctrl.adUnits, AdUnit, 'fullAdUnitsForSiteRuleset', {moreParams: {siteRuleset: model.id}, baseTable: 'adUnit'});

    ctrl.canUpdateSiteRuleset = LocalUserPermissions.hasNetwork('AX_PUBLISHER_ADMIN') || LocalUserPermissions.hasAny('MANAGE_PUBLISHING');

    ctrl.editsHook = {
      editsMade: function() {
        ctrl.editsMade();
      }
    };

    ctrl.onBeforeSubmit = function() {
      ctrl.model.advertiserFloorPrices = _.filter(ctrl.model.advertiserFloorPrices, function(fp) {
        let type = _.get(fp, 'type');
        if (_.get(fp, ['floorPrice', 'amount']) >= 0 && _.get(fp, ['floorPrice', 'currency', 'length']) === 3 && type) {
          if (type === 'ADVERTISER') {
            return _.get(fp, ['advertiser', 'id']);
          } else if (type ===  'TEAM') {
            return _.get(fp, ['team', 'id']);
          }
        }
        return false;
      });
      ctrl.model.advertiserFloorPrices = _.map(ctrl.model.advertiserFloorPrices, function(fp) {
        let type = _.get(fp, 'type');
        if (type === 'ADVERTISER' && fp.team) {
          delete fp.team;
        } else if (type === 'TEAM' && fp.advertiser) {
          delete fp.advertiser;
        }
        return fp;
      });
      ctrl.model.sizes = _.filter(ctrl.model.uiSizes, function (size) {
        let dimensions = _.get(size, 'dimensions');
        if (dimensions) {
          return _.get(dimensions, 'width') || (_.isArray(dimensions) && dimensions.length === 2);
        } else {
          return false;
        }
      });
      if (ctrl.includeHighImpact) {
        ctrl.model.sizes.push({dimensions: [0, 0]});
      }
      if (!_.has(ctrl.model, ['floorPrice', 'amount']) || _.get(ctrl.model, ['floorPrice', 'amount']) === null) {
        // This will delete the floor price on the server
        ctrl.model.floorPrice = null;
      }

      if (ctrl.showSingular) {
        ctrl.model.teams = ctrl.singularTeam ? [ctrl.singularTeam] : [];
      }
    };
  });

export default MODULE_NAME;