/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import {ObjectTypeFinder} from "../constants/object-type";

import template from './delete-info.html';

const MODULE_NAME = 'delete-info-component';

angular.module(MODULE_NAME, [])

  .component('adnDeleteInfo', {
    bindings: {
      model: '<'
    },
    controllerAs: 'directive',
    controller: function(localUserProfile) {
      const ctrl = this;
      ctrl.$onInit = function() {
        ctrl.objectType = ObjectTypeFinder.findObjectType(ctrl.model);
        ctrl.app = localUserProfile.getCurrentApp();
      };
    },
    template: template
  });

export default MODULE_NAME;
