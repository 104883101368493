/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import dynamicLocale from 'angular-dynamic-locale';
import {SESSION_EVENT} from "../../common/constants/misc-constants";
import {LocaleHelper} from "../../../components/util/locale-helper";

import standardFormModule from '../../common/standard-form-module';
import {adnFormHelper} from "../../common/controller/form-helper";
import {LOCALES} from "../../common/constants/locales";
import {LocalPausing} from "../../../components/session/local-pausing";

const MODULE_NAME = 'user-profile-controller';

angular.module(MODULE_NAME, [standardFormModule, dynamicLocale])

  .controller('UserProfileCtrl', function($rootScope, $window, $log, User, UserProfile, authenticatedUserResource, LocalNetworkProfile, tmhDynamicLocale, localUserProfile) {
    let localUser = localUserProfile.get();
    if (!localUser) {
      return;
    }

    const ctrl = this;
    ctrl.user = localUser;
    ctrl.allowPausing = localUserProfile.canPauseAdUnits();
    ctrl.enablePausingAdUnits = LocalPausing.obtain().get();

    ctrl.currencies = LocalNetworkProfile.get('availableCurrencies');
    ctrl.defaultCurrency = LocalNetworkProfile.get("defaultCurrency");

    ctrl.locales = LOCALES;
    const profileForSaving = {};

    ctrl.goDefault = function() {
      ctrl.model.preferredCurrency = null;
      ctrl.wrongCurrencyForNetwork = false;
      ctrl.editsMade();
    };

    ctrl.startProcess = function() {
      ctrl.startedProcess = true;
      ctrl.loading = 'LOADING';
      ctrl.verifyErr = false;
      ctrl.cancelErr = false;
      ctrl.startErr = false;
      ctrl.verifyCode = '';
      const model = {
        id: ctrl.model.id,
        concise: true,
        twoFactorAuth: true,
        login2faRequired: false
      };
      User.save(model, function(savedUser) {
        ctrl.qrCode = _.get(savedUser, ['twoFactorAuthConfig', 'qrCode']);
        ctrl.loading = 'LOADED';
        localUserProfile.set("twoFactorAuth", savedUser.twoFactorAuth);
        localUserProfile.set("login2faRequired", savedUser.login2faRequired);
        $log.info(_.get(savedUser, ['twoFactorAuthConfig', 'otpAuthUri']));
      }, function(err) {
        ctrl.loading = 'FAILED';
        ctrl.startErr = _.get(err, ['data']) || {text: 'An error occurred in the 2FA cancellation process', code: '', parameters: {}};
      });
    };

    ctrl.cancel2fa = function() {
      ctrl.cancelLoading = 'LOADING';
      ctrl.verifyErr = false;
      ctrl.cancelErr = false;
      ctrl.startErr = false;
      const model = {
        id: ctrl.model.id,
        concise: true,
        twoFactorAuth: false,
        login2faRequired: false
      };
      User.save(model, function(savedUser) {
        localUserProfile.set("twoFactorAuth", savedUser.twoFactorAuth);
        localUserProfile.set("login2faRequired", !!savedUser.login2faRequired);
        ctrl.loading = false;
        ctrl.qrCode = false;
        ctrl.startedProcess = false;
        ctrl.cancelLoading = 'LOADED';
        init();
      }, function(err) {
        ctrl.cancelLoading = 'FAILED';
        ctrl.cancelErr = _.get(err, ['data']) || {text: 'An error occurred in the 2FA cancellation process', code: '', parameters: {}};
      });
    };

    ctrl.verify = function() {
      ctrl.cancelLoading = 'LOADING';
      ctrl.verifyErr = false;
      ctrl.cancelErr = false;
      ctrl.startErr = false;
      authenticatedUserResource.verify2fa(ctrl.verifyCode).then(function(result) {
        if (result === true) {
          const model = {
            id: ctrl.model.id,
            concise: true,
            twoFactorAuth: true,
            login2faRequired: true
          };
          User.save(model, function(savedUser) {
            localUserProfile.set("twoFactorAuth", savedUser.twoFactorAuth);
            localUserProfile.set("login2faRequired", !!savedUser.login2faRequired);
            ctrl.loading = false;
            ctrl.qrCode = false;
            ctrl.startedProcess = false;
            init();
            ctrl.cancelLoading = 'LOADED';
          }, function() {
            ctrl.cancelLoading = 'FAILED';
            ctrl.verifyErr = {text: "The verification process failed. Try again or reload the page to proceed.", code: '', parameters: {}};
          });
        } else {
          ctrl.verifyErr = {text: "Your 2FA code was not verified successfully. Resubmit your code or cancel and start again.", code: '', parameters: {}};
          ctrl.cancelLoading = 'FAILED';
        }
      }).catch(function(res) {
        ctrl.verifyErr = _.get(res, ['data']) || {text: 'An error occurred in the verification process.', code: '', parameters: {}};
        ctrl.cancelLoading = 'FAILED';
      });
    };

    let init = function() {
        ctrl.profile = angular.copy(localUser.profile);
        ctrl.profile.adnPrefs.locale = LocaleHelper.toApiFormat(ctrl.profile.adnPrefs.locale);
        ctrl.profile.adnPrefs.defaultPageSize = ctrl.profile.adnPrefs.defaultPageSize || 25;
        ctrl.profile.adnPrefs.showReleaseHighlight = ctrl.profile.adnPrefs.showReleaseHighlight || "SHOW";

        delete ctrl.profile.adnPrefs.excelDownloadFormat; // deleting old field that's not required
        delete ctrl.profile.adnPrefs.dropdownMenu; // deleting old field that's not required
        delete ctrl.profile.adnPrefs.layoutVersion; // deleting old field that's not required
        delete ctrl.profile.adnPrefs.menuVersion; // deleting old field that's not required
        delete ctrl.profile.adnPrefs.menuClosed; // deleting old field that's not required
        delete ctrl.profile.adnPrefs.preferredCurrency; // deleting old field that's not required
        ctrl.model = {
          username: localUserProfile.get("username"),
          id: localUserProfile.get("userId"),
          locale: localUserProfile.get("locale"),
          name: localUserProfile.get("name"),
          displayName: localUserProfile.get("displayName"),
          twoFactorAuth: localUserProfile.get("twoFactorAuth"),
          login2faRequired: localUserProfile.get("login2faRequired"),
          canMasquerade: localUserProfile.get("canMasquerade"),
          preferredCurrency: localUserProfile.getPreferredCurrency()
        };

        ctrl.wrongCurrencyForNetwork = ctrl.model.preferredCurrency && _.indexOf(ctrl.currencies, ctrl.model.preferredCurrency) < 0;

        if (localUser.username === "adnuntius@adnuntius.com") {
          // whenever the profile is saved for adnuntius@adnuntius.com, delete a whole bunch of lastviewed and lastedited for the networks because it gets large.
          _.forEach(Object.keys(ctrl.profile), function(k) {
            if (_.startsWith(k, 'adnLastViewed') || _.startsWith(k, 'adnLastEdited') || _.startsWith(k, "undefined")) {
              profileForSaving[k] = null;
            }
          });
        }
      },
      save = function(form) {
        LocalPausing.obtain().set(ctrl.enablePausingAdUnits || false);

        ctrl.save_model = {
          id: ctrl.model.id,
          locale: ctrl.model.locale,
          name: ctrl.model.name,
          displayName: ctrl.model.displayName,
          preferredCurrency: ctrl.model.preferredCurrency,
          concise: true
        };

        if (ctrl.disableTwoFactorAuth) {
          ctrl.save_model.twoFactorAuth = false;
          ctrl.save_model.login2faRequired = false;
        }

        User.save(ctrl.save_model, function(savedUser) {
          let localeNow = savedUser.locale;
          let localeChanged = localUserProfile.get("locale") !== localeNow;
          if (localeChanged) {
            tmhDynamicLocale.set(LocaleHelper.toAngularFormat(localeNow));
          }

          localUserProfile.set("name", savedUser.name);
          localUserProfile.set("displayName", savedUser.displayName);
          localUserProfile.set("locale", savedUser.locale);
          localUserProfile.set("twoFactorAuth", savedUser.twoFactorAuth);
          localUserProfile.set("login2faRequired", savedUser.login2faRequired);
          localUserProfile.set("canMasquerade", savedUser.canMasquerade);
          localUserProfile.set("preferredCurrency", savedUser.preferredCurrency);

          // make sure we do not keep qr code and recovery codes beyond the initial 2fa enable update
          ctrl.qrCode = null;

          // upon enabling 2FA the response from the update will contain the twoFactorAuthConfig
          // but for subsequent request that response will not be available
          if (savedUser.twoFactorAuthConfig) {
            ctrl.qrCode = savedUser.twoFactorAuthConfig.qrCode;
          }

          profileForSaving.adnPrefs = ctrl.profile.adnPrefs;
          UserProfile.save({
            id: localUser.userId,
            profile: profileForSaving
          }, function(apiUser) {
            localUser = localUserProfile.safeSaveProfile('adnPrefs', apiUser.profile.adnPrefs);
            ctrl.profile = angular.copy(localUser.profile);
            init();

            localUserProfile.publish('profile', localUser.profile);

            const showSupportWidget = _.get(ctrl.profile, 'adnPrefs.showSupportWidget');
            $window.zE('webWidget', showSupportWidget !== false ? 'show' : 'hide');

            form.$setValidity('validation', true);
            form.validSubmission = true;
            form.$setPristine();

            $rootScope.$broadcast(SESSION_EVENT.userProfileUpdate, localUser);
          });
        });
      };

    adnFormHelper.setUpForm(ctrl, {}, {replaceInit: init, replaceSave: save});
  });

export default MODULE_NAME;