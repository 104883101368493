/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from "lodash";

const MODULE_NAME = "creative-set";

angular.module(MODULE_NAME, [])

  .factory("CreativeSetInfo", function(adnResource) {
    return adnResource('creativesets/info', {}, {}, "creativeSetInfo");
  })

  .factory('CreativeSet', function(adnResource, $q) {
    const CreativeSet = adnResource('creativesets', {}, {
      copy: {
        params: {creativeSetId: '@creativeSetId', name: '@name', copyCreatives: '@copyCreatives', userState: '@userState'}
      }
    }, 'creativeSet');

    CreativeSet.copyCreativeSet = function(creativeSet, newId, copyCreatives) {
      if (!creativeSet || !creativeSet.id) {
        return $q.reject("Insufficient parameters for the creative set copy");
      }
      return CreativeSet.copy({id: creativeSet.id, creativeSetId: newId || '', name: creativeSet.name, copyCreatives: _.isBoolean(copyCreatives) ? copyCreatives : true, userState: creativeSet.userState || ''});
    };

    return CreativeSet;
  });

export default MODULE_NAME;