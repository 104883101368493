/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

import localNetworkProfile from '../../components/session/local-network-profile';
import localUserPermissions from '../../components/session/local-user-permissions';
import trafficComponent from '../common/directives/related-data-chart-component';
import timeShifter from '../../components/util/time-shifter';
import standardFormModule from '../common/standard-form-module';
import rateLimitComponent from '../common/controller/rate-limit-component';
import standardAnalysisModule from '../common/standard-analysis-module';
import budgetModelCtrl from './budget-modal-controller';
import {adnFormHelper} from "../common/controller/form-helper";
import {LocalNetworkInfo} from "../../components/session/local-network-info";
import {defaultCurrencyDeriver} from "../line-item/currency-default-calculator";
import orderBudgetsTemplate from "./order-budget-modal.html";
import {lineItemCommons} from "../line-item/line-item-commons";
import budgetFieldsTemplate from "./budget-fields.html";

const MODULE_NAME = "order-controller";

export function updateBudgets(ctrl, obj) {
  const workingObj = obj || ctrl.model || {};
  const budgets = _.cloneDeep(workingObj.budget) || [];

  if (budgets.length && workingObj.startDate) {
    const startDatesMatch = moment(workingObj.modStartDate).format("YYYY-MM-DD") === moment(budgets[0].startDay).format("YYYY-MM-DD");
    if (!startDatesMatch) {
      budgets[0].modStartDay = moment(workingObj.modStartDate).format("YYYY-MM-DD");
    }
  }

  if (budgets.length && workingObj.endDate) {
    const endDatesMatch = moment(workingObj.modEndDate).format("YYYY-MM-DD") === moment(budgets[budgets.length - 1].endDay).format("YYYY-MM-DD");
    if (!endDatesMatch) {
      budgets[budgets.length - 1].modEndDay = moment(workingObj.modEndDate).format("YYYY-MM-DD");
    }
  }
  ctrl.uiBudgets = budgets;
  ctrl.model.budgetRollover = _.isBoolean(workingObj.budgetRollover) ? workingObj.budgetRollover : true;
}

angular.module(MODULE_NAME, [
  standardFormModule,
  standardAnalysisModule,
  timeShifter,
  rateLimitComponent,
  localNetworkProfile,
  localUserPermissions,
  trafficComponent,
  budgetModelCtrl
])

  .controller('OrderCtrl', function($stateParams, localUserProfile, readOnly, $templateCache, $uibModal, $state, $filter, $translate, adnTimeShifter, LocalNetworkProfile, LocalUserPermissions, Team, User, searchResource, model, modelComms) {
    const ctrl = this;

    const workOutDefaultCurrency = defaultCurrencyDeriver(ctrl, localUserProfile);

    ctrl.budgetResult = ctrl.budgetResult || {};
    adnFormHelper.setUpForm(ctrl, model, {
      afterInitAfterSave: function(obj) {
        updateBudgets(ctrl, obj);
      },
      modelComms: modelComms,
      afterSave: function(pOrder) {
        ctrl.budgetResult = ctrl.budgetResult || {};
        ctrl.budgetResult.data = false;

        ctrl.model.advertiser = pOrder.advertiser;
        workOutDefaultCurrency(ctrl.model.advertiser, null, null);
      },
      afterInit: function() {
        if (ctrl.model.objectiveStartDate) {
          ctrl.model.modObjectiveStartDate = adnTimeShifter.timeShift(ctrl.model.objectiveStartDate);
        }
        if (ctrl.model.objectiveEndDate) {
          ctrl.model.modObjectiveEndDate = adnTimeShifter.timeShift(ctrl.model.objectiveEndDate);
        }
        if ($state.previousParams.advertiser) {
          ctrl.model.advertiser = $state.previousParams.advertiser;
        }
        ctrl.blockSubmit = readOnly;
      }
    });

    ctrl.isPureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();
    ctrl.advertiserInfo = !ctrl.isPureMarketplace || $stateParams.isNew;
    ctrl.chartCurrency = _.get(model, ['objectives', 'BUDGET', 'currency']) || _.get(model, ['budget', 0, 'amount', 'currency']);

    const orderCommons = lineItemCommons(ctrl, $translate, $filter, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter);
    orderCommons.setObjectiveFields();
    orderCommons.setBidSpecificationFields();
    orderCommons.setObjectivesAsString();
    orderCommons.setAxAdvertiser();

    ctrl.isNew = $stateParams.isNew;

    ctrl.showTeams = LocalNetworkInfo.obtain().getInfo("teamsCount") > 1 || !LocalNetworkProfile.isPureMarketplacePlatform();

    $templateCache.put("orderBudgetList.html", budgetFieldsTemplate);

    Team.query(function(page) {
      const allTeams = page.results;
      const isViewAllObjects = LocalUserPermissions.hasNetwork('VIEW_ALL_OBJECTS');
      ctrl.teams = isViewAllObjects ? allTeams : _.filter(allTeams, function(g) {
        return LocalUserPermissions.isPartOf(g.network.tagId || g.network.id, g.id);
      });
      if (_.get(ctrl, ['model', 'team', 'id'])) {
        const hydratedTeam = _.find(allTeams, function(t) {
          return ctrl.model.team.id === t.id;
        });
        if (hydratedTeam) {
          ctrl.model.team = hydratedTeam;
          workOutDefaultCurrency(null, ctrl.model.team, null);
        }
      }
      if (allTeams.length === 1 && ctrl.teams.length === 1 && !ctrl.model.team) {
        ctrl.model.team = allTeams[0];
      }
    });

    ctrl.perms = LocalUserPermissions.getAllVisPermissions();
    ctrl.canSeeReports = LocalUserPermissions.getAnyVisibility().RUN_REPORTS === true || ctrl.perms.AX_ADVERTISER;
    ctrl.primaryNetworkCurrency = LocalNetworkProfile.get('defaultCurrency');
    ctrl.allowsObjectives = LocalNetworkProfile.isOrderObjectives();
    ctrl.v1Objectives = !_.isEmpty(model.objectives);
    if (ctrl.isAxAdvertiser) {
      ctrl.allowsRateLimits = LocalNetworkProfile.isMarketplaceAdvertiserRateLimits();
      ctrl.rateLimitTypes = ['RENDERED_IMPRESSION', 'VISIBLE_IMPRESSION', 'VIEWABLE_IMPRESSION'];
    } else {
      ctrl.allowsRateLimits = true;
      ctrl.rateLimitTypes = ['IMPRESSION', 'RENDERED_IMPRESSION', 'VISIBLE_IMPRESSION', 'VIEWABLE_IMPRESSION', 'CLICK'];
    }

    ctrl.searchAdvertisers = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "Advertiser"}).then(function(data) {
        ctrl.advertiserResults = data.searchResults;
      });
    };

    User.query({minimal: true}, function(page) {
      ctrl.users = page.results;
    });

    let hasTimeUpdated = false;
    ctrl.changedObjectiveEndDate = function() {
      if (!hasTimeUpdated && !_.isDate(model.objectiveEndDate)) {
        ctrl.model.modObjectiveEndDate.setHours(23);
        ctrl.model.modObjectiveEndDate.setMinutes(59);
        ctrl.model.modObjectiveEndDate.setSeconds(59, 999);
        hasTimeUpdated = true;
      }
    };

    ctrl.editBudgets = function() {
      ctrl.budgetResult = {
        data: false
      };
      ctrl.budgetResult.modalInstance = $uibModal.open({
        template: orderBudgetsTemplate,
        controller: 'BudgetModalCtrl',
        controllerAs: 'modalCtrl',
        size: 'lg',
        windowClass: '',
        resolve: {
          modelObject: function() {
            return _.cloneDeep(ctrl.model);
          },
          budgetResult: function() {
            return ctrl.budgetResult;
          },
          moneyCurrency: function() {
            return _.cloneDeep(ctrl.moneyCurrency);
          },
          objTranslation: function() {
            return "Order";
          }
        }
      });
      ctrl.budgetResult.modalInstance.result.then(function(data) {
        if (data.obj) {
          _.set(ctrl.model, ['budget'], _.get(data, ['obj', 'budget']));
          _.set(ctrl.model, ['budgetRollover'], _.get(data, ['obj', 'budgetRollover']));
          updateBudgets(ctrl, data.obj);
        }
      });
    };

    ctrl.showingChart = '';

    ctrl.onBeforeSubmit = function() {
      if (!_.get(ctrl.model.advertiser, 'id')) {
        ctrl.model.advertiser = null;
      }
      ctrl.model.objectiveStartDate = _.isDate(ctrl.model.modObjectiveStartDate) ? adnTimeShifter.timeShift(ctrl.model.modObjectiveStartDate, true) : null;
      ctrl.model.objectiveEndDate = _.isDate(ctrl.model.modObjectiveEndDate) ? adnTimeShifter.timeShift(ctrl.model.modObjectiveEndDate, true) : null;

      if (!_.get(ctrl.model, ['salespersonUser', 'name']) && _.get(ctrl.model, ['salespersonUser', 'id'])) {
        delete ctrl.model.salespersonUser;
      }
      if (!_.get(ctrl.model, ['adOpsUser', 'name']) && _.get(ctrl.model, ['adOpsUser', 'id'])) {
        delete ctrl.model.adOpsUser;
      }

      if (!_.get(ctrl.model, ['objectives', 'BUDGET', 'amount'])) {
        _.set(ctrl.model, ['objectives', 'BUDGET'], null);
      }
    };
  });

export default MODULE_NAME;