/**
 * Copyright © 2025 Adnuntius AS.
 */
import {AdServerConfig} from "../../components/api/api";
import * as _ from "lodash";

const CREATIVE_TAG_REPL = "/*CREATIVETAG*/";

const replacer = function (code: string, parentType: string, parentId: string, creative: any, adUnitId: string, networkId: any, destinationUrl?: string, useCookies?: boolean, targetId?: string, dimensions?: Array<any>, auW?: number, auH?: number, limited?: boolean, clickTrackingUrl?: string, targeting?: boolean): string {
  let modifiedCode = code;
  if (useCookies === false) {
    modifiedCode = modifiedCode.replace(/noCookies=/g, "noCookies=true");
  } else {
    modifiedCode = modifiedCode.replace(/noCookies=&/g, "");
  }
  if (parentType) {
    modifiedCode = modifiedCode.replace(/\[parentType]/g, parentType);
  }
  if (parentId) {
    modifiedCode = modifiedCode.replace(/\[parentId]/g, parentId);
  }
  if (creative && creative.id) {
    modifiedCode = modifiedCode.replace(/\[creativeId]/g, creative.id);
  } else {
    modifiedCode = modifiedCode.replace("creativeId=[creativeId]&", "");
  }
  if ((creative && creative.width) || auW) {
    modifiedCode = modifiedCode.replace(/\[width]/g, auW || creative.width || '');
  } else {
    modifiedCode = modifiedCode.replace("width=\"[width]\" ", "");
  }
  if ((creative && creative.height) || auH) {
    modifiedCode = modifiedCode.replace(/\[height]/g, auH || creative.height || '');
  } else {
    modifiedCode = modifiedCode.replace("height=\"[height]\" ", "");
  }
  if (targetId) {
    modifiedCode = modifiedCode.replace(/\[targetId]/g, targetId);
  }

  let dimensionsReplace = "";
  if (dimensions && dimensions.length > 0) {
    const vettedDimensions = _.map(_.filter(dimensions, function (dim) {
      return _.isFinite(dim[0]) && _.isFinite(dim[1]);
    }), function (d) {
      return "[" + d[0] + "," + d[1] + "]";
    });
    if (vettedDimensions.length > 0) {
      dimensionsReplace += "[" + vettedDimensions.join(",") + "]";
    }
  }
  if (modifiedCode.indexOf(CREATIVE_TAG_REPL) > -1) {
    let data = "";
    if (limited) {
      data += "env: \"limited\", ";
    }
    if (useCookies === false) {
      data += "useCookies: false, ";
    }
    if (adUnitId) {
      data += "auId: \"" + adUnitId + "\", ";
    }
    if (creative) {
      data += "creativeId: \"" + creative.id + "\", ";
    }
    if (auW > 0) {
      data += "auW: " + auW + ", ";
    }
    if (auH > 0) {
      data += "auH: " + auH + ", ";
    }
    if (dimensionsReplace) {
      data += "dimensions: " + dimensionsReplace + ", ";
    }
    if (clickTrackingUrl) {
      data += "clickTrackingUrl: \"" + clickTrackingUrl + "\", ";
    }
    if (targeting) {
      data += "targeting: true, ";
    }
    modifiedCode = modifiedCode.replace(CREATIVE_TAG_REPL, data);
  } else {
    modifiedCode = modifiedCode.replace("[targeting]", targeting ? "targeting=true&" : "");
    modifiedCode = modifiedCode.replace("[dimensions]", "dimensions=" + dimensionsReplace + "&");
  }
  if (clickTrackingUrl) {
    modifiedCode = modifiedCode.replace("clickTrackingUrl=\"", "clickTrackingUrl=" + clickTrackingUrl + "\"");
  }
  modifiedCode = modifiedCode.replace(/\[adUnitId]/g, adUnitId || '');
  modifiedCode = modifiedCode.replace(/\[networkId]/g, networkId || '');
  if (destinationUrl) {
    modifiedCode = modifiedCode.replace(/\[destinationUrl]/g, encodeURIComponent(destinationUrl));
  }
  modifiedCode = modifiedCode.replace("?auId=&", "?");
  modifiedCode = modifiedCode.replace("&auId=&", "&");
  modifiedCode = modifiedCode.replace("auId=&", "");
  return modifiedCode;
};

const defaultStuff = "auId=[adUnitId]&noCookies=&[parentType]=[parentId]&creativeId=[creativeId]&network=[networkId]";
const formatImageSuffix = defaultStuff + "&format=image&cb=[CACHEBUSTER]";
const trackerSuffix = defaultStuff + "&cb=[CACHEBUSTER]";
const formatClickSuffix = defaultStuff + "&r=";

const jsCall = "\x3Cdiv id=\"[targetId]\">\x3C/div>\n\x3Cscript src=\"https://cdn.adnuntius.com/adn.js\" async>\x3C/script>\n\x3Cscript>\nwindow.adn = window.adn || {}; adn.calls = adn.calls || [];\nadn.calls.push(function() {\n   " +
  "adn.request({ [parentType]: \"[parentId]\", targetId: \"[targetId]\", " + CREATIVE_TAG_REPL + "network: \"[networkId]\" });\n});\n\x3C/script>";

export class CreativeDefaults {

  static getImpTracker(parentType: string, parentId: string, creative: any, adUnitId: any, networkId: any, useCookies: boolean): string {
    return replacer(AdServerConfig.obtain().getUri("pixeli.gif") + "?" + trackerSuffix, parentType, parentId, creative, adUnitId, networkId, null, useCookies);
  }

  static getClickTracker(parentType: string, parentId: string, creative: any, adUnitId: any, networkId: any, useCookies: boolean): string {
    return replacer(AdServerConfig.obtain().getUri("c") + "?" + formatClickSuffix + "[ESCAPED_CLICK_URL]", parentType, parentId, creative, adUnitId, networkId, null, useCookies);
  }

  static getJs(parentType: string, parentId: string, creative: any, adUnitId: any, networkId: any, useCookies: boolean, targetId: string, dimensions: Array<any>, auW: number, auH: number, clickTrackingUrl: string, targeting: boolean): string {
    return replacer(jsCall, parentType, parentId, creative, adUnitId, networkId, null, useCookies, targetId, dimensions, creative ? creative.width : auW, creative ? creative.height : auH, false, clickTrackingUrl, targeting);
  }

  static getJsGAM(parentType: string, parentId: string, creative: any, adUnitId: any, networkId: any, useCookies: boolean, targetId: string, dimensions: Array<any>, auW: number, auH: number, targeting: boolean): string {
    return replacer(jsCall, parentType, parentId, creative, adUnitId, networkId, null, useCookies, targetId, dimensions, creative ? creative.width : auW, creative ? creative.height : auH, true, "%%CLICK_URL_ESC%%", targeting);
  }

  static getScriptServe(parentType: string, parentId: string, creative: any, adUnitId: any, networkId: any, useCookies: boolean, dimensions: Array<any>, clickTrackingUrl: string, targeting: boolean): string {
    return replacer("<script src=\"" + AdServerConfig.obtain().getUri("i") + "?" + defaultStuff + "&format=js&[targeting][dimensions]cb=[CACHEBUSTER]&clickTrackingUrl=\" async></script>"
      , parentType, parentId, creative, adUnitId, networkId, null, useCookies, null, null, null, null, null, clickTrackingUrl, targeting);
  }

  static getScriptServeGAM(parentType: string, parentId: string, creative: any, adUnitId: any, networkId: any, useCookies: boolean, dimensions: Array<any>, targeting: boolean): string {
    return replacer("<script src=\"" + AdServerConfig.obtain().getUri("i").replace("//delivery", "//limited.delivery") + "?" + defaultStuff + "&format=js&[targeting][dimensions]cb=%%CACHEBUSTER%%&clickTrackingUrl=%%CLICK_URL_ESC%%\" async></script>"
      , parentType, parentId, creative, adUnitId, networkId, null, useCookies, null, null, null, null, null, null, targeting);
  }

  static getThirdParty(parentType: string, parentId: string, creative: any, adUnitId: any, networkId: any, useCookies: boolean, dimensions: Array<any>, auW: number, auH: number, clickTrackingUrl: string, targeting: boolean): string {
    return replacer("<iframe class=\"adnThirdPartyCreative\" src=\"" + AdServerConfig.obtain().getUri("i") + "?" + defaultStuff + "&[targeting][dimensions]cb=[CACHEBUSTER]&clickTrackingUrl=\" width=\"[width]\" height=\"[height]\" style=\"padding: 0; border: 0;\"></iframe>",
      parentType, parentId, creative, adUnitId, networkId, null, useCookies, null, dimensions, auW, auH, null, clickTrackingUrl, targeting);
  }

  static getEmail(parentType: string, parentId: string, creative: any, adUnitId: any, networkId: any, destinationUrl: string, useCookies: boolean): string {
    return replacer("<a rel=\"nofollow\" href=\"" + AdServerConfig.obtain().getUri("c") + "?" + formatClickSuffix + "[destinationUrl]\"><img\n" +
      "    src=\"" + AdServerConfig.obtain().getUri("i") + "?" + formatImageSuffix + "\"\nalt=\"\"></a>", parentType, parentId, creative, adUnitId, networkId, destinationUrl, useCookies);
  }
}
