/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {OBJECT_TYPE} from "../common/constants/object-type";

import copyActionsTemplate from './library-creative-copy-actions.html';
import editTemplate from '../creative/creative-edit.html';
import listTemplate from './library-creative-list.html';

import standardModule from '../common/standard-module';
import targetingModule from '../targeting/targeting-module';
import creativeController from '../creative/creative-controller';
import creativeActionsController from './library-creative-actions-controller';
import localNetworkProfile from '../../components/session/local-network-profile';
import bulkEditTemplate from "../creative/bulk-upload-edit.html";
import bulkUploadTemplate from "../creative/bulk-upload-context-list.html";
import {creativeBulkAction} from "../creative/creative-commons";

const MODULE_NAME = 'library-creative-module';

angular.module(MODULE_NAME, [standardModule, creativeController, creativeActionsController, targetingModule, localNetworkProfile])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('library-creatives.library-creative', 'LibraryCreative', 'app.adv', 'app', listTemplate);

    let paramsExtras = {modelId: '', modelName: '', fromLineItem: false};
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        extension: /*@ngInject*/ function() {
          return {
            func: function(ctrl) {
              ctrl.creativeFields = ['name', {param: 'Layout', label: 'layout'}, {param: '', label: 'URLs'}, {label: 'dimensions', param: 'width'}, 'updateTime', {param: '', label: 'targeting'}];
              ctrl.creativeType = "LIBRARY_CREATIVE";
            }
          };
        },
        sectionParam: function() {
          return 'creatives';
        },
        bulk: function() {
          return {
            bulkAction: creativeBulkAction
          };
        }
      },
      views: _.merge({}, {
        'content@app': {
          controller: 'ResourceListCtrl',
          controllerAs: 'list',
          template: listTemplate
        },
        'context@app': {
          template: bulkUploadTemplate,
          controller: 'BaseContextController',
          controllerAs: 'ctrl'
        }
      })
    }))
      .state(rh.newPath('bulkupload'), rh.item({
        url: '/bulk-upload',
        resolve: {
          creativeType: function() {
            return 'LIBRARY_CREATIVE';
          }
        },
        views: {
          'content@app': {
            template: bulkEditTemplate,
            controller: 'BulkCreativeCtrl',
            controllerAs: 'resource'
          },
          'title@app': {
            template: `<h1>Library Creative Bulk Upload</h1>`
          }
        },
        data: {
          title: 'Library Creative Bulk Upload'
        }
      }))
      .state(rh.newItemPath(), rh.newItem(paramsExtras))
      .state(rh.itemPath(), rh.modelCommsItem({
        params: paramsExtras,
        resolve: {
          creativeType: function() {
            return OBJECT_TYPE.LibraryCreative;
          },
          lineItemInfo: function() {
            return {};
          },
          model: /*@ngInject*/ function($stateParams, LibraryCreative) {
            if ($stateParams.isNew) {
              return LibraryCreative.create({
                name: "",
                id: $stateParams.id
              });
            }
            return LibraryCreative.getHydrated($stateParams);
          }
        },
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'CreativeCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: copyActionsTemplate,
            controllerAs: 'ctrl',
            controller: 'LibraryCreativeActionsCtrl'
          },
          'title@app': _.merge(rh.getDefaultTitleView(), {
            controller: /*@ngInject*/ function(model, modelComms, LocalUserPermissions) {
              const ctrl = this;
              ctrl.objectType = OBJECT_TYPE.LibraryCreative;
              ctrl.itemName = model.name || 'New';
              ctrl.perms = LocalUserPermissions.getAllVisPermissions();
              ctrl.sectionPerms = LocalUserPermissions.getSectionPerms();

              ctrl.crumbs = [];

              modelComms.addSubscriber(function(data, type) {
                if (type) {
                  return;
                }
                ctrl.itemName = data.name || 'New';
              });
            }
          })
        }
      }));
  });

export default MODULE_NAME;