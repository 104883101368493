/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiRouter from '@uirouter/angularjs';
import 'angular-object-diff';
import ngSanitize from 'angular-sanitize';
import {OBJECT_TYPE} from "../../common/constants/object-type";

import resources from '../../../components/api/resources/resources';

const MODULE_NAME = "version-history-ctrl";

angular.module(MODULE_NAME, [
  uiRouter,
  'ds.objectDiff',
  resources,
  ngSanitize
])
  .controller('VersionHistoryCtrl', function($state, localUserProfile, LocalNetworkProfile, ObjectDiff, versionHistoryResource) {
    const ctrl = this;

    const app = localUserProfile.getCurrentApp();
    const profileKey = 'adnLastViewed' + LocalNetworkProfile.getNetworkId() + (app === 'ADVERTISING' ? '' : app);
    ctrl.lastViewed = _.map(_.get(localUserProfile.get(), ['profile', profileKey]), function(entry) {
      const objType = OBJECT_TYPE[entry.objectType];
      if (objType) {
        entry.objType = objType;
      }
      return entry;
    });
    ctrl.lastViewedObject = _.get(ctrl.lastViewed, [0]);
    ctrl.selectFrom = 'LAST_VIEWED';

    ctrl.loaded = true;
    ctrl.objectId = $state.previousParams.id;
    ctrl.objectType = _.find(OBJECT_TYPE, function(ot) {
      return ot.stateName === $state.previousState.name;
    });

    ctrl.objectTypes = OBJECT_TYPE;

    ctrl.showHistory = function() {
      const objType = ctrl.selectFrom === 'LAST_VIEWED' ? _.get(ctrl, ['lastViewedObject', 'objType', 'searchObject']) || _.get(ctrl, ['lastViewedObject', 'objType', 'type']) : _.get(ctrl, ['objectType', 'searchObject']) || _.get(ctrl, ['objectType', 'type']);
      const objId = ctrl.selectFrom === 'LAST_VIEWED' ? _.get(ctrl, ['lastViewedObject', 'id']) : ctrl.objectId;

      if (objId && objType) {
        ctrl.loaded = false;
        ctrl.masquerade = false;
        versionHistoryResource.get(objId, objType).then(function(data) {
          let prevVersion = {};
          ctrl.versions = _.reverse(_.map(_.reverse(data.results), function(result) {
            let currentVersion;
            try {
              currentVersion = JSON.parse(result.json);
            } catch {
              prevVersion = {};
              return {
                user: result.user || 'undefined',
                timestamp: result.timestamp,
                diff: '<p>undefined</p>',
                json: '<p>undefined</p>'
              };
            }

            if (_.isEmpty(prevVersion) && result.previousJson) {
              prevVersion = JSON.parse(result.previousJson);
            }
            let diff = ObjectDiff.diff(prevVersion, currentVersion);
            let data = {
              user: result.user,
              masqueraderUser: result.masqueraderUser,
              timestamp: result.timestamp,
              diff: ObjectDiff.toJsonDiffView(diff),
              json: ObjectDiff.toJsonView(diff),
              diffRaw: _.filter(_.map(_.get(diff, 'value'), function(val, key) {
                if (_.isPlainObject(val)) {
                  val.field = key;
                }
                return val;
              }), function(val) {
                return _.get(val, 'changed') !== 'equal';
              }),
              jsonRaw: currentVersion
            };

            if (!ctrl.masquerade && result.masqueraderUser) {
              ctrl.masquerade = true;
            }

            prevVersion = currentVersion;
            return data;
          }));

          ctrl.loaded = true;
          ctrl.totalCount = data.totalCount;
        });
      }
    };
    ctrl.showHistory();
  });

export default MODULE_NAME;