/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import standardFormModule from '../../common/standard-form-module';
import refDataDirective from './reference-data-upload-directive';

const MODULE_NAME = 'reference-data-controller';

angular.module(MODULE_NAME, [standardFormModule, refDataDirective])

  .controller('ReferenceDataCtrl', function($timeout, categorySearchResource, keyValueSearchResource) {
    const ctrl = this;

    ctrl.resolved = true;
    ctrl.search = function(newSearch) {
      ctrl.searchMeta.resolved = false;

      if (newSearch) {
        ctrl.searchMeta.currentPage = 1;
      }

      categorySearchResource.query({
        q: ctrl.search.term || '',
        pageSize: ctrl.searchMeta.pageSize,
        page: ctrl.searchMeta.currentPage - 1,
        origin: 'UPLOAD'
      }).then(function(page) {
        ctrl.list = page;
        ctrl.searchMeta.resolved = true;
      });
    };
    ctrl.searchMeta = {
      pageSize: 50,
      currentPage: 1,
      changePage: function() {
        ctrl.search();
      },
      resolved: true
    };
    ctrl.search();
    ctrl.searchHook = function() {
      let searchTimeout = function() {
        ctrl.search(true);
      };
      $timeout(searchTimeout, 1000);
    };

    ctrl.kvResolved = true;
    ctrl.kvSearch = function(newSearch) {
      ctrl.kvSearchMeta.resolved = false;

      if (newSearch) {
        ctrl.kvSearchMeta.currentPage = 1;
      }

      keyValueSearchResource.query({
        q: ctrl.kvSearch.term || '',
        pageSize: ctrl.kvSearchMeta.pageSize,
        page: ctrl.kvSearchMeta.currentPage - 1
      }).then(function(page) {
        ctrl.kvList = page;
        ctrl.kvSearchMeta.resolved = true;
      });
    };
    ctrl.kvSearchMeta = {
      pageSize: 50,
      currentPage: 1,
      changePage: function() {
        ctrl.kvSearch();
      },
      resolved: true
    };
    ctrl.kvSearch();
    ctrl.kvSearchHook = function() {
      let searchKvTimeout = function() {
        ctrl.kvSearch(true);
      };
      $timeout(searchKvTimeout, 500);
    };
  });

export default MODULE_NAME;