/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import template from './external-iframe.html';

const MODULE_NAME = "external-iframe-directive";

angular.module(MODULE_NAME, [])

  .directive('adnWidgetIframe', function($sce) {
    return {
      restrict: 'A',
      scope: {},
      template: template,
      bindToController: {
        widgetType: '<adnWidgetIframe'
      },
      controllerAs: 'widget',
      controller: function() {
        const ctrl = this;

        ctrl.$onInit = function() {
          ctrl.url = ctrl.widgetType.url;
          ctrl.hasHttps = _.startsWith(ctrl.url, 'https');
          ctrl.height = ctrl.widgetType.height;

          ctrl.trustSrc = function(url) {
            return $sce.trustAsResourceUrl(url);
          };
        };
      }
    };
  });

export default MODULE_NAME;