/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import {HttpHelper} from '../../../util/http-helper';

const MODULE_NAME = "network-profile";

angular.module(MODULE_NAME, [])

  .factory('NetworkProfile', function(adnResource, $http) {
    return adnResource('networkprofiles', {}, HttpHelper.obtain($http).transformJsonObjActions('profile'), 'networkProfile');
  });

export default MODULE_NAME;
