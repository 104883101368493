/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardAnalysisModule from '../../common/standard-analysis-module';

const MODULE_NAME = "role-analysis";

angular.module(MODULE_NAME, [standardAnalysisModule])

  .controller('RoleAnalysisCtrl', function($stateParams, LocalNetworkProfile, Team, model, User) {
    const ctrl = this;
    ctrl.query = {};

    if (!$stateParams.isNew) {

      ctrl.query = Team.query({includeHidden: true, includeInactive: true}, function(page) {
        let sgs = page.results;

        ctrl.query = User.query(function(page) {
          let allUsers = page.results;
          ctrl.users = _.filter(allUsers, function(u) {
            let rolesByNetwork = _.find(u.userRoles.rolesByNetwork, ['network.tagId', LocalNetworkProfile.getNetworkId()]);
            if (!rolesByNetwork) {
              return false;
            }
            let matchingRoles = _.filter(rolesByNetwork.roles, ['role.id', model.id]);
            if (matchingRoles.length > 0) {
              u.teams = _.map(matchingRoles, function(sg) {
                if (!sg.team) {
                  return {name: 'Current network'};
                }
                return _.find(sgs, ['id', sg.team.id]);
              });
            }
            return u.teams;
          });
          _.forEach(ctrl.users, function(item) {
            item.obfuscated = item.username.indexOf("@") < 0 && item.username.indexOf("*") > 0;
          });
        });
      });
    } else {
      ctrl.query.$resolved = true;
    }
  });

export default MODULE_NAME;