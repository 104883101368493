/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './layout-include-edit.html';
import copyTemplate from '../../common/copy-actions.html';
import listTemplate from './layout-include-list.html';

import adminModule from '../admin-module';
import layoutIncludeController from './layout-include-controller';
import layoutIncludeActionsController from './layout-include-actions-controller';
import { PERMISSIONS } from "../../../components/auth/auth-constants";

const MODULE_NAME = 'layout-include-module';

angular.module(MODULE_NAME, [adminModule, layoutIncludeController, layoutIncludeActionsController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterAndChildHelper('layout-includes.layout-include', 'LayoutInclude', 'Layout', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        readOnly: function(LocalUserPermissions) {
          return !LocalUserPermissions.hasNetwork(PERMISSIONS.manageLayouts.apiKey);
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'LayoutIncludeCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: copyTemplate,
            controller: 'LayoutIncludeActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'LayoutInclude'));
  });

export default MODULE_NAME;