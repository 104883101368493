/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import template from './marketplace-product-copy-modal.html';

import standardActionsModule from '../../common/standard-actions-module';
import { actionsHelper } from "../../common/controller/actions-helper";
import {allowedDims} from "./marketplace-product-controller";

const MODULE_NAME = "marketplace-product-actions-controller";

angular.module(MODULE_NAME, [
  standardActionsModule
])
  .controller('MarketplaceProductActionsCtrl', function($q, toastr, $state, $stateParams, $uibModal, model, modelComms, readOnly) {
    const ctrl = this;

    actionsHelper(ctrl, model, modelComms, readOnly, $stateParams, $uibModal, $q, toastr, $state, template, {
      url: 'app.marketplace-products.marketplace-product',
      lcase: 'marketplace product',
      ucase: 'Marketplace Product',
      preCopy: function(copyModel) {
        copyModel.allowedDimensions = allowedDims(copyModel);
      }
    });
  });

export default MODULE_NAME;