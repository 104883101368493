/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "ad-unit-tag";

angular.module(MODULE_NAME, [])

  .factory("AdUnitTag", function(adnResource) {
    return adnResource('adunittags', {}, {}, "adUnitTag");
  });

export default MODULE_NAME;