/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "role";

angular.module(MODULE_NAME, [])

  .factory('Role', function(adnResource) {
    return adnResource('roles');
  });

export default MODULE_NAME;