/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

const MODULE_NAME = 'money-spacer-directive';

angular.module(MODULE_NAME, [])

  .directive('adnMoneySpacer', function($filter, LocalNetworkProfile, $timeout) {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        currencyOrDec: '<adnMoneySpacer'
      },
      link: function(scope, elem, attrs, ctrl) {
        if (!ctrl) return;

        const decPlaces = _.isFinite(scope.currencyOrDec) ? scope.currencyOrDec : null;
        const curr = _.isString(scope.currencyOrDec) ? scope.currencyOrDec : LocalNetworkProfile.get('defaultCurrency');

        function formatView() {
          const val = elem.val();
          if (!val.length) {
            return;
          }
          const value = $filter('isoCurrency')(elem.val(), curr, decPlaces, true, true);
          if (value) {
            // only show the formatted value if it produces something
            elem.val(value);
          }
        }

        elem.bind('keydown', function(event) {
          if (event.ctrlKey || event.altKey || event.metaKey || typeof event.key !== 'string' || event.key.length !== 1) {
            return;
          }

          if (!_.isFinite(parseInt(event.key, 10)) && (decPlaces === 0 || event.key !== ".")) {
            event.preventDefault();
          }
        });

        elem.bind('focus', function() {
          elem.val(ctrl.$modelValue);
        });

        elem.bind('blur', function() {
          formatView();
        });

        $timeout(function() {
          formatView();
        }, 50);
      }
    };
  });

export default MODULE_NAME;