/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "trigger";

angular.module(MODULE_NAME, [])

  .factory('Trigger', function(adnResource) {
    return adnResource('triggers', {}, {});
  });

export default MODULE_NAME;