/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from "angular";
import localUserPermissions from '../../../components/session/local-user-permissions';

const MODULE_NAME = 'file-size-component';

angular.module(MODULE_NAME, [localUserPermissions])

  .component('adnFileSize', {
    bindings: {
      size: '<',
    },
    controllerAs: 'ctrl',
    controller: function() {
      const ctrl = this;
      ctrl.$onInit = function() {
        ctrl.denominator = 1024;
        ctrl.unit = "kb";
        ctrl.number = 0;

        if (ctrl.size > 1000000) {
          ctrl.denominator = 1048576;
          ctrl.unit = "MB";
          ctrl.number = 1;
        }
      };
    },
    template: `{{ctrl.size / ctrl.denominator | number : ctrl.number }}{{ctrl.unit}}`
  });

export default MODULE_NAME;
