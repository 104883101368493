/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import ngFileUpload from 'ng-file-upload';
import _ from 'lodash';
import moment from 'moment';
import {ApiConfig} from "../api/api";

const MODULE_NAME = "release-notes-helper";

angular.module(MODULE_NAME, [
  ngFileUpload
])

  .factory('releaseNotesHelper', function($http) {
    return {
      getFromId: function(id) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['releasenotes', id]), {
          params: _.assign({}, {format: 'json', concise: true})
        }).then(_.iteratee('data'));
      },
      getFromUrlSlug: function(urlSlug) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['releasenotes', urlSlug]), {
          params: _.assign({}, {format: 'json', concise: true, isUrlSlug: true})
        }).then(_.iteratee('data'));
      },
      getPublicFromUrlSlug: function(urlSlug) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['publicreleasenotes', urlSlug]), {
          params: _.assign({}, {format: 'json', concise: true})
        }).then(_.iteratee('data'));
      },
      queryPublic: function(apps, since) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['publicreleasenotes']), {
          params: _.assign({}, {format: 'json', apps: _.isArray(apps) ? apps : null, since: _.isFinite(since) ? moment.unix(since).toDate() : null})
        }).then(_.iteratee('data'));
      },
      query: function(apps, includeAll, state, since) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['releasenotes']), {
          params: _.assign({}, {format: 'json', apps: _.isArray(apps) ? apps : null, includeAll: !!includeAll, state: state ? state : 'ACTIVE', since: _.isFinite(since) ? moment.unix(since).toDate() : null})
        }).then(_.iteratee('data'));
      },
      post: function(note) {
        return $http.post(ApiConfig.obtain().getVersionedUri(['releasenotes', note.id]), note).then(_.iteratee('data'));
      }
    };
  });

export default MODULE_NAME;