/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';
import {doLevelMatch} from "../targeting/semantic-targeting-directive";

const MODULE_NAME = 'semantic-target-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnTargetingSemanticSummary', function() {
    return {
      restrict: 'A',
      template: '<div ng-repeat="t in target"><strong ng-if="t.negated">NOT </strong>{{t.sentence.sentence}}, {{t.managedScore.key}}</div>',
      scope: {
        targets: '=adnTargetingSemanticSummary'
      },
      link: function(scope) {
        scope.$watchCollection('targets', function(targets) {
          scope.target = _.cloneDeep(targets);

          _.forEach(scope.target, function(s) {
            if (s.score) {
              s.managedScore = doLevelMatch(s.score);
            }
          });
        });
      }
    };
  });

export default MODULE_NAME;