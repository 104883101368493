/**
 * Copyright © 2025 Adnuntius AS.
 */
import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import {LineItemSmoothingLabelComponent} from "./line-item/smoothing-label-component";
import {LineItemDiagnosticLabelComponent} from "./line-item/diagnostic-label-component";
import {FirstPartyAudiencePublishStateLabelComponent} from "./first-party-audience/publish-state-component";
import {LineItemStatusLabelComponent} from "./line-item/status-component";
import {LineItemStatusExplanationLabelComponent} from "./line-item/status-explanation-component";
import {EnvBannerComponent} from "./common/directives/banners/env-banner-component";
import {CreativeUserStatesComponent} from "./common/directives/creative-user-states";
import {UndoDeleteComponent} from "./common/directives/html/undo-delete-component";
import {CountrySelectComponent} from "./common/directives/country-select/country-select";
import {ReportStatusComponent} from "./report/report-status-component";
import {FormSubmissionMessage} from "./common/directives/form-controls/form-submission-message";
import {InfoIconComponent} from "./common/directives/html/info-component";
import {ValidationInfoComponent} from "./line-item/validation-info-component";
import {LineItemExecutionLabelComponent} from "./line-item/execution-component";
import {AdStatusLabelComponent} from "./ad-unit/ad-status-component";
import {AdvertiserStatusLabelComponent} from "./advertiser/advertiser-status-component";
import {KeywordFrequencyComponent} from "./targeting/targeting/keyword-frequency-component";
import {CopyrightStatusComponent} from "./line-item/copyright-status-component";
import {CreativeStatusLabelComponent} from "./line-item/creative-status-component";
import {PerfTotalComponent} from "./common/directives/perf-total-component";
import {AdTagComponent} from "./ad-unit/ad-tag-component";
import {MessageDismisserComponent} from "./common/directives/form-controls/message-dismisser.component";
import {AssetRenderComponent} from "./common/directives/html/asset-render-component";
import {ValidationWarningsComponent} from "./common/directives/error/validation-warnings-component";
import {SimpleValidationMessagesComponent} from "./common/directives/error/simple-validation-messages-component";
import {LayoutComponentSummaryComponent} from "./common/layout-component-summary-component";
import {SimpleErrorMessagesComponent} from "./common/directives/error/simple-error-messages-component";
import { CouponValidityComponent } from "./admin/coupon/coupon-validity-component";
import { CouponStatusComponent } from "./admin/coupon/coupon-status-component";
import { LineItemSourceLabelComponent } from "./line-item/line-item-source-component";
import { PaymentStatusLabelComponent } from "./payment/payment-status-component";
import {TranscodeConfigComponent} from "./common/directives/transcode-config-component";
import {AssetTranscodeComponent} from "./common/directives/html/asset-transcode-component";

export const DowngradedComponentsModule = angular
  .module('downgraded-components-module', [])
  .directive('lineItemSmoothingLabel', downgradeComponent({component: LineItemSmoothingLabelComponent}) as angular.IDirectiveFactory)
  .directive('lineItemDiagnosticLabel', downgradeComponent({component: LineItemDiagnosticLabelComponent}) as angular.IDirectiveFactory)
  .directive('couponValidityComponent', downgradeComponent({component: CouponValidityComponent}) as angular.IDirectiveFactory)
  .directive('couponStatusComponent', downgradeComponent({component: CouponStatusComponent}) as angular.IDirectiveFactory)
  .directive('firstPartyAudiencePublishStateLabel', downgradeComponent({component: FirstPartyAudiencePublishStateLabelComponent}) as angular.IDirectiveFactory)
  .directive('lineItemStatusLabel', downgradeComponent({component: LineItemStatusLabelComponent}) as angular.IDirectiveFactory)
  .directive('lineItemStatusExplanationLabel', downgradeComponent({component: LineItemStatusExplanationLabelComponent}) as angular.IDirectiveFactory)
  .directive('paymentStatusLabel', downgradeComponent({component: PaymentStatusLabelComponent}) as angular.IDirectiveFactory)
  .directive('lineItemSourceLabel', downgradeComponent({component: LineItemSourceLabelComponent}) as angular.IDirectiveFactory)
  .directive('lineItemExecutionLabel', downgradeComponent({component: LineItemExecutionLabelComponent}) as angular.IDirectiveFactory)
  .directive('adStatusLabel', downgradeComponent({component: AdStatusLabelComponent}) as angular.IDirectiveFactory)
  .directive('advertiserStatusLabel', downgradeComponent({component: AdvertiserStatusLabelComponent}) as angular.IDirectiveFactory)
  .directive('keywordFrequencyComponent', downgradeComponent({component: KeywordFrequencyComponent}) as angular.IDirectiveFactory)
  .directive('copyrightStatusComponent', downgradeComponent({component: CopyrightStatusComponent}) as angular.IDirectiveFactory)
  .directive('creativeStatusLabel', downgradeComponent({component: CreativeStatusLabelComponent}) as angular.IDirectiveFactory)
  .directive('layoutComponentSummary', downgradeComponent({component: LayoutComponentSummaryComponent}) as angular.IDirectiveFactory)
  .directive('envBanner', downgradeComponent({component: EnvBannerComponent}) as angular.IDirectiveFactory)
  .directive('creativeUserStates', downgradeComponent({component: CreativeUserStatesComponent}) as angular.IDirectiveFactory)
  .directive('formSubmissionMessage', downgradeComponent({component: FormSubmissionMessage}) as angular.IDirectiveFactory)
  .directive('undoDelete', downgradeComponent({component: UndoDeleteComponent}) as angular.IDirectiveFactory)
  .directive('countrySelect', downgradeComponent({component: CountrySelectComponent}) as angular.IDirectiveFactory)
  .directive('infoIcon', downgradeComponent({component: InfoIconComponent}) as angular.IDirectiveFactory)
  .directive('reportStatusLabel', downgradeComponent({component: ReportStatusComponent}) as angular.IDirectiveFactory)
  .directive('validationInfo', downgradeComponent({component: ValidationInfoComponent}) as angular.IDirectiveFactory)
  .directive('performanceTotal', downgradeComponent({component: PerfTotalComponent}) as angular.IDirectiveFactory)
  .directive('adTag', downgradeComponent({component: AdTagComponent}) as angular.IDirectiveFactory)
  .directive('messageDismisser', downgradeComponent({component: MessageDismisserComponent}) as angular.IDirectiveFactory)
  .directive('assetRender', downgradeComponent({component: AssetRenderComponent}) as angular.IDirectiveFactory)
  .directive('validationWarnings', downgradeComponent({component: ValidationWarningsComponent}) as angular.IDirectiveFactory)
  .directive('simpleValidationMessages', downgradeComponent({component: SimpleValidationMessagesComponent}) as angular.IDirectiveFactory)
  .directive('simpleErrorMessages', downgradeComponent({component: SimpleErrorMessagesComponent}) as angular.IDirectiveFactory)
  .directive('transcodeConfig', downgradeComponent({component: TranscodeConfigComponent}) as angular.IDirectiveFactory)
  .directive('assetTranscode', downgradeComponent({component: AssetTranscodeComponent}) as angular.IDirectiveFactory)
  .name;
