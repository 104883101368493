/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

const MODULE_NAME = 'form-control-hookable-directive';

angular.module(MODULE_NAME, [])

  .directive('adnFormControlHookable', function() {
    return {
      restrict: 'A',
      scope: {
        eventHook: '<'
      },
      require: ['ngModel', '^^adnFormControlWrapper', '?^^adnFormControlValidation'],
      link: function(scope, element, attrs, controllers) {
        let ngModel = controllers[0],
          formControlWrapper = controllers[1],
          formControlValidation = controllers[2];

        if (_.isObject(scope.eventHook)) {
          scope.eventHook.removeControlHook = function(name) {
            formControlWrapper.removeControl(name);
          };
        }

        formControlWrapper.addControl(ngModel);
        if (formControlValidation) {
          formControlValidation.setFormControl(ngModel);
        }
      }
    };
  });

export default MODULE_NAME;