/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "report-translation";

angular.module(MODULE_NAME, [])

  .factory('ReportTranslation', function(adnResource) {
    return adnResource('reporttranslations', {}, {}, 'reportTranslation');
  });

export default MODULE_NAME;