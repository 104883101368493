/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import {ASSET_ERROR} from "../resources-constants";
import {ApiConfig} from "../../api";

const MODULE_NAME = "context-service-configuration";

angular.module(MODULE_NAME, [])

  .factory("ContextServiceConfiguration", function(adnResource) {
    return adnResource('contextserviceconfigurations', {}, {}, "contextServiceConfiguration");
  })

  .factory("ContextServiceKeywordUpload", function($q, Upload) {
    return {
      upload: function(data, uploadType, resourceId) {
        if (!data || !(data.file || data.content)) {
          return $q.reject(ASSET_ERROR.NO_FILE);
        }
        return Upload.upload({
          url: ApiConfig.obtain().getVersionedUri(["contextserviceconfigurations", resourceId, "keywords"]),
          data: data
        }).then(function(response) {
          return response;
        }).catch(function(err) {
          return $q.reject(err);
        });
      }
    };
  });


export default MODULE_NAME;
