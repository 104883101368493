/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';
import {OBJECT_TYPE} from "../../constants/object-type";
import {LABELS_DIRECTIVE_INIT_SUFFIX} from "../../constants/misc-constants";

import resources from '../../../../components/api/resources/resources';
import 'ng-tags-input';

const MODULE_NAME = "labels-component";

angular.module(MODULE_NAME, [
  'ngTagsInput',
  translate,
  resources
])

  .component('adnResourceLabels', {
    template: `
    <tags-input min-length="1" on-tag-clicked="ctrl.search($tag)" add-from-autocomplete-only="{{!!ctrl.autoCompleteOnly}}" ng-disabled="ctrl.blockSubmit" ng-model="ctrl.labels" placeholder="{{'label.add.' + ctrl.transKey | translate : ctrl.transParams}}" on-tag-added="ctrl.syncLabels()" on-tag-removed="ctrl.syncLabels()">
      <auto-complete debounce-delay="200" min-length="1" source="ctrl.query($query)" select-first-match="false"></auto-complete>
      <span class="help-block small">{{'label.help.' + ctrl.transKey | translate : ctrl.transParams}}</span>
      <span ng-if="ctrl.labels.length > ctrl.maxTags" class="help-block small alert alert-info">Only the first {{ctrl.maxTags}} label<span ng-if="ctrl.maxTags > 1">s</span> will be used.</span>
    </tags-input>
    `,
    bindings: {
      resource: '<',
      key: '@adnKey',
      transKey: '@adnTransKey',
      transParams: '@adnTransParams',
      labelType: '@labelType',
      autoCompleteOnly: '<adnAutoCompleteOnly',
      blockSubmit: '<ngDisabled',
      maxTags: '@maxTags'
    },
    controllerAs: 'ctrl',
    controller: function($state, searchResource) {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.key = ctrl.key || 'model.labels';
        ctrl.transKey = ctrl.transKey || _.last(ctrl.key.split("."));
        ctrl.transParams = ctrl.transParams || '{}';

        const maxTags = parseInt(ctrl.maxTags, 10);
        ctrl.maxTags = Math.min(_.isFinite(maxTags) ? maxTags : 50, 50);

        const resourceType = OBJECT_TYPE[ctrl.labelType || 'nothing'] || OBJECT_TYPE.Label,
          initLabelsFunc = ctrl.key + LABELS_DIRECTIVE_INIT_SUFFIX;

        ctrl.search = function(tag) {
          const labelInfo = {};
          labelInfo[resourceType.apiParam + 's'] = tag.text;
          $state.go('app.search', labelInfo, {inherit: false});
        };

        ctrl.resource[initLabelsFunc] = function() {
          ctrl.labels = _.map(_.get(ctrl.resource, ctrl.key), function(label) {
            return {text: label};
          });
        };
        ctrl.resource[initLabelsFunc]();

        ctrl.syncLabels = function() {
          _.set(ctrl.resource, ctrl.key, _.map(ctrl.labels, 'text'));
        };
        ctrl.query = function($query) {
          return searchResource.query($query, resourceType.type).then(function(result) {
            return _.map(result.searchResults, function(label) {
              return {text: label.name};
            });
          });
        };
      };
    }
  });

export default MODULE_NAME;