/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

import template from './keyvalue-summary.html';

const MODULE_NAME = 'key-value-targeting-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnTargetingKeyValueSummary', function() {
    return {
      restrict: 'A',
      template: template,
      scope: {
        targets: '=adnTargetingKeyValueSummary'
      },
      link: function(scope) {
        scope.data = [];
        scope.$watchCollection('targets', function(targets) {
          scope.targets = targets || {};
        });
        scope.$watchCollection('targets', function(targets) {
          scope.data = [];
          _.forEach(targets, function(entryObj) {
            let inputted = [],
              notInputted = [];
            _.forEach(entryObj.entries, function(v, k) {
              let isNot = _.startsWith(k.toUpperCase(), "NOT ");
              let nextLevelV = _.isArray(v[0]) ? v : [v];
              _.forEach(nextLevelV, function(vLevel) {
                inputted.push({k: isNot ? k.substr(4) : k, v: vLevel.join(', '), not: isNot});
              });
            });
            _.forEach(entryObj.notEntries, function(v, k) {
              let isNot = _.startsWith(k.toUpperCase(), "NOT ");
              let nextLevelV = _.isArray(v[0]) ? v : [v];
              _.forEach(nextLevelV, function(vLevel) {
                notInputted.push({k: isNot ? k.substr(4) : k, v: vLevel.join(', '), not: isNot});
              });
            });
            scope.data.push({entries: inputted, notEntries: notInputted});
          });
        });
      }
    };
  });

export default MODULE_NAME;