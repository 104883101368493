/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiRouter from '@uirouter/angularjs';
import resources from '../../../components/api/resources/resources';
import releaseNotes from "../../../components/util/release-notes-helper";
import ngSanitize from 'angular-sanitize';
import {NOTES_BUTTONS} from "../../common/directives/notes/notes-constants";
import moment from 'moment';
import 'moment-timezone';


const MODULE_NAME = "release-notes-ctrl";

angular.module(MODULE_NAME, [
  uiRouter,
  resources,
  releaseNotes,
  ngSanitize
])
  .controller('ReleaseNotesCtrl', function(model, releaseNotesHelper) {
    const ctrl = this;

    ctrl.model = model;
    ctrl.toolbarButtons = NOTES_BUTTONS;
    ctrl.showList = 'ACTIVE';

    ctrl.immediate = model.publishTime ? 'CUSTOM' : 'IMMEDIATE';
    ctrl.model.publishTime = moment(ctrl.model.publishTime).toDate();

    ctrl.ug = !_.isArray(ctrl.model.userGroups) || !ctrl.model.userGroups.length ? 'PUBLIC' : 'INTERNAL';

    ctrl.showCurrentAsset = function() {
      if (ctrl.currentAsset) {
        ctrl.currentAsset = null;
        return;
      }
      ctrl.currentAsset = {};
    };

    ctrl.setForm = function(form) {
      ctrl.form = form;
    };

    function decipherAssetStrings() {
      ctrl.model.decipheredAssets = _.map(ctrl.model.assetStrings, function (asset) {
        const assetSplit = asset.split("{}");
        const decipheredAsset = {
          cdnId: _.get(assetSplit, [0]),
          id: _.get(assetSplit, [1]),
          fileName: _.get(assetSplit, [2]),
          mimeType: _.get(assetSplit, [3]),
          fileSizeBytes: _.get(assetSplit, [4]),
          width: _.get(assetSplit, [5]),
          height: _.get(assetSplit, [6])
        };
        decipheredAsset.imgString = "<img src='" + decipheredAsset.cdnId + "' width='" + decipheredAsset.width + "' height='" + decipheredAsset.height + "'>";
        return decipheredAsset;
      });
    }
    decipherAssetStrings();

    ctrl.eventHook = {
      onUpdate: function(asset) {
        ctrl.currentAsset = null;

        const assetString = asset.cdnId + "{}" + asset.id + "{}" + asset.fileName + "{}" + asset.mimeType + "{}" + asset.fileSizeBytes + "{}" + asset.width + "{}" + asset.height;
        ctrl.model.assetStrings = ctrl.model.assetStrings || [];
        ctrl.model.assetStrings.push(assetString);
        decipherAssetStrings();
      }
    };

    ctrl.updateList = function() {
      releaseNotesHelper.query(null, true, ctrl.showList).then(function(data) {
        ctrl.model = data;
      });
    };

    ctrl.saveNote = function() {
      return releaseNotesHelper.post(ctrl.model);
    };

    ctrl.onBeforeSubmit = function() {
      if (ctrl.immediate === 'IMMEDIATE') {
        ctrl.publishTime = null;
      }
      if (ctrl.ug === 'INTERNAL') {
        ctrl.model.userGroups = ['INTERNAL'];
      }
      if (ctrl.ug === 'PUBLIC') {
        ctrl.model.userGroups = [];
      }
    };
  });



export default MODULE_NAME;