/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import isoCurrency from '../../../components/util/iso-currency';
import countryFilter from '../../../components/util/country-filter';
import { PERCENTAGE_FIELDS, RATIO_FIELDS } from "./percent-fields";
import {CURRENCIES} from "../../../components/util/iso-currencies";
import {ALL_TARGETING_TYPES} from "../../targeting/targeting-constants";

const MODULE_NAME = "standard-filters";


angular.module(MODULE_NAME, [isoCurrency, countryFilter])

  .filter('csvOutput', function() {
    return function(stat, field) {
      if (_.isDate(stat)) {
        return stat;
      }
      if (_.isNull(stat) || _.isNaN(stat) || _.isUndefined(stat)) {
        return '';
      }
      if (field && _.isObject(stat) && _.isFinite(stat[field])) {
        return (stat[field] || 0).toString();
      }
      if (_.isFinite(stat)) {
        return field && PERCENTAGE_FIELDS[field] ? stat * 100 : stat;
      }
      if (_.has(stat, 'currency')) {
        return stat.amount.toString();
      }
      return stat;
    };
  })

  .filter('httpsPrefixer', function() {
    return function(data) {
      return _.startsWith(data, "//") ? "https:" + data : data;
    };
  })

  .filter('highlight', function() {
    function escapeRegexp(queryToEscape) {
      return ('' + queryToEscape).replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    }

    return function(matchItem, query) {
      return query && matchItem ? ('' + matchItem).replace(new RegExp(escapeRegexp(query), 'gi'), '<span class="ui-select-highlight">$&</span>') : matchItem;
    };
  })

  .filter('percentage', function($filter) {
    return function(input, decimals, blockNegative) {
      const percentageNumber = input * 100;
      if (!_.isFinite(percentageNumber) || (blockNegative && percentageNumber < 0)) {
        return "-";
      }
      let number = percentageNumber.toFixed(decimals);
      if (_.endsWith(number, '0')) {
        number = parseFloat(number);
      }
      return $filter("number")(number) + "%";
    };
  })

  .filter('adnNumber', function($filter) {
    const numberFilter = $filter('number');
    return function(stat) {
      if (_.isNull(stat) || _.isNaN(stat) || _.isUndefined(stat) || (_.isObject(stat) && _.isEmpty(stat))) {
        return 0;
      }
      return numberFilter(stat);
    };
  })

  .filter('statCurrency', function($filter) {
    const currencyFilter = $filter('isoCurrency');

    return function(stat) {
      if (_.has(stat, 'currency') && _.isFinite(stat.amount)) {
        const currency = CURRENCIES[(stat.currency).toUpperCase()];
        if (!currency) {
          return currencyFilter(stat.amount, stat.currency);
        }
        let number = stat.amount.toFixed(currency.fraction);
        if (_.endsWith(number, '0')) {
          return currencyFilter(parseFloat(number), stat.currency, 0);
        }
        return currencyFilter(stat.amount, stat.currency);
      }
      return "-";
    };
  })

  .filter('targetingType', function() {
    return function(tt) {
      const matched = _.find(ALL_TARGETING_TYPES, function(val) {
        return val.apiType === tt;
      });
      return _.get(matched, ['title'], tt);
    };
  })

  .filter('adnStat', function($filter) {
    let numberFilter = $filter('number'),
      currencyFilter = $filter('isoCurrency'),
      percentageFilter = $filter('percentage');

    function outputCurrency(stat, fractionSize) {
      const currencyValue = currencyFilter(stat.amount, stat.currency, fractionSize);
      const secondaryCurrencyValue = stat.maxAmount ? (" to " + currencyFilter(stat.maxAmount, stat.currency, fractionSize, true)) : "";
      return (currencyValue || 0) + secondaryCurrencyValue;
    }

    return function(stat, field, fractionSize, objId) {
      if (_.isNull(stat) || _.isNaN(stat) || _.isUndefined(stat) || (_.isObject(stat) && _.isEmpty(stat)) || (_.has(stat, 'currency') && !_.isFinite(_.get(stat, 'amount')))) {
        return '-';
      }
      if (objId && _.isObject(stat) && _.isFinite(stat[objId])) {
        return numberFilter(stat[objId], objId === 'averageValue' ? 2 : 0);
      }
      if (_.isFinite(stat)) {
        return field && PERCENTAGE_FIELDS[field] ? percentageFilter(stat, PERCENTAGE_FIELDS[field].decPlaces) : numberFilter(stat, Number.isInteger(stat) ? 0 : (fractionSize || _.get(RATIO_FIELDS, [field, 'decPlaces'])));
      }

      if (_.isArray(stat)) {
        let output = "";
        for (let i = 0; i < stat.length; i++) {
          output += outputCurrency(stat[i], fractionSize) + (i === stat.length - 1 ? " " : ", ");
        }
        return output;
      }
      // handle non-numeric results
      if (_.has(stat, 'currency')) {
        return outputCurrency(stat, fractionSize);
      }
      return stat.toString();
    };
  });

export default MODULE_NAME;