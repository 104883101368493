/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "email-translation";

angular.module(MODULE_NAME, [])

  .factory('EmailTranslation', function(adnResource) {
    return adnResource('emailtranslations', {}, {}, 'emailTranslation');
  });

export default MODULE_NAME;