/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {MEDIA_TYPE, MediaTypeHelper} from "../../../../util/media-type-helper";
import {Uuid} from "../../../../util/uuid";

import assetHelper from '../../../../util/asset-helper';
import {ApiConfig} from "../../../api";

const MODULE_NAME = "asset";

angular.module(MODULE_NAME, [
  assetHelper
])

  .factory('Asset', function(adnAssetHelper, $http) {
    return {
      helpfulBulkUpload: function(parentId, data) {
        // parentId gets ignored, but want to keep the same interface as other end points.
        return adnAssetHelper.helpfulUpload(['assets'], parentId, data);
      },
      bulkUpload: function(parentId, data) {
        // parentId gets ignored, but want to keep the same interface as other end points.
        return adnAssetHelper.upload(['asset'], null, data, Uuid.generate());
      },
      bulkThirdPartyUpload: function(parentId, data) {
        // parentId gets ignored, but want to keep the same interface as other end points.
        return adnAssetHelper.upload(['bulkcreatives', 'thirdparty'], null, data);
      },
      layoutSuggestionsFromCreative: function(id, creativeIds) {
        return adnAssetHelper.fromParentAsJson('assets', id, _.merge(_.isArray(creativeIds) ? {id: creativeIds.join(';'), pageSize: creativeIds.length} : {}, {responseType: 'LAYOUT_SUGGESTIONS'}));
      },
      fromCreative: function(id, creativeIds) {
        return adnAssetHelper.fromParentAsJson('assets', id, _.isArray(creativeIds) ? {id: creativeIds.join(';'), pageSize: creativeIds.length} : {});
      },
      update: function(parentId, asset, assetId) {
        const uri = ApiConfig.obtain().getVersionedUri(['assets', parentId, assetId || asset.id]);
        return $http.post(uri, _.omit(asset, 'id')).then(_.iteratee('data'));
      },
      bulkSave: function(parentId, assets) {
        const uri = parentId ? ApiConfig.obtain().getVersionedUri(['assets', parentId]) : ApiConfig.obtain().getVersionedUri(['asset']);
        return $http.post(uri, assets).then(_.iteratee('data'));
      },
      upload: function(creativeId, data, assetId) {
        const isZip = MediaTypeHelper.isMediaType(data.file.type, MEDIA_TYPE.zip);
        const path = data.file && isZip ? 'zippedassets' : 'assets';
        return adnAssetHelper.upload(path, creativeId, data, isZip ? undefined : assetId || Uuid.generate());
      }
    };
  });

export default MODULE_NAME;