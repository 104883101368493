/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

const MODULE_NAME = 'adunit-matching-label-target-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnAdUnitMatchingLabelTargetSummary', function() {
    return {
      restrict: 'A',
      template: '{{data}}',
      scope: {
        targets: '=adnAdUnitMatchingLabelTargetSummary'
      },
      link: function(scope) {
        scope.$watchCollection('targets', function(targets) {
          scope.target = targets || {};
        });
        scope.$watchCollection('target', function(target) {
          let labels = _.map(target, function(t) {
            return t.matchingLabels[0];
          });
          labels = _.sortBy(labels);
          scope.data = labels.join(', ');
        });
      }
    };
  });

export default MODULE_NAME;