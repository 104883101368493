/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {IAB_TAXONOMY} from "../../components/util/iab-taxonomy";

import iabSearchSelect from '../common/controller/iab-multi-select-component';
import standardFormModule from '../common/standard-form-module';
import {adnFormHelper} from "../common/controller/form-helper";

const MODULE_NAME = 'blocklist-controller';

angular.module(MODULE_NAME, [standardFormModule, iabSearchSelect])

  .controller('BlocklistCtrl', function($stateParams, adnListHelper, EarningsAccount, Site, AdUnit, LocalUserPermissions, Team, model, modelComms, searchResource) {
    const ctrl = this;

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms, afterInitAfterSave: function() {
        ctrl.model.team = ctrl.model.team || undefined;
        ctrl.model.iabCategoryAction = ctrl.model.iabCategoryAction || 'BLOCKED';
      }
    });

    ctrl.isNew = $stateParams.isNew;
    ctrl.isAxPublisherAdmin = LocalUserPermissions.hasNetworkPermission('AX_PUBLISHER_ADMIN');

    ctrl.iabTaxonomy = angular.copy(IAB_TAXONOMY);

    ctrl.removeFloorPrices = function(index) {
      _.pullAt(ctrl.model.advertiserFloorPrices, index);
      ctrl.editsMade();
    };
    ctrl.addFloorPrices = function() {
      ctrl.model.advertiserFloorPrices = ctrl.model.advertiserFloorPrices || [];
      ctrl.model.advertiserFloorPrices.push({});
      ctrl.editsMade();
    };

    ctrl.advertiserSearch = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "Advertiser"}).then(function(data) {
        ctrl.advertiserSearchResults = data.searchResults;
      });
    };

    ctrl.lineItemSearch = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "LineItem"}).then(function(data) {
        ctrl.lineItemSearchResults = data.searchResults;
      });
    };

    ctrl.creativeSearch = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "Creative"}).then(function(data) {
        ctrl.creativeSearchResults = data.searchResults;
      });
    };

    Team.query({minimal: true}).$promise.then(function(page) {
      ctrl.allTeams = _.filter(page.results, function (team) {
        return team.type === 'STANDARD' || team.type === 'AX_PUBLISHER';
      });
    });

    ctrl.publishers = {};
    adnListHelper.setUpBasicList(ctrl.publishers, EarningsAccount, 'fullPublishersForBlocklist', {moreParams: {blocklist: model.id}, baseTable: 'earningsAccount'});

    ctrl.sites = {};
    adnListHelper.setUpBasicList(ctrl.sites, Site, 'fullSitesForBlocklist', {moreParams: {blocklist: model.id}, baseTable: 'site'});

    ctrl.adUnits = {};
    adnListHelper.setUpBasicList(ctrl.adUnits, AdUnit, 'fullAdUnitsForBlocklist', {moreParams: {blocklist: model.id}, baseTable: 'adUnit'});

    ctrl.canUpdateBlocklist = LocalUserPermissions.hasAny('AX_PUBLISHER') || LocalUserPermissions.hasAny('MANAGE_PUBLISHING');

    ctrl.editsHook = {
      editsMade: function() {
        ctrl.editsMade();
      }
    };

    ctrl.onBeforeSubmit = function() {
      ctrl.model.advertiserDomains = _.map(ctrl.model.advertiserDomains, function(domain) {
        return _.get(domain, ['text'], domain);
      });
      ctrl.model.iabCategories = _.map(ctrl.model.iabCategories, function(iab) {
        return _.get(iab, ['id'], iab);
      });

      if (ctrl.showSingular) {
        ctrl.model.teams = ctrl.singularTeam ? [ctrl.singularTeam] : [];
      }
    };
  });

export default MODULE_NAME;