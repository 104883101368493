/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';

import template from './publisher-targeting.html';

import resources from '../../../components/api/resources/resources';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import searchPagination from '../../common/directives/pagination/search-pagination-component';
import {ADN_TARGETING_TYPES} from "../targeting-constants";
import {findCountryName, getCountryName} from "../../common/directives/country-select/countries";
import {LocalNetworkInfo} from "../../../components/session/local-network-info";

const MODULE_NAME = 'publisher-targeting-directive';

angular.module(MODULE_NAME, [translate, uiBootstrap, resources, promiseSpinner, searchPagination])

  .directive('adnPublisherTargeting', function(EarningsAccount, searchResource) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnPublisherTargeting',
        underModel: '=',
        isLockedDown: '<',
        modelLineItem: '<',
        runningModel: '<',
        mandatoryTargets: '<',
        overridableTargeting: '<',
        mandatory: '<',
        mode: '@'
      },
      link: {
        pre: function(scope) {
          scope.model = scope.model || {};
          scope.model.earningsAccounts = scope.model.earningsAccounts || [];
          scope.model.negated = scope.model.negated || false;
          scope.targetType = ADN_TARGETING_TYPES.publisher;
        },
        post: function(scope, element, attrs, tabset) {
          scope.loaded = true;

          scope.select = function(publisher) {
            publisher.selected = true;
            scope.model.earningsAccounts.push(publisher);
          };

          scope.deselect = function(publisher) {
            _.remove(scope.model.earningsAccounts, ['id', publisher.id]);
            if (scope.list && scope.list.searchResults) {
              (_.find(scope.list.searchResults, ["id", publisher.id]) || {}).selected = false;
            }
          };

          const updateSelectedSearchResults = function() {
            let selIds = _.keyBy(scope.model.earningsAccounts, 'id');
            _.forEach(_.get(scope.list, ['searchResults'], []), function(a) {
              a.selected = !!selIds[a.id];
            });
          };

          const countriesSelected = scope.runningModel[ADN_TARGETING_TYPES.siteCountry.targets][ADN_TARGETING_TYPES.siteCountry.apiProp];
          if (_.isArray(countriesSelected) && countriesSelected.length === 1) {
            scope.filter = scope.filter || {};
            scope.filter.country = countriesSelected[0].toLowerCase();
          }

          scope.search = function(newSearch, filterSearch) {
            const metaResolvedParam = filterSearch ? "filterResolved" : "resolved";
            scope.searchMeta[metaResolvedParam] = false;

            if (!filterSearch) {
              scope.filter = {
                country: _.get(scope, ['filter', 'country']) || null
              };
            }

            if (newSearch) {
              scope.searchMeta.currentPage = 1;
            }

            const searchParams = {q: scope.search.term || '', types: 'EarningsAccount', objectState: 'ACTIVE', pageSize: scope.searchMeta.pageSize, page: scope.searchMeta.currentPage - 1};
            if (scope.filter.country) {
              searchParams.country = scope.filter.country;
            }
            searchResource.query(searchParams).then(function(page) {
              page.searchResults = _.map(page.searchResults || [], function(item) {
                item.countriesExpanded = _.map(item.country || [], function(country) {
                  return getCountryName(country);
                }).join(", ");
                return item;
              });
              scope.list = page;

              updateSelectedSearchResults();

              const countryBuckets = _.get(page, ['aggregations', 'country', 'buckets']);
              const isMandatory = scope.mandatoryTargets.indexOf(ADN_TARGETING_TYPES.siteCountry.id) > -1;
              scope.hasCountries = LocalNetworkInfo.obtain().getInfo("siteCountries").length > 1 && !isMandatory && (scope.filter.country || (countryBuckets && !_.isEmpty(countryBuckets)));
              scope.hasCountriesTable = LocalNetworkInfo.obtain().getInfo("siteCountries").length > 1 && (scope.filter.country || (countryBuckets && !_.isEmpty(countryBuckets)));
              if (scope.hasCountries) {
                _.forEach(countryBuckets, function(country) {
                  country.name = findCountryName(country.name);
                });
              }

              scope.searchMeta[metaResolvedParam] = true;
            });
          };

          scope.searchMeta = {
            pageSize: 50,
            currentPage: 1,
            changePage: function() {
              scope.search(false, true);
            },
            filterResolved: true,
            resolved: true
          };

          scope.hook = {};
          scope.hook.getTemplateData = function() {
            var summary = (scope.model.negated === true ? "NOT: " : "") + _.map(scope.model.earningsAccounts, function(item) {
              return item.name;
            }).join(", ");

            return {
              vm: angular.copy(scope.model),
              model: angular.copy(scope.model),
              constant: ADN_TARGETING_TYPES.publisher,
              summary: summary
            };
          };

          const copyTemplateFunc = function(template) {
            scope.model = angular.copy(template.data.model);
            updateSelectedSearchResults();
          };
          scope.hook.copyTemplate = copyTemplateFunc;

          tabset.register(ADN_TARGETING_TYPES.publisher.widget, function() {
            if (scope.query) {
              return;
            }
            scope.search();
            if (!scope.model.earningsAccounts.length) {
              return;
            }
            const ids = _.map(scope.model.earningsAccounts, 'id');
            scope.query = EarningsAccount.query({ids: ids, pageSize: scope.model.earningsAccounts.length, includeActive: true, includeInactive: true, includeHidden: true}, function(page) {
              const keyedResults = _.keyBy(page.results, 'id');
              _.forEach(scope.model.earningsAccounts, function(sel) {
                const fullPublisherDetail = keyedResults[sel.id];
                if (fullPublisherDetail) {
                  _.assign(sel, _.pick(fullPublisherDetail, ['description']));
                  sel.countriesExpanded = _.map(fullPublisherDetail.countries, function(c) {
                    return getCountryName(c);
                  }).join(", ");
                }
              });
            });
          }, copyTemplateFunc);
        }
      }
    };
  });

export default MODULE_NAME;