/*
 * Copyright © 2025 Adnuntius AS.
 */
import * as _ from 'lodash';

const networkWhiteLabelDomains = {
  'e2080': 'digitalfuel.one', // Digital Fuel
  'df930': 'nebenan.de',   // Nebenan
  '1f8ac2': 'adzero.io',     // Carbon Trading Desk
  'network_2': 'test.com',
  '224993': 'well.dk',    // Firtal
};

export class AdTagReplacers {
  static getNetworkWhitelabelDomain(network: string): string {
    return _.get(networkWhiteLabelDomains, network) || '';
  }

  static addNetworkTagId(adTag: string, networkTagId: string) {
    let workingAdTag = adTag;
    if (networkTagId) {
      if (workingAdTag.indexOf("adUnits:") > -1) {
        workingAdTag = workingAdTag.replace("adUnits:", "network: '" + networkTagId + "', adUnits:");
      } else {
        workingAdTag = workingAdTag.replace("auId:", "network: '" + networkTagId + "', auId:");
      }
    }
    return workingAdTag;
  }

  static replace(adTag: string, whitelabelDomain?: string, networkTagId?: string): string {
    let workingAdTag = adTag;
    workingAdTag = this.addNetworkTagId(workingAdTag, networkTagId);
    if (whitelabelDomain && adTag) {
      if (workingAdTag.includes("adUnits:")) {
        return workingAdTag.replace("adUnits:", "dn: " + "'" + whitelabelDomain + "', adUnits:")
          .replace(/cdn.adnuntius.com/g, "adn." + whitelabelDomain)
          .replace(/adnuntius.com/g, whitelabelDomain);
      } else {
        return workingAdTag.replace("env: 'production'", "env: 'production', dn: " + "'" + whitelabelDomain + "'")
          .replace(/cdn.adnuntius.com/g, "adn." + whitelabelDomain)
          .replace(/adnuntius.com/g, whitelabelDomain);
      }
    }
    return workingAdTag;
  }
}
