/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {HttpHelper} from '../../../util/http-helper';
import team from './team';
import {ApiConfig} from "../../api";

const MODULE_NAME = "user";

angular.module(MODULE_NAME, [team])

  .factory('LoginStats', function($http) {
    return {
      getForUser: function(userId) {
        const uriParts = ['loginstats'];
        if (userId) {
          uriParts.push(userId);
        }
        return $http.get(ApiConfig.obtain().getVersionedUri(uriParts), {
          params: _.assign({}, {format: 'json'})
        }).then(_.iteratee('data'));
      }
    };
  })

  .factory("User", function($http, $q, adnResource, Team) {
    let defaultActions = {
      save: {
        transformRequest: HttpHelper.obtain($http).prependRequestTransform(function(data) {
          // if no password is set (i.e. update user), then don't send through the password at all.
          if (_.has(data, 'password') && _.trim(data.password).length === 0) {
            return _.omit(data, 'password');
          }
          return data;
        })
      }
    };
    let User = adnResource('users', null, defaultActions);

    User.create = function(defaults) {
      return new User(_.assign({
        networkGroups: []
      }, defaults));
    };

    User.getDataView = function(params, networkId) {
      return User.get(params).$promise.then(function(user) {
        let networkGroup = _.find(user.networkGroups, ['network.id', networkId]);
        return Team.query(_.map(_.get(networkGroup, 'groups'), 'id')).$promise.then(function(page) {
          _.set(networkGroup, 'groups', page.results);
          return user;
        });
      });
    };

    User.getUserByUsername = function(username) {
      return User.query({username: username}).$promise.then(function(page) {
        if (page.totalCount === 1) {
          return page.results[0];
        }
        return $q.reject('Expected 1 user, got ' + page.totalCount);
      });
    };

    return User;
  });

export default MODULE_NAME;