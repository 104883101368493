/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import Highcharts from 'highcharts';
import highchartsNg from 'highcharts-ng';
import translate from 'angular-translate';

import template from './stats-widget.html';

import {CHART_COLORS} from "../../common/options/chart-colors";
import {ADN_CHART_COUNT_TYPES} from '../../common/options/chart-options';

import localNetworkProfile from '../../../components/session/local-network-profile';
import {OBJECT_TYPE} from "../../common/constants/object-type";

const MODULE_NAME = "stats-widget-directive";

angular.module(MODULE_NAME, [highchartsNg, localNetworkProfile, translate])

  .directive('adnStatsWidget', function(statsResource, ChartOptionsUtil, $translate, $log, $locale, $filter, LocalNetworkProfile) {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        widgetType: '<adnStatsWidget'
      },
      template: template,
      controllerAs: 'ctrl',
      controller: function postLink() {
        const ctrl = this;

        ctrl.isCollection = true;

        let timezone = LocalNetworkProfile.get('timeZone');
        let networkTimezoneOffset = moment.tz(timezone).utcOffset();


        let getCollDataPoint = function(theChunks, objectId) {
          if (objectId === 'totals') {
            return _.get(theChunks, ['totals', ctrl.widgetType.selField]) || 0;
          }

          let objParam = "creative";
          if (ctrl.chartType.idKey === 'lineItemId') {
            objParam = "lineItem";
          } else if (ctrl.chartType.idKey === 'adUnitId') {
            objParam = "adUnit";
          } else if (ctrl.chartType.idKey === 'orderId') {
            objParam = "order";
          } else if (ctrl.chartType.idKey === 'siteId') {
            objParam = "site";
          } else if (ctrl.chartType.idKey === 'earningsAccountId') {
            objParam = "earningsAccount";
          }

          let object = _.find(theChunks, function(c) {
            return c[objParam].id === objectId;
          });
          return _.get(object, ctrl.widgetType.selField) || 0;
        };

        let chunkYValue = function(yval) {
          if (_.has(yval, 'amount')) {
            return yval.amount;
          } else if (_.has(yval, 'count')) {
            return yval.count;
          }
          return yval;
        };

        ctrl.$onInit = function() {
          ctrl.dateOption = ChartOptionsUtil.getDateOptions().last7Days;
          if (ctrl.widgetType.dateOption && ChartOptionsUtil.getDateOptions()[ctrl.widgetType.dateOption]) {
            ctrl.dateOption = ChartOptionsUtil.getDateOptions()[ctrl.widgetType.dateOption];
          }
          ctrl.startDate = ctrl.dateOption.startDate();
          ctrl.endDate = ctrl.dateOption.endDate();

          const networkTrans = LocalNetworkProfile.getNetworkTranslations(ctrl.widgetType.selField);
          ctrl.chartTitle = (networkTrans || $translate.instant("report.field." + ctrl.widgetType.selField)) + " for " + $translate.instant('chart.dateOption.' + ctrl.dateOption.id + '.sentence');
          if (ctrl.widgetType.dateOption === 'custom') {
            ctrl.dateOption = 'custom';
            ctrl.startDate = ctrl.widgetType.dateOptionStartDate || ctrl.startDate;
            ctrl.endDate = ctrl.widgetType.dateOptionEndDate || ctrl.endDate;
            ctrl.chartTitle = (networkTrans || $translate.instant("report.field." + ctrl.widgetType.selField)) + ": " + $filter('date')(ctrl.startDate, 'short') + " - " + $filter('date')(ctrl.endDate, 'short');
          }

          const objType = OBJECT_TYPE[ctrl.widgetType.objectType] || OBJECT_TYPE.LineItem;
          ctrl.chartType = ChartOptionsUtil.getOptions(objType.caps);

          let statsSeriesIds = angular.copy(ctrl.widgetType.objIds);
          const totalsId = {id: 'totals', name: 'Totals'};
          if (ctrl.widgetType.totalsType === 'SHOW') {
            statsSeriesIds.push(totalsId);
          } else if (ctrl.widgetType.totalsType === 'SHOW_ONLY') {
            statsSeriesIds = [totalsId];
          }

          ctrl.ids = _.map(ctrl.widgetType.objIds, 'id');
          if (!ctrl.ids.length) {
            ctrl.isPending = false;
            ctrl.noData = "Select at least one object to report stats on.";
            return;
          }

          let advStatsSeries = _.map(statsSeriesIds, function(c) {
              return {
                data: [],
                id: c.id,
                name: c.name,
                yAxis: 0
              };
            }),
            createChartConfig = function(series, yAxisData) {
              return {
                chart: {
                  type: 'line',
                  zoomType: 'x',
                  showAxes: true,
                  height: ctrl.widgetType.height || 300
                },
                plotOptions: {
                  series: {
                    marker: {
                      enabled: false
                    },
                    turboThreshold: 2000
                  }
                },
                tooltip: {
                  shared: true
                },
                xAxis: {
                  type: 'datetime',
                  title: {
                    enabled: false,
                    text: 'Date and Time'
                  }
                },
                yAxis: yAxisData,
                series: series,
                title: {
                  text: null
                },
                credits: {
                  enabled: false
                },
                accessibility: {
                  enabled: false
                },
                exporting: {
                  enabled: true
                }
              };
            };

          ctrl.statsChart = createChartConfig(advStatsSeries, {
            title: {text: null},
            min: 0,
            floor: 0,
            opposite: false,
            id: 0
          }, 300);

          let startStatsGet = function() {
            let chunkSize = ChartOptionsUtil.getChunkSize(ctrl.startDate, ctrl.endDate);

            let statsParams = {
              id: _.slice(ctrl.ids, 0, 50).join(';'),
              idKey: ctrl.chartType.idKey,
              startDate: ctrl.startDate,
              endDate: ctrl.endDate,
              groupBy: chunkSize.key
            };
            if (ctrl.isCollection) {
              statsParams.aggregateTo = objType.caps;

              let field = _.find(ctrl.chartType.fields, ['id', ctrl.widgetType.selField]);
              _.forEach(ctrl.statsChart.series, function(series) {
                series.isPercentage = field.isPercentage;
                series.allowDecimals = field.allowDecimals;
                series.tooltip = field.tooltip;
              });
            }
            statsResource.get(statsParams, function(stats) {
              Highcharts.setOptions({
                global: {timezoneOffset: networkTimezoneOffset * -1},
                lang: {
                  shortMonths: $locale.DATETIME_FORMATS.SHORTMONTH,
                  months: $locale.DATETIME_FORMATS.MONTH,
                  weekdays: $locale.DATETIME_FORMATS.DAY,
                  thousandsSep: $locale.NUMBER_FORMATS.GROUP_SEP || ' ',
                  decimalPoint: $locale.NUMBER_FORMATS.DECIMAL_SEP || '.'
                }
              });
              ctrl.totals = stats.totals || stats;
              ctrl.error = false;

              let allSeries = ctrl.statsChart.series;
              let keyedChunks = _.keyBy(stats.chunks, 'chunkStart');
              _.forEach(allSeries, function(series, loopCounter) {
                series.yAxis = 0;

                let yAxisFieldId = _.get(ctrl.chartType.fields[series.id], 'yAxis');
                let yAxisFieldColor = _.get(ctrl.statsChart.yAxis[yAxisFieldId], 'labels.style.color');
                series.color = ctrl.isCollection ? CHART_COLORS[loopCounter % CHART_COLORS.length] : yAxisFieldColor || CHART_COLORS[CHART_COLORS.length - 1];

                let runningTotal = 0;
                series.data = _.map(stats.chunkRange, function(statsDate) {
                  let dataPoint = null;
                  if (keyedChunks[statsDate]) {
                    const theValue = series.id === 'totals' ? keyedChunks[statsDate] : keyedChunks[statsDate].chunks;
                    dataPoint = ctrl.isCollection ? getCollDataPoint(theValue, series.id) : keyedChunks[statsDate][series.id];
                  }
                  let value = chunkYValue(dataPoint) || 0;
                  if (series.isPercentage) {
                    value = value * 100;
                    runningTotal = value;
                  } else {
                    runningTotal += value;
                  }
                  return {
                    x: moment(statsDate).valueOf(),
                    y: ctrl.countType === ADN_CHART_COUNT_TYPES.cumulative && !series.singularOnly ? runningTotal : value
                  };
                });
              });

              if (!stats.chunks.length) {
                ctrl.noData = "No data was found for your selected " + $translate.instant('object.' + ctrl.widgetType.objectType + '.plural').toLowerCase();
              }
              ctrl.haveStats = true;
              ctrl.isPending = false;
            }, function(data) {
              $log.warn(data);
              if (data.status !== -1) {
                ctrl.error = true;
              }
              ctrl.isPending = false;
            }, ctrl);
          };
          startStatsGet();

        };
      }
    };
  });

export default MODULE_NAME;