/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import template from './layout-copy-modal.html';
import layoutExampleCopyTemplate from './layout-example-copy-modal.html';

import standardActionsModule from '../../common/standard-actions-module';
import { actionsHelper } from "../../common/controller/actions-helper";
import { ALL_LAYOUTS } from "../layout-example/examples/all-layouts";

const MODULE_NAME = "layout-actions-controller";

angular.module(MODULE_NAME, [
  standardActionsModule
])
  .controller('LayoutActionsCtrl', function($q, toastr, $state, $stateParams, $uibModal, model, modelComms, readOnly) {
    const ctrl = this;

    actionsHelper(ctrl, model, modelComms, readOnly, $stateParams, $uibModal, $q, toastr, $state, template, {
      url: 'app.layouts.layout',
      lcase: 'layout',
      ucase: 'Layout'
    });

    ctrl.copyFromLayoutExample = function() {
      const modalInstance = $uibModal.open({
        windowClass: '',
        template: layoutExampleCopyTemplate,
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          modalCtrl.layoutExamples = _.map(ALL_LAYOUTS, function(v) {
            return v;
          });

          modelComms.addSubscriber(function(data, type) {
            if (!type) {
              modalCtrl.model = data;
            }
          });

          modalCtrl.copy = function() {
            if (modalCtrl.copyName) {
              modalCtrl.model.name = modalCtrl.layoutExample.name;
            }
            if (modalCtrl.copyDescription) {
              modalCtrl.model.description = modalCtrl.layoutExample.description;
            }
            modalCtrl.model.layoutComponents = modalCtrl.layoutExample.layoutComponents;
            modalCtrl.model.layoutType = modalCtrl.layoutExample.layoutType;
            modalCtrl.model.renderTemplate = modalCtrl.layoutExample.renderTemplate;

            modelComms.modelUpdated(modalCtrl.model);
            modalInstance.close();
          };
        }
      });
    };
  });

export default MODULE_NAME;