/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {AUTH_EVENT} from "./auth-constants";

import httpInterceptor from './http-interceptor';
import authProvider from '../api/auth-provider';
import {Session} from "../session/session";

const MODULE_NAME = "auth";

angular.module(MODULE_NAME, [
  httpInterceptor,
  authProvider
])

  .config(function($httpProvider) {
    $httpProvider.interceptors.push('authHttpInterceptor');
  })

  .factory('AuthService', function($q, apiAuth) {
    let authRefreshPromise;
    // this initialises the session on first load from local storage if it exists.
    const svc = {
      authenticate: function(username, password, masqUsername, twoFactorAuthCode) {
        return apiAuth.authenticate(username, password, masqUsername, twoFactorAuthCode);
      },
      twoFactorAuth: function(username, twoFactorAuthCode) {
        return apiAuth.twoFactorAuth(twoFactorAuthCode, Session.obtainData().accessToken).then(function(res) {
          return Session.obtain().init(res.scope, res.access_token, res.refresh_token, username, res.expires_in, username);
        });
      },
      getSession: function() {
        return Session.obtain();
      },
      getSessionData: function() {
        return Session.obtainData();
      },
      isAuthenticated: function() {
        const session = Session.obtainData();
        return _.isObject(session) && !_.isEmpty(session);
      },
      refresh: function() {
        if (svc.isAuthenticated()) {
          // make sure only one auth refresh is being done at a time
          return $q.when(authRefreshPromise).then(function() {
            authRefreshPromise = apiAuth.refresh(Session.obtainData().refreshToken)
              .then(function(res) {
                return Session.obtain().init(res.scope, res.access_token, res.refresh_token, res.username, res.expires_in, Session.obtainData().masqueraderUsername);
              });
            return authRefreshPromise;
          });
        }

        return $q.reject(AUTH_EVENT.notAuthenticated);
      },
      updatePassword: function(password, authToken) {
        return apiAuth.updatePassword(password, authToken).then(function(res) {
          return Session.obtain().init(res.scope, res.access_token, res.refresh_token, res.username, res.expires_in);
        });
      },
      resetPassword: function(username) {
        return apiAuth.resetPassword(username);
      },
      switchUi: function(username, masqUsername, accessToken, refreshToken, expiresIn) {
        return Session.obtain().init(null, accessToken, refreshToken, username, expiresIn, masqUsername);
      },
      signIn: function(username, password, masqUsername, twoFactorAuthCode) {
        return svc.authenticate(username, password, masqUsername, twoFactorAuthCode).then(function(res) {
          return Session.obtain().init(res.scope, res.access_token, res.refresh_token, masqUsername || username, res.expires_in, username);
        });
      },
      signOut: function() {
        return Session.obtain().destroy();
      }
    };
    return svc;
  });

export default MODULE_NAME;