/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "folder";

angular.module(MODULE_NAME, [])

  .factory('Folder', function(adnResource) {
    return adnResource('folders', {}, {});
  });

export default MODULE_NAME;