/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import uiTranslate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import uiSelect from 'ui-select';
import _ from 'lodash';

import resources from '../../../components/api/resources/resources';
import {LocalNetworkInfo} from "../../../components/session/local-network-info";

const MODULE_NAME = 'scanned-creatives';

angular.module(MODULE_NAME, [
  resources,
  uiSelect,
  uiBootstrap,
  uiTranslate
])

  .component('adnScannedCreatives', {
    template: `
      <div ng-if="ctrl.enabled">
        <adn-promise-spinner resolved="ctrl.violationCheck" and-hide="true">
          <div ng-if="ctrl.violations.length > 0">
            <div ng-if="ctrl.violations.length > 0" class="alert alert-info">
              <div ng-if="!ctrl.singular">{{ctrl.violations.length}} creatives have been blocked from delivery on certain sites due to publisher technical requirements.</div>
              <div ng-if="ctrl.singular">This creative has been blocked from delivery on certain sites due to publisher technical requirements.</div>
            </div>
            <div class="topStatsWidgetContainer" style="margin-bottom: 15px; margin-top: -10px;">
              <p class="small"><a ng-click="ctrl.showFullDetails=!ctrl.showFullDetails"><span class="fa fa-chevron-right" style="font-size: 7px;" ng-class="{'fa-rotate-90': ctrl.showFullDetails}"></span> <span ng-if="ctrl.showFullDetails">hide</span><span ng-if="!ctrl.showFullDetails">show</span> full details</a></p>
              <div ng-if="ctrl.showFullDetails" class="topStatsTableContainer">
                <div class="list-group">
                  <div ng-repeat="violation in ctrl.violations" class="list-group-item">
                    <p><strong>Creative: </strong><a ui-sref="app.creatives.creative({id: violation.creative.id})"><strong>{{violation.creative.name}}</strong></a></p>
                    <table class="table table-condensed table-bordered table-hover">
                        <tr><th>Publisher and Sites</th><th>Reason for Blocking</th></tr>
                        <tr ng-repeat="source in violation.violations">
                          <td>
                            <div><strong>{{source.earningsAccount.name}} <small>({{source.sites.length}} sites)</small></strong></div>
                            <span ng-repeat="site in source.sites">{{site.name}}<span ng-if="!$last">, </span></span>
                          </td>
                          <td>
                            <div ng-repeat="(key, violations) in source.policyViolationsByCode">
                              <p ng-if="key === 'policy.violation.unauthorised.domain'">The following sources of technology are not accepted: <span ng-repeat="policyViolation in violations">{{policyViolation.parameters.domain}}<span ng-if="!$last">, </span></span></p>
                              <p ng-if="key === 'policy.violation.cookie.expiry.too.long'">The following cookies expire too far into the future: <span ng-repeat="policyViolation in violations">{{policyViolation.parameters.cookieName}} from {{policyViolation.parameters.cookieUrl}} expires in {{policyViolation.parameters.cookieDays}} days<span ng-if="!$last">, </span></span></p>
                            </div>
                          </td>
                        </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </adn-promise-spinner>
      </div>
    `,
    controllerAs: 'ctrl',
    bindings: {
      refresh: '<',
      limit: '<',
      singular: '<'
    },
    controller: function($timeout, adnChecker) {
      const ctrl = this;

      ctrl.enabled = LocalNetworkInfo.obtain().getInfo("scanEnabledSites") > 0;
      if (!ctrl.enabled) {
        return;
      }

      ctrl.$onInit = function() {
        const limitIds = _.map(ctrl.limit, 'id');

        ctrl.violationCheck = false;
        adnChecker.getBlockedCreatives(ctrl.refresh).then(function(data) {
          ctrl.violations = _.filter(data, function(violation) {
            const hasViolation = violation.violations.length;
            return ctrl.limit ? limitIds.indexOf(violation.creative.id) > -1 && hasViolation : hasViolation;
          });
          ctrl.violationCheck = true;
          ctrl.showFullDetails = false;
        });
      };
    }
  });

export default MODULE_NAME;