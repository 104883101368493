/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import template from './form-buttons-directive.html';
import {formButtonVisibleCheck} from "./form-button-visible-check";

const MODULE_NAME = "form-buttons-transcluded";

angular.module(MODULE_NAME, [])

  .directive('adnFormSubmitButtons', function($window, $timeout) {
    return {
      restrict: 'A',
      replace: false,
      transclude: true,
      scope: {
        resource: '<adnFormSubmitButtons',
        form: '<adnForm'
      },
      link: function(scope) {
        formButtonVisibleCheck($window, $timeout, scope, scope.form);
      },
      template: template
    };
  });

export default MODULE_NAME;