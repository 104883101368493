/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import timeShifter from '../../components/util/time-shifter';
import moment from "moment/moment";

const MODULE_NAME = 'next-report-component';

angular.module(MODULE_NAME, [timeShifter])

  .component('adnNextReportComponent', {
    bindings: {
      model: '<',
      condensed: '<'
    },
    controllerAs: 'directive',
    controller: function(localUserProfile, adnTimeShifter) {
      const ctrl = this;
      ctrl.$onInit = function() {
        const adjustedDate = adnTimeShifter.timeShift(ctrl.model.scheduledFor);
        ctrl.model.scheduledForAdjustment = moment().add(100, 'y').isBefore(adjustedDate) ? null : adjustedDate;
      };
    },
    template: `
      <span ng-if="!directive.model.scheduledFor"><span ng-if="directive.condensed">Unavailable</span><span ng-if="!directive.condensed">Next execution date is unavailable</span></span>
      <span ng-if="directive.model.scheduledFor">
        <span ng-if="!directive.model.scheduledForAdjustment"><span ng-if="directive.condensed">Expired</span><span ng-if="!directive.condensed">This report schedule has expired and will not execute any more reports.</span></span>
        <span ng-if="directive.model.scheduledForAdjustment">{{::directive.model.scheduledForAdjustment | date: 'fullDate'}} {{::directive.model.scheduledForAdjustment | date: 'shortTime'}}</span>
      </span>
    `
  });

export default MODULE_NAME;
