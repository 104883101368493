/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import {CreativeViewer} from "../../../components/util/creative-viewer";
import {LocalCreativePreview} from "../../../components/session/local-creative-preview-data";

import template from './preview.html';
import {Uuid} from "../../../components/util/uuid";

const MODULE_NAME = 'preview-component';

angular.module(MODULE_NAME, [])

  .component('adnPreview', {
    bindings: {
      previewTrigger: '<',
      creativeId: '<'
    },
    template: template,
    controllerAs: 'ctrl',
    controller: function(localUserProfile, $document, LocalNetworkProfile, $element) {
      const ctrl = this;

      let thisPreviewData;
      ctrl.showBorder = true;

      ctrl.js = {
        showJsMessage: false,
        enabled: false
      };
      ctrl.dimensionMessage = "";

      ctrl.$onInit = function() {
        ctrl.preview = function() {
          ctrl.resolved = false;
          ctrl.previewTrigger.previewInline(thisPreviewData, cookiePreviewData);
          ctrl.resolved = true;
        };

        ctrl.networkId = LocalNetworkProfile.getNetworkTag();

        let cookiePreviewData = {};

        ctrl.uuid = Uuid.generate(ctrl.creativeId);
        ctrl.setCookieInfo = function() {
          new LocalCreativePreview().add(ctrl.uuid, LocalNetworkProfile.getNetworkTag(), cookiePreviewData);
        };

        ctrl.previewTrigger.previewInline = function(previewData, apiPreviewData, blankPageOnly) {
          ctrl.blankPageOnly = !!blankPageOnly;
          if (!previewData || !previewData.creativeContent || !previewData.creativeWidth || !previewData.creativeHeight) {
            return;
          }
          thisPreviewData = previewData;
          thisPreviewData.showBorder = ctrl.showBorder;

          if (!previewData.maxWidth) {
            ctrl.blankPage = true;
          }

          let content = previewData.creativeContent;
          ctrl.js.showJsMessage = !previewData.maxWidth && (content.match(/\/script>/g) || []).length > 2;

          if (previewData.dimensionType === 'FLEXIBLE') {
            ctrl.dimensionMessage = "Using dimensions of " + previewData.creativeWidth + "x" + previewData.creativeHeight + " for this preview";
          } else if (previewData.dimensionType === 'FIXED_WIDTH') {
            ctrl.dimensionMessage = "Using height of " + previewData.creativeHeight + " pixels for this preview";
          } else if (previewData.dimensionType === 'FIXED_HEIGHT') {
            ctrl.dimensionMessage = "Using width of " + previewData.creativeWidth + " pixels for this preview";
          }

          previewData.allowScript = ctrl.js.enabled;
          cookiePreviewData = apiPreviewData;
          if (ctrl.blankPageOnly) {
            return;
          }
          let divContainer = $element[0].querySelector('.previewContainer');
          new CreativeViewer($document).viewCreative(divContainer, previewData);
        };
      };
    }
  });

export default MODULE_NAME;

