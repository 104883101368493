/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import titleTemplate from '../base/default-title-template.html';
import editTemplate from './fields-list.html';

import standardModule from '../common/standard-module';
import adTagCtrl from './fields-controller';

const MODULE_NAME = 'fields-module';

angular.module(MODULE_NAME, [standardModule, adTagCtrl])

  .config(function($stateProvider) {
    $stateProvider.state('app.fields', {
      url: '/fields',
      parent: 'app.dat-map',
      views: {
        'content@app': {
          template: editTemplate,
          controller: 'FieldsCtrl',
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: "Fields"
      }
    });
  });

export default MODULE_NAME;