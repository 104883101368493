/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {HttpHelper} from '../../../util/http-helper';

const MODULE_NAME = "report-schedule";

angular.module(MODULE_NAME, [])

  .factory("ReportSchedule", function($http, adnResource) {
    let actions = HttpHelper.obtain($http).transformJsonActions('template');
    let ReportSchedule = adnResource('reportschedules', {}, actions, 'reportSchedules');

    ReportSchedule.create = function(defaults) {
      return new ReportSchedule(_.assign({
        recurrence: 'DAILY'
      }, defaults));
    };
    return ReportSchedule;
  });

export default MODULE_NAME;