/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import localNetworkProfile from '../../../../components/session/local-network-profile';

const MODULE_NAME = 'browser-update-banner-component';

angular.module(MODULE_NAME, [localNetworkProfile])

  .component('adnBrowserUpdateBanner', {
    controllerAs: 'ctrl',
    controller: function() {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.ghostery = false;
        try {
          // Object.hasOwn isn't supported by some browsers that our customers use, so this is how we check that they don't have it.
          ctrl.ghostery = !Object.hasOwn(ctrl, 'ghostery');
        } catch {
          ctrl.ghostery = true;
        }
      };
    },
    template: `<div class="warningBanner blockedBanner" ng-if="!ctrl.forceOff && ctrl.ghostery">
                <p style="font-size: 25px">Your Browser Isn't Supported</p>
              <p>We use some more advanced commands that your current browser doesn't support.</p>
              <p>Update your browser to a later version to ensure all functionality is available to you.</p>
              <p><a ng-click="ctrl.forceOff=true"><span class="fa fa-times-circle"></span> Turn this warning off</a></p>
           </div>`
  });

export default MODULE_NAME;