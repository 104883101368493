/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import {OBJECT_TYPE} from "../constants/object-type";

import template from './top-stats.html';
import moment from 'moment';

import resources from '../../../components/api/resources/resources';
import columnSelector from './column-selector-component';
import topNSelector from './top-n-component';
import _ from "lodash";
import {statComparator} from "./comparators";
import {Downloader} from "../../../components/util/downloader";

const MODULE_NAME = 'top-stats-table';

angular.module(MODULE_NAME, [resources, columnSelector, topNSelector])

  .component('adnTopStatsTable', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      object: '<',
      parentType: '@',
      childType: '@',
      statsHook: '<',
      currency: '<',
      useAlt: '<',
      blockLink: '<',
      dateSelectorId: '@',
      topN: '@',
      callbackHook: '<'
    },
    controller: function($scope, $rootScope, $log, $translate, statsResource, ChartOptionsUtil) {
      const ctrl = this;

      ctrl.sel = {};

      ctrl.sorting = {
        orderByParam: 'impressions',
        asc: false
      };
      ctrl.sortComparator = statComparator;
      ctrl.sortUpdate = function(field) {
        if (field.key === ctrl.sorting.orderByParam) {
          ctrl.sorting.asc = !ctrl.sorting.asc;
        } else {
          ctrl.sorting.orderByParam = field.key;
          ctrl.sorting.asc = false;
        }

        if (_.isFunction(ctrl.statsHook)) {
          ctrl.statsHook(null, ctrl.sorting);
        }
      };

      ctrl.$onInit = function() {
        let chartOptionObject = OBJECT_TYPE[ctrl.parentType];
        let groupByObject = OBJECT_TYPE[ctrl.childType];
        ctrl.defaultTopN = 50;
        ctrl.topN = ctrl.topN || ctrl.defaultTopN;

        let chartOptions = ChartOptionsUtil.getOptions(chartOptionObject.caps);
        ctrl.dataParam = groupByObject.apiParam;
        ctrl.parentParam = chartOptionObject.apiParam;
        ctrl.stateName = groupByObject.stateName;

        const fieldKeyPrefix = _.get(groupByObject, ['apiParam'], ctrl.secondaryTypeDirect);
        let chartOptionsFieldsKey = fieldKeyPrefix ?  fieldKeyPrefix + 'Fields' : 'totalsFields';
        const allFields = chartOptions[chartOptionsFieldsKey];

        function updateValues() {
          ctrl.downloadFields = _.map(ctrl.sel.fullFields, function(f) {
            return f.text || f.name;
          });
          ctrl.downloadFields.unshift($translate.instant(ctrl.dataParam));
          ctrl.downloadColumnOrder = _.map(ctrl.sel.fullFields, function(f) {
            return f.key || f.id;
          });
          ctrl.downloadColumnOrder.unshift(ctrl.dataParam + 'name');
          ctrl.csvTableTitle = $translate.instant(ctrl.dataParam) + " Totals";

          _.forEach(ctrl.dataTotals, function(dt) {
            dt[ctrl.dataParam + 'name'] = _.get(dt, [ctrl.dataParam, 'name']);
          });
        }

        ctrl.getRows = function() {
          return ctrl.dataTotals;
        };

        ctrl.saveXls = function() {
          updateValues();
          new Downloader().saveXls2(ctrl.csvTableTitle, ctrl.downloadFields, ctrl.downloadColumnOrder, ctrl.dataTotals, allFields);
        };

        ctrl.saveDownloadFields = function() {
          updateValues();
        };

        ctrl.showButton = _.isFunction(ctrl.callbackHook);
        ctrl.doCallback = function() {
          if (_.isFunction(ctrl.callbackHook)) {
            ctrl.callbackHook();
          }
        };

        ctrl.startDate = 'UNDEFINED';

        function callForStats() {
          ctrl.loaded = false;
          ctrl.dataTotals = null;
          ctrl.error = false;
          let params = {
            id: ctrl.object.id,
            idKey: chartOptionObject.apiParam + 'Id',
            groupBy: groupByObject.caps,
            currency: ctrl.currency
          };

          if (ctrl.parentType === 'UserSegment') {
            params.idKey = 'segmentTargets';
          }

          if (ctrl.startDate && ctrl.endDate) {
            params.startDate = ctrl.startDate;
            params.endDate = ctrl.endDate;
          }

          params.topN = ctrl.topN || 50;

          statsResource.get(params, function(data) {
            const allOthersIndex = _.findIndex(data.chunks, function(chunk) {
              return chunk[ctrl.dataParam].name === 'All Others' && !chunk[ctrl.dataParam].id;
            });

            ctrl.dataTotals = data.chunks;
            ctrl.otherRow = allOthersIndex > -1 ? _.pullAt(data.chunks, allOthersIndex)[0] : null;
            if (ctrl.otherRow) {
              const remainingObjects = data.totalCount - params.topN;
              const translationField = groupByObject.apiParam + (remainingObjects === 1 ? "" : ".plural");
              ctrl.otherRow.updatedName = remainingObjects + " remaining " + $translate.instant(translationField);
            }

            ctrl.totals = data.totals;
            ctrl.loaded = true;
            ctrl.totalCount = data.totalCount;
            if (_.isFunction(ctrl.statsHook)) {
              ctrl.statsHook(ctrl.dataTotals, ctrl.sorting);
            }
          }, function(err) {
            $log.warn(err);
            ctrl.error = true;
          }, $scope);
        }

        const chartDateListener = $rootScope.$on('chartDates' + chartOptions.idKey + (ctrl.dateSelectorId || ''), function(event, data) {
          if (ctrl.startDate !== 'UNDEFINED' && moment(ctrl.startDate).isSame(data.startDate) && moment(ctrl.endDate).isSame(data.endDate) && (!ctrl.currency || ctrl.currency === data.currency)) {
            return;
          }

          ctrl.startDate = data.startDate;
          ctrl.endDate = data.endDate;
          ctrl.currency = data.currency || ctrl.currency;

          callForStats();
        });

        ctrl.topNHook = {
          newN: function(newN) {
            ctrl.topN = newN;
            callForStats();
          }
        };


        $scope.$on('$destroy', function() {
          chartDateListener();
        });
      };
    }
  });

export default MODULE_NAME;