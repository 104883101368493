/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

import {JOIN_INFO} from "./join-data";

import resources from '../../../components/api/resources/resources';
import {Uuid} from "../../../components/util/uuid";
import {ALL_TARGETING_TYPES} from "../../targeting/targeting-constants";

const MODULE_NAME = "list-helper";

angular.module(MODULE_NAME, [
  resources
])

  .factory('adnListHelper', function($filter, $rootScope, adnModalLoading, $location, $interpolate, $exceptionHandler, LocalNetworkProfile, UserProfile, localUserProfile) {

    let sub;

    let BasicListHelper = function(ctrl, ResourceFactory, listId, config) {
      config = config || {};

      let recursionProtector = false;

      config.afterFill = config.afterFill || _.noop;
      config.listMethod = config.listMethod || 'query';
      config.moreParams = config.moreParams || {};
      config.afterBulkAction = config.afterBulkAction || _.noop;
      config.viewModel = config.viewModel || '';
      config.pageSize = Math.max(1, Math.min(100, config.pageSize || _.get(localUserProfile.getProfile(), ['adnPrefs', 'defaultPageSize']) || 25));
      config.selectIn = config.selectIn || {};
      config.noInitQuery = config.noInitQuery || false;
      config.filterBy = config.filterBy || '';
      config.concise = _.isBoolean(config.concise) ? config.concise : true;
      config.filterByValue = config.filterByValue || '';
      config.filterByLike = config.filterByLike || '';
      config.where = config.where || '';
      config.onlyMine = config.onlyMine || false;
      config.getDefaultOrderByParam = config.getDefaultOrderByParam || function() {
        return "name";
      };

      ctrl.setOrderByParam = function(param) {
        ctrl.orderByParam = param;
      };
      ctrl.setAsc = function(asc) {
        ctrl.asc = asc;
      };
      ctrl.setNullsOrderPolicy = function(nullsOrderPolicy) {
        ctrl.nullsOrderPolicy = nullsOrderPolicy;
      };
      ctrl.setMoreParams = function(moreParams) {
        ctrl.moreParams = moreParams;
      };

      let localUser = localUserProfile.get(),
        profile = localUser.profile;

      let initDateFilters = function() {
        ctrl.startDateFilter = {dir: '>'};
        ctrl.endDateFilter = {dir: '<'};
      };

      let init = function() {
        let defaultOrderByParam = config.getDefaultOrderByParam();
        ctrl.setOrderByParam(defaultOrderByParam.replace("DESC", ""));
        ctrl.setAsc(defaultOrderByParam.indexOf("DESC") !== 0);
        ctrl.setMoreParams(config.moreParams);
        ctrl.setNullsOrderPolicy();
        const pageNo = parseInt(_.get($location.search(), 'pageNo'));
        ctrl.currentPage = _.isFinite(pageNo) && config.paginate ? pageNo : 1;
        ctrl.pageSize = config.pageSize;
        ctrl.include = 'ACTIVE';
        ctrl.showingInclude = ctrl.include;
        ctrl.selectIn = config.selectIn;

        initDateFilters();

        const listSettings = listId ? _.get(profile, 'adnLists.' + listId, {}) : 'name';
        if (listSettings.orderByParam) {
          ctrl.setOrderByParam(listSettings.orderByParam);
          ctrl.setAsc(_.isBoolean(listSettings.asc) ? listSettings.asc : true);
          if (listSettings.isNumeric) {
            ctrl.setNullsOrderPolicy(ctrl.asc ? 'FIRST' : 'LAST');
          }
        }
        if (listSettings.filterByKeyValue) {
          ctrl.filterByKeyValue = listSettings.filterByKeyValue;
        }
        if (listSettings.selectIn) {
          ctrl.selectIn = listSettings.selectIn;
          _.forEach(ctrl.selectIn, function(val, key) {
            if (_.isObject(val)) {
              ctrl[key + 'Working'] = _.filter(_.map(val, function(val, key) {
                return val === true ? key : '';
              }), function(val) {
                return val;
              });
            }
          });
        }
        if (listSettings.startDateFilter) {
          ctrl.startDateFilter = listSettings.startDateFilter;
          ctrl.startDateFilter.workingStartDate = listSettings.startDateFilter.startDate ? moment(listSettings.startDateFilter.startDate).toDate() : null;
        }
        if (listSettings.endDateFilter) {
          ctrl.endDateFilter = listSettings.endDateFilter;
          ctrl.endDateFilter.workingEndDate = listSettings.endDateFilter.endDate ? moment(listSettings.endDateFilter.endDate).toDate() : null;
        }
        if (listSettings.targetType) {
          ctrl.targetType = listSettings.targetType;
          ctrl.workingTargetType = listSettings.targetType || null;
        }
        if (listSettings.team) {
          ctrl.team = listSettings.team;
          ctrl.moreParams.team = listSettings.team.id;
        }
        if (listSettings.order) {
          ctrl.order = listSettings.order;
          ctrl.moreParams.order = listSettings.order.id;
        }
      };
      init();

      let makeQuery = function(baseTable, joinParam, whereFieldData, joinType) {
        const leftJoinInfo = JOIN_INFO[ctrl.orderByParam];
        let params = {orderBy: _.get(leftJoinInfo, 'whereField') || ctrl.orderByParam.split("|")[0], concise: config.concise, orderByDirection: ctrl.asc ? 'ASCENDING' : 'DESCENDING', pageSize: ctrl.pageSize, page: ctrl.currentPage};
        params.locale = localUserProfile.get("locale");
        if (ctrl.nullsOrderPolicy) {
          params.nullsOrderPolicy = ctrl.nullsOrderPolicy;
        }
        if (!config.childCall && (!ctrl.selectIn || _.isEmpty(ctrl.selectIn))) {
          if (ctrl.filterByKeyValue) {
            const filterValues = ctrl.filterByKeyValue.split(":");
            if (filterValues.length === 2) {
              params.filterBy = filterValues[0];
              params.filterByValue = filterValues[1];
            }
          }

          if ((config.filterBy && config.filterByValue) || (ctrl.filterBy && ctrl.filterByValue)) {
            params.filterBy = ctrl.filterBy || config.filterBy;
            params.filterByValue = ctrl.filterByValue || config.filterByValue;
          }
          if ((config.filterBy && config.filterByLike) || (ctrl.filterBy && ctrl.filterByLike)) {
            params.filterBy = ctrl.filterBy || config.filterBy;
            params.filterByLike = ctrl.filterByLike || config.filterByLike;
          }
        }
        if (config.excludeIfMissing || ctrl.excludeIfMissing) {
          params.excludeIfMissing = ctrl.excludeIfMissing || config.excludeIfMissing;
        }
        params.includeActive = false;
        params.includeInactive = false;
        params.includeHidden = false;

        if (ctrl.searchTermCommitted) {
          params.filterBy = 'name;description;siteUrl;username';
          params.filterByLike = ctrl.searchTermCommitted;
        }

        params.joins = [];

        const joinInfo = joinParam ? JOIN_INFO[joinParam] : null;
        if (leftJoinInfo && (!joinInfo || joinInfo.table !== leftJoinInfo.table)) {
          params.joins.push('left join ' + leftJoinInfo.table + ' on ' + (config.baseTable || leftJoinInfo.table) + '.' + leftJoinInfo.field + '=' + leftJoinInfo.joinField);
        }

        if (config.onlyMine === true) {
          params.onlyMine = config.onlyMine;
        }

        params.where = [];
        if (config.where) {
          params.where = [config.where];
        } else {
          if (_.isArray(whereFieldData)) {
            _.forEach(whereFieldData, function(fd) {
              params.where.push(fd);
            });
          } else if (whereFieldData || joinType === 'LEFT') {
            const joinString = joinType === 'LEFT' ? 'left' : 'inner';
            const joinInfo = JOIN_INFO[joinParam];
            params.joins.push(joinString + ' join ' + joinInfo.table + ' on ' + config.baseTable + '.' + joinInfo.field + '=' + joinInfo.joinField);
            if (joinType === 'LEFT') {
              params.where.push(joinInfo.whereField + ' is null');
            } else {
              params.where.push(joinInfo.whereField + '=' + whereFieldData);
            }
            ctrl.joinMessage = joinInfo.translation + ': ' + (joinType === 'LEFT' ? 'orphan' : whereFieldData);
          } else {
            ctrl.joinMessage = '';
          }
        }

        if (_.get(ctrl.startDateFilter, 'startDate')) {
          params.where.push(config.baseTable + ".startDate" + ctrl.startDateFilter.dir + moment(ctrl.startDateFilter.startDate).format("YYYY-MM-DD"));
        }
        if (_.get(ctrl.endDateFilter, 'endDate')) {
          params.where.push(config.baseTable + ".endDate" + ctrl.endDateFilter.dir + moment(ctrl.endDateFilter.endDate).format("YYYY-MM-DD"));
        }

        if (ctrl.targetType) {
          params.targeting = _.get(ALL_TARGETING_TYPES, [ctrl.targetType, 'apiType'], "");
        }

        if (ctrl.team) {
          ctrl.moreParams.team = ctrl.team.id;
        }

        if (ctrl.order) {
          ctrl.moreParams.order = ctrl.order.id;
        }

        if (ctrl.selectIn) {
          // can't have both selectIn and filterBy work together.
          ctrl.filterByKeyValue = "";
          ctrl.filterBy = "";
          ctrl.filterByValue = "";
          _.forEach(ctrl.selectIn, function(valObj, key) {
            const values = [];
            _.forEach(valObj, function(val, key) {
              if (val === true) {
                values.push(key);
              }
            });
            if (values.length === 0) {
              return;
            }
            params.where.push((config.baseTable || leftJoinInfo.table) + '.' + key + " in " + values.join(","));
          });
        }

        if (ctrl.include) {
          params['include' + _.capitalize(ctrl.include)] = true;
        }
        const fillFunction = function(page) {
          ctrl.items = page.results;
          ctrl.totalCount = page.totalCount;
          ctrl.totalCountText = $filter('number')(ctrl.totalCount);
          ctrl.showingInclude = ctrl.include;

          if (ctrl.items.length && !ctrl.items[0].id) {
            _.forEach(ctrl.items, function(item) {
              item.id = Uuid.generate();
            });
          }

          config.afterFill(ctrl.items);
        };

        const errorFunction = function(e) {
          if ((_.get(e, ['data', 'parameters', 'message']) || "").indexOf("Unable to order query") > -1) {
            if (!recursionProtector) {
              ctrl.makeSortQuery(config.getDefaultOrderByParam());
            }
            recursionProtector = true;
            return;
          }
          $exceptionHandler(e);
        };

        params.joins = params.joins.join(";");
        params.where = params.where.join(";");

        if (config.id) {
          params.id = config.id.join(";");
        }
        if (config.listMethod === 'query' || config.listGetMethod) {
          return ResourceFactory[config.listGetMethod || config.listMethod](_.assign({}, params, ctrl.moreParams), fillFunction, errorFunction);
        }
        let value = {$resolved: false};
        value = ResourceFactory[config.listMethod](params, config.viewModel, ctrl.moreParams).then(fillFunction).then(function() {
          value.$resolved = true;
        }).catch(errorFunction);
        return value;
      };
      ctrl.searchQuery = {$resolved: true};
      ctrl.sortQuery = {$resolved: true};
      ctrl.pageQuery = {$resolved: true};
      ctrl.filterQuery = {$resolved: true};
      ctrl.selectInQuery = {$resolved: true};
      ctrl.joinQuery = {$resolved: true};
      ctrl.includeQuery = {$resolved: true};
      ctrl.dateQuery = {$resolved: true};
      ctrl.targetQuery = {$resolved: true};
      ctrl.teamQuery = {$resolved: true};
      ctrl.orderQuery = {$resolved: true};

      ctrl.joinOnSelect = {
        onSelect: function(baseTable) {
          ctrl.joinQuery = makeQuery(baseTable, ctrl.joinFilterBy, _.get(ctrl, ['join' + ctrl.joinFilterBy, 'name']) || '');
        }
      };

      ctrl.filterOnSelect = function(baseTable, clearOut) {
        const fBy = clearOut ? null : ctrl.joinFilterBy;
        if (ctrl.showOrphan && !clearOut) {
          ctrl.joinQuery = makeQuery(baseTable, null, ['lineItemId is null', 'creativeSetId is null']);
          const joinInfo = JOIN_INFO[fBy];
          ctrl.joinMessage = joinInfo.translation + ': orphan';
        } else {
          ctrl.joinQuery = makeQuery(baseTable, fBy, _.get(ctrl, ['join' + fBy, 'name']) || '');
          ctrl.showOrphan = false;
        }
        if (!fBy && _.get(ctrl, ['join' + ctrl.joinFilterBy])) {
          ctrl['join' + ctrl.joinFilterBy] = null;
        }
        if (clearOut) {
          ctrl.showOrphan = false;
        }
        ctrl.savedFilterBy = fBy;
        ctrl.orderFilterPopover = false;
        ctrl.orderFilterPopover2 = false;
        ctrl.orderFilterPopover3 = false;
      };

      ctrl.makeQuery = function() {
        ctrl.query = makeQuery();
      };
      if (!config.noInitQuery) {
        ctrl.makeQuery();
      }

      ctrl.changePage = function() {
        ctrl.pageQuery = makeQuery();

        if (config.paginate) {
          $location.search('pageNo', ctrl.currentPage === 1 ? undefined : ctrl.currentPage);
        }
      };

      if (config.paginate) {
        if (_.isFunction(sub)) {
          sub();
        }
        sub = $rootScope.$on('$locationChangeSuccess', function(event, currentUrl, prevUrl) {
          const currentSplit = currentUrl.split("pageNo=");
          const prevUrlSplit = prevUrl.split("pageNo=");

          const currentPageNo = parseInt(_.get(currentSplit, [1]));
          const prevPageNo = parseInt(_.get(prevUrlSplit, [1]));

          if (_.get(currentSplit, [0]).replace("?", "") === _.get(prevUrlSplit, [0]).replace("?", "") && currentPageNo !== prevPageNo) {
            ctrl.currentPage = _.isFinite(currentPageNo) ? currentPageNo : 1;
            ctrl.changePage();
          }
        });
      }

      let saveUserProfile = function() {
        UserProfile.save({
          id: localUser.userId,
          profile: _.pick(profile, ['adnLists'])
        }, function(apiUser) {
          localUser = localUserProfile.safeSaveProfile('adnLists', apiUser.profile.adnLists);
          profile = localUser.profile;
        });
      };

      ctrl.makeFilterQuery = function() {
        if (listId) {
          _.set(profile, 'adnLists.' + listId + '.filterByKeyValue', ctrl.filterByKeyValue);
        }
        ctrl.filterQuery = makeQuery();

        saveUserProfile();
      };

      ctrl.searchList = function(clearTerm) {
        if (clearTerm) {
          ctrl.searchTerm = "";
        }
        ctrl.searchTermCommitted = ctrl.searchTerm;
        ctrl.searchQuery = makeQuery();
        ctrl.undoItems = [];
      };

      ctrl.makeDateQuery = function(clearAll) {
        if (clearAll) {
          initDateFilters();
        } else {
          ctrl.datePopover = false;
        }
        ctrl.startDateFilter.startDate = ctrl.startDateFilter.workingStartDate;
        ctrl.endDateFilter.endDate = ctrl.endDateFilter.workingEndDate;
        ctrl.dateQuery = makeQuery();
        if (listId) {
          const startDateCopy = _.cloneDeep(ctrl.startDateFilter);
          const startDate = moment(startDateCopy.startDate);
          startDateCopy.startDate = startDateCopy.startDate && startDate.isValid() ? startDate : "";
          delete startDateCopy.workingStartDate;
          _.set(profile, 'adnLists.' + listId + '.startDateFilter', startDateCopy);
          const endDateCopy = _.cloneDeep(ctrl.endDateFilter);
          const endDate = moment(endDateCopy.endDate);
          endDateCopy.endDate = endDateCopy.endDate && endDate.isValid() ? endDate : "";
          delete endDateCopy.workingEndDate;
          _.set(profile, 'adnLists.' + listId + '.endDateFilter', endDateCopy);
        }
        saveUserProfile();
      };

      ctrl.makeSelectInQuery = function(key, value, maxOfKind) {
        if (value) {
          if (_.isArray(value)) {
            ctrl.selectIn[key] = {};
            _.forEach(value, function(val) {
              ctrl.selectIn[key][val] = true;
            });
          } else {
            ctrl.selectIn[key] = ctrl.selectIn[key] || {};
            ctrl.selectIn[key][value] = !ctrl.selectIn[key][value];
          }
        } else {
          _.forEach(_.keys(ctrl.selectIn[key]), function(k) {
            ctrl.selectIn[key][k] = false;
          });
          ctrl[key + 'Working'] = [];
        }
        if (!LocalNetworkProfile.hasObjectSource()) {
          // safety for line item source filter and the in-and-out nature of showing source depending on network status
          delete ctrl.selectIn.source;
          delete ctrl.selectIn.sourceSum;
        }
        ctrl.selectIn[key + 'Sum'] = _.sum(_.map(ctrl.selectIn[key], function(val) {
          return val === true ? 1 : 0;
        }));
        ctrl.selectIn[key + 'Selected'] = ctrl.selectIn[key + 'Sum'] > 0 && ctrl.selectIn[key + 'Sum'] < maxOfKind;
        ctrl.selectInQuery = makeQuery();
        if (listId) {
          _.set(profile, 'adnLists.' + listId + '.selectIn', ctrl.selectIn);
          // can't have selectIn and filterBy stuff at the same time
          delete profile.adnLists[listId].filterByKeyValue;
          delete profile.adnLists[listId].filterBy;
          delete profile.adnLists[listId].filterByValue;
        }
        saveUserProfile();
        ctrl[key + 'Popover'] = false;
      };

      ctrl.makeIncludeQuery = function() {
        ctrl.includeQuery = makeQuery();
        ctrl.undoItems = [];
      };

      ctrl.makeSortQuery = function(param, isNumeric) {
        const isAsc = param === ctrl.orderByParam ? !ctrl.asc : !isNumeric;
        ctrl.setNullsOrderPolicy();
        if (isNumeric) {
          ctrl.setNullsOrderPolicy(isAsc ? 'FIRST' : 'LAST');
        }
        if (listId) {
          _.set(profile, 'adnLists.' + listId + '.orderByParam', param);
          _.set(profile, 'adnLists.' + listId + '.asc', isAsc);
          _.set(profile, 'adnLists.' + listId + '.isNumeric', isNumeric);
        }
        ctrl.setOrderByParam(param);
        ctrl.setAsc(isAsc);
        ctrl.sortQuery = makeQuery();

        saveUserProfile();
      };

      // have to use this for data refresh -- beats me, angularjs being weird.
      ctrl.getTargetingName = function() {
        return _.get(ctrl, ['targetingTypes', ctrl.targetType, 'title']);
      };

      ctrl.makeTargetingQuery = function(clearOut) {
        if (clearOut) {
          ctrl.targetType = null;
          ctrl.workingTargetType = null;
        } else {
          ctrl.targetType = ctrl.workingTargetType;
        }
        if (listId) {
          _.set(profile, 'adnLists.' + listId + '.targetType', ctrl.targetType);
        }
        ctrl.targetingPopover = false;
        ctrl.targetQuery = makeQuery();

        saveUserProfile();
      };

      // have to use this for data refresh -- beats me, angularjs being weird.
      ctrl.getTeamName = function() {
        return _.get(ctrl, ['team', 'name']);
      };

      ctrl.makeTeamQuery = function(clearOut, andReturn) {
        if (clearOut) {
          delete ctrl.team;
          delete ctrl.moreParams.team;
          delete ctrl.workingTeam;
          if (andReturn) {
            return;
          }
        } else {
          ctrl.team = ctrl.workingTeam;
          ctrl.makeOrderQuery(true, true);
        }
        if (listId) {
          _.set(profile, 'adnLists.' + listId + '.team', ctrl.team);
        }
        ctrl.teamPopover = false;
        ctrl.teamQuery = makeQuery();
        saveUserProfile();
      };

      // have to use this for data refresh -- beats me, angularjs being weird.
      ctrl.getOrderName = function() {
        return _.get(ctrl, ['order', 'name']);
      };

      ctrl.makeOrderQuery = function(clearOut, andReturn) {
        if (clearOut) {
          delete ctrl.order;
          delete ctrl.moreParams.order;
          delete ctrl.workingOrder;
          if (andReturn) {
            return;
          }
        } else {
          ctrl.order = ctrl.workingOrder;
          ctrl.makeTeamQuery(true);
        }
        if (listId) {
          _.set(profile, 'adnLists.' + listId + '.order', ctrl.order);
        }
        ctrl.orderPopover = false;
        ctrl.orderQuery = makeQuery();
        saveUserProfile();
      };

      ctrl.addItemToList = function(item) {
        ctrl.items = ctrl.items || [];
        ctrl.items.push(item);
        ctrl.totalCount++;
        ctrl.totalCountText = $filter('number')(ctrl.totalCount);
      };

      let undoInit = function() {
        ctrl.undoItems = [];
      };
      undoInit();

      const bulkSelectCalc = function() {
        const selectedSize = _.size(ctrl.bulkSelection);
        if (selectedSize === 0) {
          ctrl.bulkSelectState = "empty";
        } else {
          const currentPageMatches = _.reduce(ctrl.items, function(sum, item) {
            return ctrl.bulkSelection[item.id] ? sum + 1 : sum;
          }, 0);

          if (currentPageMatches === ctrl.items.length) {
            ctrl.bulkSelectState = "all";
          } else if (currentPageMatches === 0) {
            ctrl.bulkSelectState = "empty";
          } else {
            ctrl.bulkSelectState = "partial";
          }
        }
        ctrl.bulkSelectSize = selectedSize;
      };
      const clearBulk = function() {
        ctrl.bulkSelection = {};
        bulkSelectCalc();
      };
      ctrl.bulkSelection = {};
      ctrl.selectToggle = function(item) {
        if (ctrl.bulkSelection[item.id]) {
          delete ctrl.bulkSelection[item.id];
        } else {
          ctrl.bulkSelection[item.id] = item;
        }
        bulkSelectCalc();
      };
      ctrl.bulkSelectState = "empty";

      ctrl.selectAll = function() {
        _.forEach(ctrl.items, function(item) {
          ctrl.bulkSelection[item.id] = item;
        });
        bulkSelectCalc();
      };

      ctrl.deselectAll = function() {
        _.forEach(ctrl.items, function(item) {
          delete ctrl.bulkSelection[item.id];
        });
        bulkSelectCalc();
      };

      const bulkActionFunc = function(selections, action, param, postActionFunc) {
        const items = _.map(selections, function(v) {
          const obj = {id: v.id};
          obj[param || 'objectState'] = action ? action : v[param || 'objectState'];
          return obj;
        });
        ResourceFactory.bulkSave(ctrl.bulkParams, items).$promise.then(function() {
          ctrl.makeQuery();
          clearBulk();
          config.afterBulkAction();

          postActionFunc(selections);
          if (_.isFunction(config.afterAction)) {
            config.afterAction(selections, action);
          }
        });
      };

      ctrl.undoMiscBulkAction = function() {
        if (ctrl.undoBulkSelection.length < 1) {
          return;
        }

        ResourceFactory.bulkSave(ctrl.bulkParams, ctrl.undoBulkSelection).$promise.then(function() {
          ctrl.undoBulkSelection = [];
          ctrl.makeQuery();
          config.afterBulkAction();
        }, function(response) {
          ctrl.form.apiErrors = ctrl.form.apiErrors || [];
          _.forEach(_.get(response, ['data', 'errors']), function(err) {
            const errorInfo = err.error;
            ctrl.form.apiErrors.push({
              key: errorInfo.code,
              apiText: errorInfo.text ? $interpolate(errorInfo.text)(errorInfo.parameters) : ''
            });
            ctrl.form.bulkError = true;
          });
        });
      };

      ctrl.miscBulkAction = function(modalInst, modalCtrl, items, Resource, params) {
        ctrl.form = ctrl.form || {};
        const modalInstance = modalInst || {close: _.noop};

        if (items.length < 1) {
          modalInstance.close();
          return;
        }

        const updatedKeys = _.keys(items[0]);

        adnModalLoading.applyModalLoading();

        (Resource || ResourceFactory).bulkSave(params, items).$promise.then(function() {
          ctrl.bulkParams = params;
          if (Resource) {
            ctrl.bulkSelection = {};
          } else {
            ctrl.undoBulkSelection = _.map(ctrl.bulkSelection, function(v) {
              return _.pick(v, updatedKeys);
            });
          }
          modalInstance.close({items: items});
          ctrl.form.bulkError = false;
          adnModalLoading.removeModalLoading();
          ctrl.makeQuery();
          config.afterBulkAction();
        }, function(response) {
          _.set(modalCtrl, ['form', 'apiErrors'], _.get(modalCtrl, ['form', 'apiErrors'], []));
          _.forEach(_.get(response, ['data', 'errors']), function(err) {
            const errorInfo = err.error;
            modalCtrl.form.apiErrors.push({
              key: errorInfo.code,
              apiText: errorInfo.text ? $interpolate(errorInfo.text)(errorInfo.parameters) : ''
            });
            adnModalLoading.removeModalLoading();
            modalCtrl.form.bulkError = true;
          });
        });
      };

      ctrl.doBulkAction = function(action, param) {
        const undoItems = _.map(ctrl.bulkSelection, function(i) {
          return _.pick(i, ['id', param || 'objectState']);
        });
        bulkActionFunc(ctrl.bulkSelection, action, param, function() {
          let currentInclude = ctrl.showingInclude;
          ctrl.currentAction = action;
          ctrl.prevAction = param ? null : currentInclude || 'ACTIVE';
          ctrl.prevParam = param;
          ctrl.undoItems = undoItems;
        });
      };

      ctrl.undoBulkAction = function(param) {
        bulkActionFunc(ctrl.undoItems, ctrl.prevAction || null, ctrl.prevParam || param, function() {
          ctrl.prevParam = '';
          ctrl.undoItems = [];
        });
      };
    };

    let ListHelper = function(ctrl, ResourceFactory, readOnly, listId, config) {
      ctrl.readOnly = readOnly;

      config = config || {};
      config.afterAction = config.afterAction || _.noop;
      config.paginate = true;

      if (ResourceFactory) {
        new BasicListHelper(ctrl, ResourceFactory, listId, config);
      }
    };

    return {
      setUpList: function(ctrl, ResourceFactory, readOnly, listId, config) {
        new ListHelper(ctrl, ResourceFactory, readOnly, listId, config);
      },
      setUpBasicList: function(ctrl, ResourceFactory, listId, config) {
        new BasicListHelper(ctrl, ResourceFactory, listId, config);
      }
    };
  });

export default MODULE_NAME;