/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import titleTemplate from '../base/default-title-template.html';
import editTemplate from './perms-edit.html';

import standardModule from '../common/standard-module';
import bookingCalendarController from './perms-controller';
import {Uuid} from "../../components/util/uuid";

const MODULE_NAME = 'perms-module';

angular.module(MODULE_NAME, [standardModule, bookingCalendarController])

  .config(function($stateProvider) {
    $stateProvider.state('app.perms', {
      url: '/perms',
      resolve: {
        model: /*@ngInject*/ function($q, $stateParams, LineItem) {
          return LineItem.create({
            id: $stateParams.id || Uuid.generate(),
            userState: 'RESERVED',
            name: 'George'
          });
        }
      },
      views: {
        'content@app': {
          template: editTemplate,
          controller: 'PermsCtrl',
          controllerAs: 'resource'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: "Perms"
      }
    });
  });

export default MODULE_NAME;