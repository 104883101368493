/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import {OBJECT_TYPE} from "../constants/object-type";

import template from './top-targeting.html';
import moment from 'moment';

import resources from '../../../components/api/resources/resources';
import userPermissions from '../../../components/session/local-user-permissions';
import columnSelector from './column-selector-component';
import _ from "lodash";
import {Downloader} from "../../../components/util/downloader";

const MODULE_NAME = 'top-targeting-table';

angular.module(MODULE_NAME, [resources, userPermissions, columnSelector])

  .component('adnTopTargetingTable', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      object: '<',
      parentType: '@',
      topN: '@',
      dateSelectorId: '@'
    },
    controller: function($scope, $rootScope, $log, $translate, statsResource, ChartOptionsUtil) {
      const ctrl = this;
      ctrl.sel = {};

      ctrl.sorting = {
        orderByParam: 'impressions',
        asc: true
      };

      ctrl.$onInit = function() {
        let chartOptionObject = OBJECT_TYPE[ctrl.parentType];
        let params = {
          id: ctrl.object.id,
          idKey: chartOptionObject.apiParam + 'Id',
        };

        ctrl.sortUpdate = function(field) {
          if (field.key === ctrl.sorting.orderByParam) {
            ctrl.sorting.asc = !ctrl.sorting.asc;
            return;
          }
          ctrl.sorting.orderByParam = field.key;
          ctrl.sorting.asc = true;
        };

        let chartOptions = ChartOptionsUtil.getOptions(chartOptionObject.caps);
        ctrl.parentParam = chartOptionObject.apiParam;
        const allFields = chartOptions.targetingFields;

        function updateValues() {
          ctrl.downloadFields = _.map(ctrl.sel.fullFields, function(f) {
            return f.text || f.name;
          });
          ctrl.downloadFields.unshift("Type", "Targeting");
          ctrl.downloadColumnOrder = _.map(ctrl.sel.fullFields, function(f) {
            return f.key || f.id;
          });
          ctrl.downloadColumnOrder.unshift("type", "target");
          ctrl.csvTableTitle = "Targeting Totals";
        }

        ctrl.getRows = function() {
          return ctrl.dataTotals;
        };

        ctrl.saveXls = function() {
          updateValues();
          new Downloader().saveXls2(ctrl.csvTableTitle, ctrl.downloadFields, ctrl.downloadColumnOrder, ctrl.dataTotals, allFields);
        };

        ctrl.saveDownloadFields = function() {
          updateValues();
        };

        ctrl.startDate = 'UNDEFINED';
        const topTargetingCall = $rootScope.$on('chartDates' + chartOptions.idKey + (ctrl.dateSelectorId || ''), function(event, data) {
          if (ctrl.startDate !== 'UNDEFINED' && moment(ctrl.startDate).isSame(data.startDate) && moment(ctrl.endDate).isSame(data.endDate)) {
            return;
          }

          ctrl.startDate = data.startDate;
          ctrl.endDate = data.endDate;

          ctrl.loaded = false;
          ctrl.dataTotals = null;
          ctrl.error = false;
          ctrl.targetingExpression = false;

          //params.topN = ctrl.topN || "50";
          params.groupBy = null;

          if (ctrl.startDate && ctrl.endDate) {
            params.startDate = ctrl.startDate;
            params.endDate = ctrl.endDate;
          }

          statsResource.getTargeting(params, function (data) {
            ctrl.dataTotals = _.map(data, function (val, targeting) {
              let expression = targeting.split("=");
              let targetType = expression[0];
              let targetDescription = expression.slice(1).join("=");
              let formattedData = {
                type: targetType,
              };
              if (targetType === 'SEMANTIC_CATEGORY') {
                let sentence = targetDescription.split(":");
                if (sentence.length > 1) {
                  let score = sentence[0];
                  if (score === 'SLIGHTLY_RELATED') {
                    formattedData.score = 1;
                  }
                  if (score === 'SOMEWHAT_RELATED') {
                    formattedData.score = 2;
                  }
                  if (score === 'MODERATELY_RELATED') {
                    formattedData.score = 3;
                  }
                  if (score === 'CLOSELY_RELATED') {
                    formattedData.score = 4;
                  }
                  if (score === 'VERY_CLOSELY_RELATED') {
                    formattedData.score = 5;
                  }
                  if (formattedData.score || score === 'NOT_RELATED' || score === 'WEAKLY_RELATED') {
                    targetDescription = sentence.slice(1).join(":");
                  }
                }
              }
              formattedData.target = targetDescription;
              formattedData.targetShort = targetDescription.substring(0, 100);
              formattedData.targetLong = targetDescription.length >= 100;
              formattedData.showShort = formattedData.targetLong;
              if (formattedData.target) {
                ctrl.targetingExpression = true;
              }
              _.forEach(val, function(stat, keyStat) {
                formattedData[keyStat] = stat;
              });
              return formattedData;
            });
            ctrl.loaded = true;
          }, function (err) {
            $log.warn(err);
            ctrl.error = true;
          }, $scope);
        });

        $scope.$on('$destroy', function() {
          topTargetingCall();
        });
      };
    }
  });

export default MODULE_NAME;