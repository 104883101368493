/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiCodemirror from 'angular-ui-codemirror';

import standardFormModule from '../../common/standard-form-module';
import listHelper from '../../common/controller/list-helper';
import { adnFormHelper } from "../../common/controller/form-helper";
import moment from "moment";

const MODULE_NAME = 'coupon-controller';

export function validityCalculation(coupon) {
  coupon.validity = coupon.validationWarnings && coupon.validationWarnings.length === 0
    && moment(coupon.validFrom).isBefore(moment())
    && moment(coupon.validTo).isAfter(moment()) ? "Valid" : "Invalid";
}

angular.module(MODULE_NAME, [
  uiCodemirror,
  standardFormModule,
  listHelper
])

  .controller('CouponCtrl', function($stateParams, searchResource, adnTimeShifter, model, modelComms) {
    const ctrl = this;

    ctrl.isNew = $stateParams.isNew;

    if (ctrl.isNew) {
      const startDate = moment().startOf('day').toDate(),
        endDate = moment().add(30, 'day').endOf('day').toDate();
      model.validFrom = adnTimeShifter.timeShift(startDate, true);
      model.validTo = adnTimeShifter.timeShift(endDate, true);
    }

    ctrl.productSearch = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": 'Product', objectState: 'ACTIVE'}).then(function(data) {
        ctrl.productResults = data.searchResults;
      });
    };

    let afterInit = function() {
        ctrl.model.type = ctrl.model.type || 'MONETARY';
        ctrl.model.couponStatus = ctrl.model.couponStatus || 'PENDING';
        ctrl.model.oneTimeValidity = ctrl.model.oneTimeValidity ?? true;
        ctrl.model.allProducts = _.get(ctrl.model, ['products'], []).length < 1;
        ctrl.model.couponEndDateType = ctrl.model.couponEndDateType || 'VALID_TO';

        if (ctrl.model.validFrom) {
          ctrl.model.modValidFrom = adnTimeShifter.timeShift(ctrl.model.validFrom);
        }
        if (ctrl.model.validTo) {
          ctrl.model.modValidTo = adnTimeShifter.timeShift(ctrl.model.validTo);
        }

        ctrl.model.claimableAts = ctrl.model.claimableAts || ['CAMPAIGN_CREATION'];

        if (!ctrl.model.expiry) {
          ctrl.model.expiryUnit = "Days";
        } else {
          const inDays = moment.duration(ctrl.model.expiry).asDays();
          const inMonths = moment.duration(ctrl.model.expiry).asMonths();
          const inYears = moment.duration(ctrl.model.expiry).asYears();

          if (inMonths % 1 === 0 && ctrl.model.expiry.indexOf("M") === ctrl.model.expiry.length - 1) {
            ctrl.model.expiryNumber = inMonths;
            ctrl.model.expiryUnit = 'Months';
          } else if (inYears % 1 === 0 && ctrl.model.expiry.indexOf("Y") === ctrl.model.expiry.length - 1) {
            ctrl.model.expiryNumber = inYears;
            ctrl.model.expiryUnit = 'Years';
          } else {
            ctrl.model.expiryNumber = inDays;
            ctrl.model.expiryUnit = 'Days';
          }
        }
        validityCalculation(ctrl.model);
      },
      afterSave = function() {
        ctrl.model.allProducts = ctrl.model.products.length < 1;
        validityCalculation(ctrl.model);
      };
    adnFormHelper.setUpForm(ctrl, model, {afterSave: afterSave, afterInit: afterInit, modelComms: modelComms});

    ctrl.onBeforeSubmit = function() {
      ctrl.model.validFrom = _.isDate(ctrl.model.modValidFrom) ? adnTimeShifter.timeShift(ctrl.model.modValidFrom, true) : null;
      ctrl.model.validTo = _.isDate(ctrl.model.modValidTo) ? adnTimeShifter.timeShift(ctrl.model.modValidTo, true) : null;
      ctrl.model.expiry = ctrl.model.expiryNumber > 0 && ctrl.model.expiryUnit ? "P" + ctrl.model.expiryNumber + ctrl.model.expiryUnit.charAt(0) : null;

      if (ctrl.model.allProducts) {
        ctrl.model.products = [];
      }
    };

  });

export default MODULE_NAME;
