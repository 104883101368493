/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './first-party-audience-edit.html';
import listTemplate from './first-party-audience-list.html';

import standardModule from '../common/standard-module';
import firstPartyAudienceController from './first-party-audience-controller';

const MODULE_NAME = 'first-party-audience-module';

angular.module(MODULE_NAME, [standardModule, firstPartyAudienceController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterAndChildHelper('first-party-audiences.first-party-audience', 'FirstPartyAudience', null, 'app.inv', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list())
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'FirstPartyAudienceCtrl',
            controllerAs: 'resource'
          }
        }

      }, 'FirstPartyAudience', []));
  });

export default MODULE_NAME;