/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardListModule from '../common/standard-list-module';
import workspaceRegistry from '../../components/workspace-registry';

const MODULE_NAME = 'workspaces-list';

angular.module(MODULE_NAME, [
  standardListModule,
  workspaceRegistry
])
  .controller('WorkspaceListCtrl', function(adnListHelper, ResourceFactory, WorkspaceRegistry) {
    const ctrl = this;

    const registryAction = function(workspace, currentType) {
      if (currentType === 'ACTIVE') {
        WorkspaceRegistry.update(workspace);
      } else {
        WorkspaceRegistry.remove(workspace);
      }
    };

    adnListHelper.setUpList(ctrl, ResourceFactory, false, 'workspaceList', {
      afterAction: function(workspaces, currentType) {
        _.forEach(workspaces, function(workspace) {
          registryAction(workspace, currentType);
        });
      }
    });
  });

export default MODULE_NAME;