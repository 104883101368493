/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';

import template from './adunit-targeting.html';

import resources from '../../../components/api/resources/resources';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import searchPagination from '../../common/directives/pagination/search-pagination-component';
import adUnitDimensions from '../../ad-unit/dimension-output-component';
import {ADN_TARGETING_TYPES} from "../targeting-constants";
import {findCountryName} from "../../common/directives/country-select/countries";
import {LocalNetworkInfo} from "../../../components/session/local-network-info";

const MODULE_NAME = 'adunit-targeting-directive';

angular.module(MODULE_NAME, [translate, uiBootstrap, resources, promiseSpinner, searchPagination, adUnitDimensions])

  .directive('adnAdUnitTargeting', function(AdUnit, searchResource, LocalNetworkProfile) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnAdUnitTargeting',
        underModel: '=',
        isLockedDown: '<',
        modelLineItem: '<',
        runningModel: '<',
        mandatoryTargets: '<',
        overridableTargeting: '<',
        mandatory: '<',
        mode: '@'
      },
      link: {
        pre: function(scope) {
          scope.model = scope.model || {};
          scope.model.adUnits = scope.model.adUnits || [];
          scope.model.negated = scope.model.negated || false;
          scope.targetType = ADN_TARGETING_TYPES.adUnit;
        },
        post: function(scope, element, attrs, tabset) {
          scope.loaded = true;
          scope.isPureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();
          scope.selectAll = function() {
            _.forEach(_.get(scope.list, ['searchResults'], []), function(au) {
              au.selected = true;
              scope.model.adUnits.push(au);
            });
            scope.model.adUnits = _.uniqBy(scope.model.adUnits, 'id');
          };

          scope.removeAll = function() {
            scope.model.adUnits = [];
            if (scope.list && scope.list.searchResults) {
              _.forEach(scope.list.searchResults, function(au) {
                au.selected = false;
              });
            }
          };

          scope.select = function(adUnit) {
            adUnit.selected = true;
            scope.model.adUnits.push(adUnit);
          };

          scope.deselect = function(adUnit) {
            _.remove(scope.model.adUnits, ['id', adUnit.id]);
            if (scope.list && scope.list.searchResults) {
              (_.find(scope.list.searchResults, ["id", adUnit.id]) || {}).selected = false;
            }
          };

          const updateSelectedSearchResults = function() {
            let selIds = _.keyBy(scope.model.adUnits, 'id');
            _.forEach(_.get(scope.list, ['searchResults'], []), function(a) {
              a.selected = !!selIds[a.id];
            });
          };

          const countriesSelected = scope.runningModel[ADN_TARGETING_TYPES.siteCountry.targets][ADN_TARGETING_TYPES.siteCountry.apiProp];
          if (_.isArray(countriesSelected) && countriesSelected.length === 1) {
            scope.filter = scope.filter || {};
            scope.filter.country = countriesSelected[0].toLowerCase();
          }

          scope.search = function(newSearch, filterSearch) {
            const metaResolvedParam = filterSearch ? "filterResolved" : "resolved";
            const metaResolvedErrorParam = metaResolvedParam + "Error";
            scope.searchMeta[metaResolvedParam] = false;
            scope.searchMeta[metaResolvedErrorParam] = false;

            if (!filterSearch) {
              scope.filter = {
                siteUrl: null,
                country: _.get(scope, ['filter', 'country']) || null
              };
            }

            if (newSearch) {
              scope.searchMeta.currentPage = 1;
            }

            const queryParams = {q: scope.searchMeta.term || '', types: 'AdUnit', objectState: 'ACTIVE', pageSize: scope.searchMeta.pageSize, page: scope.searchMeta.currentPage - 1};
            if (scope.filter.country) {
              queryParams.country = scope.filter.country;
            }
            if (_.isNumber(scope.searchMeta.amount)) {
              queryParams.rangeField = 'floorPrice.amount';
              queryParams.rangeType = scope.searchMeta.rangeType;
              queryParams.rangeValue = scope.searchMeta.amount;
            }
            if (scope.filter.siteUrl) {
              queryParams.siteUrl = scope.filter.siteUrl;
            }
            const targeting = _.get(scope, ['runningModel'], {});
            if (targeting.siteTarget && targeting.siteTarget.sites && targeting.siteTarget.negated === false) {
              queryParams.site = _.map(targeting.siteTarget.sites, function(s) {
                return s.id || s;
              });
            }
            if (targeting.publisherTarget && targeting.publisherTarget.earningsAccounts && targeting.publisherTarget.negated === false) {
              queryParams.earningsAccount = _.map(targeting.publisherTarget.earningsAccounts, function(s) {
                return s.id || s;
              });
            }
            const orderId = _.get(scope.modelLineItem, ['order', 'id']);
            if (orderId) {
              queryParams.filterBy = 'ORDER_TEAM';
              queryParams.filterByValue = orderId;
            }
            searchResource.query(queryParams).then(function(page) {
              scope.limited = (queryParams.site && queryParams.site.length) > 0 ||
                (queryParams.earningsAccount && queryParams.earningsAccount.length > 0);
              scope.limitedType = scope.isPureMarketplace ? "publisher" : "earnings account";
              scope.list = page;
              page.searchResults = _.map(page.searchResults || [], function(item) {
                item.countryExpanded = item.country ? findCountryName(item.country) : "";
                return item;
              });

              updateSelectedSearchResults();

              const siteUrlBuckets = _.get(page, ['aggregations', 'siteUrl', 'buckets']);
              scope.hasUrls = scope.filter.siteUrl || (siteUrlBuckets && !_.isEmpty(siteUrlBuckets));

              scope.searchMeta[metaResolvedParam] = true;

              const countryBuckets = _.get(page, ['aggregations', 'country', 'buckets']);
              const isMandatory = scope.mandatoryTargets.indexOf(ADN_TARGETING_TYPES.siteCountry.id) > -1;
              scope.hasCountries = LocalNetworkInfo.obtain().getInfo("siteCountries").length > 1 && !isMandatory && (scope.filter.country || (countryBuckets && !_.isEmpty(countryBuckets)));
              scope.hasCountriesTable = LocalNetworkInfo.obtain().getInfo("siteCountries").length > 1 && (scope.filter.country || (countryBuckets && !_.isEmpty(countryBuckets)));
              if (scope.hasCountries) {
                _.forEach(countryBuckets, function(country) {
                  country.name = findCountryName(country.name);
                });
              }

            }).catch(function(err) {
              scope.list = {};
              scope.searchMeta[metaResolvedParam] = true;
              if (_.get(err, ['data', 'code']) === 'error.api.order.no.team') {
                return scope.searchMeta[metaResolvedErrorParam] = "Specify a team on the order to obtain ad unit results here";
              }
              const statusText = _.get(err, ['statusText']);
              if (statusText) {
                scope.searchMeta[metaResolvedErrorParam] = statusText;
              }
            });
          };

          scope.searchMeta = {
            pageSize: 50,
            currentPage: 1,
            rangeType: 'LTE',
            changePage: function() {
              scope.search(false, true);
            },
            resolvedError: '',
            filterResolvedError: '',
            filterResolved: true,
            resolved: true
          };

          scope.hook = {};
          scope.hook.getTemplateData = function() {
            var summary = (scope.model.negated === true ? "NOT: " : "") + _.map(scope.model.adUnits, function(item) {
              return item.name;
            }).join(", ");

            return {
              vm: angular.copy(scope.model),
              model: angular.copy(scope.model),
              constant: ADN_TARGETING_TYPES.adUnit,
              summary: summary
            };
          };

          const copyTemplateFunc = function(template) {
            scope.model = angular.copy(template.data.model);
            updateSelectedSearchResults();
          };
          scope.hook.copyTemplate = copyTemplateFunc;

          tabset.register(ADN_TARGETING_TYPES.adUnit.widget, function() {
            if (scope.query) {
              return;
            }
            scope.search();
            if (!scope.model.adUnits.length) {
              return;
            }
            const ids = _.map(scope.model.adUnits, 'id');
            scope.query = AdUnit.query({ids: ids, pageSize: scope.model.adUnits.length, includeActive: true, includeInactive: true, includeHidden: true}, function(page) {
              const keyedResults = _.keyBy(page.results, 'id');
              _.forEach(scope.model.adUnits, function(sel) {
                const fullAdUnitDetail = keyedResults[sel.id];
                if (fullAdUnitDetail) {
                  _.assign(sel, _.pick(fullAdUnitDetail, ['description', 'minWidth', 'minHeight', 'width', 'height', 'floorPrice', 'requestedInLastDay']));
                  sel.countryExpanded = fullAdUnitDetail.country ? findCountryName(fullAdUnitDetail.country) : "";
                }
              });
            });
          }, copyTemplateFunc);
        }
      }
    };
  });

export default MODULE_NAME;