/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import editTemplate from './layout-example-edit.html';
import copyTemplate from './layout-example-context.html';
import listTemplate from './layout-example-list.html';

import adminModule from '../admin-module';
import layoutExampleController from './layout-example-controller';
import layoutExampleActionsController from './layout-example-actions-controller';

import {ALL_LAYOUTS} from "./examples/all-layouts";

const MODULE_NAME = 'layout-example-module';

angular.module(MODULE_NAME, [adminModule, layoutExampleController, layoutExampleActionsController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('layout-examples.layout-example', 'LayoutExample', 'app.adm', 'app/admin', listTemplate);
    let listInfo = rh.list({
      views: {
        'content@app': {
          controller:  /*@ngInject*/ function() {
            const ctrl = this;
            ctrl.allLayouts = ALL_LAYOUTS;
          },
          controllerAs: 'list',
          template: listTemplate
        },
        'context@app': {
          template: '',
          controller:  /*@ngInject*/ function() {}
        }
      }
    });
    listInfo.resolve = {};
    $stateProvider.state(rh.listPath(), listInfo)
      .state(rh.itemPath(), rh.modelCommsItem({
        resolve: {
          modelComms:  /*@ngInject*/ function() {
            return null;
          },
          model:  /*@ngInject*/ function($stateParams) {
            return _.find(ALL_LAYOUTS, ['id', $stateParams.id]);
          }
        },
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'LayoutExampleCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: copyTemplate,
            controller: 'LayoutExampleActionsCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'LayoutExample'));
  });

export default MODULE_NAME;