/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

const MODULE_NAME = "state-loading-directive";

angular.module(MODULE_NAME, [])

  .directive('adnStateLoading', function() {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        let loadingClass = attrs.adnStateLoading || 'loading',
          eventEndHandler = _.bind(element.removeClass, element, loadingClass);
        scope.$on('$stateChangeStart', _.bind(element.addClass, element, loadingClass));
        scope.$on('$stateChangeSuccess', eventEndHandler);
        scope.$on('$stateChangeError', eventEndHandler);
      }
    };
  });

export default MODULE_NAME;