/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import titleTemplate from '../base/default-title-template.html';
import editTemplate from './reach-analysis-edit.html';

import standardModule from '../common/standard-module';
import reachAnalysisCtrl from './reach-analysis-controller';

const MODULE_NAME = 'reach-analysis-module';

angular.module(MODULE_NAME, [standardModule, reachAnalysisCtrl])

  .config(function($stateProvider) {
    $stateProvider.state('app.reach-analysis', {
      url: '/reach-analysis',
      views: {
        'content@app': {
          template: editTemplate,
          controller: 'ReachAnalysisCtrl',
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: "Reach Analysis"
      }
    });
  });

export default MODULE_NAME;