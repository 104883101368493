/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardAnalysisModule from '../common/standard-analysis-module';

const MODULE_NAME = "external-ad-unit-analysis";

angular.module(MODULE_NAME, [standardAnalysisModule])

  .controller('ExternalAdUnitAnalysisCtrl', function($stateParams, model, modelComms, AdUnit, adnListHelper) {
    const ctrl = this;
    ctrl.query = {};
    ctrl.model = angular.copy(model);

    ctrl.showingChart = '';

    ctrl.isNew = $stateParams.isNew;
    adnListHelper.setUpBasicList(ctrl, AdUnit, 'fullExternalAdUnitsForAdUnit', {
      moreParams: {site: model.id},
      afterFill: function() {
        ctrl.externalAdUnitsEmpty = _.isEmpty(ctrl.items);
        ctrl.externalAdUnits = ctrl.items;
        ctrl.externalAdUnitsCount = Object.keys(ctrl.items).length;
        ctrl.selAdUnit = _.find(ctrl.items, function() {
          return true;
        });
      }
    });

    modelComms.addSubscriber(function(data, type) {
      if (!type && data.name) {
        ctrl.model = data;

        if (ctrl.isNew) {
          ctrl.isNew = false;
        }
      }
    });
  });

export default MODULE_NAME;