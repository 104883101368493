/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../common/standard-form-module';
import adnAuthService from '../../components/auth/auth';

const MODULE_NAME = 'fields-controller';

angular.module(MODULE_NAME, [standardFormModule, adnAuthService])

  .controller('FieldsCtrl', function(VisitorProfileFields) {
    const ctrl = this;

    VisitorProfileFields.get().then(function(fields) {
      ctrl.fields = fields;
      _.forEach(ctrl.fields, function(field) {
        field.aliasesJoined = (field.aliases || []).join(", ");
      });
    });
  });

export default MODULE_NAME;