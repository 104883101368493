/*
 * Copyright © 2025 Adnuntius AS.
 */

import {Inject} from "@angular/core";
import {SearchHelper} from "./search-helper";

export class CategorySearchResource extends SearchHelper {
  constructor(@Inject('$http') $http, @Inject('localUserProfile') localUserProfile) {
    super($http, localUserProfile, 'categories');
  }
}
