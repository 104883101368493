/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from "lodash";

import template from './date-selector-directive.html';
import localNetworkProfile from '../../../../components/session/local-network-profile';
import moment from "moment/moment";

const MODULE_NAME = 'date-selector';

angular.module(MODULE_NAME, [localNetworkProfile])

  .directive('adnDateSelector', function() {
    return {
      restrict: 'A',
      replace: false,
      scope: {},
      bindToController: {
        resource: '<adnDateSelector',
        param: '@adnParam',
        nameParam: '@adnNameParam',
        cssClass: '@cssClass',
        blockSubmit: '<ngDisabled',
        changeCallback: '&adnChangeCallback',
        required: '<adnRequired',
        defaultToZero: '<'
      },
      controllerAs: 'ctrl',
      controller: function($locale, LocalNetworkProfile) {
        const ctrl = this;

        ctrl.$onInit = function() {

          ctrl.required = !!ctrl.required;

          const defaults = LocalNetworkProfile.getDefaults();
          ctrl.pickerOptions = {startingDay: _.isFinite(defaults.startDayCalendar) ? defaults.startDayCalendar : 1};

          let dateFormat = $locale.DATETIME_FORMATS.shortDate;
          ctrl.dateFormat = dateFormat.replace('yy', 'y');


          const initStartDate = ctrl.resource[ctrl.param];

          ctrl.change = function() {
            ctrl.changeCallback();

            if (initStartDate && ctrl.defaultToZero) {
              const initDayOfMonth = moment(initStartDate).date();
              const modDayOfMonth = moment(ctrl.resource[ctrl.param]).date();
              if (moment(ctrl.resource[ctrl.param]).isAfter(moment(initStartDate)) && initDayOfMonth !== modDayOfMonth && moment(ctrl.resource[ctrl.param]).isAfter(moment())) {
                ctrl.resource[ctrl.param] = moment(ctrl.resource[ctrl.param]).startOf('day').toDate();
              }
            }
          };
        };
      },
      template: template
    };
  });

export default MODULE_NAME;