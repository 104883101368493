/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';

const MODULE_NAME = "floor-price-comp";

angular.module(MODULE_NAME, [translate, uiBootstrap])

  .component('adnFloorPriceComponent', {
    template: `
      <div ng-repeat="fp in ctrl.floorPrices">
        {{fp.price | adnStat}}
        <span ng-if="fp.sizes">
          - <span ng-repeat="size in fp.sizes">{{size[0]}}&times;{{size[1]}}<span ng-if="!$last">, </span></span>
        </span>
        <span ng-if="fp.targetingType">
          - <span>{{fp.targetingType | targetingType}}</span>
        </span>
      </div>
      <div ng-if="!ctrl.floorPrices">-</div>
    `,
    controllerAs: 'ctrl',
    bindings: {
      floorPrices: '<'
    },
    controller: function() {
      const ctrl = this;

      ctrl.$onInit = function() {
      };
    }
  });

export default MODULE_NAME;