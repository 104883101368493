/**
 * Copyright © 2025 Adnuntius AS.
 */
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'keyword-frequency-component',
  template: `
    <span class="label {{labelClass}}" title="{{freqRounded}}">{{text}}</span>
  `
})
export class KeywordFrequencyComponent implements OnInit {
  text: string;
  labelClass: string;
  freqRounded: string;
  @Input() freq: number;

  constructor() {
  }

  ngOnInit(): void {
    this.labelClass = "label-default";
    if (this.freq >= 50) {
      this.labelClass = "label-success";
    } else if (this.freq >= 20) {
      this.labelClass = "label-primary";
    }

    this.freqRounded = (Math.round(this.freq * 100) / 100).toFixed(2) + "%";

    if (this.freq > 80) {
      this.text = "> 80%";
    } else if (this.freq > 50) {
      this.text = "50-80%";
    } else if (this.freq >= 35) {
      this.text = "35-50%";
    } else if (this.freq >= 20) {
      this.text = "20-35%";
    } else if (this.freq >= 15) {
      this.text = "15-20%";
    } else if (this.freq >= 10) {
      this.text = "10-15%";
    } else if (this.freq >= 5) {
      this.text = "5-10%";
    } else if (this.freq >= 3) {
      this.text = "3-5%";
    } else if (this.freq >= 2) {
      this.text = "2%";
    } else if (this.freq >= 1) {
      this.text = "1%";
    } else if (this.freq >= 0.45) {
      this.text = "0.5%";
    } else if (this.freq >= 0.1) {
      this.text = "0.1%";
    } else if (this.freq >= 0.01) {
      this.text = "0.01%";
    } else {
      this.text = "< 0.01%";
    }
  }
}
