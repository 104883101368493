/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "promise-spinner-component";

angular.module(MODULE_NAME, [])

  .component('adnPromiseSpinner', {
    transclude: true,
    bindings: {
      resolved: '<',
      andHide: '@',
      style: '@'
    },
    controllerAs: 'ctrl',
    controller: () => {
    },
    template: `
        <p class="text-center" ng-if="!ctrl.resolved">
          <span class="fa fa-2x fa-cog fa-spin"></span>
        </p>
        <div ng-if="!ctrl.andHide || !!ctrl.resolved" style="{{ctrl.style}}">
          <section class="animate-fade" ng-show="!!ctrl.resolved" ng-transclude></section>
        </div>
    `
  });

export default MODULE_NAME;