/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {Uuid} from "../../../util/uuid";

const MODULE_NAME = "notification-preferences";

angular.module(MODULE_NAME, [])

  .factory("NotificationPreferences", function(adnResource, localUserProfile, $q) {
    let NotifPrefs = adnResource('notificationpreferences', {}, {}, 'notificationPreferences');
    NotifPrefs.get = function() {
      let value = {$resolved: false, results: []}, promise;
      let userId = localUserProfile.get().userId;
      promise = NotifPrefs.query({user: userId, orderBy: 'userId'}).$promise;
      value.$promise = promise.then(function(response) {
        let notifPref = new NotifPrefs(response);
        if (!notifPref.user) {
          notifPref = new NotifPrefs({id: Uuid.generate(), userId: userId});
        }
        _.assign(notifPref, value, {$resolved: true});
        return notifPref;
      }, function(response) {
        value.$resolved = true;
        return $q.reject(response);
      });
      return value;
    };
    return NotifPrefs;
  });

export default MODULE_NAME;