/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {OBJECT_TYPE} from "../common/constants/object-type";

import copyActionsTemplate from './creative-copy-actions.html';
import editTemplate from './creative-edit.html';
import bulkEditTemplate from './bulk-upload-edit.html';
import videoTemplate from './video-upload.html';
import listTemplate from './creative-list.html';

import standardModule from '../common/standard-module';
import targetingModule from '../targeting/targeting-module';
import creativeController from './creative-controller';
import bulkCreativeController from './bulk-upload-creative-controller';
import creativeTagComponent from './creative-tag-component';
import creativeActionsController from './creative-actions-controller';
import videoUploadController from './video-upload-controller';
import {calculateCrumbs} from "../common/controller/crumb-calculator";
import {creativeBulkAction, creativeListCommons} from "./creative-commons";
import bulkUploadTemplate from "./bulk-upload-context-list.html";
import {PERMISSIONS} from "../../components/auth/auth-constants";

import creativeListComponent from './creative-list-component';

const MODULE_NAME = 'creative-module';

angular.module(MODULE_NAME, [standardModule, creativeListComponent, bulkCreativeController, creativeController, creativeActionsController, targetingModule, creativeTagComponent, videoUploadController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('creatives.creative', 'Creative', 'app.adv', 'app', listTemplate);

    const creativeId = '';//'rcjc5h96wg3px1bg';

    let paramsExtras = {modelId: '', modelName: '', fromLineItem: false, fromCreativeSet: false};
    $stateProvider.state(rh.listPath(), rh.list({
      views: _.merge({}, {
        'content@app': {
          controller: 'ResourceListCtrl',
          controllerAs: 'list',
          template: listTemplate
        },
        'context@app': {
          template: bulkUploadTemplate,
          controller: 'BaseContextController',
          controllerAs: 'ctrl'
        }
      }),
      resolve: {
        readOnly: function(LocalUserPermissions) {
          return !LocalUserPermissions.hasAny(PERMISSIONS.manageAdvertising.apiKey) && !LocalUserPermissions.hasAny(PERMISSIONS.axAdvertiser.apiKey) && !LocalUserPermissions.hasAny(PERMISSIONS.creativeAgency.apiKey);
        },
        extension: /*@ngInject*/ function($state, LocalNetworkProfile) {
          return {
            func: function(ctrl, afterFill) {
              creativeListCommons(ctrl, afterFill, LocalNetworkProfile, $state);
            }
          };
        },
        sectionParam: function() {
          return 'creatives';
        },
        bulk: function() {
          return {
            bulkAction: creativeBulkAction
          };
        }
      }
    }))
      .state(rh.newPath('videoupload'), rh.item({
        url: '/video-upload',
        resolve: {
          uploadResponse: /*@ngInject*/ function(Asset) {
            if (!creativeId) {
              return null;
            }
            return Asset.layoutSuggestionsFromCreative(creativeId);
          },
          creative: /*@ngInject*/ function(Creative) {
            if (!creativeId) {
              return null;
            }
            return Creative.get({id: creativeId}).$promise;
          },
          creativeType: function() {
            return 'CREATIVE';
          },
          modalHolder: function() {
            return {modal: {close: _.noop}};
          }
        },
        views: {
          'content@app': {
            template: videoTemplate,
            controller: 'VideoUploadCtrl',
            controllerAs: 'ctrl'
          },
          'title@app': {
            template: `<h1>Video Upload</h1>`
          }
        },
        data: {
          title: 'Video Upload'
        }
      }))
      .state(rh.newPath('bulkupload'), rh.item({
        url: '/bulk-upload',
        resolve: {
          creativeType: function() {
            return 'CREATIVE';
          }
        },
        views: {
          'content@app': {
            template: bulkEditTemplate,
            controller: 'BulkCreativeCtrl',
            controllerAs: 'resource'
          },
          'title@app': {
            template: `<h1>Creative Bulk Upload</h1>`
          }
        },
        data: {
          title: 'Creative Bulk Upload'
        }
      }))
      .state(rh.newItemPath(), rh.newItem(paramsExtras))
      .state(rh.itemPath(), rh.modelCommsItem({
        params: paramsExtras,
        resolve: {
          creativeType: function() {
            return OBJECT_TYPE.Creative;
          },
          lineItemInfo: /*@ngInject*/ function($stateParams) {
            return {
              id: $stateParams.modelId,
              name: $stateParams.modelName,
              fromLineItem: $stateParams.fromLineItem,
              fromCreativeSet: $stateParams.fromCreativeSet,
              advertiserId: $stateParams.advertiserId,
              advertiserName: $stateParams.advertiserName
            };
          },
          model: /*@ngInject*/ function($stateParams, Creative, LocalNetworkProfile) {
            if ($stateParams.isNew) {
              let networkDefaults = LocalNetworkProfile.getDefaults();
              return Creative.create({
                name: $stateParams.modelName ? (networkDefaults.defaultCreativeName || '').replace("{lineItemName}", $stateParams.modelName).replace("{fileName}", '') : "",
                lineItem: {id: $stateParams.fromLineItem ? $stateParams.modelId : ''},
                creativeSet: {id: $stateParams.fromCreativeSet ? $stateParams.modelId : ''},
                id: $stateParams.id
              });
            }
            return Creative.getHydrated($stateParams);
          }
        },
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'CreativeCtrl',
            controllerAs: 'resource'
          },
          'context@app': {
            template: copyActionsTemplate,
            controllerAs: 'ctrl',
            controller: 'CreativeActionsCtrl'
          },
          'title@app': _.merge(rh.getDefaultTitleView(), {
            controller: /*@ngInject*/ function(model, modelComms, LocalUserPermissions, lineItemInfo, dataViewResource, CreativeSetInfo) {
              const ctrl = this;
              ctrl.objectType = OBJECT_TYPE.Creative;
              ctrl.itemName = model.name || 'New';
              ctrl.perms = LocalUserPermissions.getAllVisPermissions();
              ctrl.sectionPerms = LocalUserPermissions.getSectionPerms();

              ctrl.crumbs = [];

              function getCrumbInfo() {
                if (ctrl.crumbs && ctrl.crumbs.length > 0) {
                  return;
                }
                let parentObject = lineItemInfo.id ? lineItemInfo : model.lineItem ? model.lineItem : model.creativeSet;
                if (!parentObject || !parentObject.id) {
                  return;
                }
                if (lineItemInfo.fromLineItem || _.get(model, ['lineItem', 'id'])) {
                  dataViewResource.get('LineItemView', {id: parentObject.id}, false).then(function(dataViewObj) {
                    let newCrumbs = [];

                    let crumbs = ['Advertiser', 'Order', 'LineItem'];
                    if (LocalUserPermissions.isSelfService() || LocalUserPermissions.isAxPublisherOnly()) {
                      crumbs = ['LineItem'];
                    }

                    _.forEach(crumbs, function(lCrumb) {
                      calculateCrumbs(ctrl, lCrumb, dataViewObj, newCrumbs);
                    });
                    modelComms.relDataUpdated(dataViewObj.lineItem, OBJECT_TYPE.LineItem);
                    modelComms.relDataUpdated(dataViewObj.advertiser, OBJECT_TYPE.Advertiser);
                    ctrl.crumbs = newCrumbs;
                  });
                } else if (lineItemInfo.fromCreativeSet || _.get(model, ['creativeSet', 'id'])) {
                  let newCrumbs = [];

                  const doCreativeSetCrumbs = function(dataViewObj) {
                    let crumbs = ['Advertiser', 'Folio', 'CreativeSet'];
                    _.forEach(crumbs, function(lCrumb) {
                      calculateCrumbs(ctrl, lCrumb, dataViewObj, newCrumbs);
                    });
                    modelComms.relDataUpdated(dataViewObj.creativeSet, OBJECT_TYPE.CreativeSet);
                    modelComms.relDataUpdated(dataViewObj.folio, OBJECT_TYPE.Folio);
                    modelComms.relDataUpdated(dataViewObj.advertiser, OBJECT_TYPE.Advertiser);
                    ctrl.crumbs = newCrumbs;
                  };

                  if (_.get(model, ['creativeSet', 'id']) && !_.get(lineItemInfo, ['advertiser', 'id'])) {
                    CreativeSetInfo.get({id: model.creativeSet.id}, function(cs) {
                      doCreativeSetCrumbs({
                        creativeSet: model.creativeSet,
                        folio: cs.folio,
                        advertiser: cs.advertiser
                      });
                    });
                  } else {
                    doCreativeSetCrumbs({
                      creativeSet: {
                        id: lineItemInfo.id,
                        name: lineItemInfo.name
                      },
                      advertiser: {
                        id: lineItemInfo.advertiserId,
                        name: lineItemInfo.advertiserName
                      }
                    });
                  }
                } else {
                  ctrl.crumbs = [];
                }
              }

              modelComms.addSubscriber(function(data, type) {
                if (type) {
                  return;
                }
                if (!_.get(model, ['lineItem', 'id']) && _.get(data, ['lineItem', 'id'])) {
                  model.lineItem = data.lineItem;
                }
                if (!_.get(model, ['creativeSet', 'id']) && _.get(data, ['creativeSet', 'id'])) {
                  model.creativeSet = data.creativeSet;
                }
                ctrl.itemName = data.name || 'New';
                getCrumbInfo();
              });
              getCrumbInfo();
            }
          })
        }
      }));
  });

export default MODULE_NAME;