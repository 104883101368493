/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import _ from 'lodash';

import localNetworkProfile from '../../../components/session/local-network-profile';

const MODULE_NAME = "team-selector-comp";

angular.module(MODULE_NAME, [translate, uiBootstrap, localNetworkProfile])

  .component('adnTeamSelector', {
    controllerAs: 'ctrl',
    bindings: {
      teams: '<',
      teamsWarning: '<',
      model: '<',
      param: '@'
    },
    controller: function() {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.param = ctrl.param || 'teams';

        ctrl.indexTeams = function() {
          ctrl.teamNames = _.keyBy(ctrl.teams, function(team) {
            return team.id;
          });
        };
      };
    },
    template: `
        <div class="form-control-static">
          <adn-promise-spinner resolved="ctrl.teams" and-hide="true">
            <p ng-init="ctrl.indexTeams()"><span ng-if="!ctrl.model[ctrl.param].length">None</span><span ng-repeat="team in ctrl.model[ctrl.param]">{{ctrl.teamNames[team].name}}<span ng-if="!$last">, </span></span></p>
            <p><a ng-click="expanded=!expanded"><span class="fa fa-chevron-down" ng-class="{'fa-rotate-270': !expanded}"></span> <span ng-if="!expanded">Show</span><span ng-if="expanded">Hide</span> teams for selection</a></p>
            <div ng-if="expanded">
              <p class="alert alert-warning" ng-if="ctrl.teamsWarning">The advertiser could not be added to all the teams you selected because you lack the correct permission for the teams.</p>
              <div adn-form-control-validation>
                <ul class="list-unstyled doubleColumned">
                  <li class="checkbox" ng-repeat="team in ::ctrl.teams">
                    <label>
                      <input type="checkbox" name="ctrl.model.teams" checklist-model="ctrl.model[ctrl.param]" checklist-value="team.id" adn-form-control>
                      {{team.name}}
                      <div class="small help-block"><small>{{'team.type.' + team.type | translate}}</small></div>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </adn-promise-spinner>
        </div>
    `
  });

export default MODULE_NAME;