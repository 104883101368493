/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../common/standard-form-module';
import {adnFormHelper} from "../common/controller/form-helper";

const MODULE_NAME = 'external-ad-unit-controller';

angular.module(MODULE_NAME, [standardFormModule])

  .controller('ExternalAdUnitCtrl', function(model, modelComms, Creative, AdUnit, adnListHelper, ExternalDemandSource) {
    const ctrl = this;

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms,
      afterInit: function() {
        ctrl.model.dimensions = _.isArray(ctrl.model.dimensions) ? ctrl.model.dimensions : [[]];
        ctrl.model.dimensions[0] = _.isArray(ctrl.model.dimensions[0]) ? ctrl.model.dimensions[0] : [];
      }
    });

    ctrl.extDemandSources = false;
    ExternalDemandSource.query().$promise.then(function(extDemandSourcesPage) {
      ctrl.extDemandSources = extDemandSourcesPage.results;

      if (ctrl.extDemandSources.length < 1) {
        return;
      }

      if (!ctrl.model.externalDemandSource && ctrl.extDemandSources.length === 1) {
        ctrl.model.externalDemandSource = ctrl.extDemandSources[0];
      }

      const extDemSourceId = _.get(ctrl.model, ['externalDemandSource', 'id']);
      if (extDemSourceId) {
        const fullExtDemandSource = _.find(ctrl.extDemandSources, ['id', extDemSourceId]);
        ctrl.model.externalDemandSource.externalPartner = _.get(fullExtDemandSource, 'externalPartner') || ctrl.model.externalDemandSource.externalPartner;
      }
    });

    ctrl.addDimensions = function() {
      ctrl.model.dimensions = ctrl.model.dimensions || [];
      ctrl.model.dimensions.push([]);
      ctrl.editsMade();
    };
    ctrl.eventHook = {};

    ctrl.removeDimensions = function(index) {
      _.pullAt(ctrl.model.dimensions, index);
      ctrl.eventHook.removeControlHook('dims' + index + '.width');
      ctrl.eventHook.removeControlHook('dims' + index + '.height');
      ctrl.editsMade();
    };

    ctrl.onBeforeSubmit = function() {
      // TODO: this is required to make the API read dimensions. Should be removed when the API is no longer backwards compatible.
      delete ctrl.model.width;
      delete ctrl.model.height;
    };

    adnListHelper.setUpBasicList(ctrl, AdUnit, 'fullAdUnitsForExternalAdUnit', {moreParams: {externalAdUnit: model.id}, noInitQuery: true});
    if (!ctrl.isNew) {
      ctrl.makeQuery();
    }

    ctrl.creativeList = {};
    adnListHelper.setUpBasicList(ctrl.creativeList, Creative, 'fullCreativesForExternalAdUnit', {moreParams: {externalAdUnit: model.id}, noInitQuery: true});
    if (!ctrl.isNew) {
      ctrl.creativeList.makeQuery();
    }
  });

export default MODULE_NAME;