/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import Highcharts from 'highcharts';
import _ from 'lodash';

import reachAnalysisComp from '../common/controller/reach-analysis-component';

const MODULE_NAME = 'reach-analysis-controller';

angular.module(MODULE_NAME, [reachAnalysisComp])

  .controller('ReachAnalysisCtrl', function(NetworkForecast, reachEstimateResource, localUserProfile, LocalUserPermissions) {
    const ctrl = this;
    ctrl.copyHook = {
      initCompare: function() {
        ctrl.compareReachAnalysis = true;
      }
    };
    ctrl.compareReachAnalysis = false;

    ctrl.isAxAdvertiser = LocalUserPermissions.isAxAdvertiser();
    ctrl.isSelfService = LocalUserPermissions.isSelfService();

    ctrl.canSeeCharts = localUserProfile.canSeeReachCharts();
    if (!ctrl.canSeeCharts) {
      return;
    }

    reachEstimateResource.diag(function(diagnostics) {
      ctrl.diagnostics = diagnostics;
      var timestamps = _.map(diagnostics.chunks, 'time');
      var samples = _.map(diagnostics.chunks, 'availableSamples');
      Highcharts.chart('samples', {
        chart: {type: 'column'},
        title: {text: 'Samples'},
        xAxis: {categories: timestamps},
        yAxis: [
          {title: {text: 'Samples'}, min: 0}
        ],
        series: [
          {name: 'Available Samples', data: samples, yAxis: 0}
        ]
      });
    });

    NetworkForecast.get(function(forecast) {
      ctrl.forecast = forecast;

      function label(chunk) {
        return chunk.dayOfWeek + ' ' + chunk.time;
      }

      const timestamps = _.map(forecast.chunks, label);
      const requests = _.map(forecast.chunks, 'requestsLastWeek');
      const predictions = _.map(forecast.chunks, 'requestsNextWeek');

      Highcharts.chart('requests', {
        chart: {type: 'line'},
        title: {text: 'Network Traffic'},
        xAxis: {categories: timestamps},
        yAxis: {title: {text: 'Requests'}},
        series: [{
          name: 'Observed Last Week',
          data: requests
        }, {
          name: 'Predicted Next Week',
          data: predictions
        }]
      });
    });

  });


export default MODULE_NAME;