/**
 * Copyright © 2025 Adnuntius AS.
 */
import { Component, Input } from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'undo-delete',
  template: `
    <div *ngIf="undoList.undoItems && undoList.undoItems.length" class="alert alert-success">
      <p>
        {{'message.undo' + undoList.currentAction + '.text' | translate: {size: undoList.undoItems.length} }}&nbsp;
        <a (click)="undoList.undoBulkAction(param)">{{'message.undo' + undoList.currentAction + '.linkText' | translate}}&nbsp;</a>
      </p>
    </div>
    <div *ngIf="undoList.undoBulkSelection && undoList.undoBulkSelection.length" class="alert alert-success">
      <p>
        Bulk edit on {{undoList.undoBulkSelection.length}} selections was successful.
        <a (click)="undoList.undoMiscBulkAction(param)">Undo the bulk edit?</a>
      </p>
    </div>
  `
})
export class UndoDeleteComponent {
  @Input() undoList: any;
  @Input() param: string;

  constructor(public translate: TranslateService) {
  }
}

