/**
 * Copyright © 2025 Adnuntius AS.
 */
import {Component, Input, OnInit} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'line-item-source-label',
  template: `<span class="label {{labelClass[source]}}" *ngIf="source">{{ "lineItem.source." + source | translate }}</span>`
})
export class LineItemSourceLabelComponent implements OnInit {
  labelClass: Record<string, string>;
  @Input() source: string;

  constructor(public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.labelClass = {};
    this.labelClass['DIRECT'] = 'label-primary';
    this.labelClass['SELF_SERVICE'] = 'label-info';
    this.labelClass['MARKETPLACE'] = 'label-default';
  }
}