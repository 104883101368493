/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import twoPanelTemplate from '../../common/two-panel.html';
import editTemplate from './team-edit.html';
import analysisTemplate from './team-analysis.html';
import listTemplate from './team-list.html';

import adminModule from '../admin-module';
import teamController from './team-controller';
import teamAnalysis from './team-analysis';
import teamActions from './team-actions-controller';
import feeDisplay from './team-fee-component';
import {PERMISSIONS} from "../../../components/auth/auth-constants";
import copyTemplate from "../../common/copy-actions.html";
import _ from "lodash";
import {getTargetingType} from "../../targeting/targeting-constants";

const MODULE_NAME = 'team-module';

angular.module(MODULE_NAME, [adminModule, feeDisplay, teamController, teamAnalysis, teamActions])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('teams.team', 'Team', 'app.adm', 'app/admin', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      data: {
        app: ['ADVERTISING', 'DATA']
      },
      resolve: {
        extension: /*@ngInject*/ function(LocalNetworkProfile) {
          return {
            func: function(ctrl, afterFill) {
              if (afterFill) {
                return;
              }
              ctrl.teamFields = ['name', 'type', 'externalReference'];

              if (LocalNetworkProfile.isPureMarketplacePlatform()) {
                ctrl.teamFields.push({param: '', label: 'targeting'});
                ctrl.teamFields.push({param: '', label: 'fees'});
              }

              ctrl.selFields = _.map(ctrl.teamFields, function(field) {
                return _.isString(field) ? field : field.label;
              });

              ctrl.getTargetTranslation = function(apiValue) {
                return getTargetingType(apiValue).title;
              };
            }
          };
        },
        readOnly: /*@ngInject*/ function (LocalUserPermissions) {
          return !LocalUserPermissions.hasNetwork(PERMISSIONS.manageSystem.apiKey)
            && !LocalUserPermissions.hasDataNetworkPermission(PERMISSIONS.dataAdmin.apiKey)
            && !LocalUserPermissions.hasNetwork(PERMISSIONS.axAdvertiserAdmin.apiKey);
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: null,
            template: twoPanelTemplate
          },
          'context@app': {
            controller: 'TeamActionsCtrl',
            controllerAs: 'ctrl',
            template: copyTemplate
          },
          'leftPanel@app.teams.team': {
            template: editTemplate,
            controller: 'TeamCtrl',
            controllerAs: 'resource'
          },
          'rightPanel@app.teams.team': {
            template: analysisTemplate,
            controller: 'TeamAnalysisCtrl',
            controllerAs: 'resource'
          }
        }
      }, 'Team'));
  });

export default MODULE_NAME;