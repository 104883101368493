/**
 * Copyright © 2025 Adnuntius AS.
 */
export class Resource {
  private resource: any;

  constructor(adnResource, endPoint: string) {
    this.resource = adnResource(endPoint);
  }

  query(a, b, c, d) {
    return this.resource.query(a, b, c, d);
  }

  get(a, b, c, d) {
    return this.resource.get(a, b, c, d);
  }

  create(data: any) {
    return new this.resource(data);
  }
}