/**
 * Copyright © 2025 Adnuntius AS.
 */
import {Component, Input, OnInit} from '@angular/core';
import {EXECUTION_STATUS} from "../../components/api/resources/resources-constants";

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'report-status-label',
  template: `<span class="label {{labelClass[reportStatus]}}">{{ "report.status." + reportStatus | translate }}</span>`
})
export class ReportStatusComponent implements OnInit {
  labelClass: Record<string, string>;
  @Input() reportStatus: any;

  constructor(public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.labelClass = {};
    this.labelClass[EXECUTION_STATUS.pending] = 'label-info';
    this.labelClass[EXECUTION_STATUS.processing] = 'label-info';
    this.labelClass[EXECUTION_STATUS.completed] = 'label-success';
    this.labelClass[EXECUTION_STATUS.rejected] = 'label-default';
    this.labelClass[EXECUTION_STATUS.failed] = 'label-danger';
    this.labelClass[EXECUTION_STATUS.cancelled] = 'label-info';
    this.labelClass[EXECUTION_STATUS.queued] = 'label-info';
    this.labelClass[EXECUTION_STATUS.timedOut] = 'label-danger';
  }
}
