/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "third-party-audience";

angular.module(MODULE_NAME, [])

  .factory("ThirdPartyAudience", function(adnResource) {
    return adnResource('thirdpartyaudiences', {}, {}, 'thirdPartyAudiences');
  });

export default MODULE_NAME;