/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import ngFileUpload from 'ng-file-upload';
import translate from 'angular-translate';

import template from './reference-data-upload.html';

import resource from '../../../components/api/resources/resources';

const MODULE_NAME = "ref-data-upload-directive";

angular.module(MODULE_NAME, [ngFileUpload, uiBootstrap, translate, resource])

  .directive('adnReferenceDataUpload', function(ReferenceData, FieldMapping, ContextServiceKeywordUpload) {
    return {
      template: template,
      restrict: 'A',
      require: '?^^form',
      scope: {
        name: '@adnName',
        uploadType: '@uploadType',
        onUpdate: '&adnOnUpdate',
        resourceId: '&adnResourceId'
      },
      link: {
        pre: function(scope) {
          scope.name = scope.name || 'upload';
          scope.uploadType = scope.uploadType || 'categories';
          scope.allFilesProcessed = true;
          scope.validationWarnings = [];
          scope.onUpdate = scope.onUpdate || _.noop;
          scope.resourceId = scope.resourceId() || '';
        },
        post: function(scope, element, attrs, form) {
          let fileUploadLimit = scope.maxFiles || 1,
            ctrl = form || {$setValidity: _.noop};

          let resource = ReferenceData;
          scope.accept = ".txt";
          if (scope.uploadType === 'fieldmappings') {
            resource = FieldMapping;
            scope.accept = ".csv";
          } else if (scope.uploadType === 'trackedKeywords') {
            resource = ContextServiceKeywordUpload;
            scope.accept = ".csv";
          }

          let updateWarnings = function(paramWarnings) {
            if (paramWarnings) {
              scope.validationWarnings = scope.validationWarnings.concat(_.isArray(paramWarnings) ? paramWarnings : [paramWarnings]);
            } else {
              scope.validationWarnings = [];
            }
          };

          scope.upload = function(allFiles) {
            scope.filesFailedUpload = [];
            updateWarnings();
            if (!_.isArray(allFiles)) {
              ctrl.$setValidity('upload', false);
              return;
            }

            let files = _.clone(_.slice(allFiles, 0, fileUploadLimit));
            if (allFiles.length > fileUploadLimit) {
              updateWarnings({
                text: 'Only {{fileUploadLimit}} file can be uploaded at a time. The last {{restFilesCount}} of the {{allFilesCount}} selected files were not uploaded.',
                parameters: {
                  fileUploadLimit: fileUploadLimit,
                  restFilesCount: allFiles.length - fileUploadLimit,
                  allFilesCount: allFiles.length
                },
                preventsAdServing: false
              });
            }
            allFiles = null;

            // add a pending validation
            ctrl.$setValidity('upload');
            scope.allFilesProcessed = false;
            scope.allFilesProgress = 0;

            let setAllFilesProgress = function() {
                let progress = _.reduce(files, function(result, file) {
                  return result + (_.isFinite(file.progress) ? file.progress : 0);
                }, 0);
                scope.allFilesProgress = Math.min(100, parseInt(progress / files.length, 10));
              },
              allFilesChecks = function() {
                if (!_.find(files, ['uploaded', undefined])) {
                  scope.allFilesProcessed = true;
                }

                // if at least one file is valid, then say the upload is a success
                ctrl.$setValidity('upload', _.some(files, 'validity'));

                scope.filesFailedUpload = _.filter(files, ['validity', false]);
              };

            // TODO: when/if the API supports it, should do multi-file upload with one request.
            _.forEach(files, function(file) {
              file.validity = undefined;

              resource.upload({file: file}, scope.uploadType, scope.resourceId).then(function(fileResponse) {
                file.validity = true;
                allFilesChecks();
                scope.onUpdate();

                let validationWarnings = _.get(fileResponse, ['data', 'validationWarnings']);
                if (validationWarnings) {
                  updateWarnings(validationWarnings);
                }
              }, function(error) {
                file.validity = false;

                let warnings = [];
                if (!error.data || error.status === -1) {
                  // sometimes the API can crap out and issue a time-out, which is handled here.
                  warnings.push({
                    text: "The upload failed to complete. Try uploading again or updating the format of the data in the file you're trying to upload.",
                    preventsAdServing: false
                  });
                } else {
                  // API can return validation error or general error.
                  // General error on unsupported mime type, validation error on everything else (like file size too big).
                  if (error.data.errors) {
                    warnings = _.flatten(warnings.concat(_.map(error.data.errors, function(value) {
                      let returnValue = angular.copy(value.error);
                      returnValue.preventsAdServing = false;
                      return returnValue;
                    })));
                  } else if (error.data.map) {
                    warnings = _.flatten(warnings.concat(_.map(error.data.map, function(value) {
                      return {text: value, preventsAdServing: false};
                    })));
                  } else if (error.data) {
                    warnings.push(error.data);
                  }
                }

                updateWarnings(warnings);
                allFilesChecks();
              }, function(progressEvent) {
                file.progress = Math.min(100, parseInt(100 * progressEvent.loaded / progressEvent.total, 10));
                setAllFilesProgress();
              });
            }, function() {
              ctrl.$setValidity('upload', false);
            });
          };
        }
      }
    };
  });

export default MODULE_NAME;
