/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';
import moment from 'moment';

import template from './date-targeting.html';
import minutesTemplate from './date-minutes.html';
import {ADN_TARGETING_TYPES} from "../targeting-constants";
import {TargetingHelper} from "../targeting-helper";

const MODULE_NAME = 'date-targeting-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnDateTargeting', function($templateCache) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnDateTargeting',
        lineItemStartDate: '@',
        underModel: '=',
        mandatory: '<',
        overridableTargeting: '<',
        isLockedDown: '<',
        mode: '@'
      },
      link: {
        pre: function(scope) {
          if (!scope.model) {
            scope.model = {};
          }
          scope.model.dateRanges = scope.model.dateRanges || [];
          scope.model.timeZoneSetting = scope.model.timeZoneSetting || 'USER';
          scope.targetType = ADN_TARGETING_TYPES.date;
        },
        post: function(scope, element, attrs, tabset) {
          $templateCache.put('date-minutes.html', minutesTemplate);
          scope.loaded = true;
          let apiFormat = "YYYY-MM-DDTHH:mm",
            dateToKey = function(date) {
              return moment(date).format('YYYY-MM-DD');
            },
            completeApiDay = function(date) {
              return {
                first: moment(date.first).startOf('day').format(apiFormat),
                second: moment(date.first).add(1, 'd').startOf('day').format(apiFormat)
              };
            },
            createTimeTemplate = function(d) {
              let lineItemStartDate = moment(scope.lineItemStartDate);
              let dateToUse = lineItemStartDate.isAfter(moment()) ? lineItemStartDate : moment();
              return {
                first: d && d.first && moment(d.first).isValid() ? moment(d.first).utc().toDate() : dateToUse.startOf('day').toDate(),
                second: d && d.second && moment(d.second).isValid() ? moment(d.second).utc().toDate() : dateToUse.endOf('day').toDate()
              };
            };

          var init = function() {
            scope.vm = {dateRanges: angular.copy(scope.model.dateRanges)};
            _.forEach(scope.vm.dateRanges, function(d) {
              d.entireDay = TargetingHelper.isEntireDay(d);
              d.time = createTimeTemplate(d);
              d.formatted = {
                first: moment(d.first).toDate(),
                second: moment(d.second).toDate()
              };
            });
          };
          init();

          scope.dates = _.keyBy(scope.model.dateRanges, function(d) {
            return dateToKey(d.first);
          });

          scope.datepickerOptions = {
            showWeeks: false
          };

          scope.addDate = function() {
            let times = createTimeTemplate();
            scope.vm.dateRanges.push({
              entireDay: true,
              time: times,
              formatted: {
                first: moment(times.first).utc().toDate(),
                second: moment(times.second).utc().toDate()
              }
            });
          };

          scope.remove = function(index) {
            _.pullAt(scope.vm.dateRanges, index);
          };

          let prepareForSave = function() {
            let formattedForSave = [];
            _.forEach(scope.vm.dateRanges, function(d) {
              if (!moment(d.formatted.first).isValid() || !moment(d.formatted.second).isValid()) {
                return;
              }
              if (d.entireDay) {
                formattedForSave.push(completeApiDay(d.formatted));
              } else {
                if (!moment(d.time.first).isValid() || !moment(d.time.second).isValid()) {
                  return;
                }
                let firstDate = moment(d.formatted.first).set('hour', moment(d.time.first).get('hour')).set('minute', moment(d.time.first).get('minute')),
                  secondDate = moment(d.formatted.second).set('hour', moment(d.time.second).get('hour')).set('minute', moment(d.time.second).get('minute'));
                if (moment(firstDate).isAfter(moment(secondDate))) {
                  return;
                }
                formattedForSave.push({
                  first: moment(firstDate).format(apiFormat),
                  second: moment(secondDate).format(apiFormat)
                });
              }
            });
            scope.model.dateRanges = formattedForSave;
          };

          scope.hook = {};
          scope.hook.getTemplateData = function() {
            prepareForSave();
            return {
              vm: angular.copy(scope.vm),
              model: angular.copy(scope.model),
              constant: ADN_TARGETING_TYPES.date,
              summary: ''
            };
          };

          const copyTemplateFunc = function(template) {
            scope.model = angular.copy(template.data.model);
            init();
          };
          scope.hook.copyTemplate = copyTemplateFunc;

          tabset.register(ADN_TARGETING_TYPES.date.widget, prepareForSave, copyTemplateFunc);
          tabset.callBeforeSubmit(prepareForSave);
          tabset.registerAllTabs(prepareForSave);
        }
      }
    };
  });

export default MODULE_NAME;