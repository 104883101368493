/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import editTemplate from './user-profile-edit.html';
import titleTemplate from '../../base/default-title-template.html';

import adminModule from '../admin-module';
import userProfileCtrl from './user-profile-controller';

const MODULE_NAME = 'user-profile-module';

angular.module(MODULE_NAME, [adminModule, userProfileCtrl])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('user-profiles.user-profile', 'UserProfile', 'app.adm', 'app/admin', null);
    $stateProvider.state('app.user-profile',
      _.assign(rh.item(), {
        url: '/user-profile',
        parent: 'app.adm',
        data: {
          app: ['ADVERTISING', 'DATA']
        },
        views: {
          'content@app': {
            template: editTemplate,
            controller: 'UserProfileCtrl',
            controllerAs: 'resource'
          },
          'title@app': {
            template: titleTemplate
          }
        },
      }));
  });

export default MODULE_NAME;