/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

import template from './day-parting-targeting.html';
import {ADN_TARGETING_TYPES} from "../targeting-constants";

const MODULE_NAME = 'day-parting-targeting-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnDayPartingTargeting', function() {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnDayPartingTargeting',
        underModel: '=',
        isLockedDown: '<',
        overridableTargeting: '<',
        mandatory: '<',
        mode: '@'
      },
      link: {
        post: function(scope, element, attrs, tabset) {
          scope.loaded = true;
          scope.targetType = ADN_TARGETING_TYPES.dayParting;
          scope.weekDays = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"];
          scope.daysOfWeek = _.union(scope.weekDays, ["SATURDAY", "SUNDAY"]);
          let daysOfWeekSort = function(a, b) {
            return _.indexOf(scope.daysOfWeek, a) - _.indexOf(scope.daysOfWeek, b);
          };
          let numberSort = function(a, b) {
            return a - b;
          };
          let daysOfWeekUpdate = function(vmObj) {
            vmObj.daysOfWeek.sort(daysOfWeekSort);
            _.forEach(vmObj.daysOfWeek, function(day) {
              vmObj.days[day] = true;
            });
          };

          let doHourString = function(entryObj) {
            let hourString = [];
            let prevHour = -100;
            _.forEach(entryObj.hoursOfDay, function(hour) {
              if (hourString.length === 0) {
                hourString.push({start: hour, end: hour});
              } else {
                if (prevHour + 1 === hour) {
                  hourString[hourString.length - 1].end = hour;
                } else {
                  hourString.push({start: hour, end: hour});
                }
              }
              prevHour = hour;
            });
            _.forEach(hourString, function(hsObj) {
              hsObj.string = hsObj.start + ":00-" + (hsObj.end || hsObj.start) + ":59";
            });
            return hourString;
          };

          let init = function() {
            scope.vm = angular.copy(scope.model);
            _.forEach(scope.vm, function(entryObj) {
              entryObj.days = {};

              daysOfWeekUpdate(entryObj);

              let hourArray = [];
              _.forEach(entryObj.hoursOfDay, function(hour) {
                hourArray.push(parseInt(hour, 10));
              });
              hourArray.sort(numberSort);
              entryObj.hoursOfDay = hourArray;

              entryObj.hours = {};
              _.forEach(entryObj.hoursOfDay, function(hour) {
                entryObj.hours[hour] = true;
              });

              entryObj.hourString = doHourString(entryObj);
            });
          };
          init();

          scope.earlyMorningHours = [0, 1, 2, 3, 4, 5];
          scope.morningHours = [6, 7, 8, 9, 10, 11];
          scope.afternoonHours = [12, 13, 14, 15, 16, 17];
          scope.eveningHours = [18, 19, 20, 21, 22, 23];
          scope.allHours = _.union(scope.earlyMorningHours, scope.morningHours, scope.afternoonHours, scope.eveningHours);

          scope.addDayPart = function() {
            let item = {daysOfWeek: angular.copy(scope.daysOfWeek), days: {}, hours: {}, hoursOfDay: [], timeZoneSetting: 'SYSTEM', editing: true};
            daysOfWeekUpdate(item);
            scope.vm.unshift(item);
            scope.hourOperation(item, scope.allHours, true);
          };

          scope.deleteEntry = function(index) {
            _.pullAt(scope.vm, index);
          };

          scope.toggleDay = function(entry, day, newValue) {
            let isSelected = _.indexOf(entry.daysOfWeek, day) > -1,
              requiresAction = false;
            if (newValue && !isSelected) {
              entry.daysOfWeek.push(day);
              requiresAction = true;
            }
            if (!newValue && isSelected) {
              _.pull(entry.daysOfWeek, day.toString());
              requiresAction = true;
            }
            if (requiresAction) {
              entry.daysOfWeek.sort(daysOfWeekSort);
              entry.days[day] = newValue;
            }
          };

          scope.toggleHour = function(entry, hour, newValue) {
            let isSelected = _.indexOf(entry.hoursOfDay, hour) > -1,
              requiresAction = false;
            if (newValue && !isSelected) {
              entry.hoursOfDay.push(hour);
              requiresAction = true;
            }
            if (!newValue && isSelected) {
              _.pull(entry.hoursOfDay, hour);
              requiresAction = true;
            }
            if (requiresAction) {
              entry.hoursOfDay.sort(numberSort);
              entry.hourString = doHourString(entry);
              entry.hours[hour] = newValue;
            }
          };

          scope.dayOperation = function(entry, days, newValue) {
            _.forEach(days, function(day) {
              scope.toggleDay(entry, day, newValue);
            });
          };

          scope.hourOperation = function(entry, hours, newValue) {
            _.forEach(hours, function(hour) {
              scope.toggleHour(entry, hour, newValue);
            });
          };

          let prepareForSave = function() {
            scope.model = scope.model || [];
            scope.model.length = 0;

            _.forEach(scope.vm, function(entryObj) {
              if (entryObj.daysOfWeek.length > 0 && entryObj.hoursOfDay.length > 0) {
                scope.model.push(_.pick(entryObj, ['daysOfWeek', 'hoursOfDay', 'timeZoneSetting']));
              }
            });

            init();
          };

          scope.hook = {};
          scope.hook.getTemplateData = function() {
            prepareForSave();
            return {
              vm: angular.copy(scope.vm),
              model: angular.copy(scope.model),
              constant: ADN_TARGETING_TYPES.dayParting,
              summary: ''
            };
          };

          const copyTemplateFunc = function(template) {
            scope.model = angular.copy(template.data.model);
            init();
          };
          scope.hook.copyTemplate = copyTemplateFunc;

          tabset.register(ADN_TARGETING_TYPES.dayParting.widget, prepareForSave, copyTemplateFunc);
          tabset.callBeforeSubmit(prepareForSave);
          tabset.registerAllTabs(prepareForSave);
        }
      }
    };
  });

export default MODULE_NAME;