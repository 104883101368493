/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './invoice-translation-edit.html';
import deleteTemplate from '../common/delete-actions.html';
import listTemplate from './invoice-translation-list.html';

import standardModule from '../common/standard-module';
import adminModule from '../admin/admin-module';
import invoiceTranslationCtrl from './invoice-translation-controller';

const MODULE_NAME = 'invoice-translation-module';

angular.module(MODULE_NAME, [adminModule, invoiceTranslationCtrl, standardModule])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('invoice-translations.invoice-translation', 'InvoiceTranslation', 'app.rep', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list())
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: 'InvoiceTranslationCtrl',
            controllerAs: 'resource',
            template: editTemplate
          },
          'context@app': {
            controller: /*@ngInject*/ function(model, modelComms, $stateParams) {
              const ctrl = this;
              ctrl.model = model;
              ctrl.modelComms = modelComms;
              ctrl.isNew = $stateParams.isNew;
            },
            controllerAs: 'ctrl',
            template: deleteTemplate
          }
        }
      }, 'InvoiceTranslation'));
  });

export default MODULE_NAME;