/**
 * Copyright © 2025 Adnuntius AS.
 */
import {Component, Inject, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';

export const DEVICE_ORDER_ID = {
  'APPLE': 1,
  'ANDROID': 10,
  'DESKTOP': 100,
  'MOBILE': 101,
  'TABLET': 102,
  'WINDOWS': 202,
  'LINUX': 203,
  'SAMSUNG': 302,
  'LENOVO': 303,
  'OPPO': 304,
  'HUAWEI': 305,
  'OTHER': 5000,
  'OTHERS': 5000
};

@Component({
  selector: 'performance-total',
  template: `
    <ul class="list-unstyled doubleColumned totalsTable">
      <li *ngFor="let kv of items">
        <span *ngIf="kv" class="titleField">
          <span *ngIf="totals[kv.key] === undefined">&nbsp;</span>
          <span *ngIf="totals[kv.key] !== undefined"><span class="fa fa-fw {{kv.icon}}" *ngIf="kv.icon"></span>
            <span *ngIf="!totals[kv.key].count">{{keyed ? (keyed + '.' + (kv.tKey || kv.key) | ogTranslate) : kv.name}}</span><span *ngIf="totals[kv.key].count">{{(kv.tKey || kv.key)}} count | avg</span></span></span>
        <span  *ngIf="kv" class="numericColumn">
          <span *ngIf="totals[kv.key] === undefined">&nbsp;</span>
          <span *ngIf="totals[kv.key].count === undefined">{{totals[useAlt ? (kv.altId || kv.key) : kv.key] | stat : kv.key }}</span>
          <span *ngIf="totals[kv.key].count !== undefined">{{totals[useAlt ? (kv.altId || kv.key) : kv.key].count | stat : kv.key }} | {{totals[kv.key].averageValue | stat : kv.key }}</span>
        </span>
      </li>
    </ul>
  `
})
export class PerfTotalComponent implements OnInit {
  items: any;
  totals: any;
  empty = () => { return 1; };
  @Input() chartType: string;
  @Input() keyed: string;
  @Input() orderBy: string;
  @Input() param: string;
  @Input() useAlt: boolean;
  @Input() changeHook: any;

  constructor(@Inject('ChartOptionsUtil') private ChartOptionsUtil: any, @Inject('$translate') private $translate: any) {
  }

  private rowsOrganiser() {
    let fields;
    if (this.chartType) {
      const chartOptions = this.ChartOptionsUtil.getOptions(this.chartType);
      fields = _.filter(chartOptions.totalsFields, function(f) {
        return f.id !== 'customFields';
      });
    } else {
      fields = _.map(this.totals, (d, k) => {
        return {
          name: this.keyed ? (this.$translate.instant(this.keyed + "." + k) || k) : k,
          id: k,
          key: k,
          orderBy: d
        };
      });
    }

    if (this.orderBy === 'VALUE') {
      fields = _.orderBy(fields, function(s) {
        return s.orderBy;
      }, 'desc');
    }

    this.items = [];

    _.forEach(fields, (d) => {
      const id = _.get(d, 'id');
      d.orderId = DEVICE_ORDER_ID[id] || 1000;
      this.items.push(d);
    });
    this.items = _.orderBy(this.items, 'orderId');

    const customData = [];
    _.forEach(this.ChartOptionsUtil.getLineItemCustomFields(this.totals), function(f) {
      customData.push({key: f, icon: 'fa-wrench'});
    });
    _.forEach(customData, (d) => {
      this.items.push(d);
    });

    this.items = _.filter(this.items, function(item) {
      return _.isFinite(this.totals[item.key]) || this.totals[item.key];
    }.bind(this));
  }

  ngOnInit(): void {
    const theTotals = this.changeHook.getTotals();
    this.totals = this.param ? theTotals[this.param] : theTotals;
    this.rowsOrganiser();
    this.changeHook.chartChangeHandlers.push((totals) => {
      this.totals = this.param ? totals[this.param] : totals;
      this.rowsOrganiser();
    });
  }
}
