/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import {IAB_TAXONOMY} from "../../components/util/iab-taxonomy";

import standardFormModule from '../common/standard-form-module';
import {adnFormHelper} from "../common/controller/form-helper";
import _ from "lodash";
import {IAB_CATEGORIES_V1} from "../../components/util/iab-categories-v1";
import {LocalNetworkInfo} from "../../components/session/local-network-info";
import {MoneyHelper} from "../../components/util/money";

const MODULE_NAME = 'advertiser-controller';

angular.module(MODULE_NAME, [standardFormModule])

  .controller('AdvertiserCtrl', function(model, readOnly, modelComms, LocalNetworkProfile, LocalUserPermissions, Team, User, $stateParams) {
    const ctrl = this;

    // need to initialise before onInit to make the country select component work as expected.
    model.address = model.address || {country: ""};

    ctrl.suiPlatform = LocalNetworkProfile.isSuiPlatform();
    ctrl.isMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();
    ctrl.advertiserInfo = $stateParams.isNew;
    ctrl.canSeeTechFees = LocalUserPermissions.hasNetwork("MANAGE_SYSTEM");

    ctrl.showTeams = LocalNetworkInfo.obtain().getInfo("teamsCount") > 1 || !LocalNetworkProfile.isPureMarketplacePlatform();
    ctrl.pureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();

    const defaultCurrency = LocalNetworkProfile.get('defaultCurrency');
    ctrl.networkCurrencies = _.orderBy(LocalNetworkProfile.get('availableCurrencies'), function(curr) {
      return curr === defaultCurrency ? 0 : 1;
    });

    function initFeesForParam(ref) {
      _.forEach(ctrl.networkCurrencies, function(currency) {
        const foundFee = _.find(_.get(ref, 'multiCurrencyMonetaryAmounts', []), function(fee) {
          return fee.currency === currency;
        });
        if (!foundFee) {
          const monetary = _.get(ref, 'monetary');
          if (monetary && monetary.currency === currency) {
            ref.type = 'MULTI_CURRENCY_MONETARY';
            ref.multiCurrencyMonetaryAmounts = ref.multiCurrencyMonetaryAmounts || [];
            ref.multiCurrencyMonetaryAmounts.push(monetary);
          } else {
            ref.multiCurrencyMonetaryAmounts = ref.multiCurrencyMonetaryAmounts || [];
            ref.multiCurrencyMonetaryAmounts.push({currency: currency});
          }
        }
      });
      ref.multiCurrencyMonetaryAmounts = _.orderBy(ref.multiCurrencyMonetaryAmounts, function(fee) {
        return ctrl.networkCurrencies.indexOf(fee.currency);
      });
    }

    function initialiseLayoutFees() {
      let layoutFees = _.get(ctrl.model, 'layoutFees');
      ctrl.model.uiLayoutFees = _.isArray(layoutFees) ? layoutFees : [];
      ctrl.model.uiLayoutFees[0] = ctrl.model.uiLayoutFees[0] ? ctrl.model.uiLayoutFees[0] : {};
      ctrl.model.uiLayoutFees = _.map(ctrl.model.uiLayoutFees, function(layoutFee) {
        const adjFee = _.cloneDeep(layoutFee) || {};
        adjFee.fee = adjFee.fee || {type: 'MULTI_CURRENCY_MONETARY'};
        initFeesForParam(adjFee.fee);
        return adjFee;
      });
    }

    ctrl.removeLayoutFee = function(index) {
      _.pullAt(ctrl.model.uiLayoutFees, index);
      if (ctrl.model.uiLayoutFees.length === 0) {
        ctrl.addLayoutFee();
      }
      ctrl.editsMade();
    };

    ctrl.addLayoutFee = function() {
      ctrl.model.uiLayoutFees = ctrl.model.uiLayoutFees || [];
      const defaultFee = {fee: {type: 'MULTI_CURRENCY_MONETARY'}};
      initFeesForParam(defaultFee.fee);
      ctrl.model.uiLayoutFees.push(defaultFee);
      ctrl.editsMade();
    };


    function updateUsers() {
      ctrl.userSearch = false;
      if (ctrl.suiPlatform && _.get(ctrl.model, ['ownerTeam', 'id'])) {
        ctrl.userSearch = true;
        User.query({selfServiceOnly: true, team: ctrl.model.ownerTeam.id}).$promise.then(function(page) {
          ctrl.users = page.results;
          ctrl.userSearch = false;
        }, function() {
          ctrl.userSearch = false;
        });
      }
    }

    ctrl.teamChanged = function() {
      updateUsers();
    };

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms,
      afterInit: function() {
        updateUsers();
      },
      afterSave: function() {
        updateUsers();
      }, afterInitAfterSave: function(saveObj) {
        ctrl.model.teams = _.map(_.get(saveObj, 'teams') || ctrl.model.teams, 'id');
        ctrl.model.viewOnlyTeams = _.map(_.get(saveObj, 'viewOnlyTeams') || ctrl.model.viewOnlyTeams, 'id');
        ctrl.blockSubmit = readOnly;
        initialiseLayoutFees();
      }
    });
    ctrl.iabTaxonomy = angular.copy(IAB_TAXONOMY);
    ctrl.iabCategoriesV1 = angular.copy(IAB_CATEGORIES_V1);
    ctrl.isAxAdvertiserAdmin = LocalUserPermissions.hasNetworkPermission('AX_ADVERTISER_ADMIN');

    Team.query(function(page) {
      const allTeams = page.results;
      const isViewAllObjects = LocalUserPermissions.hasNetwork('VIEW_ALL_OBJECTS');
      const firstFilterTeams = isViewAllObjects ? allTeams : _.filter(allTeams, function(g) {
        return LocalUserPermissions.isPartOf(g.network.tagId || g.network.id, g.id);
      });
      ctrl.teams = _.filter(firstFilterTeams, function(t) {
        return t.type === 'AX_ADVERTISER' || t.type === 'STANDARD';
      });
      ctrl.viewOnlyTeams = _.filter(firstFilterTeams, function(t) {
        return t.type === 'AX_ADVERTISER' || t.type === 'STANDARD' || t.type === 'CREATIVE_AGENCY';
      });
      if (ctrl.isAxAdvertiserAdmin && allTeams.length > 0 && ctrl.teams.length > 0 && !ctrl.model.team) {
        ctrl.model.ownerTeam = ctrl.teams[0];
      }
    });

    ctrl.onBeforeSubmit = function() {
      ctrl.model.layoutFees = _.filter(ctrl.model.uiLayoutFees, function(layoutFee) {
        return _.get(layoutFee, ['layout', 'id']) && _.filter(_.map(_.get(layoutFee, ['fee', 'multiCurrencyMonetaryAmounts']), function(m) {
          return MoneyHelper.fixMoney(m);
        }), function(f) {
          return MoneyHelper.isMoney(f);
        }).length > 0;
      });
      ctrl.model.layoutFees = _.map(ctrl.model.layoutFees, function(layoutFee) {
        const adjFee = _.cloneDeep(layoutFee);
        adjFee.fee.multiCurrencyMonetaryAmounts = _.filter(_.map(adjFee.fee.multiCurrencyMonetaryAmounts, function(m) {
          return MoneyHelper.fixMoney(m);
        }), function(f) {
          return MoneyHelper.isMoney(f);
        });
        return adjFee;
      });
    };
  });

export default MODULE_NAME;