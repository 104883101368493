/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import template from './layout-example-copy-modal.html';

import { Uuid } from "../../../components/util/uuid";

import standardActionsModule from '../../common/standard-actions-module';

const MODULE_NAME = "layout-example-actions-controller";

angular.module(MODULE_NAME, [
  standardActionsModule
])
  .controller('LayoutExampleActionsCtrl', function($q, Layout, toastr, $state, $uibModal, model) {
    const ctrl = this;

    ctrl.copy = function() {
      let modalInstance = $uibModal.open({
        windowClass: '',
        template: template,
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          modalCtrl.model = angular.copy(model);
          modalCtrl.destination = true;

          modalCtrl.copy = function() {
            const layout = new Layout(modalCtrl.model);
            layout.id = Uuid.generate();
            layout.$save().then(function(savedLayoutExample) {
              modalInstance.close({model: savedLayoutExample, destination: modalCtrl.destination});
            });
          };
        }
      });
      modalInstance.result.then(function(result) {
        if (result.destination) {
          $q.all(result.allPromises).then(function() {
            $state.go('app.layouts.layout', {id: result.model.id}, {inherit: false});
          });
        } else {
          toastr.success("A new layout based on this example was successfully created.", "New Layout Created");
        }
      });
    };
  });

export default MODULE_NAME;