/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import {TargetingHelper} from "../targeting-helper";

import template from './third-party-audience-summary.html';

const MODULE_NAME = 'third-party-audience-target-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnThirdPartyAudienceTargetSummary', function() {
    return {
      restrict: 'A',
      template: template,
      scope: {
        targets: '=adnThirdPartyAudienceTargetSummary'
      },
      link: function(scope) {
        scope.data = [];
        scope.$watchCollection('targets', function(targets) {
          scope.targets = targets || {};
          scope.isComplex = TargetingHelper.isComplexThirdPartyAudience(scope.targets);
        });

        scope.showOnly = 2;
        scope.quantity = scope.showOnly;
        scope.switchUp = function() {
          if (scope.quantity === scope.showOnly) {
            scope.quantity = scope.data.length;
            return;
          }
          scope.quantity = scope.showOnly;
        };
        scope.$watchCollection('targets', function(targets) {
          scope.data = targets;
        });
      }
    };
  });

export default MODULE_NAME;