/**
 * Copyright © 2025 Adnuntius AS.
 */
import * as _ from 'lodash';
import {MEDIA_TYPE, MediaTypeHelper} from "./media-type-helper";


export class LayoutComponentHelper {

  static getSummary(layoutComponents: Record<string, any>): Record<string, number> {
    const counts: Record<string, number> = {
      image: 0,
      text: 0,
      url: 0,
      option: 0,
      flash: 0,
      video: 0,
      audio: 0,
      thirdParty: 0,
      code: 0,
      html: 0
    };
    _.forEach(layoutComponents, (lc) => {
      if (lc.mimeTypes && lc.mimeTypes.length) {
        const mediaType: any = MediaTypeHelper.getMediaType(lc.mimeTypes[0]);
        if (mediaType.image) {
          counts.image++;
        } else if (mediaType.video) {
          counts.video++;
        } else if (mediaType.audio) {
          counts.audio++;
        } else if (mediaType === MEDIA_TYPE.html) {
          counts.html++;
        } else if (mediaType === MEDIA_TYPE.flash) {
          counts.flash++;
        }
      } else if (lc.type === 'URL') {
        counts.url++;
      } else if (lc.type === 'CHOICE') {
        counts.option++;
      } else if (lc.type === 'TEXT') {
        if (lc.tag === 'thirdParty') {
          counts.thirdParty++;
        } else if (lc.textType === 'CODE') {
          counts.code++;
        } else {
          counts.text++;
        }
      }
    });
    return counts;
  }
}