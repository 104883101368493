/*
 * Copyright © 2025 Adnuntius AS.
 */

import {CURRENCIES} from "./iso-currencies";
import * as _ from 'lodash';

export class MoneyHelper {

  static fixMoney(rawMonetaryValue: unknown) {
    const value = _.get(rawMonetaryValue, 'amount');
    const managedValue = _.isString(value) ? parseFloat(value) : value;
    if (rawMonetaryValue && !_.isFinite(managedValue)) {
      return {currency: _.get(rawMonetaryValue, ['currency']), amount: null};
    } else if (rawMonetaryValue && _.isFinite(managedValue) && _.isString(value)) {
      return {
        currency: _.get(rawMonetaryValue, ['currency']),
        amount: managedValue
      };
    }
    return rawMonetaryValue;
  }

  static isMoney(data: any): boolean {
    const currency = _.get(data, 'currency');
    const amount = _.get(data, 'amount');
    return _.isString(currency) && CURRENCIES[currency] && _.isFinite(amount);
  }
}
