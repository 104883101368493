/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import { HttpHelper } from "../../../util/http-helper";
import { ResourceHelper } from "../../../util/resource-helper";

const MODULE_NAME = "product";

angular.module(MODULE_NAME, [])

  .factory('Product', function(adnResource, $http) {
    const transformJsonObjActions = HttpHelper.obtain($http).transformJsonObjActions('data');
    transformJsonObjActions.save.transformRequest.unshift(function(product) {
      ['minimumBudget', 'maximumBudget', 'minimumCpm'].forEach(fieldName => ResourceHelper.checkMoney(product, fieldName));
      return product;
    });
    return adnResource('product', {}, transformJsonObjActions);
  });

export default MODULE_NAME;
