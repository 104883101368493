/**
 * Copyright © 2025 Adnuntius AS.
 */
import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: 'message-dismisser',
  template: `<div *ngIf="result && result.data" class="alert alert-success clickable" (click)="result.data=false" title="Click to dismiss message">{{message}}<span class="fa fa-times pull-right"></span></div>`
})
export class MessageDismisserComponent implements OnInit {
  message: string;
  @Input() result: any;
  @Input() messageType: string;

  ngOnInit(): void {
    this.message = "Your targeting updates were saved successfully";
    if (this.messageType === 'creative') {
      this.message = "Your creative updates were saved successfully";
    } else if (this.messageType === 'productLayouts') {
      this.message = "Your layout selections were updated successfully";
    } else if (this.messageType === 'orderBudgets') {
      this.message = "Your budget updates were saved successfully";
    }
  }
}
