/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import twoPanelBottomTemplate from '../common/two-panel-bottom.html';
import editTemplate from './advertiser-edit.html';
import rightTemplate from './advertiser-analysis.html';
import informationTemplate from './advertiser-information.html';
import deleteTemplate from '../common/delete-actions.html';
import listTemplate from './advertiser-list.html';

import lineItemListComponent from '../common/controller/line-item-list-component';
import standardModule from '../common/standard-module';
import advertiserController from './advertiser-controller';
import advertiserInformation from './advertiser-information';
import advertiserAnalysisController from './advertiser-analysis-controller';
import teamSelectorComponent from "../common/controller/team-selector-component";
import {PERMISSIONS} from "../../components/auth/auth-constants";
import _ from "lodash";

const MODULE_NAME = 'advertiser-module';

angular.module(MODULE_NAME, [standardModule, teamSelectorComponent, advertiserAnalysisController, lineItemListComponent, advertiserController, advertiserInformation])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterAndChildHelper('advertisers.advertiser', 'Advertiser', 'Order', 'app.adv', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        extension: /*@ngInject*/ function(LocalNetworkProfile, LocalUserPermissions) {
          return {
            func: function(ctrl, afterFill) {
              if (afterFill) {
                return;
              }
              ctrl.advertiserFields = ['name', 'description', 'url', {param: 'OwnerTeam', label: 'team'}, {param: 'externalReference', label: 'extReference'}, 'contact', 'phone', 'email'];

              if (LocalNetworkProfile.isPureMarketplacePlatform()) {
                ctrl.advertiserFields.push({param: 'bidInLastDay', label: 'status'});
                ctrl.advertiserFields.push({param: "(last24HourDeliveryStats).impressionDelivery", label: 'impressionDelivery24', numeric: true});
                ctrl.advertiserFields.push({param: "(last24HourDeliveryStats).renderedDelivery", label: 'renderedDelivery24', numeric: true});
                ctrl.advertiserFields.push({param: "(last24HourDeliveryStats).viewableDelivery", label: 'viewableDelivery24', numeric: true});
                ctrl.advertiserFields.push({param: "(last24HourDeliveryStats).clickDelivery", label: 'clickDelivery24', numeric: true});
                ctrl.advertiserFields.push({param: "(last24HourDeliveryStats).spendDelivery", label: 'spendDelivery24', numeric: true});
                ctrl.advertiserFields.push({param: "(last24HourDeliveryStats).viewability", label: 'viewability24', numeric: true});
                ctrl.advertiserFields.push({param: "(last24HourDeliveryStats).ctr", label: 'ctr24', numeric: true});

                ctrl.advertiserFields.push({param: "(last7DayDeliveryStats).impressionDelivery", label: 'impressionDelivery7', numeric: true});
                ctrl.advertiserFields.push({param: "(last7DayDeliveryStats).renderedDelivery", label: 'renderedDelivery7', numeric: true});
                ctrl.advertiserFields.push({param: "(last7DayDeliveryStats).viewableDelivery", label: 'viewableDelivery7', numeric: true});
                ctrl.advertiserFields.push({param: "(last7DayDeliveryStats).clickDelivery", label: 'clickDelivery7', numeric: true});
                ctrl.advertiserFields.push({param: "(last7DayDeliveryStats).spendDelivery", label: 'spendDelivery7', numeric: true});
                ctrl.advertiserFields.push({param: "(last7DayDeliveryStats).viewability", label: 'viewability7', numeric: true});
                ctrl.advertiserFields.push({param: "(last7DayDeliveryStats).ctr", label: 'ctr7', numeric: true});
              }

              const skipForDefaults = ['phone', 'email', 'contact', 'impressionDelivery24', 'impressionDelivery7', 'viewableDelivery24', 'clickDelivery24', 'viewability24',
                'clickDelivery7', 'viewableDelivery7', 'viewability7', 'ctr7', 'ctr24'];
              ctrl.defaultFields = _.filter(ctrl.advertiserFields, function(f) {
                return skipForDefaults.indexOf(f.label || f.param || f) < 0;
              });
              ctrl.sel = {};
              ctrl.perms = LocalUserPermissions.getAllVisPermissions();

              ctrl.applyLimitedFilter = function() {
                ctrl.limitedFilter = true;
              };
            }
          };
        },
        readOnly: function(LocalUserPermissions) {
          return !LocalUserPermissions.hasAny(PERMISSIONS.manageAdvertising.apiKey) && !LocalUserPermissions.hasNetworkPermission(PERMISSIONS.axAdvertiserAdmin.apiKey);
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: null,
            template: twoPanelBottomTemplate
          },
          'context@app': {
            controller: /*@ngInject*/ function(model, modelComms, $stateParams) {
              const ctrl = this;
              ctrl.model = model;
              ctrl.modelComms = modelComms;
              ctrl.isNew = $stateParams.isNew;
            },
            controllerAs: 'ctrl',
            template: deleteTemplate
          },
          'rightPanel@app.advertisers.advertiser': {
            template: rightTemplate,
            controller: 'AdvertiserAnalysisCtrl',
            controllerAs: 'resource'
          },
          'leftPanel@app.advertisers.advertiser': {
            template: editTemplate,
            controller: 'AdvertiserCtrl',
            controllerAs: 'resource'
          },
          'bottomPanel@app.advertisers.advertiser': {
            template: informationTemplate,
            controller: 'AdvertiserInformationCtrl',
            controllerAs: 'resource'
          }
        }
      }, 'Advertiser'));
  });

export default MODULE_NAME;
