/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import template from './creative-view.html';

import standardFormModule from '../../common/standard-form-module';
import creativePreviewDir from './creative-preview-via-layout-comp';

const MODULE_NAME = 'creative-preview-via-id-comp';

angular.module(MODULE_NAME, [standardFormModule, creativePreviewDir])

  .component('adnCreativePreviewViaId', {
    bindings: {
      creative: '<',
      creativePreviewTrigger: '<previewTrigger',
      maxWidth: '@',
      maxHeight: '@'
    },
    template: template,
    controllerAs: 'ctrl',
    controller: function($q, CreativePreview) {
      const ctrl = this;
      ctrl.previewTrigger = {};
      ctrl.resolved = false;

      ctrl.$onInit = function() {
        let preview = function(params) {
          // Bail out on the following set of conditions
          if (!params.creative || !params.creative.id || _.find(params.creative.validationWarnings, ['preventsAdServing', true]) || !params.creative.layout) {
            params.resolved = true;
            return;
          }

          let previewData,
            apiPreviewData = {creativeId: params.creative.id, creativeWidth: params.creative.width, creativeHeight: params.creative.height},
            promise = CreativePreview.get({id: params.creative.id}).$promise;

          $q.all([promise]).then(function(data) {
            let html = data[0].html;

            previewData = {
              pageTitle: 'Full-Screen Creative View',
              creativeContent: html,
              creativeWidth: params.creative.width || params.creative.height || 300,
              creativeHeight: params.creative.height || params.creative.width || 300,
              dimensionType: params.creative.dimensionType,
              maxWidth: params.maxWidth,
              maxHeight: params.maxHeight,
              center: false
            };

            ctrl.previewTrigger.previewInline(previewData, apiPreviewData);
            params.resolved = true;
          });
        };
        preview(ctrl);

        if (ctrl.creativePreviewTrigger) {
          ctrl.creativePreviewTrigger.previewCreative = function(previewData) {
            preview(previewData);
          };
        }
      };
    }
  });

export default MODULE_NAME;