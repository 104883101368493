/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import uiTranslate from 'angular-translate';

import resources from '../../../components/api/resources/resources';
import promiseSpinner from './html/promise-spinner-component';

const MODULE_NAME = 'exploration-table';

angular.module(MODULE_NAME, [
  uiTranslate,
  resources,
  promiseSpinner
])

  .component('adnExplorationTable', {
    bindings: {
      model: '<',
      param: '@',
      filterParam: '@'
    },
    template: `
      <p ng-if="ctrl.model.exploration[ctrl.param].length < 50">Approximately <strong>{{ctrl.model.exploration[ctrl.param].length}} {{'exploration.' + ctrl.param + '.title' | translate}}</strong> were found in last week's sampled traffic</p>
      <p ng-if="ctrl.model.exploration[ctrl.param].length >= 50">The <strong>top 50 {{'exploration.' + ctrl.param + '.title' | translate}}</strong> that were found in last week's sampled traffic are below</p>
      <div ng-if="ctrl.model.exploration[ctrl.param].length">
        <uib-tabset>
          <uib-tab heading="Table">
          <div>
            <table class="table table-condensed table-bordered table-hover small">
              <tr>
                <th>{{'exploration.' + ctrl.param + '.title' | translate}}</th>
                <th>Presence (%)</th>
                <th>Expected Ad Requests</th>
                <th>&nbsp;</th>
              </tr>
              <tr ng-repeat="row in ctrl.model.exploration[ctrl.param]">
                <td><div>{{ row.name }}</div><div ng-if="ctrl.filterParam==='SEGMENT' && row.id" class="small"><small>ID: {{row.id}}</small></div></td>
                <td>{{ row.y / 100 | percentage : 0 }}</td>
                <td>{{ row.nextWeek | adnStat }} </td>
                <td class="actions"><button type="button" class="btn btn-default btn-xs" ng-click="ctrl.model.updateFilter(ctrl.filterParam, row)"><span class="fa fa-filter" title="Add to traffic filter"></span></button></td>
              </tr>
            </table>
          </div>
          </uib-tab>
          <uib-tab heading="Chart">
            <div ng-if="ctrl.model.exploration[ctrl.param].statsChart">
              <highchart config="ctrl.model.exploration[ctrl.param].statsChart"></highchart>
            </div>
          </uib-tab>
        </uib-tabset>
      </div>
    `,
    controllerAs: 'ctrl',
    controller: function() {
    }
  });

export default MODULE_NAME;