/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {LocalStorage} from "./local-storage";

const MODULE_NAME = "local-network-profile";

const NETWORK_PROFILE_STORAGE_KEY = 'network.profile';

export const DEFAULT_OBJECTIVE_FIELDS = {
  IMPRESSION: true,
  RENDERED_IMPRESSION: true,
  VISIBLE_IMPRESSION: true,
  VIEWABLE_IMPRESSION: true,
  CLICK: true,
  CONVERSION: true,
  BUDGET: true
};

export const DEFAULT_MARKETPLACE_OBJECTIVE_FIELDS = {
  IMPRESSION: false,
  RENDERED_IMPRESSION: false,
  VISIBLE_IMPRESSION: false,
  VIEWABLE_IMPRESSION: false,
  CLICK: false,
  CONVERSION: false,
  BUDGET: true
};

export const DEFAULT_BID_SPECIFICATION_FIELDS = {
  cpm: true,
  cpc: true,
  cpa: true
};

export const DEFAULT_MARKETPLACE_BID_SPECIFICATION_FIELDS = {
  cpm: true,
  cpc: true
};

angular.module(MODULE_NAME, [])

  .factory('LocalNetworkProfile', function($log, $q, $injector) {

    const defaultLineItemState = "RESERVED",
      defaultMarketplaceLineItemState = "APPROVED",
      defaultLineItemDaysApart = 7,
      defaultDeduplicationLevel = "CREATIVE",
      defaultCreativeName = "Creative for {lineItemName}",
      defaultGeoLat = 59.325,
      defaultGeoLng = 18.05,
      defaultGeoZoom = 10,
      defaultStartDayCalendar = 1,
      defaultRateLimitScope = 'PER_USER',
      defaultRateLimitTranslations = '1',
      defaultRunReportsOptions = ['REACH_ANALYSIS'],
      appSectionVisibility = "HIDE_INEDITABLE",
      defaultDeliveryCurve = "EVEN",
      defaultIabCategoryOnLineItem = "HIDE",
      defaultMarketplaceDeliveryCurve = "FRONT_LOADED",
      defaultIgnoreMarketplaceTargeting = false,
      defaultTranslations = {
        impression: 'Impressions',
        impressionAbbr: 'Imp.',
        rendered: 'Rendered Impressions',
        renderedAbbr: 'R Imp.',
        renderedRate: 'Rendered Rate',
        renderedRateAbbr: 'R R.',
        earnings: 'Earnings',
        earningsAbbr: 'E.',
        revenueShare: 'Revenue Share',
        revenueShareAbbr: 'R. S.',
        winRate: 'Win Rate',
        winRateAbbr: 'W R.',
        impressionsPerUniqueUser: 'Imps. per Unique',
        impressionsPerUniqueUserAbbr: 'I U.',
        fees: 'Fees',
        feesAbbr: 'Fees',
        techFee: 'Tech Fee',
        techFeeAbbr: 'T F.',
        creativeTagFee: 'Creative Tag Fee',
        creativeTagFeeAbbr: 'C T F.',
        creativeFormatFee: 'Creative Format Fee',
        creativeFormatFeeAbbr: 'C F F.',
        dataFee: 'Data Fee',
        dataFeeAbbr: 'D F.',
        supplementaryFee: 'Supplementary Fee',
        supplementaryFeeAbbr: 'S F.',
      },
      defaultDefaults = {
        lineItemUserState: defaultLineItemState,
        marketplaceLineItemUserState: defaultMarketplaceLineItemState,
        lineItemDaysApart: defaultLineItemDaysApart,
        lineItemDeduplicationLevel: defaultDeduplicationLevel,
        rateLimitScope: defaultRateLimitScope,
        rateLimitTranslations: defaultRateLimitTranslations,
        geoLat: defaultGeoLat,
        geoLng: defaultGeoLng,
        geoZoom: defaultGeoZoom,
        defaultCreativeName: defaultCreativeName,
        appSectionVisibility: appSectionVisibility,
        startDayCalendar: defaultStartDayCalendar,
        runReportsOptions: defaultRunReportsOptions,
        namedLocations: [],
        translations: defaultTranslations,
        showCpmFees: false,
        showExternalReference: false,
        deliveryCurve: defaultDeliveryCurve,
        iabCategoryOnLineItem: defaultIabCategoryOnLineItem,
        marketplaceDeliveryCurve: defaultMarketplaceDeliveryCurve,
        defaultIgnoreMarketplaceTargeting: defaultIgnoreMarketplaceTargeting,
        objectiveFields: DEFAULT_OBJECTIVE_FIELDS,
        marketplaceObjectiveFields: DEFAULT_MARKETPLACE_OBJECTIVE_FIELDS,
        bidSpecificationFields: DEFAULT_BID_SPECIFICATION_FIELDS,
        marketplaceBidSpecificationFields: DEFAULT_MARKETPLACE_BID_SPECIFICATION_FIELDS,
        rateLimitFields: null
      };

    const networkTransKeys = [
      {translation: 'Publisher', codes: ['earningsAccount', 'label.earningsAccount', 'Earnings Account', 'report.reportType.EARNINGS_ACCOUNT.simple']},
      {translation: 'Publishers', codes: ['earningsAccount.plural', 'menu.earningsAccount', 'Earnings Accounts', 'object.EarningsAccount.plural']},
      {translation: 'Publisher Report', codes: ['report.reportType.EARNINGS_ACCOUNT']},
      {translation: 'Publisher Reports', codes: ['report.reportType.EARNINGS_ACCOUNT.plural']},
      {translation: 'A report template for reporting on publishers', codes: ['report.reportType.EARNINGS_ACCOUNT.explanation']},
      {translation: 'View advertiser stats across publishers', codes: ['View advertiser stats across earnings accounts']},
      {translation: 'Publisher Queries', codes: ['Earnings Account Queries', 'EA Queries']}
    ];

    const transKeys = [
      {key: 'impression', codes: ['impression', 'impressions', 'report.field.impressions', 'lineItem.objective.IMPRESSION', 'lineItem.objective.IMPRESSION.plural', 'lineItem.rateLimit.type.IMPRESSION', 'reportTemplate.option.impressions', 'chart.statType.impression', 'chart.statType.report.field.impressions']},
      {key: 'impression', suffix: ' Objective', codes: ['impressionObjective']},
      {key: 'impression', suffix: ' (24h)', codes: ['impressionDelivery24', 'label.impressionDelivery24']},
      {key: 'impression', suffix: ' (7d)', codes: ['impressionDelivery7', 'label.impressionDelivery7']},
      {key: 'impression', suffix: 's per Unique User', codes: ['impressionsPerUniqueUser']},
      {key: 'impression', singular: true, codes: ['lineItem.rateLimit.type.IMPRESSION.singular']},
      {key: 'impressionAbbr', codes: ['report.field.impressions.abbr', 'lineItem.objective.IMPRESSION.list']},
      {key: 'earnings', codes: ['earnings', 'report.field.earnings', 'reportTemplate.option.earnings', 'chart.statType.earnings', 'chart.statType.report.field.earnings']},
      {key: 'earningsAbbr', codes: ['report.field.earnings.abbr']},
      {key: 'revenueShare', codes: ['revenueShare', 'report.field.revenueShare', 'reportTemplate.option.revenueShare', 'chart.statType.revenueShare', 'chart.statType.report.field.revenueShare']},
      {key: 'revenueShareAbbr', codes: ['report.field.revenueShare.abbr']},
      {key: 'rendered', codes: ['rendered', 'report.field.rendered', 'lineItem.objective.RENDERED_IMPRESSION', 'lineItem.objective.RENDERED_IMPRESSION.plural', 'lineItem.rateLimit.type.RENDERED_IMPRESSION', 'reportTemplate.option.rendered', 'chart.statType.rendered', 'chart.statType.report.field.rendered']},
      {key: 'rendered', singular: true, codes: ['lineItem.rateLimit.type.RENDERED_IMPRESSION.singular']},
      {key: 'rendered', suffix: ' Objective', codes: ['renderedImpressionObjective']},
      {key: 'rendered', suffix: ' (24h)', codes: ['renderedDelivery24', 'label.renderedDelivery24']},
      {key: 'rendered', suffix: ' (7d)', codes: ['renderedDelivery7', 'label.renderedDelivery7']},
      {key: 'renderedAbbr', codes: ['report.field.rendered.abbr', 'lineItem.objective.RENDERED_IMPRESSION.list']},
      {key: 'renderedRate', codes: ['renderedRate', 'report.field.renderedRate', 'reportTemplate.option.renderedRate', 'chart.statType.renderedRate', 'chart.statType.report.field.renderedRate']},
      {key: 'renderedRateAbbr', codes: ['report.field.renderedRate.abbr']},
      {key: 'winRate', codes: ['winRate', 'report.field.winRate', 'reportTemplate.option.winRate', 'chart.statType.winRate', 'chart.statType.report.field.winRate']},
      {key: 'winRateAbbr', codes: ['report.field.winRate.abbr']},
      {key: 'impressionsPerUniqueUser', codes: ['impressionsPerUniqueUser', 'report.field.impressionsPerUniqueUser', 'reportTemplate.option.impressionsPerUniqueUser', 'chart.statType.impressionsPerUniqueUser', 'chart.statType.report.field.impressionsPerUniqueUser']},
      {key: 'impressionsPerUniqueUserAbbr', codes: ['report.field.impressionsPerUniqueUser.abbr']},
      {key: 'fees', codes: ['fee', 'report.field.fee', 'reportTemplate.option.fee', 'chart.statType.fee', 'chart.statType.report.field.fee']},
      {key: 'feesAbbr', codes: ['report.field.fee.abbr']},
      {key: 'techFee', codes: ['techFee', 'report.field.techFee', 'reportTemplate.option.techFee', 'chart.statType.techFee', 'chart.statType.report.field.techFee']},
      {key: 'techFeeAbbr', codes: ['report.field.techFee.abbr']},
      {key: 'creativeTagFee', codes: ['creativeTagFee', 'report.field.creativeTagFee', 'reportTemplate.option.creativeTagFee', 'chart.statType.creativeTagFee', 'chart.statType.report.field.creativeTagFee']},
      {key: 'creativeTagFeeAbbr', codes: ['report.field.creativeTagFee.abbr']},
      {key: 'creativeFormatFee', codes: ['creativeFormatFee', 'report.field.creativeFormatFee', 'reportTemplate.option.creativeFormatFee', 'chart.statType.creativeFormatFee', 'chart.statType.report.field.creativeFormatFee']},
      {key: 'creativeFormatFeeAbbr', codes: ['report.field.creativeFormatFee.abbr']},
      {key: 'dataFee', codes: ['dataFee', 'report.field.dataFee', 'reportTemplate.option.dataFee', 'chart.statType.dataFee', 'chart.statType.report.field.dataFee']},
      {key: 'dataFeeAbbr', codes: ['report.field.dataFee.abbr']},
      {key: 'supplementaryFee', codes: ['supplementaryFee', 'report.field.supplementaryFee', 'reportTemplate.option.supplementaryFee', 'chart.statType.supplementaryFee', 'chart.statType.report.field.supplementaryFee']},
      {key: 'supplementaryFeeAbbr', codes: ['report.field.supplementaryFee.abbr']},
    ];

    let Profile = function(tagId) {
        this.networkId = '';
        this.tagId = tagId;
        this.networkName = '';
        this.adnUiLogo = '';
        this.reportLogoUrl = '';
        this.availableCurrencies = [];
        this.rawLogTypes = [];
        this.defaultCurrency = 'EUR';
        this.defaultTierId = '';
        this.timeZone = undefined;
        this.advancedLogging = false;
        this.dataPlatform = false;
        this.advertisingPlatform = false;
        this.suiPlatform = false;
        this.marketplacePlatform = false;
        this.hybridMarketplacePlatform = false;
        this.transcodeOutputs = [];
        this.tiers = [];
        this.adnDefaults = angular.copy(defaultDefaults);
      },
      NetworkProfile,
      Network;

    Profile.prototype.save = function() {
      LocalStorage.obtain().setItem(NETWORK_PROFILE_STORAGE_KEY, this);
      return this;
    };

    Profile.fromObj = function(obj) {
      if (angular.isObject(obj)) {
        return _.create(Profile.prototype, _.defaults(obj));
      }
      return null;
    };

    let approvedLineItemStates = ["PROPOSED", "SUBMITTED", "RESERVED", "APPROVED", "PAUSED", "STOPPED"],
      approvedDedupOptions = ["NONE", "CREATIVE", "LINE_ITEM"];

    let sanitiseProfileDefaults = function(dataObj) {
      if (dataObj.lineItemUserState !== '' && (!dataObj.lineItemUserState || approvedLineItemStates.indexOf(dataObj.lineItemUserState) < 0)) {
        dataObj.lineItemUserState = defaultLineItemState;
      }
      if (dataObj.marketplaceLineItemUserState !== '' && (!dataObj.marketplaceLineItemUserState || approvedLineItemStates.indexOf(dataObj.marketplaceLineItemUserState) < 0)) {
        dataObj.marketplaceLineItemUserState = defaultMarketplaceLineItemState;
      }
      if (dataObj.lineItemDeduplicationLevel !== '' && (!dataObj.lineItemDeduplicationLevel || approvedDedupOptions.indexOf(dataObj.lineItemDeduplicationLevel) < 0)) {
        dataObj.lineItemDeduplicationLevel = defaultDeduplicationLevel;
      }
      if (dataObj.lineItemDaysApart !== 0 && (!dataObj.lineItemDaysApart || !_.isFinite(dataObj.lineItemDaysApart) || dataObj.lineItemDaysApart < 0 || dataObj.lineItemDaysApart > 1000)) {
        dataObj.lineItemDaysApart = defaultLineItemDaysApart;
      }
      if (!dataObj.geoZoom || !_.isFinite(dataObj.geoZoom) || dataObj.geoZoom < 1 || dataObj.geoZoom > 20) {
        dataObj.geoZoom = defaultGeoZoom;
      }
      if (dataObj.geoLat !== 0 && (!dataObj.geoLat || !_.isFinite(dataObj.geoLat))) {
        dataObj.geoLat = defaultGeoLat;
      }
      if (dataObj.geoLng !== 0 && (!dataObj.geoLng || !_.isFinite(dataObj.geoLng))) {
        dataObj.geoLng = defaultGeoLng;
      }
      if (!_.isObject(dataObj.cpm) || !_.isFinite(dataObj.cpm.amount) || dataObj.cpm.amount < 0) {
        dataObj.cpm = "";
      }
      if (!_.isFinite(dataObj.startDayCalendar) || dataObj.startDayCalendar < 0 || dataObj.startDayCalendar > 6) {
        dataObj.startDayCalendar = defaultStartDayCalendar;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'objectiveFields')) {
        dataObj.objectiveFields = DEFAULT_OBJECTIVE_FIELDS;
      }
      // fixing rename of CLICKS to CLICK
      if (Object.prototype.hasOwnProperty.call(dataObj.objectiveFields, 'CLICKS')) {
        dataObj.objectiveFields.CLICK = dataObj.objectiveFields.CLICKS;
        delete dataObj.objectiveFields.CLICKS;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'marketplaceObjectiveFields')) {
        dataObj.marketplaceObjectiveFields = DEFAULT_MARKETPLACE_OBJECTIVE_FIELDS;
      }
      // fixing rename of CLICKS to CLICK
      if (Object.prototype.hasOwnProperty.call(dataObj.marketplaceObjectiveFields, 'CLICKS')) {
        dataObj.marketplaceObjectiveFields.CLICK = dataObj.marketplaceObjectiveFields.CLICKS;
        delete dataObj.marketplaceObjectiveFields.CLICKS;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'bidSpecificationFields')) {
        dataObj.bidSpecificationFields = DEFAULT_BID_SPECIFICATION_FIELDS;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'marketplaceBidSpecificationFields')) {
        dataObj.marketplaceBidSpecificationFields = DEFAULT_MARKETPLACE_BID_SPECIFICATION_FIELDS;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'defaultCreativeName')) {
        dataObj.defaultCreativeName = defaultCreativeName;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'appSectionVisibility')) {
        dataObj.appSectionVisibility = appSectionVisibility;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'rateLimitScope')) {
        dataObj.rateLimitScope = defaultRateLimitScope;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'rateLimitTranslations')) {
        dataObj.rateLimitTranslations = defaultRateLimitTranslations;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'showCpmFees')) {
        dataObj.showCpmFees = false;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'namedLocations')) {
        dataObj.namedLocations = [];
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'showExternalReference')) {
        dataObj.showExternalReference = false;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'deliveryCurve')) {
        dataObj.deliveryCurve = defaultDeliveryCurve;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'marketplaceDeliveryCurve')) {
        dataObj.marketplaceDeliveryCurve = defaultMarketplaceDeliveryCurve;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'iabCategoryOnLineItem')) {
        dataObj.iabCategoryOnLineItem = defaultIabCategoryOnLineItem;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'defaultIgnoreMarketplaceTargeting')) {
        dataObj.defaultIgnoreMarketplaceTargeting = defaultIgnoreMarketplaceTargeting;
      }
      if (!Object.prototype.hasOwnProperty.call(dataObj, 'runReportsOptions') || !_.isArray(dataObj.runReportsOptions)) {
        dataObj.runReportsOptions = defaultRunReportsOptions;
      }
      if (!dataObj.translations) {
        dataObj.translations = defaultTranslations;
      }
      if (!dataObj.rateLimitFields) {
        dataObj.rateLimitFields = _.cloneDeep(dataObj.objectiveFields);
      }
      _.forEach(defaultTranslations, function(val, key) {
        dataObj.translations[key] = dataObj.translations[key] || val;
      });
    };

    let instance;
    let simpleRefresh = function() {
      instance = Profile.fromObj(LocalStorage.obtain().getItem(NETWORK_PROFILE_STORAGE_KEY));
    };
    simpleRefresh();

    Profile.prototype.refresh = function() {
      simpleRefresh();
      NetworkProfile = NetworkProfile || $injector.get('NetworkProfile');
      Network = Network || $injector.get('Network');
      const refreshPromises = [];
      refreshPromises.push(Network.get({id: instance.tagId}).$promise.then(function(network) {
        instance.availableCurrencies = network.availableCurrencies;
        instance.networkName = network.name;
        instance.defaultCurrency = network.defaultCurrency;
        instance.defaultTierId = _.get(network.defaultTier, 'id');
        instance.tiers = network.tiers;
        instance.timeZone = network.timeZone;
        instance.reportLogoUrl = network.reportLogoUrl;
        instance.advancedLogging = network.advancedLogging;
        instance.dataPlatform = network.dataPlatform;
        instance.advertisingPlatform = network.advertisingPlatform;
        instance.suiPlatform = network.suiPlatform;
        instance.defaultCpmCostModel = network.defaultCpmCostModel;
        instance.paymentProvider = network.paymentProvider;
        instance.marketplacePlatform = network.marketplacePlatform;
        instance.hybridMarketplacePlatform = network.hybridMarketplacePlatform;
        instance.requiresUserConsent = network.requiresUserConsent;
        instance.rawLogTypes = network.rawLogTypes;
        instance.audienceFees = network.audienceFees;
        instance.tagId = network.tagId;
        instance.networkId = network.id;
        if (network.transcodeConfig && network.transcodeConfig.enabled) {
          instance.transcodeOutputs = _.keys(network.transcodeConfig.outputs);
        } else {
          instance.transcodeOutputs = [];
        }
        instance.save();
      }));
      refreshPromises.push(NetworkProfile.get({id: instance.tagId}).$promise.then(function(networkProfile) {
        instance.adnUiLogo = _.get(networkProfile, ['profile', 'adnUiLogo']);
        instance.adnDefaults = _.assign({}, _.get(networkProfile, ['profile', 'adnDefaults']) || {});
        sanitiseProfileDefaults(instance.adnDefaults);
        instance.save();
      }));
      return $q.all(refreshPromises).then(function() {
        instance.save();
      });
    };

    return {
      create: function(lup) {
        instance = new Profile(lup.defaultNetworkTagId);
        instance.save();
        return instance.refresh();
      },
      destroy: function() {
        instance = null;
        return LocalStorage.obtain().remove(NETWORK_PROFILE_STORAGE_KEY);
      },
      get: function(prop) {
        simpleRefresh();
        return prop ? _.get(instance, prop) : instance;
      },
      getNetworkId: function() {
        return this.get('tagId');
      },
      getNetworkTag: function() {
        return this.get('tagId');
      },
      isDataPlatform: function() {
        return this.get('dataPlatform');
      },
      isAdvertisingPlatform: function() {
        return this.get('advertisingPlatform');
      },
      isSuiPlatform: function() {
        return this.get('suiPlatform');
      },
      isMarketplacePlatform: function() {
        return this.get('marketplacePlatform') || this.get('hybridMarketplacePlatform');
      },
      isPureMarketplacePlatform: function() {
        return this.get('marketplacePlatform');
      },
      isGdprEnabled: function() {
        return this.get('requiresUserConsent');
      },
      isDomainCharts: function() {
        const networkId = this.get('networkId');
        return _.indexOf(['adsolutions'], networkId) > -1;
      },
      isExperimental: function() {
        const networkId = this.get('networkId');
        return _.indexOf(["winning", "adnuntius", "da_trippers", "try_opt", "dev_network", "network_1", "buyers_network"], networkId) > -1;
      },
      impsOnAxProductRateLimits: function() {
        const networkId = this.get('networkId');
        return _.indexOf(["buyers_network", "eet_group"], networkId) > -1;
      },
      isOrderObjectives: function() {
        const networkId = this.get('networkId');
        return _.indexOf(['adnuntius', 'bitshift_internal_network', 'advertising_only', 'dev_network', 'network_1', 'buyers_network', 'dem', 'try_opt', 'lws1', 'vart_oslo', 'yyq8', 'b26fe'], networkId) > -1;
      },
      isMarketplaceAdvertiserRateLimits: function() {
        const networkId = this.get('networkId');
        return _.indexOf(['adnuntius', 'bitshift_internal_network', 'dev_network', 'network_1', 'try_opt'], networkId) > -1;
      },
      isCreativeCopyrightStatus: function() {
        const networkId = this.get('networkId');
        return _.indexOf(["da_trippers", "vpg3"], networkId) > -1;
      },
      getTranscodeOutputs: function() {
        return this.get('transcodeOutputs');
      },
      set: function(prop, value) {
        _.set(instance, prop, value);
        return instance.save();
      },
      showVideoUpload: function() {
        const networkId = this.get('networkId');
        return _.indexOf(['adnuntius', 'bitshift_internal_network', 'dev_network', 'network_1', 'buyers_network', '2ac7c', 'nordsjo'], networkId) > -1;
      },
      sanitiseProfileData: function(dataObj) {
        if (!_.isObject(dataObj)) {
          $log.warn("Should have an object to sanitise. Instead have: ", dataObj);
          return;
        }
        sanitiseProfileDefaults(dataObj);
      },
      hasObjectSource: function() {
        return this.isSuiPlatform() || this.isMarketplacePlatform();
      },
      getDefaults: function() {
        const profileDefaults = _.get(instance, 'adnDefaults') || {};
        sanitiseProfileDefaults(profileDefaults);
        return profileDefaults;
      },
      isVerificationCheckNetwork: function(hasManageSystem) {
        return _.includes(['165438', '1287', 'f', '49', 'dev_network', '8ff93', 'fa357', 'b7462', 'df930'], this.getNetworkTag()) && (!this.isPureMarketplacePlatform() || hasManageSystem);
      },
      getPaymentProvider: function() {
        return this.get("paymentProvider") || 'PAYPAL';
      },
      getAudienceFees: function() {
        return this.get("audienceFees") || {};
      },
      getDefaultCpmCostModel: function() {
        return this.get("defaultCpmCostModel");
      },
      canSeeReachCalendars: function() {
        const networkId = this.get('networkId');
        return _.indexOf(["syn", "zm1p", "3slk"], networkId) > -1;
      },
      getNetworkMarketplaceTranslation: function(key) {
        if (!instance || !instance.marketplacePlatform) {
          return;
        }
        return (_.find(networkTransKeys, function(transKey) {
          return transKey.codes.indexOf(key) > -1;
        }) || {translation: ""}).translation;
      },
      getNetworkTranslations: function(key) {
        const transKey = _.find(transKeys, function(transKey) {
          return transKey.codes.indexOf(key) > -1;
        }) || {key: undefined};
        const networkTranslation = _.get(this.getDefaults(), ['translations', transKey.key]) || this.getNetworkMarketplaceTranslation(key);
        if (!networkTranslation) {
          return networkTranslation;
        }
        const networkTranslationSuffixed = networkTranslation + (transKey.suffix || "");
        if (!transKey.singular || networkTranslationSuffixed.slice(-1) !== "s") {
          return networkTranslationSuffixed;
        }
        return networkTranslationSuffixed.slice(0, networkTranslationSuffixed.length - 1);
      }
    };
  });

export default MODULE_NAME;