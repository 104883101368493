/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import template from './report-translation-copy-modal.html';

import standardActionsModule from '../common/standard-actions-module';
import { actionsHelper } from "../common/controller/actions-helper";

const MODULE_NAME = 'report-translation-actions-controller';

angular.module(MODULE_NAME, [standardActionsModule])

  .controller('ReportTranslationActionsCtrl', function($q, toastr, $stateParams, $state, $uibModal, model, modelComms, readOnly) {
    const ctrl = this;

    actionsHelper(ctrl, model, modelComms, readOnly, $stateParams, $uibModal, $q, toastr, $state, template, {
      url: 'app.report-translations.report-translation',
      lcase: 'reportTranslation',
      ucase: 'ReportTranslation'
    });
  });

export default MODULE_NAME;