/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {OBJECT_TYPE} from "../common/constants/object-type";

import objectStateParamsFilter from '../common/filters/object-type-state-params-filter';
import standardFormModule from '../common/standard-form-module';
import { ResourceHelper } from "../../components/util/resource-helper";
import LocalNetworkProfile from "../../components/session/local-network-profile";

const MODULE_NAME = 'search-controller';

angular.module(MODULE_NAME, [standardFormModule, objectStateParamsFilter, LocalNetworkProfile])

  .controller('SearchCtrl', function($scope, $state, $stateParams, $location, searchCoordinator, adnObjectTypeStateParamsFilter, LocalNetworkProfile) {
    const ctrl = this;
    ctrl.term = ResourceHelper.uiSrefUpdater($stateParams.q);
    ctrl.labels = ResourceHelper.uiSrefUpdater($stateParams.labels);
    ctrl.rateControlLabels = $stateParams.rateControlLabels;
    ctrl.aggregations = searchCoordinator.aggregations;
    ctrl.includeDeleted = _.get($stateParams, 'objectState', '').indexOf("HIDDEN") > -1;
    ctrl.isPureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();

    let syncSearch = function() {
      if (ctrl.syncing) {
        return;
      }
      ctrl.syncing = true;
      let params = $location.search();
      ctrl.searchMeta.currentPage = params.page || 1;
      searchCoordinator.sync(params, ctrl.searchMeta).then(function() {
        ctrl.syncing = false;
        ctrl.term = params.q;
        ctrl.searchTerm = params.q;
        ctrl.labels = params.labels;
      }).catch(function() {
        ctrl.syncing = false;
      });
    };
    ctrl.searchMeta = {
      pageSize: 50,
      changePage: function() {
        ctrl.toggleFilter('page', ctrl.searchMeta.currentPage);
        syncSearch();
      },
      resolved: true
    };
    syncSearch();

    ctrl.searchResults = searchCoordinator.searchResults;
    ctrl.OBJECT_TYPE = OBJECT_TYPE;

    ctrl.submit = function(term) {
      $state.go($state.$current, {q: term}, {inherit: false});
    };

    ctrl.labelSearch = function(label) {
      $state.go($state.$current, adnObjectTypeStateParamsFilter(label), {inherit: false});
    };

    ctrl.includeDeletedChange = function() {
      let currentSearch = $location.search();
      currentSearch.objectState = ctrl.includeDeleted ? "HIDDEN;ACTIVE;INACTIVE" : "ACTIVE;INACTIVE";
      $location.search(currentSearch);
    };

    $scope.$on('$locationChangeSuccess', syncSearch);

    ctrl.toggleFilter = function(param, value) {
      let currentSearch = $location.search();
      if (param !== 'page') {
        delete currentSearch.page;
      }
      if (currentSearch[param] && (param !== 'page' || value < 2)) {
        delete currentSearch[param];
        $location.search(currentSearch);
        return;
      }
      $location.search(param, value);
    };

    ctrl.getFilterValue = function(aggregation) {
      let filter = $location.search()[aggregation.name];
      return _.get(_.find(aggregation.buckets, ['filter', filter]), 'name', filter);
    };
  });

export default MODULE_NAME;