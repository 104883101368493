/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import titleTemplate from '../base/default-title-template.html';
import editTemplate from './ad-tag-generator.html';

import standardModule from '../common/standard-module';
import adTagCtrl from './ad-tag-controller';

const MODULE_NAME = 'ad-tag-module';

angular.module(MODULE_NAME, [standardModule, adTagCtrl])

  .config(function($stateProvider) {
    $stateProvider.state('app.ad-tag', {
      url: '/ad-tag?auId&auW&auH&design&auName',
      views: {
        'content@app': {
          template: editTemplate,
          controller: 'AdTagCtrl',
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: "Ad Tag Generator and Tester"
      }
    });
  });

export default MODULE_NAME;