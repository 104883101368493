/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from "lodash";

import {OBJECT_TYPE} from "../common/constants/object-type";
import {Uuid} from "../../components/util/uuid";

import template from './library-creative-copy-modal.html';
import standardActionsModule from '../common/standard-actions-module';
import searchSelect from '../common/controller/search-select-component';
import linkTemplate from "./creative-copy-modal.html";

const MODULE_NAME = "library-creative-actions-controller";

angular.module(MODULE_NAME, [
  standardActionsModule,
  searchSelect
])
  .controller('LibraryCreativeActionsCtrl', function(toastr, $log, adnModalLoading, $stateParams, $state, $uibModal, model, modelComms, Creative, LibraryCreative, readOnly) {
    const ctrl = this;

    ctrl.objectId = model.id;
    ctrl.readOnly = readOnly;
    ctrl.isNew = $stateParams.isNew;

    ctrl.model = model;
    ctrl.modelComms = modelComms;

    ctrl.createCreative = function() {
      let modalInstance = $uibModal.open({
        windowClass: '',
        template: linkTemplate,
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          function defaultCreative(creativeName) {
            return "Creative linked to " + creativeName;
          }

          modelComms.addSubscriber(function(data, type) {
            if (!type) {
              modalCtrl.creative = {};
              modalCtrl.creative.name = defaultCreative(data.name);
              modalCtrl.creative.libraryCreative = data;
            }
          });

          modalCtrl.destination = true;

          modalCtrl.disabled = false;
          modalCtrl.validationErrors = [];
          modalCtrl.createSubmitted = function() {
            modalCtrl.disabled = true;
            adnModalLoading.applyModalLoading();

            const creative = Creative.create({
              id: Uuid.generate(),
              name: modalCtrl.creative.name,
              libraryCreative: modalCtrl.creative.libraryCreative
            });

            creative.savePartial(["id", "name", "libraryCreative"]).then(function(creative) {
              modalCtrl.disabled = false;
              modalInstance.close({destination: modalCtrl.destination, creativeId: creative.id});
              adnModalLoading.removeModalLoading();
            }, function(errors) {
              modalCtrl.disabled = false;
              modalCtrl.validationErrors = errors.data.errors;
              adnModalLoading.removeModalLoading();
            });
            modalInstance.result.then(function(result) {
              adnModalLoading.removeModalLoading();
              if (result.destination && result.creativeId) {
                $state.go('app.creatives.creative', {id: result.creativeId});
              }
              if (result.errors) {
                $log.warn(result.errors);
                toastr.warning("This creative could not be created.", "Creative Creation Failed");
              } else if (!result.destination) {
                toastr.success("A creative linking to this library creative was successfully created.", "Creative Created");
              }
            });
          };
        }
      });
    };


    ctrl.copy = function() {
      let modalInstance = $uibModal.open({
        windowClass: '',
        template: template,
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          modelComms.addSubscriber(function(data, type) {
            if (!type) {
              modalCtrl.creative = data;
              modalCtrl.creative.name = data.name + " (copy)";
            }
            if (_.get(type, 'type') === OBJECT_TYPE.LineItem.type) {
              modalCtrl.creative.lineItem = data;
            }
          });

          modalCtrl.destination = true;

          modalCtrl.disabled = false;
          modalCtrl.validationErrors = [];
          modalCtrl.copyCreative = function() {
            modalCtrl.disabled = true;
            adnModalLoading.applyModalLoading();

            let newId = Uuid.generate();
            LibraryCreative.copyCreative(modalCtrl.creative, modalCtrl.creative.lineItem, newId, modalCtrl.creative.userState).then(function() {
              modalCtrl.disabled = false;
              modalInstance.close({destination: modalCtrl.destination, creativeId: newId});
              adnModalLoading.removeModalLoading();
            }, function(errors) {
              modalCtrl.disabled = false;
              modalCtrl.validationErrors = errors.data.errors;
              adnModalLoading.removeModalLoading();
            });
          };
        }
      });
      modalInstance.result.then(function(result) {
        adnModalLoading.removeModalLoading();
        if (result.destination && result.creativeId) {
          $state.go('app.library-creatives.library-creative', {id: result.creativeId});
        }
        if (result.errors) {
          $log.warn(result.errors);
          toastr.warning("This library creative could not be copied.", "Library Creative Copy Failed");
        } else if (!result.destination) {
          toastr.success("A copy of this library creative was successfully created.", "Library Creative Copied");
        }
      });
    };
  });

export default MODULE_NAME;