/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import titleTemplate from '../../base/default-title-template.html';
import editTemplate from './network-edit.html';

import standardModule from '../../common/standard-module';
import networkCtrl from './network-controller';

const MODULE_NAME = 'network-module';

angular.module(MODULE_NAME, [standardModule, networkCtrl])

  .config(function($stateProvider) {
    $stateProvider.state('app.network', {
      url: '/network',
      parent: 'app.adm',
      resolve: {
        ResourceFactory: function(Network) {
          return Network;
        },
        network: function(LocalNetworkProfile, Network) {
          return Network.get({id: LocalNetworkProfile.getNetworkId()}).$promise;
        },
        networkProfile: function(LocalNetworkProfile, NetworkProfile) {
          return NetworkProfile.get({id: LocalNetworkProfile.getNetworkId()}).$promise;
        }
      },
      views: {
        'content@app': {
          template: editTemplate,
          controller: 'NetworkCtrl',
          controllerAs: 'resource'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: "Network",
        app: ['ADVERTISING', 'DATA']
      }
    });
  });

export default MODULE_NAME;