/**
 * Copyright © 2025 Adnuntius AS.
 */
import * as _ from 'lodash';
import * as moment from "moment";
import {LocalStorage} from "./local-storage";

export class LocalCreativePreview {
  static readonly USER_PROFILE_STORAGE_KEY = 'creative.preview';

  localPreviewData: any;

  private save() {
    LocalStorage.obtain().setItem(LocalCreativePreview.USER_PROFILE_STORAGE_KEY, this.localPreviewData);
  }

  private refresh() {
    const rawLocalStorage = LocalStorage.obtain().getItem(LocalCreativePreview.USER_PROFILE_STORAGE_KEY);
    this.localPreviewData = rawLocalStorage && _.isArray(rawLocalStorage) ? rawLocalStorage : [];
    this.localPreviewData = _.filter(this.localPreviewData, (d) => {
      return d.date && moment(d.date).add(30, 'minute').isAfter(moment());
    });
    this.save();
  }

  add(uuid, networkId, previewData) {
    this.refresh();
    this.localPreviewData.push({
      uuid: uuid,
      networkId: networkId,
      previewData: previewData,
      date: moment()
    });
    this.save();
  }

  get(uuid: string) {
    this.refresh();
    return _.find(this.localPreviewData, ['uuid', uuid]);
  }
}