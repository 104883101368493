/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import {Uuid} from "../../../components/util/uuid";

import template from './html-insertion.html';

const MODULE_NAME = "html-insertion-directive";

angular.module(MODULE_NAME, [])

  .directive('adnHtmlInsertion', function($timeout, $document) {
    return {
      restrict: 'A',
      scope: {},
      template: template,
      bindToController: {
        widgetType: '<adnHtmlInsertion'
      },
      controllerAs: 'widget',
      controller: function() {
        const ctrl = this;

        ctrl.$onInit = function() {
          ctrl.hasHtml = !!ctrl.widgetType.html;
          ctrl.previewId = Uuid.generate();

          ctrl.doPreview = function() {
            let previewContainer = angular.element($document[0].getElementById(ctrl.previewId));

            let existingIframe = previewContainer.find("iframe");
            if (existingIframe && existingIframe.length) {
              existingIframe.detach();
            }

            let ifr = $document[0].createElement('iframe');
            ifr.allowTransparency = true;

            ifr.setAttribute('style', 'width: 100%; min-height: ' + ctrl.widgetType.height + 'px; border: 2px solid #ddd; padding: 5px;');
            ifr.setAttribute('width', "100%");
            ifr.setAttribute('scrolling', 'yes');
            ifr.frameBorder = '0';
            previewContainer.append(ifr);

            let iframeDoc = ifr.contentDocument || ifr.contentWindow.document;
            iframeDoc.open();
            iframeDoc.write(angular.copy(ctrl.widgetType.html));
            iframeDoc.close();

            let iframeElement = angular.element(iframeDoc);
            let bodyElement = iframeElement.find("body");
            bodyElement.attr('style', 'margin: 0; padding: 0;');
          };

          $timeout(function() {
            if (ctrl.widgetType.html) {
              ctrl.doPreview();
            }
          }, 200);
        };
      }
    };
  });

export default MODULE_NAME;