/**
 * Copyright © 2025 Adnuntius AS.
 */
export const REPORT_OPTIONS = [
  {
    id: "network_1",
    langs: ['en', 'de']
  },
  {
    id: 'da_trippers',
    langs: ['en', 'de']
  },
  {
    id: 'russ',
    langs: ['en', 'de']
  }
];