/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {OBJECT_TYPE} from "../constants/object-type";

import listColumnSelectorComponent from "./list-column-selector-component";

import standardListModule from '../standard-list-module';

const MODULE_NAME = "resource-list-controller";

export function columnFinder(ctrl, columnField, param) {
  const columnArray = _.get(ctrl, [columnField], []);
  return _.isArray(columnArray) ? !!_.find(columnArray, function(item) {
    return item === param || _.get(item, ['param']) === param || _.get(item, ['label']) === param;
  }) : false;
}

angular.module(MODULE_NAME, [standardListModule, listColumnSelectorComponent])

  .controller('ResourceListCtrl', function($uibModal, $filter, $q, $translate, timezonesResource, Site, LineItem, Creative, LocalNetworkProfile, bulk, adnTimeShifter, $state, listExtension, listConfig, extension, adnListHelper, ResourceFactory, LocalUserPermissions, ChildResourceFactory, readOnly, orderByParam, localUserProfile, objectType) {
    const ctrl = this;

    if (bulk) {
      _.forEach(bulk, function(value, key) {
        ctrl[key] = value(ctrl, {$state: $state, $uibModal: $uibModal, $filter: $filter, $translate: $translate, $q: $q}, {TimeZones: timezonesResource, Site: Site, LineItem: LineItem, Creative: Creative}, {LocalNetworkProfile: LocalNetworkProfile, LocalUserPermissions: LocalUserPermissions, adnTimeShifter: adnTimeShifter, localUserProfile: localUserProfile});
      });
    }

    ctrl.columnFinder = function(column, param) {
      return columnFinder(ctrl, column, param);
    };

    _.forEach(listExtension, function(val, key) {
      if (_.isFunction(val)) {
        ctrl[key] = val;
      }
    });

    let extensionHandler = function(afterFill) {
      if (extension && _.isFunction(extension.func)) {
        extension.func(ctrl, afterFill);
      }
    };

    const config = _.assign({
      getDefaultOrderByParam: function() {
        return orderByParam;
      },
      baseTable: objectType.apiParam,
      afterFill: function() {
        extensionHandler(true);
      }
    }, listConfig || {});
    adnListHelper.setUpList(ctrl, ResourceFactory, readOnly, "full" + (_.get(listConfig, 'listIdOverride') || objectType.type) + "List", config);
    ctrl.canExpand = !!ChildResourceFactory;

    ctrl.perms = LocalUserPermissions.getAllVisPermissions();
    getListChildren(ctrl, adnListHelper, objectType, ChildResourceFactory);

    extensionHandler();
  });

export const getListChildren = function(ctrl, adnListHelper, objectType, ChildResourceFactory) {
  let ctrlChildren = {};
  ctrl.getChildren = function(item) {
    item.showChildren = !item.showChildren;
    if (!ctrlChildren[item.id]) {
      ctrlChildren[item.id] = {};

      let theConfig = {moreParams: {}};
      theConfig.moreParams[objectType.apiParam] = item.id;
      theConfig.childCall = true;
      if (objectType.childType) {
        theConfig.baseTable = OBJECT_TYPE[objectType.childType].apiParam;
      }
      adnListHelper.setUpBasicList(ctrlChildren[item.id], ChildResourceFactory, "full" + _.get(OBJECT_TYPE[objectType.childType], 'type') + "List", theConfig);
    }
  };
  ctrl.children = ctrlChildren;
};

export default MODULE_NAME;