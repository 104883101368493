/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import template from './external-ad-units.html';

import resources from '../../../components/api/resources/resources';

const MODULE_NAME = 'external-ad-unit-helper';

angular.module(MODULE_NAME, [resources])

  .component('adnExternalAdUnits', {
    bindings: {
      hook: '<',
      translation: '@'
    },
    template: template,
    controllerAs: 'ctrl',
    controller: function(ExternalAdUnit, searchResource) {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.extAdUnitTrans = ctrl.translation || 'adUnit';

        ctrl.hook.initExtAdUnits = function() {
          let externalAdUnitIds = _.map(ctrl.hook.model.externalAdUnits, 'id');
          ctrl.populatedExternalAdUnits = ctrl.hook.model.externalAdUnits;
          _.forEach(ctrl.populatedExternalAdUnits, function(eau) {
            eau.resolved = false;
          });
          ExternalAdUnit.query({ids: externalAdUnitIds, includeHidden: true, includeInactive: true}).$promise.then(function(extAdUnitsPage) {
            ctrl.populatedExternalAdUnits = extAdUnitsPage.results;
            _.forEach(ctrl.populatedExternalAdUnits, function(eau) {
              eau.currentId = eau.id;
              eau.resolved = true;
            });
          });
        };

        ctrl.hook.initExtAdUnits();


        ctrl.searchExternalAdUnits = function(searchElement) {
          searchResource.query({"q": searchElement.search, "types": "ExternalAdUnit"}).then(function(data) {
            ctrl.externalAdUnitResults = data.searchResults;
          });
        };

        ctrl.editableExternalAdUnit = function(index, isEditable) {
          let extAdUnit = ctrl.populatedExternalAdUnits[index];
          extAdUnit.editable = isEditable;
          if (!extAdUnit.name) {
            _.pullAt(ctrl.populatedExternalAdUnits, index);
          }
        };

        ctrl.extAdUnitSelected = function(index) {
          let selId = ctrl.populatedExternalAdUnits[index].currentId;
          let theAdUnit = _.find(ctrl.externalAdUnitResults, ['id', selId]);
          theAdUnit.currentId = selId;
          theAdUnit.resolved = false;
          ctrl.populatedExternalAdUnits[index] = theAdUnit;
          ExternalAdUnit.get({id: selId}, function(extAdUnit) {
            extAdUnit.currentId = selId;
            extAdUnit.resolved = true;
            ctrl.populatedExternalAdUnits[index] = extAdUnit;
          });
        };

        ctrl.addExternalAdUnit = function() {
          ctrl.hook.editsMade();
          ctrl.populatedExternalAdUnits = ctrl.populatedExternalAdUnits || [];
          ctrl.populatedExternalAdUnits.unshift({
            resolved: true,
            editable: true
          });
        };

        ctrl.deleteExternalAdUnit = function(index) {
          ctrl.hook.editsMade();
          _.pullAt(ctrl.populatedExternalAdUnits, index);
        };

        ctrl.hook.beforeSubmitExtAdUnit = function() {
          ctrl.hook.model.externalAdUnits = [];
          _.forEach(ctrl.populatedExternalAdUnits, function(eau) {
            if (!eau.id) {
              return;
            }
            ctrl.hook.model.externalAdUnits.push({
              id: eau.id
            });
          });
        };

        ctrl.hasExternalAdUnits = true;
        if (!ctrl.hook.model.externalAdUnits || !ctrl.hook.model.externalAdUnits.length) {
          ExternalAdUnit.query({pageSize: 1}).$promise.then(function(eauPage) {
            ctrl.hasExternalAdUnits = !!eauPage.results.length;
          });
        }
      };
    }
  });

export default MODULE_NAME;