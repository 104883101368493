/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

const MODULE_NAME = 'publisher-target-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnTargetingPublisherSummary', function() {
    return {
      restrict: 'A',
      template: '<strong ng-if="target.negated">NOT:&nbsp;</strong>{{publishers}}',
      scope: {
        targets: '=adnTargetingPublisherSummary'
      },
      link: function(scope) {
        scope.$watchCollection('targets', function(targets) {
          scope.target = targets;
        });
        scope.$watchCollection('target', function(target) {
          scope.publishers = _.map(target.earningsAccounts, function(pub) {
            return pub.name;
          }).join(', ');
        });
      }
    };
  });

export default MODULE_NAME;