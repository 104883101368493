/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiRouter from '@uirouter/angularjs';
import {OBJECT_TYPE} from "../../common/constants/object-type";

import resources from '../../../components/api/resources/resources';
import adnChecker from "../../../components/util/adn-checker";

const MODULE_NAME = "creative-checker-ctrl";

angular.module(MODULE_NAME, [
  uiRouter,
  resources,
  adnChecker,
])
  .controller('CreativeCheckerCtrl', function($state, $stateParams, localUserProfile, LocalNetworkProfile, adnChecker) {
    const ctrl = this;

    const app = localUserProfile.getCurrentApp();
    const profileKey = 'adnLastViewed' + LocalNetworkProfile.getNetworkId() + (app === 'ADVERTISING' ? '' : app);
    ctrl.lastViewed = _.map(_.get(localUserProfile.get(), ['profile', profileKey]), function(entry) {
      const objType = OBJECT_TYPE[entry.objectType];
      if (objType) {
        entry.objType = objType;
      }
      return entry;
    });
    ctrl.lastViewedObject = _.get(ctrl.lastViewed, [0]);
    ctrl.selectFrom = 'CUSTOM';

    ctrl.loaded = true;
    ctrl.objectId = $state.previousParams.id;
    ctrl.objectType = _.find(OBJECT_TYPE, function(ot) {
      return ot.stateName === $state.previousState.name;
    });
    ctrl.objectTypes = OBJECT_TYPE;
    ctrl.hashResults = true;

    ctrl.doCheck = function() {
      const id = ctrl.selectFrom === 'LAST_VIEWED' ? ctrl.lastViewedObject : ctrl.objectId;
      ctrl.loaded = false;
      adnChecker.checkId(id, ctrl.forceRerun, ctrl.hashResults, ctrl.ignoreScanHistory === 'IGNORE').then(function(data) {
        ctrl.loaded = true;

        ctrl.results = data.results;

        if (ctrl.results[0]) {
          ctrl.results[0].cookies = _.map(ctrl.results[0].scan.cookies, function(header) {
            if (_.isString(header)) {
              const split = header.split(String.fromCharCode(31));
              return {
                name: split[0],
                value: split[1],
                expires: split[2],
                days: split[3]
              };
            } else {
              return header;
            }
          });
        }
      });
    };
    if ($stateParams.creativeId) {
      ctrl.objectId = $stateParams.creativeId;
      ctrl.doCheck();
    }
  });

export default MODULE_NAME;