/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import standardAnalysisModule from '../common/standard-analysis-module';
import siteAccessModalTemplate from "./site-access-modal.html";
import _ from "lodash";

const MODULE_NAME = 'earnings-account-analysis';

angular.module(MODULE_NAME, [standardAnalysisModule])

  .controller('EarningsAccountAnalysisCtrl', function($stateParams, $state, $uibModal, model, Site, Team, adnModalLoading, adnListHelper, LocalUserPermissions, LocalNetworkProfile, modelComms) {
    const ctrl = this;
    ctrl.query = {};
    ctrl.model = angular.copy(model);

    ctrl.showAdvAccess = LocalNetworkProfile.isPureMarketplacePlatform();

    ctrl.isNew = $stateParams.isNew;
    ctrl.copySite = {site: null};
    adnListHelper.setUpBasicList(ctrl, Site, 'fullSitesForEarningsAccount', {
      moreParams: {earningsAccount: model.id}, afterFill: function() {
        ctrl.copySite.site = ctrl.items[0];
      }
    });

    ctrl.canSeeReports = LocalUserPermissions.getAnyVisibility().RUN_REPORTS === true;

    function siteGroupsQuery() {
      ctrl.teamReport = false;
      Site.query({groupByTeams: 'true', earningsAccount: model.id}, function(page) {
        ctrl.teamReport = page.results;
        _.forEach(ctrl.teamReport, function(team) {
          team.teamLimit = 10;
          team.siteLimit = 10;
          team.additionalTeamLimit = 10;
          team.removedTeamLimit = 10;
        });
      });
    }

    if (ctrl.showAdvAccess) {
      siteGroupsQuery();
    }

    ctrl.saveSite = function(promise) {
      promise.then(function(site) {
        ctrl.showSiteForm = false;
        if (!_.isArray(ctrl.items)) {
          ctrl.items = [];
        }
        ctrl.addItemToList(site);
      });
    };

    const theCountry = _.isArray(ctrl.model.countries) ? ctrl.model.countries[0] : null;
    ctrl.loadSiteForm = function() {
      ctrl.site = Site.create({
        earningsAccount: ctrl.model
      });
      if (theCountry) {
        ctrl.site.country = theCountry;
      }
      const ownerTeamId = _.get(ctrl.model, ['team', 'id']);
      if (ownerTeamId) {
        ctrl.site.ownerTeam = {id: ownerTeamId};
      }

      ctrl.showSiteForm = true;
    };

    ctrl.loadExternalSite = function() {
      $state.go("app.sites.new", {earningsAccountName: model.name, earningsAccountId: _.get(ctrl.model, ['id']), earningsAccountCountry: theCountry, earningsAccountOwnerTeamId: _.get(ctrl.model, ['team', 'id'])});
    };

    ctrl.onSiteBeforeSubmit = function() {
      if (!_.get(ctrl, ['copySite', 'site']) || _.isArray(ctrl.copySite.site.teams)) {
        return;
      }
      return Site.get({id: ctrl.copySite.site.id}, function(theSite) {
        ctrl.site.teams = theSite.teams;
      }).$promise;
    };

    ctrl.editAccess = function(teamReport) {
      $uibModal.open({
        template: siteAccessModalTemplate,
        size: 'lg',
        windowClass: '',
        resolve: {
          obj: function() {
            return ctrl.model;
          },
          matchedObjects: function() {
            return ctrl.matchedSites;
          }
        },
        controllerAs: 'siteAccessCtrl',
        controller: function($uibModalInstance) {
          const siteAccessCtrl = this;

          siteAccessCtrl.teamReport = teamReport;
          siteAccessCtrl.selTeams = _.map(teamReport.teams, 'id');

          Team.query({minimal: true}).$promise.then(function(page) {
            siteAccessCtrl.allNotAxPublisherTeams = _.filter(page.results, function(team) {
              return team.type !== 'AX_PUBLISHER' && team.type !== 'CREATIVE_AGENCY';
            });
          });

          siteAccessCtrl.selectTeams = function() {
            siteAccessCtrl.selTeams = _.map(siteAccessCtrl.allNotAxPublisherTeams, 'id');
          };

          siteAccessCtrl.deselectTeams = function() {
            siteAccessCtrl.selTeams = [];
          };

          siteAccessCtrl.saveAndClose = function() {
            const objectsForUpdating = _.map(siteAccessCtrl.teamReport.sites, function(site) {
              return {
                id: site.id,
                teams: siteAccessCtrl.selTeams
              };
            });
            Site.bulkSave(objectsForUpdating).$promise.then(function() {
              ctrl.disabled = false;
              $uibModalInstance.close();
              adnModalLoading.removeModalLoading();
            }, function(errors) {
              ctrl.disabled = false;
              siteAccessCtrl.validationErrors = errors.data.errors;
              adnModalLoading.removeModalLoading();
            });
          };
        }
      })
        .closed.then(function() {
        siteGroupsQuery();
      });
    };

    modelComms.addSubscriber(function(data, type) {
      if (!type && data.name) {
        ctrl.model = data;

        if (ctrl.isNew) {
          ctrl.isNew = false;
        }
      }
    });
  });

export default MODULE_NAME;