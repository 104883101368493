/**
 * Copyright © 2025 Adnuntius AS.
 */
import {Component, Input} from '@angular/core';

@Component({
  selector: 'env-banner',
  template: `<div class="warningBanner envBanner" *ngIf="env">Careful now &mdash; you're looking at: <span>{{env}}</span></div>`
})
export class EnvBannerComponent {
  @Input() env: string;
}
