/**
 * Copyright © 2025 Adnuntius AS.
 */
import * as _ from 'lodash';
import {LABELS_DIRECTIVE_INIT_SUFFIX} from "../constants/misc-constants";
import {SubmitCalls} from "../directives/submit-calls";

const objectStateToMethodMap = {
  'ACTIVE': '$activate',
  'HIDDEN': '$delete',
  'INACTIVE': '$archive'
};

export const adnFormHelper = {
  setUpForm: function(ctrl, model, config) {
    const fh = this;
    let form;

    config = config || {};
    config.afterInit = config.afterInit || _.noop;
    config.afterCancel = config.afterCancel || _.noop;
    config.afterSave = config.afterSave || _.noop;
    config.afterInitAfterSave = config.afterInitAfterSave || _.noop;
    config.afterFormSet = config.afterFormSet || _.noop;
    config.modelComms = config.modelComms || {modelUpdated: _.noop, relDataUpdated: _.noop};

    fh.model = model;

    ctrl.resetLabels = function() {
      fh.model.formattedLabels = _.map(fh.model.labels, function(label) {
        return {text: label};
      });
    };

    const init = config.replaceInit || function() {
      ctrl.model = _.cloneDeep(fh.model);

      _.forIn(ctrl, function(v, k) {
        if (_.endsWith(k, LABELS_DIRECTIVE_INIT_SUFFIX)) {
          v();
        }
      });

      config.afterInit();
      config.afterInitAfterSave();
      config.modelComms.modelUpdated(ctrl.model, ctrl.isNew);
    };

    ctrl.updateRelData = function(relData, type) {
      config.modelComms.relDataUpdated(relData, type);
    };

    ctrl.updateModelHappened = function() {
      config.modelComms.modelUpdated(ctrl.model);
    };

    ctrl.editsMade = function(editForm) {
      const theForm = editForm || form;
      if (theForm) {
        theForm.$setDirty();
      }
    };

    ctrl.isolateSubForm = function(subForm) {
      subForm.$setDirty = _.noop;
    };

    ctrl.resetFormState = function(editForm) {
      const theForm = editForm || form;
      theForm.$setPristine();
      theForm.validSubmission = false;
    };

    ctrl.cancel = config.replaceCancel || function(form) {
      init();
      ctrl.resetFormState(form);

      config.afterCancel(form);
    };

    ctrl.getUnderlyingModel = function() {
      return fh.model;
    };

    const saveMethod = function(resource) {
      _.set(ctrl.model, 'validationWarnings', resource.validationWarnings);
      _.set(ctrl.model, 'status', resource.status);
      fh.model = _.cloneDeep(ctrl.model);

      ctrl.isNew = false;
      config.afterSave(resource);
      config.afterInitAfterSave(resource);
      config.modelComms.modelUpdated(ctrl.model, ctrl.isNew);
    };

    ctrl.saveDirect = config.replaceSave || function(resource) {
      saveMethod(resource);
    };

    ctrl.save = config.replaceSave || function(promise) {
      promise.then(saveMethod);
    };

    ctrl.setForm = function(editForm) {
      if (!form) {
        // eslint-disable-next-line angular/timeout-service
        window.setTimeout(function () {
          SubmitCalls.updateFormWarnings(editForm, ctrl.model);
        }, 100);
      }
      form = editForm;
      config.afterFormSet();
    };

    ctrl.getForm = function() {
      return form;
    };

    ctrl.processingForm = function () {
      form.$setValidity("validation");
    };

    ctrl.successfulSave = function() {
      form.$setValidity('validation', true);
      form.validSubmission = true;
      form.$setPristine();
    };

    ctrl.failedSave = function() {
      form.$setValidity('validation', false);
      form.validSubmission = false;
      form.$setPristine();
    };

    config.modelComms.updateObjectState = function(oldModel, objectState) {
      const resultFunction = function(aResource) {
        ctrl.model.objectState = aResource.objectState;
        _.set(ctrl.model, 'validationWarnings', aResource.validationWarnings);
        _.set(ctrl.model, 'status', aResource.status);
      };
      return oldModel[objectStateToMethodMap[objectState]](resultFunction);
    };

    config.modelComms.updateObjectId = function(oldModel, newId, hasDuplicateCallback, isUniqueCallback) {
      const headModel = _.clone(oldModel);
      headModel.id = newId;
      headModel.$head(function(resp) {
        hasDuplicateCallback(resp);
      }).catch(function(resp) {
        isUniqueCallback(resp);
        ctrl.model.id = newId;
      });
    };

    init();
  }
};