/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from "lodash";
import ngFileUpload from 'ng-file-upload';
import assetHelper from '../../../util/asset-helper';
import {ApiConfig} from "../../api";
import {ASSET_ERROR} from "../resources-constants";

const MODULE_NAME = "mapping";

angular.module(MODULE_NAME, [ngFileUpload, assetHelper])

  .factory('FieldMapping', function(adnResource, $q, Upload) {
    const FieldMapping = adnResource('fieldmappings', {}, {}, 'FieldMapping');
    FieldMapping.create = function(defaults) {
      return new FieldMapping(_.merge({
      }, defaults));
    };
    FieldMapping.upload = function(data, uploadType) {
     if (!data || !(data.file || data.content)) {
       return $q.reject(ASSET_ERROR.NO_FILE);
     }
     return Upload.upload({
       url: ApiConfig.obtain().getVersionedUri([uploadType || 'fieldmappings']),
       data: data
     }).then(function(response) {
       return response;
     }).catch(function(err) {
       return $q.reject(err);
     });
   };
    return FieldMapping;
  });

export default MODULE_NAME;