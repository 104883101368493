/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

import recipientsTemplate from './recipients-modal.html';

import nextReportComponent from './next-report-component';
import reportUrlComponent from './report-url-component';
import standardFormModule from '../common/standard-form-module';
import searchSelect from '../common/controller/search-select-component';
import dateSelector from '../common/directives/form-controls/date-selector';
import reportHistoryComp from '../report/report-history-component';
import {ADN_REPORT_TYPES} from '../report/report-types';
import {EmailHelper} from "../../components/util/email";
import {adnFormHelper} from "../common/controller/form-helper";
import {LOCALES_FIRST_DAY} from "../common/constants/locales";

const MODULE_NAME = 'report-schedule-controller';

export function afterSaveAfterInitMods(ref, adnTimeShifter, timeZone, scheduleLocale) {
  const workingLocale = ref.locale || scheduleLocale;
  ref.firstDayOfWeek = LOCALES_FIRST_DAY[workingLocale] || (workingLocale.indexOf("_") > -1 ? 'MONDAY' : 'SUNDAY');
  ref.modOptions = 'DEFAULT';
  if (ref.recurrence && ((ref.recurrence === 'DAILY' && ref.periodTime) || (ref.recurrence === 'MONTHLY' && (ref.periodTime || ref.periodDayOfMonth)) || (ref.recurrence === 'WEEKLY' && (ref.periodTime || ref.periodDayOfWeek)))) {
    ref.modOptions = 'CUSTOM';
  }
  ref.modPeriodTime = ref.periodTime ?
    adnTimeShifter.timeShift(moment.tz("2020-02-01 " + ref.periodTime, timeZone).toDate()) :
    adnTimeShifter.timeShift(moment.tz(timeZone).startOf('day').toDate());

  ref.periodDayOfMonth = ref.periodDayOfMonth || 1;
  ref.periodDayOfWeek = ref.periodDayOfWeek || "MONDAY";
}

export function beforeSubmitMods(ref) {
  if (ref.modOptions === 'CUSTOM') {
    ref.periodTime = _.isDate(ref.modPeriodTime) ? moment(ref.modPeriodTime).format("HH:mm:ss") : "00:00:00";
    ref.periodDayOfMonth = _.isNumber(ref.periodDayOfMonth) ? ref.periodDayOfMonth : 1;
    ref.periodDayOfWeek = ref.periodDayOfWeek || 'MONDAY';
  } else {
    ref.periodTime = null;
    ref.periodDayOfMonth = null;
    ref.periodDayOfWeek = null;
  }
}

angular.module(MODULE_NAME, [standardFormModule, searchSelect, dateSelector, reportHistoryComp, nextReportComponent, reportUrlComponent])

  .controller('ReportScheduleCtrl', function($stateParams, $uibModal, adnTimeShifter, User, LocalUserPermissions, localUserProfile, LocalNetworkProfile, searchResource, model, modelComms, ReportTemplate) {
    const ctrl = this;
    ctrl.isNew = $stateParams.isNew;

    const scheduleLocale = localUserProfile.get('locale') || 'en_GB';

    ctrl.availableCurrencies  = LocalNetworkProfile.get('availableCurrencies');
    const preferredCurrency = localUserProfile.getPreferredCurrency();

    let combineEmails = function() {
      ctrl.model.recipientEmailAddressesCombined = (ctrl.model.recipientEmailAddresses || []).join(', ');
      const timeZone = LocalNetworkProfile.get('timeZone') || 'utc';
      afterSaveAfterInitMods(ctrl.model, adnTimeShifter, timeZone, scheduleLocale);
    };
    adnFormHelper.setUpForm(ctrl, model, {
      afterInit: function() {
        combineEmails();
        ctrl.model.currency = ctrl.model.currency || preferredCurrency || LocalNetworkProfile.get('defaultCurrency');
      },
      afterSave: combineEmails,
      modelComms: modelComms
    });

    if (ctrl.isNew) {
      ctrl.model.period = ADN_REPORT_TYPES.LINE_ITEM.defaultPeriod.id;
      ctrl.model.recurrence = 'WEEKLY';

      ctrl.model.timeZone = LocalNetworkProfile.get('timeZone') || 'UTC';
      ctrl.model.locale = scheduleLocale;

      ctrl.allAdvertisers = false;
    }

    ctrl.allAdvertisers = ctrl.allAdvertisers || _.get(ctrl.model.parameters || {}, 'ALL_ACTIVE', false);

    if (model.recipientUsers && model.recipientUsers.length > 0) {
      User.query({ids: _.map(model.recipientUsers, 'id')}, function(page) {
        let allUsers = page.results;
        _.forEach(ctrl.model.recipientUsers, function(ru) {
          let match = _.find(allUsers, ['id', ru.id]);
          ru.displayName = match.displayName;
        });
      });
    }

    let perms = LocalUserPermissions.getAllNetworkPermissions();
    let anyPerms = LocalUserPermissions.getAllVisPermissions();

    const networkDefaults = LocalNetworkProfile.getDefaults();
    const canSeeAdvertiserReports = networkDefaults.runReportsOptions.indexOf("ADVERTISER_REPORTS") > -1;
    ReportTemplate.query(function(page) {
      ctrl.reportTemplates = _.filter(page.results, function(rt) {
        if ((rt.reportType === 'LINE_ITEM' || rt.reportType === 'LINE_ITEM_LIST') && (anyPerms.RUN_REPORTS || anyPerms.MANAGE_ADVERTISING)) {
          return true;
        } else if (rt.reportType === 'SALESPERSON' && (anyPerms.RUN_REPORTS || anyPerms.MANAGE_ADVERTISING)) {
          return true;
        } else if (rt.reportType === 'ADVERTISER' && (perms.MANAGE_BUSINESS || (anyPerms.RUN_REPORTS && canSeeAdvertiserReports))) {
          return true;
        } else if (rt.reportType === 'NETWORK' && (perms.MANAGE_SYSTEM)) {
          return true;
        } else if (rt.reportType === 'AD_UNIT' && (anyPerms.RUN_REPORTS || anyPerms.MANAGE_PUBLISHING)) {
          return true;
        } else if (rt.reportType === 'SITE' && (anyPerms.RUN_REPORTS || anyPerms.MANAGE_PUBLISHING)) {
          return true;
        } else if (rt.reportType === 'SITE_GROUP' && (anyPerms.MANAGE_BUSINESS)) {
          return true;
        } else if (rt.reportType === 'EARNINGS_ACCOUNT' && (anyPerms.MANAGE_BUSINESS)) {
          return true;
        }
        return false;
      });

      const selRepTemplate = _.find(ctrl.reportTemplates, ['id', _.get(model, ['reportTemplate', 'id'])]);
      if (!selRepTemplate) {
        return;
      }
      ctrl.model.reportTemplate = selRepTemplate;
    });

    ctrl.editRecipients = function() {
      let modalInstance = $uibModal.open({
        windowClass: '',
        size: 'lg',
        template: recipientsTemplate,
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          User.query({minimal: true}, function(page) {
            modalCtrl.allUsers = page.results;
          });

          modalCtrl.selUsers = _.map(ctrl.model.recipientUsers, 'id');

          modalCtrl.save = function() {
            ctrl.model.recipientUsers = _.filter(_.map(modalCtrl.selUsers, function(su) {
              return _.find(modalCtrl.allUsers, ['id', su]);
            }), function(su) {
              return su && !!su.id;
            });

            if (!ctrl.isNew) {
              ctrl.model.savePartial('recipientUsers').then(function(savedSchedule) {
                _.set(ctrl.model, 'validationWarnings', savedSchedule.validationWarnings);
                modalInstance.close({success: true});
              }, function() {
                modalInstance.close({success: false});
              });
            } else {
              ctrl.editsMade();
              modalInstance.close();
            }
          };
        }
      });
      modalInstance.result.then(function(result) {
        ctrl.recipientEmailsSaved = result;
      });
    };

    ctrl.searchLineItems = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "LineItem"}).then(function(data) {
        ctrl.lineItemResults = data.searchResults;
      });
    };

    ctrl.searchAdUnits = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "AdUnit"}).then(function(data) {
        ctrl.adUnitResults = data.searchResults;
      });
    };

    ctrl.searchOrders = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "Order"}).then(function(data) {
        ctrl.orderResults = data.searchResults;
      });
    };

    ctrl.searchSites = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "Site"}).then(function(data) {
        ctrl.siteResults = data.searchResults;
      });
    };

    ctrl.searchSiteGroups = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "SiteGroup"}).then(function(data) {
        ctrl.siteGroupResults = data.searchResults;
      });
    };

    ctrl.searchSalesperson = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "User"}).then(function(data) {
        ctrl.salespersonResults = data.searchResults;
      });
    };

    ctrl.searchEarningsAccounts = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "EarningsAccount"}).then(function(data) {
        ctrl.earningsAccountResults = data.searchResults;
      });
    };

    ctrl.searchAdvertisers = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "Advertiser"}).then(function(data) {
        ctrl.advertiserResults = data.searchResults;
      });
    };

    ctrl.onBeforeSubmit = function() {
      ctrl.model.parameters = ctrl.model.parameters || {};

      _.forEach(ADN_REPORT_TYPES, function(rt) {
        delete ctrl.model.parameters[rt.idKey];
      });
      delete ctrl.model.parameters.relatedLineItem;
      delete ctrl.model.parameters[ADN_REPORT_TYPES.ADVERTISER.allActiveFlag];

      let theReportType = ADN_REPORT_TYPES[ctrl.model.reportTemplate.reportType];
      if (theReportType === ADN_REPORT_TYPES.ADVERTISER && ctrl.allAdvertisers) {
        // Advertiser reports support the special "ALL_ACTIVE" object selector
        ctrl.model.parameters[ADN_REPORT_TYPES.ADVERTISER.allActiveFlag] = true;
      } else {
        if (theReportType === ADN_REPORT_TYPES.LINE_ITEM) {
          ctrl.model.parameters[theReportType.idKey] = ctrl.model.lineItem.id;
          ctrl.model.parameters.relatedLineItem = [{id: ctrl.model.lineItem.id}];
        } else if (theReportType === ADN_REPORT_TYPES.NETWORK) {
          ctrl.model.parameters[theReportType.idKey] = LocalNetworkProfile.getNetworkId();
        } else {
          ctrl.model.parameters[theReportType.idKey] = ctrl.model[theReportType.objKey].id;
        }
      }

      beforeSubmitMods(ctrl.model);

      ctrl.model.recipientEmailAddresses = _.filter(_.map((ctrl.model.recipientEmailAddressesCombined || "").split(','), function(email) {
        return _.trim(email);
      }), function(email) {
        return email.length > 0 && EmailHelper.isEmail(email);
      });
    };
  });

export default MODULE_NAME;