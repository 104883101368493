/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';

import template from './pagination.html';

const MODULE_NAME = "pagination-component";

angular.module(MODULE_NAME, [
  translate
])

  .component('adnPagination', {
    template: template,
    bindings: {
      list: '<'
    },
    controllerAs: 'ctrl',
    controller: () => {
    }
  });

export default MODULE_NAME;