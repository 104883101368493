/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import editTemplate from './report-translation-edit.html';
import copyTemplate from '../common/copy-actions.html';
import listTemplate from './report-translation-list.html';

import standardModule from '../common/standard-module';
import reportTranslationController from './report-translation-controller';
import reportTranslationActions from './report-translation-actions-controller';

const MODULE_NAME = 'report-translation-module';

angular.module(MODULE_NAME, [standardModule, reportTranslationController, reportTranslationActions])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('report-translations.report-translation', 'ReportTranslation', 'app.rep', 'app', listTemplate);

    $stateProvider.state(rh.listPath(), rh.list())
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: 'ReportTranslationCtrl',
            controllerAs: 'resource',
            template: editTemplate
          },
          'context@app': {
            controller: 'ReportTranslationActionsCtrl',
            controllerAs: 'ctrl',
            template: copyTemplate
          }
        }
      }, 'ReportTranslation'));
  });

export default MODULE_NAME;